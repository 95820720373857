import React from "react";
import util from "../Utilities/UtilityHelper";
import moment from "moment";
import sc from "./../services/stringconst";
import { useState } from "react";
import { Client } from "../services/apiclient";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import auth from "../services/authservice";
import PaymentDetails from "./paymentdetails";

const Payment = (props) => {
  const client = new Client(null, null);
  const navigate = new useNavigate();
  const location = useLocation();

  let scheduleItem = location.state.scheduleItem;
  let payments = [];
  payments = scheduleItem.payments;

  const [scheduleStateData, setScheduleStateData] = useState();

  let loanData = location.state.stateData.loan;
  let loanPackage = location.state.stateData.loanPackage;
  let scheduleItemAmount = location.state.scheduleItem.amount;
  let isLender = location.state.stateData.loanDisplayTab === 2;
  let paymentDueDate = location.state.scheduleItem.dueDate;

  useEffect(() => {
    if (!location.state) {
      navigate(sc.homeroute);
      return;
    }

    if (location.state.stateData) {
      setScheduleStateData(location.state.stateData);
    }
  }, []);

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a
            onClick={() =>
              navigate(sc.loanrepaymentscheduleroute, {
                state: scheduleStateData,
              })
            }
            className="headerButton goBack"
          >
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div>Payments</div>
      </div>

      <div id="appCapsule" className="extra-header-active full-height">
        <div style={{ height: "100%", verticalAlign: "baseline", top: "0px" }}>
          <div className="card">
            <div className="accordion" id="accordionExample4">
              {payments.map((item) => (
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    style={{ background: "#f9f9f9" }}
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={"#accordionc1" + item._id}
                    >
                      {item.status === sc.PaymentSent && (
                        <ion-icon
                          style={{ color: "orange" }}
                          name="arrow-forward-outline"
                        ></ion-icon>
                      )}
                      {item.status === sc.PaymentConfirmed && (
                        <ion-icon
                          style={{ color: "green" }}
                          name="checkmark-outline"
                        ></ion-icon>
                      )}
                      {item.status === sc.PaymentRejected && (
                        <ion-icon
                          style={{ color: "red" }}
                          name="close-outline"
                        ></ion-icon>
                      )}
                      <strong>
                        {" "}
                        {util.commaCurrencyFormatter(item.amount)}
                      </strong>
                    </button>
                  </h2>
                  <div
                    id={"accordionc1" + item._id}
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample4"
                  >
                    <PaymentDetails
                      loanData={loanData}
                      ShowAlert={props.ShowAlert}
                      paymentDetails={item}
                      scheduleItemAmount={scheduleItemAmount}
                      isLender={isLender}
                      repaymentScheduleDueDate={paymentDueDate}
                      loanPackage={loanPackage}
                      stateData={location.state.stateData}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Payment;
