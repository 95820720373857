import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import sc from "../../services/stringconst";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import auth from "../../services/authservice";
import BankAccountDto from "../../models/Shared/BankAccountDto";

type Props = {
  account: BankAccountDto;
  removeAccountModal(account: BankAccountDto): any;
};
const Account = ({ account, removeAccountModal }: Props) => {
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {}, []);

  const verifyUserAccount = () => {
    navigate(sc.identificationverificationroute, {
      state: { accountNumber: account.accountNumber },
    });
  };
  const removeUserAccount = () => {
    removeAccountModal(account);
  };

  return (
    <>
      <div id="appCapsule" className="extra-header-active full-height">
        <div className="card">
          <div className="card-body">
            <ul className="listview flush transparent image-listview">
              <li>
                <div className="item">
                  <div className="in">
                    <div>
                      {" "}
                      <span className="text-muted">
                        Name &nbsp;&nbsp;&nbsp;&nbsp;
                      </span>{" "}
                      {account.accountName}
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div className="item">
                  <div className="in">
                    <div>
                      {" "}
                      <span className="text-muted">Number &nbsp;</span>{" "}
                      {account.accountNumber}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <>
            <a className="btn btn-danger" onClick={removeUserAccount}>
              Remove
            </a>
          </>
        </div>
      </div>
    </>
  );
};
export default Account;
