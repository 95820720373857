import React, { useState, useEffect } from "react";
import auth from "../../services/authservice";
import { useNavigate } from "react-router-dom";
import sc from "../../services/stringconst";
import {
  IoBusinessOutline,
  IoChevronBackOutline,
  IoKeyOutline,
  IoListOutline,
  IoLockClosedOutline,
  IoWalletOutline,
} from "react-icons/io5";
import { useSelector } from "react-redux";
import { getUser } from "../../store/store";

const Settings = () => {
  let navigate = useNavigate();
  const user = useSelector(getUser);

  useEffect(() => {}, []);

  const back = () => {
    navigate(-1);
  };

  const setLoginPin = () => {
    navigate(sc.setloginpinroute);
  };

  const changePassword = () => {
    navigate(sc.changepasswordroute);
  };
  const gotoFacilityTypeList = () => {
    navigate(sc.facilitytypelistroute);
  };
  const gotoBankAccounts = () => {
    navigate(sc.bankaccountsroute);
  };
  const gotoPropertyTypes = () => {
    navigate(sc.propertytypesroute);
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <IoChevronBackOutline size={sc.IconSize15} /> Back
          </a>
        </div>
        <div className="text-muted-medium">Settings</div>
      </div>
      <div id="appCapsule" className="extra-header-active full-height">
        <div style={{ marginTop: "1em" }}>
          <ul
            className="listview image-listview transparent flush"
            style={{ cursor: "pointer" }}
          >
            <li onClick={setLoginPin}>
              <div className="item">
                <div className="icon-box">
                  <IoLockClosedOutline size={sc.IconSize25} />
                </div>
                <div className="in">
                  <div>Set Login PIN</div>
                </div>
              </div>
            </li>
            <li onClick={changePassword}>
              <div className="item">
                <div className="icon-box">
                  <IoKeyOutline size={sc.IconSize25} />
                </div>
                <div className="in">
                  <div>Change Password</div>
                </div>
              </div>
            </li>
            {user.realtorId && (
              <li onClick={gotoBankAccounts}>
                <div className="item">
                  <div className="icon-box">
                    <IoWalletOutline size={sc.IconSize25} />
                  </div>
                  <div className="in">
                    <div>Bank Accounts</div>
                  </div>
                </div>
              </li>
            )}
            {user.isAdmin && (
              <>
                <li onClick={gotoPropertyTypes}>
                  <div className="item">
                    <div className="icon-box">
                      <IoBusinessOutline size={sc.IconSize25} />
                    </div>
                    <div className="in">
                      <div>Property Types</div>
                    </div>
                  </div>
                </li>
                <li onClick={gotoFacilityTypeList}>
                  <div className="item">
                    <div className="icon-box">
                      <IoListOutline size={sc.IconSize25} />
                    </div>
                    <div className="in">
                      <div>Property Facilities</div>
                    </div>
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>
        {/* Chat profile owner from here */}
      </div>
    </>
  );
};
export default Settings;
