import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import auth from "../../../services/authservice";
import RealtorDto from "../../../models/Realtor/RealtorDto";
import {
  IoAddOutline,
  IoArrowDownOutline,
  IoArrowForwardCircleOutline,
  IoArrowForwardOutline,
  IoBusinessOutline,
  IoSearchOutline,
} from "react-icons/io5";
import realtorClient from "../../../httpClients/clients/realtorClient";
import ErrorDto from "../../../models/AppFeature/ErrorDto";
import LoadingTextPlaceHolder from "../../shared/Preload/loadingtextplaceholder";
import UserViewDto from "../../../models/User/UserViewDto";
import UserClient from "../../../httpClients/clients/userClient";

type Props = {
  ShowAlert(message: string, title: string): any;
};

const CustomerList = ({ ShowAlert }: Props) => {
  const location = useLocation();

  let result: UserViewDto[] = [];
  const [data, setData] = useState(result);
  const [dataLoaded, setDataLoaded] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    getCustomers(0);
  }, []);

  const openCustomer = (customerId: string) => {
    navigate(sc.customerdetailroute, {
      state: { customerId: customerId, route: sc.customersroute },
    });
  };

  const setSearchParamValue = (e: any) => {
    setSearchParam(e.target.value);
  };

  const [allCustomersShown, setAllCustomersShown] = useState(true);

  const [searchParam, setSearchParam] = useState("");

  const getCustomers = (skip: number) => {
    UserClient.getCustomers(skip, searchParam).then(
      (result: UserViewDto[]) => {
        setAllCustomersShown(result.length == 0);
        setDataLoaded(true);

        if (skip == 0) {
          setData(result);
        } else {
          let tempCustomerList: UserViewDto[];
          tempCustomerList = [...data];
          for (let i = 0; i < result.length; i++) {
            tempCustomerList.push(result[i]);
          }
          setData(tempCustomerList);
        }
      },
      (error: ErrorDto) => {
        setDataLoaded(true);
        ShowAlert(error.message, sc.warning);
      }
    );
  };
  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="text-muted-medium">Customers</div>
      </div>

      <div id="appCapsule" className="extra-header-active full-height">
        <table style={{ float: "right" }}>
          <tr>
            <td>
              <div className="form-group basic">
                <input
                  type="text"
                  value={searchParam}
                  onChange={setSearchParamValue}
                  className="form-control"
                  id="searchParam"
                  placeholder="name phone email ..."
                />
              </div>
            </td>
            <td>
              <span className="btn" onClick={() => getCustomers(0)}>
                <IoSearchOutline size={sc.IconSize20} />
              </span>
            </td>
          </tr>
        </table>
        <div style={{ clear: "both" }}>
          <ul className="listview image-listview">
            {data.map((value) => (
              <li key={value._id}>
                <div className="item">
                  <img
                    src={auth.loadUserImage(value.photoUrl)}
                    alt="image"
                    className="image"
                  />
                  <div className="in">
                    <div className="text-muted-dark">
                      {value.firstname} {value.lastname}
                      <br />
                      <span className="text-muted">
                        {value.mobileNumber}
                        <br />
                        {value.email}
                      </span>
                    </div>
                  </div>
                </div>
                <span
                  className="text-muted"
                  style={{ cursor: "pointer" }}
                  onClick={() => openCustomer(value._id ?? "")}
                >
                  <IoArrowForwardOutline size={sc.IconSize15} />
                </span>
              </li>
            ))}
          </ul>
        </div>

        {!allCustomersShown && (
          <div className="loadMore">
            <a className="text" onClick={() => getCustomers(data.length)}>
              <IoArrowDownOutline size={sc.IconSize25} />
            </a>
          </div>
        )}
        <br />
        <br />

        {!dataLoaded && (
          <>
            <br />
            <LoadingTextPlaceHolder />
          </>
        )}
      </div>
    </>
  );
};
export default CustomerList;
