import React, { useState } from "react";
import sc from "../../services/stringconst";

type Props = {
  label: string;
  values: string[];
};
const ValueLabelArray = ({ label, values }: Props) => {
  return (
    <div className="form-group basic">
      <label className="label text-muted" htmlFor="input">
        {label}
      </label>
      {values.map((value) => (
        <>
          <label className=" text-muted-medium-dark" htmlFor="input">
            {value}
          </label>
          <br />
        </>
      ))}
    </div>
  );
};
export default ValueLabelArray;
