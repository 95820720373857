import React, { useEffect } from "react";
import { useState } from "react";
import { IoBackspaceOutline, IoLogInOutline } from "react-icons/io5";

type Props = {
  submitPin(pin: string): any;
  clearPin?: boolean;
};

const Keypad = ({ submitPin, clearPin }: Props) => {
  const [pin, setValue] = useState("");

  const addToPin = (val: string) => {
    if (pin.length === 6) {
      return;
    }
    let newValue = `${pin}${val}`;
    setValue(newValue);
  };

  const removeFromPin = () => {
    if (pin.length === 0) {
      return;
    }
    let newValue = pin.substring(0, pin.length - 1);
    setValue(newValue);
  };

  useEffect(() => {
    setValue("");
  }, [clearPin]);

  // const addToPin = (val: string) => {
  //   alert(val);
  //   if (pin.length === 6) {
  //     return;
  //   }
  //   let newValue = `${pin}${val}`;
  //   pin = newValue;
  //   alert(pin);
  // };

  // const removeFromPin = () => {
  //   if (pin.length === 0) {
  //     return;
  //   }
  //   let newValue = pin.substring(0, pin.length - 1);
  //   pin = newValue;
  // };

  return (
    <>
      <div style={{ textAlign: "center", width: "100%" }}>
        <div>
          <input
            id="pin1"
            style={{
              width: 11,
              height: 11,
              borderWidth: 0,
              borderRadius: 10,
              background: pin.length >= 1 ? "#ffb300c0" : "#ffe6acc0",
            }}
            readOnly
          />{" "}
          &nbsp;
          <input
            id="pin2"
            style={{
              width: 11,
              height: 11,
              borderWidth: 0,
              borderRadius: 10,
              background: pin.length >= 2 ? "#ffb300c0" : "#ffe6acc0",
            }}
            readOnly
          />{" "}
          &nbsp;
          <input
            id="pin3"
            style={{
              width: 11,
              height: 11,
              borderWidth: 0,
              borderRadius: 10,
              background: pin.length >= 3 ? "#ffb300c0" : "#ffe6acc0",
            }}
            readOnly
          />{" "}
          &nbsp;
          <input
            id="pin4"
            style={{
              width: 11,
              height: 11,
              borderWidth: 0,
              borderRadius: 10,
              background: pin.length >= 4 ? "#ffb300c0" : "#ffe6acc0",
            }}
            readOnly
          />{" "}
          &nbsp;
          <input
            id="pin5"
            style={{
              width: 11,
              height: 11,
              borderWidth: 0,
              borderRadius: 10,
              background: pin.length >= 5 ? "#ffb300c0" : "#ffe6acc0",
            }}
            readOnly
          />{" "}
          &nbsp;
          <input
            id="pin6"
            style={{
              width: 11,
              height: 11,
              borderWidth: 0,
              borderRadius: 10,
              background: pin.length >= 6 ? "#ffb300c0" : "#ffe6acc0",
            }}
            readOnly
          />{" "}
          &nbsp; &nbsp;
        </div>
        <br />
        <div>
          <span
            className="badge badge-outline-secondary"
            onClick={() => addToPin("1")}
            style={{ width: 60, height: 60, fontSize: 20, cursor: "pointer" }}
          >
            1
          </span>{" "}
          &nbsp; &nbsp; &nbsp;
          <span
            className="badge badge-outline-secondary"
            onClick={() => addToPin("2")}
            style={{ width: 60, height: 60, fontSize: 20, cursor: "pointer" }}
          >
            2
          </span>{" "}
          &nbsp; &nbsp; &nbsp;
          <span
            className="badge badge-outline-secondary"
            onClick={() => addToPin("3")}
            style={{ width: 60, height: 60, fontSize: 20, cursor: "pointer" }}
          >
            3
          </span>{" "}
          &nbsp; &nbsp;
        </div>
        <br />
        <div>
          <span
            className="badge badge-outline-secondary"
            onClick={() => addToPin("4")}
            style={{ width: 60, height: 60, fontSize: 20, cursor: "pointer" }}
          >
            4
          </span>{" "}
          &nbsp; &nbsp; &nbsp;
          <span
            className="badge badge-outline-secondary"
            onClick={() => addToPin("5")}
            style={{ width: 60, height: 60, fontSize: 20, cursor: "pointer" }}
          >
            5
          </span>{" "}
          &nbsp; &nbsp; &nbsp;
          <span
            className="badge badge-outline-secondary"
            onClick={() => addToPin("6")}
            style={{ width: 60, height: 60, fontSize: 20, cursor: "pointer" }}
          >
            6
          </span>{" "}
          &nbsp; &nbsp;
        </div>
        <br />
        <div>
          <span
            className="badge badge-outline-secondary"
            onClick={() => addToPin("7")}
            style={{ width: 60, height: 60, fontSize: 20, cursor: "pointer" }}
          >
            7
          </span>{" "}
          &nbsp; &nbsp; &nbsp;
          <span
            className="badge badge-outline-secondary"
            onClick={() => addToPin("8")}
            style={{ width: 60, height: 60, fontSize: 20, cursor: "pointer" }}
          >
            8
          </span>{" "}
          &nbsp; &nbsp; &nbsp;
          <span
            className="badge badge-outline-secondary"
            onClick={() => addToPin("9")}
            style={{ width: 60, height: 60, fontSize: 20, cursor: "pointer" }}
          >
            9
          </span>{" "}
          &nbsp; &nbsp;
        </div>
        <br />
        <div>
          <span
            className={
              pin.length > 0 ? "badge badge-danger" : "badge badge-danger-light"
            }
            onClick={removeFromPin}
            style={{ width: 50, height: 50, fontSize: 20, cursor: "pointer" }}
          >
            <IoBackspaceOutline />
          </span>{" "}
          &nbsp; &nbsp; &nbsp;
          <span
            className="badge badge-outline-secondary"
            onClick={() => addToPin("0")}
            style={{ width: 60, height: 60, fontSize: 20, cursor: "pointer" }}
          >
            0
          </span>{" "}
          &nbsp; &nbsp; &nbsp;
          <span
            className={
              pin.length === 6
                ? "badge badge-primary"
                : "badge badge-primary-light"
            }
            onClick={() => submitPin(pin)}
            style={{ width: 50, height: 50, fontSize: 20, cursor: "pointer" }}
          >
            <IoLogInOutline />
          </span>{" "}
          &nbsp; &nbsp;
        </div>
      </div>
    </>
  );
};
export default Keypad;
