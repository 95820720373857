import React, { useState } from "react";
import sc from "../../../services/stringconst";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../childcomponents/custombutton";
import auth from "../../../services/authservice";
import { IoChevronBackOutline } from "react-icons/io5";
import ErrorDto from "../../../models/AppFeature/ErrorDto";
import UserClient from "../../../httpClients/clients/userClient";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const ChangePassword = ({ ShowAlert }: Props) => {
  const navigate = useNavigate();
  const [httpProcessing, setHttpProcessing] = useState(false);

  const changePassword = (e: any) => {
    e.preventDefault();

    if (!changePasswordData.oldPassword) {
      ShowAlert(sc.oldpasswordrequired, sc.warning);
      return;
    }
    if (!changePasswordData.newPassword) {
      ShowAlert(sc.newpasswordrequired, sc.warning);
      return;
    }
    if (!changePasswordData.repeatNewPassword) {
      ShowAlert(sc.repeatedpasswordrequired, sc.warning);
      return;
    }
    if (
      changePasswordData.oldPassword.toLowerCase() ===
      changePasswordData.newPassword.toLowerCase()
    ) {
      ShowAlert(sc.currentpasswordcannotbesameasnew, sc.warning);
      return;
    }

    if (
      changePasswordData.newPassword !== changePasswordData.repeatNewPassword
    ) {
      ShowAlert(sc.newpasswordmismatch, sc.warning);
      return;
    }

    var data = {
      oldPassword: changePasswordData.oldPassword,
      newPassword: changePasswordData.newPassword,
    };
    setHttpProcessing(true);
    UserClient.changePassword(data).then(
      (value) => {
        setHttpProcessing(false);
        ShowAlert(sc.passwordChangeSuccessful, sc.success);
        navigate(sc.settingsroute);
      },
      (error: ErrorDto) => {
        setHttpProcessing(false);
        ShowAlert(error.message, sc.danger);
      }
    );
  };

  const [changePasswordData, setChangePasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    repeatNewPassword: "",
  });

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setChangePasswordData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const back = () => {
    navigate(-1);
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <IoChevronBackOutline size={sc.IconSize15} /> Settings
          </a>
        </div>
        <div className="text-muted-medium">Password</div>
      </div>
      <div id="appCapsule" className="extra-header-active full-height">
        <div className="section mb-5 p-2" style={{ marginTop: "1em" }}>
          <div>
            <div className="card-body">
              <div className="form-group basic">
                <div className="input-wrapper">
                  <label className="label" htmlFor="password">
                    Current Password
                  </label>
                  <input
                    type="password"
                    onChange={handleChange}
                    id="oldPassword"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="form-group basic">
                <div className="input-wrapper">
                  <label className="label" htmlFor="password">
                    New Password
                  </label>
                  <input
                    type="password"
                    onChange={handleChange}
                    id="newPassword"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="form-group basic">
                <div className="input-wrapper">
                  <label className="label" htmlFor="retypepassword">
                    Retype New Password
                  </label>
                  <input
                    type="password"
                    onChange={handleChange}
                    id="repeatNewPassword"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="form-group basic">
                <div className="input-wrapper">
                  <CustomButton
                    httpProcessing={httpProcessing}
                    text="Change Password"
                    onClick={changePassword}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
