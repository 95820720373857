import React, { useState } from "react";
import sc from "../../services/stringconst";
import {
  IoCloseOutline,
  IoPencilOutline,
  IoSaveOutline,
} from "react-icons/io5";

type Props = {
  label: string;
  value: string;
  editMessage: string;
  ShowAlert(message: string, title: string): any;
  isRequired: boolean;
  applyChange(value: string): any;
};
const InputTextBox = ({
  label,
  value,
  editMessage,
  ShowAlert,
  isRequired,
  applyChange,
}: Props) => {
  const [input, setInput] = useState(value);
  const [inputTemp, setInputTemp] = useState(value);

  const setInputTempValue = (e: any) => {
    setInputTemp(e.target.value);
  };
  const cancelInputEdit = () => {
    setInputEnabled(false);
    setInputTemp(input);
  };

  const [inputEnabled, setInputEnabled] = useState(false);
  const editField = () => {
    if (editMessage) {
      ShowAlert(editMessage, sc.warning);
    }
    setInputEnabled(true);
  };

  const setInputEnabledValue = () => {
    if (isRequired && (inputTemp === "" || inputTemp === null)) {
      ShowAlert(label + sc.isRequired, sc.warning);
      return;
    }

    let newValue = !inputEnabled;
    setInputEnabled(newValue);
    setInput(inputTemp);
    if (newValue === false) {
      applyChange(inputTemp);
    }
  };

  return (
    <div className="form-group basic">
      <div className="input-wrapper">
        <label className="label" htmlFor="input">
          {label}
        </label>
        <div className="row">
          <div style={{ width: "80%" }}>
            <input
              maxLength={50}
              style={{ pointerEvents: inputEnabled ? "auto" : "none" }}
              type="text"
              className="form-control"
              id="input"
              value={inputEnabled ? inputTemp : input}
              onChange={setInputTempValue}
            />
          </div>

          <div style={{ width: "10%" }}>
            <span
              style={{
                display: inputEnabled ? "block" : "none",
                fontSize: "1.6em",
              }}
              className="text-primary"
            >
              <IoSaveOutline
                size={sc.IconSize25}
                onClick={setInputEnabledValue}
              />
            </span>
          </div>
          <div style={{ width: "10%" }}>
            <IoPencilOutline
              style={{ display: !inputEnabled ? "block" : "none" }}
              size={sc.IconSize25}
              onClick={editField}
            />
            <span
              style={{
                display: inputEnabled ? "block" : "none",
                fontSize: "1.6em",
              }}
              className="text-danger"
            >
              <IoCloseOutline size={sc.IconSize25} onClick={cancelInputEdit} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InputTextBox;
