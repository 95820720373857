import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import auth from "../../../services/authservice";
import CustomButton from "../../childcomponents/custombutton";
import { IoChevronBackOutline } from "react-icons/io5";
import propertyClient from "../../../httpClients/clients/propertyClient";
import AttributeDto from "../../../models/Property/AttributeDto";
import ErrorDto from "../../../models/AppFeature/ErrorDto";
import PropertyDevelopmentDto from "../../../models/Property/PropertyDevelopmentDto";
import UtilityHelper from "../../../Utilities/UtilityHelper";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const AddPropertyDevelopment = ({ ShowAlert }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const propertyId = location.state.propertyId;
  const [httpProcessing, setHttpProcessing] = useState(false);

  const back = () => {
    navigate(-1);
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setDevelopment((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  let developmentTemp: PropertyDevelopmentDto = {};
  const [development, setDevelopment] = useState(developmentTemp);

  const addDevelopmentData = async () => {
    try {
      if (!development.title) {
        ShowAlert("Title" + sc.isRequired, sc.warning);
        return;
      }

      if (!development.description) {
        ShowAlert("Description" + sc.isRequired, sc.warning);
        return;
      }

      if (!development.developmentDate) {
        ShowAlert("development date" + sc.isRequired, sc.warning);
        return;
      }

      let developmentDateTemp = development.developmentDate;

      development.developmentDate = UtilityHelper.dateToUnixTimeStamp(
        new Date(development.developmentDate.toString())
      );

      setHttpProcessing(true);

      const result = await propertyClient
        .AddPropertyDevelopment(propertyId, development)
        .then(
          () => {
            setHttpProcessing(false);
          },
          (error: ErrorDto) => {
            development.developmentDate = developmentDateTemp;
            setHttpProcessing(false);
            ShowAlert(error.message, sc.warning);
          }
        );

      setHttpProcessing(false);

      navigate(sc.propertydetailsroute, {
        state: { propertyId: propertyId, tabSelection: 4 },
      });
    } catch (e: any) {
      setHttpProcessing(false);
      ShowAlert(e.message, sc.warning);
    }
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <IoChevronBackOutline size={sc.IconSize15} /> Back
          </a>
        </div>
        <div className="text-muted-medium">Development</div>
      </div>

      <div
        id="appCapsule"
        className="extra-header-active full-height"
        style={{ background: "white" }}
      >
        <div>
          <div className="card-body pb-1">
            <div className="form-group basic">
              <label className="label" htmlFor="title">
                Title
              </label>
              <input
                required
                value={development.title}
                onChange={handleChange}
                id="title"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>
            <div className="form-group basic">
              <label className="label" htmlFor="description">
                Description
              </label>
              <textarea
                required
                value={development.description}
                onChange={handleChange}
                id="description"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>

            <div className="form-group basic">
              <label className="label" htmlFor="developmentDate">
                Date
              </label>
              <input
                required
                type="date"
                value={development.developmentDate}
                onChange={handleChange}
                id="developmentDate"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>

            <br />

            <div>
              <CustomButton
                httpProcessing={httpProcessing}
                text="Add"
                onClick={addDevelopmentData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddPropertyDevelopment;
