import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Client } from "../services/apiclient";
import { useLocation } from "react-router-dom";
import sc from "../services/stringconst";
import auth from "../services/authservice";
import CustomButton from "./childcomponents/custombutton";

const NewUserAccount = (props) => {
  const location = useLocation();

  const [banks, setBanks] = useState([]);
  const [httpProcessing, setHttpProcessing] = useState(false);

  let navigate = new useNavigate();
  let client = new Client(null, null);

  const back = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUserAccount((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  useEffect(() => {
    client.getbanks().then(
      (data) => {
        setBanks(data);
      },
      (error) => {
        props.ShowAlert(error.response, sc.danger);
      }
    );
  }, []);

  const [userAccount, setUserAccount] = useState({
    _id: null,
    bankId: null,
    accountNumber: undefined,
    accountName:
      auth.getUserProfileFirstname() + " " + auth.getUserProfileLastname(),
    isVisible: true,
    bankName: undefined,
  });

  const addUpdateUserAccount = () => {
    if (!userAccount.bankId) {
      props.ShowAlert(sc.bankrequired, sc.warning);
      return;
    }

    if (!userAccount.accountName) {
      props.ShowAlert(sc.accountnamerequired, sc.warning);
      return;
    }

    if (!userAccount.accountNumber) {
      props.ShowAlert(sc.accountnumberrequired, sc.warning);
      return;
    }

    userAccount.bankName = banks.filter(
      (o) => o._id === userAccount.bankId
    )[0].name;

    setHttpProcessing(true);
    // if (!userAccount._id) {
    client.createuseraccount(userAccount).then(
      (data) => {
        setHttpProcessing(false);
        if (location.state?.source === sc.verification) {
          navigate(sc.identificationverificationroute, {
            state: { accountNumber: userAccount.accountNumber },
          });
        } else {
          navigate(sc.useraccountroute);
        }
      },
      (error) => {
        setHttpProcessing(false);
        props.ShowAlert(error.response, sc.danger);
      }
    );
    // }
    // else {
    //   client.updateuseraccount(userAccount._id, userAccount).then(data => {
    //     setHttpProcessing(false);
    //     navigate(sc.useraccountroute);
    //   },
    //     error => {
    //       setHttpProcessing(false);
    //       props.ShowAlert(error.response, sc.danger);
    //     });
    // }
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div>New Bank Account</div>
      </div>

      <div
        id="appCapsule"
        className="extra-header-active full-height"
        style={{ background: "white" }}
      >
        <div className="card">
          <div className="card-body">
            <div className="item">
              <div className="in">
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        <span className="text-muted">Bank</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <select
                          value={userAccount.bankId}
                          onChange={handleChange}
                          id="bankId"
                          style={{ width: "100%" }}
                          className="form-control custom-select"
                        >
                          <option>Select Bank</option>
                          {banks.map((value) => (
                            <option value={value._id} key={value._id}>
                              {value.name}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="item">
              <div className="in">
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        <span className="text-muted">Account Name</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <input
                            readOnly
                            value={userAccount.accountName}
                            onChange={handleChange}
                            id="accountName"
                            style={{ width: "100%" }}
                            className="form-control"
                            placeholder="Firstname Lastname"
                          />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="item">
              <div className="in">
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        <span className="text-muted">Account Number</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <input
                            type="number"
                            value={userAccount.accountNumber}
                            onChange={handleChange}
                            id="accountNumber"
                            style={{ width: "100%" }}
                            className="form-control"
                            placeholder="0000000000"
                          />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br />
          </div>
          <div className="card-footer">
            <CustomButton
              httpProcessing={httpProcessing}
              text="Add"
              onClick={addUpdateUserAccount}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default NewUserAccount;
