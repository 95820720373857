import React from "react";


 const RepaymentSent =()=> {

    return (
<>

<div className="section mt-2 text-center" style={{padding: "20% 10%"}}>
<div className="card">
            <div className="card-body">
                <h3> Repayment Sent</h3> 
                </div>
                <div className="card-body">

                <ion-icon name="checkmark-circle-outline" style={{fontSize:"5em", color: "green"}}></ion-icon><br/>
                <span className="text-muted">Payment Number# </span> <span style={{fontSize:"1.1em"}}>  4554-3633</span> 
                </div>
                </div>
<br/>

                <div className="form-group transparent">
                    <button type="submit" className="btn btn-info btn-block">Dashboard</button>
                </div>    
                </div>
</>
    );

}
export default RepaymentSent