import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import sc from "../../../services/stringconst";
import UserClient from "../../../httpClients/clients/userClient";
import NotificationDto from "../../../models/AppFeature/NotificationDto";
import moment from "moment";
import appStyles from "../../shared/app.style";
import {
  IoCashOutline,
  IoPersonOutline,
  IoStarHalfOutline,
  IoChevronBackOutline,
  IoArrowDownOutline,
} from "react-icons/io5";
import LoadingImagePlaceHolder from "../../shared/Preload/loadingimageplaceholder";

type Props = {
  ShowAlert(message: string, title: string): any;
};

const Notification = ({ ShowAlert }: Props) => {
  const navigate = useNavigate();
  let [dataLoaded, setDataLoaded] = useState(false);

  let tempData: NotificationDto[] = [];
  const [notificationList, setNotificationList] = useState(tempData);
  const [allNotificationShown, setAllNotificationShown] = useState(false);

  useEffect(() => {
    GetNotificationData();
  }, []);

  const GetNotificationData = async () => {
    setDataLoaded(false);
    UserClient.getNotifications(notificationList.length).then((data) => {
      setDataLoaded(true);
      if (data.length < 20) {
        setAllNotificationShown(true);
      }
      let tempNotificationList: NotificationDto[];
      tempNotificationList = [...notificationList];
      for (let i = 0; i < data.length; i++) {
        tempNotificationList.push(data[i]);
      }
      setNotificationList(tempNotificationList);
    });
  };

  return (
    <div className="bg-white">
      <div id="appCapsule">
        {/* <!-- App Header --> */}
        <div
          style={appStyles.pageFrame}
          className="appHeader bg-primary-main  bg-primary text-light"
        >
          <div className="left">
            <a onClick={() => navigate(-1)} className="headerButton goBack">
              <IoChevronBackOutline size={sc.IconSize15} /> Back
            </a>
          </div>
          <div className="text-muted-medium">Notifications</div>
        </div>
        {/* <!-- App Header --> */}

        <div className="section full">
          {!dataLoaded && (
            <>
              <LoadingImagePlaceHolder />
            </>
          )}

          <ul className="listview image-listview flush">
            {notificationList.map((item) => (
              <li>
                <a className="item">
                  <div
                    className={
                      item.seen
                        ? "icon-box badge-secondary"
                        : "icon-box badge-primary"
                    }
                  >
                    {item.refType === sc.Payment && (
                      <IoCashOutline size={sc.IconSize25} />
                    )}
                    {item.refType === sc.RatingEntity && (
                      <IoStarHalfOutline size={sc.IconSize25} />
                    )}
                    {item.refType === sc.UserEntity && (
                      <IoPersonOutline size={sc.IconSize25} />
                    )}
                  </div>
                  <div className="in">
                    <div>
                      <div className="mb-12">
                        {item.title}
                        <span className="text-warning"></span>
                      </div>
                      <div className="text-small mb-12">{item.message}</div>
                      <div className="text-xsmall">
                        {moment(item.audit.createdOn).format(
                          "DD.MMM.YY hh:mm:ss"
                        )}
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <br />

        {allNotificationShown && (
          <div className="loadMore">
            <a onClick={GetNotificationData} className="text">
              <IoArrowDownOutline size={sc.IconSize25} />
            </a>
          </div>
        )}
        <br />
        <br />
      </div>
    </div>
  );
};

export default Notification;
