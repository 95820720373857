import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import auth from "../../../services/authservice";
import sc from "../../../services/stringconst";
import {
  IoBusinessOutline,
  IoInformationOutline,
  IoListOutline,
  IoLogInOutline,
  IoPeopleOutline,
  IoPersonOutline,
  IoPieChartOutline,
  IoSearchOutline,
} from "react-icons/io5";
import store, { getUser } from "../../../store/store";
import { useSelector } from "react-redux";

type Props = {
  loginStatus: boolean;
};
const Footer = ({ loginStatus }: Props) => {
  const navigate = useNavigate();
  const user = useSelector(getUser);

  let showHome = false;
  if (!window.location.href.toLowerCase().endsWith("welcome")) {
    showHome = true;
  }

  useEffect(() => {
    const user = auth.getCurrentUser();
    if (user) {
      setIsLoggedIn(true);
      setActiveTab("dashboard");
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const gotoLogin = () => {
    navigate(sc.loginroute);
  };

  const gotoDashboard = () => {
    let user = auth.getCurrentUser();
    if (user.userRole == sc.Admin) navigate(sc.adminhomeroute);
    if (user.userRole == sc.Realtor) navigate(sc.realtorhomeroute);
    if (user.userRole == sc.Customer) navigate(sc.homeroute);
    setActiveTab("dashboard");
  };
  const gotoProperty = () => {
    navigate(sc.propertiesroute);
    setActiveTab("property");
  };
  const gotoFindProperty = () => {
    navigate(sc.findpropertyroute);
    setActiveTab("property");
  };
  const gotoRequests = () => {
    navigate(sc.requestsroute);
    setActiveTab("request");
  };
  const gotoRealtors = () => {
    navigate(sc.realtorsroute);
    setActiveTab("realtor");
  };
  const gotoCustomers = () => {
    navigate(sc.customersroute);
    setActiveTab("customer");
  };

  let itemActiveClass = "item itemActive";
  let itemClass = "item";

  const [dashboardClass, setDashboardClass] = useState(itemActiveClass);
  const [propertyClass, setPropertyClass] = useState(itemClass);
  const [requestClass, setRequestClass] = useState(itemClass);
  const [realtorClass, setRealtorClass] = useState(itemClass);
  const [customerClass, setCustomerClass] = useState(itemClass);

  const setActiveTab = (activeTab: string) => {
    setDashboardClass(activeTab === "dashboard" ? itemActiveClass : itemClass);
    setPropertyClass(activeTab === "property" ? itemActiveClass : itemClass);
    setRequestClass(activeTab === "request" ? itemActiveClass : itemClass);
    setRealtorClass(activeTab === "realtor" ? itemActiveClass : itemClass);
    setCustomerClass(activeTab === "customer" ? itemActiveClass : itemClass);

    setDashboardIconSize(
      activeTab === "dashboard" ? itemActiveIconSize : itemIconSize
    );
    setPropertyIconSize(
      activeTab === "property" ? itemActiveIconSize : itemIconSize
    );
    setRequestIconSize(
      activeTab === "request" ? itemActiveIconSize : itemIconSize
    );
    setRealtorIconSize(
      activeTab === "realtor" ? itemActiveIconSize : itemIconSize
    );
    setCustomerIconSize(
      activeTab === "customer" ? itemActiveIconSize : itemIconSize
    );
  };

  let itemActiveIconSize = 20;
  let itemIconSize = 20;
  const [dashboardIconSize, setDashboardIconSize] =
    useState(itemActiveIconSize);
  const [propertyIconSize, setPropertyIconSize] = useState(itemIconSize);
  const [requestIconSize, setRequestIconSize] = useState(itemIconSize);
  const [realtorIconSize, setRealtorIconSize] = useState(itemIconSize);
  const [customerIconSize, setCustomerIconSize] = useState(itemIconSize);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <div>
      {/*<!-- App Bottom Menu --> */}
      {loginStatus && (
        <div
          style={{
            width: auth.appWidthFooter(),
            marginLeft: auth.appMarginFooter(),
            background: "#2d1f3b",
            borderRadius: 25,
            marginBottom: "2%",
          }}
          className="appBottomMenu"
        >
          {user.userRole === sc.Admin && (
            <>
              <div onClick={gotoDashboard} className={dashboardClass}>
                <div className="col">
                  <IoPieChartOutline size={dashboardIconSize} />
                </div>
              </div>
              <div onClick={gotoRequests} className={requestClass}>
                <div className="col">
                  <IoListOutline size={propertyIconSize} />
                </div>
              </div>
              <div onClick={gotoCustomers} className={customerClass}>
                <div className="col">
                  <IoPeopleOutline size={customerIconSize} />
                </div>
              </div>
              <div onClick={gotoProperty} className={propertyClass}>
                <div className="col">
                  <IoBusinessOutline size={requestIconSize} />
                </div>
              </div>

              <div onClick={gotoRealtors} className={realtorClass}>
                <div className="col">
                  <IoPersonOutline size={realtorIconSize} />
                </div>
              </div>
            </>
          )}
          {user.userRole === sc.Realtor && (
            <>
              <div onClick={gotoDashboard} className={dashboardClass}>
                <div className="col">
                  <IoPieChartOutline size={dashboardIconSize} />
                </div>
              </div>

              <div onClick={gotoRequests} className={requestClass}>
                <div className="col">
                  <IoListOutline size={requestIconSize} />
                </div>
              </div>

              <div onClick={gotoProperty} className={propertyClass}>
                <div className="col">
                  <IoBusinessOutline size={requestIconSize} />
                </div>
              </div>
            </>
          )}
          {user.userRole === sc.Customer && (
            <>
              <div onClick={gotoDashboard} className={dashboardClass}>
                <div className="col">
                  <IoPieChartOutline size={dashboardIconSize} />
                </div>
              </div>
              <div onClick={gotoFindProperty} className={propertyClass}>
                <div className="col">
                  <IoSearchOutline size={requestIconSize} />
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {!loginStatus && !showHome && (
        <div className="appBottomMenu_login">
          <a
            href="https://myplace.ng/#how-it-work"
            style={{
              fontSize: "1.5em",
              position: "absolute",
              left: 20,
              zIndex: "10000",
            }}
            className="text-muted"
          >
            <IoInformationOutline size={sc.IconSize20} />
          </a>
          <a onClick={gotoLogin} style={{ zIndex: "10000" }}>
            <div className="col">
              <strong>Signin</strong> <IoLogInOutline size={sc.IconSize20} />
            </div>
          </a>
        </div>
      )}

      {/*<!-- * App Bottom Menu --> */}
    </div>
  );
};

export default Footer;
