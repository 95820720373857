
class ModelBuilder {

  buildLoanDetailsData(item) {
    
      let loan={
        loanData: {
          _id: item._id,
          loanId: item.loanId,
          title: item.title,
          description: item.description,
          collateralType: item.collateralType,
          loanPackageId: item.loanPackageId,
          loanPackageTitle: item.loanPackageTitle,
          status: item.status,

          borrower: item.borrower,
          borrowerFirstname: item.borrowerFirstname,
          borrowerLastname: item.borrowerLastname,

          lender: item.lender,
          lenderFirstname: item.lenderFirstname,
          lenderLastname: item.lenderLastname,

          amount: item.amount,
          totalAmount: item.amount,
          principal: item.principal,
          interestPercentage: item.interestPercentage,

          nextDueDate: item.nextDueDate,
          repaidAmount: item.repaidAmount,
          paymentProgress: item.paymentProgress,
          disbursementDate: item.disbursementDate,
          lenderNote: item.lenderNote,
          borrowerNoteNote: item.borrowerNoteNote,
          interest: item.interest,
          disbursed:item.disbursed,
          hasUnapprovedPayment:item.hasUnapprovedPayment
        },
        loanPackage: {
          firstname: item.lenderFirstname,
          lastname: item.lenderLastname,
          title: item.loanPackageTitle,
          repaymentFrequency: item.repaymentFrequency,
          repaymentType: item.repaymentType,
          durationType: item.durationType,
          interestPercentage: item.interestPercentage,
          durationValue: item.durationValue,
          collateralType: [],
        }
      }
      return loan;
  }
}



export default new ModelBuilder();