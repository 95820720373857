import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import auth from "../../../services/authservice";
import CustomButton from "../../childcomponents/custombutton";
import util from "../../../Utilities/UtilityHelper";
import { Device } from "@capacitor/device";
import UserClient from "../../../httpClients/clients/userClient";
import UserDto from "../../../models/User/UserDto";
import appFeaturesClient from "../../../httpClients/clients/appFeaturesClient";
import StateDto from "../../../models/AppFeature/StateDto";
import CityDto from "../../../models/AppFeature/CityDto";
import { IoChevronBack, IoCloseCircle } from "react-icons/io5";
import sharedStyles from "./../../shared/app.style";
import ErrorDto from "../../../models/AppFeature/ErrorDto";

type Props = {
  setUser(isUser: boolean): any;
  ShowAlert(message: string, title: string): any;
};

const Register = ({ setUser, ShowAlert }: Props) => {
  const location = useLocation();

  const handleChange = (e: any) => {
    if (e.target.id !== "address") e.target.value = e.target?.value.trim();
    const { id, value } = e.target;
    setRegdata((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleTandDChange = (e: any) => {
    settandc(e.target.value);
  };

  const [tandc, settandc] = useState(false);

  let result: UserDto = {};
  const [regdata, setRegdata] = useState(result);

  let navigate = useNavigate();

  const [isFirstPage, setIsFirstPage] = useState(true);

  const [httpProcessing, setHttpProcessing] = useState(false);

  const goToLogin = () => {
    navigate(sc.loginroute);
  };

  const ValidatePageOne = () => {
    //Validation Code here

    if (!regdata.firstname) {
      ShowAlert(sc.firstnamerequired, sc.warning);
      return;
    }
    if (!util.validateString(regdata.firstname)) {
      ShowAlert(sc.firstnameinvalidcharacter, sc.warning);
      return;
    }

    if (!regdata.lastname) {
      ShowAlert(sc.lastnamerequired, sc.warning);
      return;
    }
    if (!util.validateString(regdata.lastname)) {
      ShowAlert(sc.lastnameinvalidcharacter, sc.warning);
      return;
    }

    if (!regdata.gender) {
      ShowAlert(sc.genderRequired, sc.warning);
      return;
    }
    if (!regdata.address) {
      ShowAlert(sc.addressrequired, sc.warning);
      return;
    }
    if (!regdata.state) {
      ShowAlert(sc.staterequired, sc.warning);
      return;
    }
    if (!regdata.city) {
      ShowAlert(sc.cityrequired, sc.warning);
      return;
    }
    setIsFirstPage(false);
  };

  const goToPageOne = () => {
    setIsFirstPage(true);
  };

  const ValidatePageTwoAndSubmit = async () => {
    //Validation Code here

    if (!regdata.identityId) {
      ShowAlert(sc.identityidrequired, sc.warning);
      return;
    }
    if (!util.validateNumber(regdata.identityId)) {
      ShowAlert(sc.bvninvalidcharacter, sc.warning);
      return;
    }

    if (!regdata.dateOfBirth) {
      ShowAlert(sc.dateofbirthrequired, sc.warning);
      return;
    }
    let age = util.yearDiff(regdata.dateOfBirth);

    if (age < 18 || age > 100) {
      ShowAlert(sc.invaliddateofbirth, sc.warning);
      return;
    }

    if (!regdata.email) {
      ShowAlert(sc.emailrequired, sc.warning);
      return;
    }
    if (!util.validateEmail(regdata.email)) {
      ShowAlert(sc.emailinvalidcharacter, sc.warning);
      return;
    }

    if (!regdata.mobileNumber) {
      ShowAlert(sc.mobilenumberrequired, sc.warning);
      return;
    }
    if (!util.validateNumber(regdata.mobileNumber)) {
      ShowAlert(sc.phoneinvalidcharacter, sc.warning);
      return;
    }

    if (!regdata.username) {
      ShowAlert(sc.usernamerequired, sc.warning);
      return;
    }
    if (!util.validateUsername(regdata.username)) {
      ShowAlert(sc.usernameinvalidcharacter, sc.warning);
      return;
    }

    if (!regdata.password) {
      ShowAlert(sc.passwordrequired, sc.warning);
      return;
    }

    if (!tandc) {
      ShowAlert(sc.tandcrequired, sc.warning);
      return;
    }

    if (!auth.getDeviceId()) {
      auth.setDeviceId(sc.getUUId());
    }
    regdata.deviceId = auth.getDeviceId();

    let deviceInfo = await Device.getInfo();

    if (deviceInfo) {
      regdata.deviceInfo = `${deviceInfo.platform}|${deviceInfo.manufacturer}|${deviceInfo.osVersion}|${deviceInfo.model}`;
    }
    regdata.pushNotificationUserToken = auth.getPushNotificationUserToken();

    setHttpProcessing(true);

    UserClient.register(regdata).then(
      (data: UserDto) => {
        setHttpProcessing(false);

        if (data) {
          navigate(sc.verifyregistrationroute, { state: data.email });
        }
      },
      (error: ErrorDto) => {
        setHttpProcessing(false);
        ShowAlert(error.message, sc.warning);
      }
    );
  };

  let statesInit: StateDto[] = [];
  let citiesInit: CityDto[] = [];

  let [states, setStates] = useState(statesInit);
  let [cities, setCities] = useState(citiesInit);

  useEffect(() => {
    let currentUser = auth.getCurrentUser();
    if (currentUser !== null && currentUser !== undefined) {
      navigate(sc.homeroute);
    }
    appFeaturesClient.GetStates().then((result) => {
      setStates(result);
    });
  }, []);

  const getCities = (e: any) => {
    handleChange(e);
    appFeaturesClient.GetCities(e.target.value).then((result) => {
      setCities(result);
      setRegdata((prevState) => ({
        ...prevState,
        city: "",
      }));
    });
  };

  return (
    <>
      <div>
        <div id="appCapsule" className="extra-header-active full-height">
          <div className="section mb-5 p-2">
            <div className="col-md-12">
              {isFirstPage && (
                <div id="page1">
                  <div
                    style={sharedStyles.pageFrame}
                    className="appHeader bg-primary-main  bg-primary text-light"
                  >
                    <div className="left">
                      <a onClick={goToLogin} className="headerButton goBack">
                        <IoChevronBack size={sc.IconSize25} /> Login
                      </a>
                    </div>
                    <div className="text-muted-medium">Register</div>
                    <div className="right"></div>
                  </div>
                  <div>
                    <div>
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="firstname">
                            Firstname
                          </label>
                          <input
                            type="text"
                            value={regdata.firstname}
                            onChange={handleChange}
                            className="form-control"
                            id="firstname"
                          />
                        </div>
                      </div>

                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="lastname">
                            Lastname
                          </label>
                          <input
                            type="text"
                            value={regdata.lastname}
                            onChange={handleChange}
                            className="form-control"
                            id="lastname"
                          />
                        </div>
                      </div>

                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="gender">
                            {regdata.gender ? (
                              "Select Gender"
                            ) : (
                              <span>&nbsp;</span>
                            )}
                          </label>

                          <select
                            required
                            onChange={handleChange}
                            value={regdata.gender}
                            className="form-control custom-select"
                            id="gender"
                          >
                            <option key="" value="" selected disabled hidden>
                              Select Gender
                            </option>
                            <option key={sc.F} value={sc.F}>
                              {sc.Female}
                            </option>
                            <option key={sc.M} value={sc.M}>
                              {sc.Male}
                            </option>
                          </select>
                        </div>
                      </div>
                      <br />
                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="address">
                            Address
                          </label>
                          <input
                            type="text"
                            value={regdata.address}
                            onChange={handleChange}
                            className="form-control"
                            id="address"
                          />
                        </div>
                      </div>

                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="state">
                            {regdata.state ? (
                              "Select State"
                            ) : (
                              <span>&nbsp;</span>
                            )}
                          </label>

                          <select
                            required
                            onChange={getCities}
                            value={regdata.state}
                            className="form-control custom-select"
                            id="state"
                          >
                            <option key="" value="" selected disabled hidden>
                              Select State
                            </option>
                            {states.map((state) => (
                              <option key={state.title} value={state.title}>
                                {state.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="city">
                            {regdata.city ? "Select City" : <span>&nbsp;</span>}
                          </label>

                          <select
                            required
                            onChange={handleChange}
                            value={regdata.city}
                            className="form-control custom-select"
                            id="city"
                          >
                            <option key="" value="" selected disabled hidden>
                              Select City
                            </option>
                            {cities.map((city) => (
                              <option value={city.title} key={city.title}>
                                {city.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <br />
                      <div className="form-group transparent">
                        <button
                          onClick={ValidatePageOne}
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!isFirstPage && (
                <div id="page2">
                  <div
                    style={sharedStyles.pageFrame}
                    className="appHeader bg-primary-main  bg-primary text-light"
                  >
                    <div className="left">
                      <a onClick={goToPageOne} className="headerButton goBack">
                        <IoChevronBack size={sc.IconSize25} /> Previous
                      </a>
                    </div>
                    <div className="text-muted-medium">Register</div>
                    <div className="right"></div>
                  </div>
                  {/* <h5 style={{color:"blue"}}>To ease lenders verification, we recommend you provide the email and phone number connected to your BVN</h5> */}
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="bvn">
                        BVN
                      </label>
                      <input
                        value={regdata.identityId}
                        onChange={handleChange}
                        type="number"
                        className="form-control"
                        id="identityId"
                      />
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="bvn">
                        Date of Birth
                      </label>
                      <input
                        value={regdata.dateOfBirth}
                        onChange={handleChange}
                        type="date"
                        className="form-control"
                        id="dateOfBirth"
                      />
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="email1">
                        E-mail
                      </label>
                      <input
                        value={regdata.email}
                        onChange={handleChange}
                        type="email"
                        className="form-control"
                        id="email"
                      />
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="mobilenumber">
                        Mobile Number
                      </label>
                      <input
                        value={regdata.mobileNumber}
                        onChange={handleChange}
                        type="number"
                        className="form-control"
                        id="mobileNumber"
                      />
                    </div>
                  </div>

                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="username">
                        Username
                      </label>
                      <input
                        value={regdata.username}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="username"
                      />
                      <i className="clear-input">
                        <IoCloseCircle size={sc.IconSize25} />
                      </i>
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="password1">
                        Password
                      </label>
                      <input
                        value={regdata.password}
                        onChange={handleChange}
                        type="password"
                        className="form-control"
                        id="password"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="custom-control custom-checkbox mt-2 mb-1">
                    <div className="form-check">
                      <input
                        checked={tandc}
                        onChange={handleTandDChange}
                        id="tandc"
                        type="checkbox"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="tandc">
                        I agree{" "}
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#termsModal"
                        >
                          terms and conditions
                        </a>
                      </label>
                    </div>
                  </div>
                  <br />
                  <div className="form-group transparent">
                    <CustomButton
                      httpProcessing={httpProcessing}
                      text="Register"
                      onClick={ValidatePageTwoAndSubmit}
                    />
                  </div>
                </div>
              )}

              {/* <!-- Terms Modal --> */}
              <div
                className="modal fade modalbox"
                id="termsModal"
                tabIndex={-1}
                role="dialog"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content" style={sharedStyles.pageFrame}>
                    <div
                      className="modal-header"
                      style={sharedStyles.pageFrame}
                    >
                      <h5 className="modal-title">Terms and Conditions</h5>
                      <a href="#" data-bs-dismiss="modal">
                        Close
                      </a>
                    </div>
                    <div className="modal-body">
                      <p>
                        These terms and conditions ("Agreement") set forth the
                        general terms and conditions of your use of the
                        "LoanHub" mobile application ("Mobile Application" or
                        "Service") and any of its related products and services
                        (collectively, "Services"). This Agreement is legally
                        binding between you ("User", "you" or "your") and this
                        Mobile Application developer ("Operator", "we", "us" or
                        "our"). If you are entering into this agreement on
                        behalf of a business or other legal entity, you
                        represent that you have the authority to bind such
                        entity to this agreement, in which case the terms
                        "User", "you" or "your" shall refer to such entity. If
                        you do not have such authority, or if you do not agree
                        with the terms of this agreement, you must not accept
                        this agreement and may not access and use the Mobile
                        Application and Services. By accessing and using the
                        Mobile Application and Services, you acknowledge that
                        you have read, understood, and agree to be bound by the
                        terms of this Agreement. You acknowledge that this
                        Agreement is a contract between you and the Operator,
                        even though it is electronic and is not physically
                        signed by you, and it governs your use of the Mobile
                        Application and Services.
                      </p>
                      <h2>Accounts and membership</h2>
                      <p>
                        You must be at least 16 years of age to use the Mobile
                        Application and Services. By using the Mobile
                        Application and Services and by agreeing to this
                        Agreement you warrant and represent that you are at
                        least 16 years of age.
                      </p>
                      <p>
                        If you create an account in the Mobile Application, you
                        are responsible for maintaining the security of your
                        account and you are fully responsible for all activities
                        that occur under the account and any other actions taken
                        in connection with it. We may, but have no obligation
                        to, monitor and review new accounts before you may sign
                        in and start using the Services. Providing false contact
                        information of any kind may result in the termination of
                        your account. You must immediately notify us of any
                        unauthorized uses of your account or any other breaches
                        of security. We will not be liable for any acts or
                        omissions by you, including any damages of any kind
                        incurred as a result of such acts or omissions. We may
                        suspend, disable, or delete your account (or any part
                        thereof) if we determine that you have violated any
                        provision of this Agreement or that your conduct or
                        content would tend to damage our reputation and
                        goodwill. If we delete your account for the foregoing
                        reasons, you may not re-register for our Services. We
                        may block your email address and Internet protocol
                        address to prevent further registration.
                      </p>
                      <h2>Adult content</h2>
                      <p>
                        Please be aware that there may be certain adult or
                        mature content available in the Mobile Application.
                        Where there is mature or adult content, individuals who
                        are less than 18 years of age or are not permitted to
                        access such content under the laws of any applicable
                        jurisdiction may not access such content. If we learn
                        that anyone under the age of 18 seeks to conduct a
                        transaction through the Services, we will require
                        verified parental consent, in accordance with the
                        Children’s Online Privacy Protection Act of 1998
                        ("COPPA"). Certain areas of the Mobile Application and
                        Services may not be available to children under 18 under
                        any circumstances.
                      </p>
                      <h2>Links to other resources</h2>
                      <p>
                        Although the Mobile Application and Services may link to
                        other resources (such as websites, mobile applications,
                        etc.), we are not, directly or indirectly, implying any
                        approval, association, sponsorship, endorsement, or
                        affiliation with any linked resource, unless
                        specifically stated herein. We are not responsible for
                        examining or evaluating, and we do not warrant the
                        offerings of, any businesses or individuals or the
                        content of their resources. We do not assume any
                        responsibility or liability for the actions, products,
                        services, and content of any other third parties. You
                        should carefully review the legal statements and other
                        conditions of use of any resource which you access
                        through a link in the Mobile Application. Your linking
                        to any other off-site resources is at your own risk.
                      </p>
                      <h2>Prohibited uses</h2>
                      <p>
                        In addition to other terms as set forth in the
                        Agreement, you are prohibited from using the Mobile
                        Application and Services or Content: (a) for any
                        unlawful purpose; (b) to solicit others to perform or
                        participate in any unlawful acts; (c) to violate any
                        international, federal, provincial or state regulations,
                        rules, laws, or local ordinances; (d) to infringe upon
                        or violate our intellectual property rights or the
                        intellectual property rights of others; (e) to harass,
                        abuse, insult, harm, defame, slander, disparage,
                        intimidate, or discriminate based on gender, sexual
                        orientation, religion, ethnicity, race, age, national
                        origin, or disability; (f) to submit false or misleading
                        information; (g) to upload or transmit viruses or any
                        other type of malicious code that will or may be used in
                        any way that will affect the functionality or operation
                        of the Mobile Application and Services, third party
                        products and services, or the Internet; (h) to spam,
                        phish, pharm, pretext, spider, crawl, or scrape; (i) for
                        any obscene or immoral purpose; or (j) to interfere with
                        or circumvent the security features of the Mobile
                        Application and Services, third party products and
                        services, or the Internet. We reserve the right to
                        terminate your use of the Mobile Application and
                        Services for violating any of the prohibited uses.
                      </p>
                      <h2>Intellectual property rights</h2>
                      <p>
                        "Intellectual Property Rights" means all present and
                        future rights conferred by statute, common law or equity
                        in or in relation to any copyright and related rights,
                        trademarks, designs, patents, inventions, goodwill and
                        the right to sue for passing off, rights to inventions,
                        rights to use, and all other intellectual property
                        rights, in each case whether registered or unregistered
                        and including all applications and rights to apply for
                        and be granted, rights to claim priority from, such
                        rights and all similar or equivalent rights or forms of
                        protection and any other results of intellectual
                        activity which subsist or will subsist now or in the
                        future in any part of the world. This Agreement does not
                        transfer to you any intellectual property owned by the
                        Operator or third parties, and all rights, titles, and
                        interests in and to such property will remain (as
                        between the parties) solely with the Operator. All
                        trademarks, service marks, graphics and logos used in
                        connection with the Mobile Application and Services, are
                        trademarks or registered trademarks of the Operator or
                        its licensors. Other trademarks, service marks, graphics
                        and logos used in connection with the Mobile Application
                        and Services may be the trademarks of other third
                        parties. Your use of the Mobile Application and Services
                        grants you no right or license to reproduce or otherwise
                        use any of the Operator or third party trademarks.
                      </p>
                      <h2>Limitation of liability</h2>
                      <p>
                        To the fullest extent permitted by applicable law, in no
                        event will the Operator, its affiliates, directors,
                        officers, employees, agents, suppliers or licensors be
                        liable to any person for any indirect, incidental,
                        special, punitive, cover or consequential damages
                        (including, without limitation, damages for lost
                        profits, revenue, sales, goodwill, use of content,
                        impact on business, business interruption, loss of
                        anticipated savings, loss of business opportunity)
                        however caused, under any theory of liability,
                        including, without limitation, contract, tort, warranty,
                        breach of statutory duty, negligence or otherwise, even
                        if the liable party has been advised as to the
                        possibility of such damages or could have foreseen such
                        damages. To the maximum extent permitted by applicable
                        law, the aggregate liability of the Operator and its
                        affiliates, officers, employees, agents, suppliers and
                        licensors relating to the services will be limited to an
                        amount no greater than one dollar or any amounts
                        actually paid in cash by you to the Operator for the
                        prior one month period prior to the first event or
                        occurrence giving rise to such liability. The
                        limitations and exclusions also apply if this remedy
                        does not fully compensate you for any losses or fails of
                        its essential purpose.
                      </p>
                      <h2>Severability</h2>
                      <p>
                        All rights and restrictions contained in this Agreement
                        may be exercised and shall be applicable and binding
                        only to the extent that they do not violate any
                        applicable laws and are intended to be limited to the
                        extent necessary so that they will not render this
                        Agreement illegal, invalid or unenforceable. If any
                        provision or portion of any provision of this Agreement
                        shall be held to be illegal, invalid or unenforceable by
                        a court of competent jurisdiction, it is the intention
                        of the parties that the remaining provisions or portions
                        thereof shall constitute their agreement with respect to
                        the subject matter hereof, and all such remaining
                        provisions or portions thereof shall remain in full
                        force and effect.
                      </p>
                      <h2>Dispute resolution</h2>
                      <p>
                        The formation, interpretation, and performance of this
                        Agreement and any disputes arising out of it shall be
                        governed by the substantive and procedural laws of
                        Nigeria without regard to its rules on conflicts or
                        choice of law and, to the extent applicable, the laws of
                        Nigeria. The exclusive jurisdiction and venue for
                        actions related to the subject matter hereof shall be
                        the courts located in Nigeria, and you hereby submit to
                        the personal jurisdiction of such courts. You hereby
                        waive any right to a jury trial in any proceeding
                        arising out of or related to this Agreement. The United
                        Nations Convention on Contracts for the International
                        Sale of Goods does not apply to this Agreement.
                      </p>
                      <h2>Changes and amendments</h2>
                      <p>
                        We reserve the right to modify this Agreement or its
                        terms related to the Mobile Application and Services at
                        any time at our discretion. When we do, we will revise
                        the updated date at the bottom of this page. We may also
                        provide notice to you in other ways at our discretion,
                        such as through the contact information you have
                        provided.
                      </p>
                      <p>
                        An updated version of this Agreement will be effective
                        immediately upon the posting of the revised Agreement
                        unless otherwise specified. Your continued use of the
                        Mobile Application and Services after the effective date
                        of the revised Agreement (or such other act specified at
                        that time) will constitute your consent to those
                        changes.
                      </p>
                      <h2>Acceptance of these terms</h2>
                      <p>
                        You acknowledge that you have read this Agreement and
                        agree to all its terms and conditions. By accessing and
                        using the Mobile Application and Services you agree to
                        be bound by this Agreement. If you do not agree to abide
                        by the terms of this Agreement, you are not authorized
                        to access or use the Mobile Application and Services.
                      </p>
                      <h2>Contacting us</h2>
                      <p>
                        If you have any questions, concerns, or complaints
                        regarding this Agreement, we encourage you to contact us
                        using the details below:
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="nofollow"
                          href="https://myplace.ng"
                        >
                          https://myplace.ng
                        </a>
                      </p>
                      <p>This document was last updated on January 18, 2024</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- * Terms Modal --> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Register;
