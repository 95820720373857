import React from "react";
import sc from "./stringconst";
import auth from "./authservice";

type Props = {
  message: string;
  alertType: string;
};
const Alert = ({ message, alertType }: Props) => {
  if (message == "Failed to fetch") {
    message = "No Connection";
  }
  if (!message && alertType === sc.danger) {
    message = "Check network connection";
  }

  return (
    <div>
      <div
        style={{
          width: auth.appAlertWidth(),
          marginLeft: "10%",
          position: "fixed",
          bottom: "10%",
          zIndex: "1000",
          textAlign: "center",
          fontSize: 12,
        }}
        className={alertType}
        role="alert"
      >
        {message}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    </div>
  );
};

export default Alert;
