import React from "react";
import LoanDetails from "./loandetails";
import { useEffect } from "react";
import { Client } from "../services/apiclient";
import { useState } from "react";
import sc from "../services/stringconst";
import util from "../Utilities/UtilityHelper";
import { useLocation } from "react-router-dom";
import auth from "../services/authservice";
import { useNavigate } from "react-router-dom";
import * as moment from "moment";
import ConfirmModal from "./childcomponents/confirmmodal";
import { useScrollBy } from "react-use-window-scroll";
import ModelBuilder from "../services/modelbuilder";
import LoadingTextPlaceHolder from "./childcomponents/loadingtextplaceholder";

const RepaymentsView = (props) => {
  const navigate = useNavigate();
  const client = new Client(null, null);
  const location = new useLocation();
  const scrollBy = useScrollBy();

  let [dataLoaded, setDataLoaded] = useState(false);
  let [headerLabel, setHeaderLabel] = useState();

  const [paymentData, setPaymentData] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    let status = location.state.status;
    let isLender = location.state.isLender;
    setHeaderLabel(status, isLender);

    if (location.state.status === sc.Overdue) {
      client.repaymentsbystatus(status, isLender).then(
        (data) => {
          setDataLoaded(true);
          setPaymentData(data);
          setPosition();
        },
        (error) => {
          setDataLoaded(true);
          props.ShowAlert(error.response, sc.danger);
        }
      );
    }

    if (location.state.status === sc.Unreceived) {
      client.repaymentsbystatus(status, isLender).then(
        (data) => {
          setDataLoaded(true);
          setPaymentData(data);
          setPosition();
        },
        (error) => {
          setDataLoaded(true);
          props.ShowAlert(error.response, sc.danger);
        }
      );
    }

    if (location.state.status === sc.Pending) {
      client.repaymentsbystatus(status, isLender).then(
        (data) => {
          setDataLoaded(true);
          setPaymentData(data);
          setPosition();
        },
        (error) => {
          setDataLoaded(true);
          props.ShowAlert(error.response, sc.danger);
        }
      );
    }
  };

  const setPosition = () => {
    if (location.state) {
      if (location.state.loanPagePosition) {
        scrollBy({
          top: parseInt(location.state.loanPagePosition.toString()),
          left: 0,
          behavior: "smooth",
        });
      }
    }
  };

  const openRepaymentSchedule = (loan) => {
    let loanObject = ModelBuilder.buildLoanDetailsData(loan.loanData);
    navigate(sc.loanrepaymentscheduleroute, {
      state: {
        isLender: location.state.isLender,
        status: headerLabel,
        loanPackage: loanObject.loanPackage,
        loan: loan.loanData,
        loanPagePosition: window.scrollY,
      },
    });
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div>{headerLabel} Repayments</div>
      </div>

      <div id="appCapsule" className="extra-header-active full-height">
        <div className="section mt-2">
          {paymentData.map((value) => (
            <div>
              <ul
                className="listview image-listview"
                style={{ marginTop: "0.5em" }}
              >
                <li>
                  <a className="item">
                    <div className="in">
                      <div>
                        <a
                          data-bs-toggle="modal"
                          data-bs-target={"#detailModal" + value._id}
                          className="text-primary"
                        >
                          <b>
                            <ion-icon name="document-outline"></ion-icon>{" "}
                            {value.loanTitle}
                          </b>
                        </a>
                        <header style={{ margin: "0.5em 0em" }}>
                          <span className="text-default me-1 mb-1">
                            <ion-icon name="person-outline"></ion-icon>
                            <span
                              onClick={() =>
                                navigate(sc.profileviewroute, {
                                  state: value.borrower,
                                })
                              }
                            >
                              {value.borrowerFirstname} {value.borrowerLastname}
                            </span>
                          </span>{" "}
                        </header>
                      </div>

                      <span
                        onClick={() => openRepaymentSchedule(value)}
                        style={{ textAlign: "right", width: "40%" }}
                      >
                        <span
                          className={util.getcssClassByStatus(value.status)}
                          style={{ width: "100%" }}
                        >
                          {util.commaCurrencyFormatter(
                            value.scheduleItemAmount
                          )}
                          &nbsp; <ion-icon name="open-outline"></ion-icon>
                        </span>
                        <footer>
                          <ion-icon name="calendar-outline"></ion-icon>{" "}
                          {value.dateLabel}{" "}
                          {moment(value.sortValue).format("DD.MMM.YY")}
                        </footer>
                      </span>
                    </div>
                  </a>
                </li>
              </ul>

              <div
                className="modal fade modalbox"
                id={"detailModal" + value._id}
                tabIndex="-1"
                role="dialog"
              >
                <div className="modal-dialog" role="document">
                  <div
                    className="modal-content"
                    style={{
                      width: auth.appWidth(),
                      marginLeft: auth.appMargin(),
                    }}
                  >
                    <div
                      className="modal-header"
                      style={{
                        width: auth.appWidth(),
                        marginLeft: auth.appMargin(),
                      }}
                    >
                      <h5 className="modal-title">Details</h5>
                      <a href="#" data-bs-dismiss="modal">
                        Close
                      </a>
                    </div>
                    <div style={{ overflow: "scroll" }}>
                      <LoanDetails
                        loanData={
                          ModelBuilder.buildLoanDetailsData(value.loanData)
                            .loanData
                        }
                        loanPackage={
                          ModelBuilder.buildLoanDetailsData(value.loanData)
                            .loanPackage
                        }
                        isLender={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <br />

          {!dataLoaded && (
            <>
              <br />
              <LoadingTextPlaceHolder />
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default RepaymentsView;
