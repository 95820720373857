import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import sc from "../../../../services/stringconst";
import auth from "../../../../services/authservice";
import {
  IoArrowForwardOutline,
  IoChevronBackOutline,
  IoOpenOutline,
} from "react-icons/io5";
import ErrorDto from "../../../../models/AppFeature/ErrorDto";
import ValueLabel from "../../../childcomponents/valuelabel";
import LoadingTextPlaceHolder from "../../../shared/Preload/loadingtextplaceholder";
import realtorClient from "../../../../httpClients/clients/realtorClient";
import RepresentativeDetailDto from "../../../../models/User/RepresentativeDetailDto";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const Representative = ({ ShowAlert }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  if (!location.state) {
    navigate(sc.customersroute);
  }
  let representativeId = location.state.representativeId;

  let representativeTemp: RepresentativeDetailDto = {};
  const [representative, setRepresentative] = useState(representativeTemp);

  const back = () => {
    navigate(-1);
  };

  useEffect(() => {
    GetRepresentative();
  }, []);

  const [dataLoaded, setDataLoaded] = useState(false);

  const GetRepresentative = () => {
    realtorClient.getRepresentative(representativeId).then(
      (result: RepresentativeDetailDto) => {
        setDataLoaded(true);
        setRepresentative(result);
      },
      (error: ErrorDto) => {
        setDataLoaded(true);
        ShowAlert(error.message, sc.warning);
      }
    );
  };

  const OpenProperty = (propertyId: string) => {
    navigate(sc.propertyroute, {
      state: {
        propertyId: propertyId,
      },
    });
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <IoChevronBackOutline size={sc.IconSize15} />
          </a>
        </div>
        <div className="text-muted-medium">Representative</div>
      </div>

      <div id="appCapsule" className="extra-header-active full-height">
        <div>
          <div className="section mt-3 text-center">
            <div className="avatar-section">
              <a>
                <img
                  src={auth.loadUserImage(representative.user?.photoUrl)}
                  alt="avatar"
                  className="imaged rounded"
                  style={{ width: "8em", height: "8em" }}
                  data-bs-toggle="modal"
                  data-bs-target="#DialogImage"
                />
              </a>
            </div>
          </div>
          <div>
            <div>
              <div>
                <div className="carpet-color-bg">
                  <div style={{ margin: "1em", padding: "0.5px 0" }}>
                    <ValueLabel
                      label="Firstname"
                      value={representative.user?.firstname}
                    />

                    <ValueLabel
                      label="Lastname"
                      value={representative.user?.lastname}
                    />
                  </div>
                </div>
                <div style={{ margin: "1em" }}>
                  <ValueLabel
                    label="Mobile Number"
                    value={representative.user?.mobileNumber}
                  />

                  <ValueLabel
                    label="Email"
                    value={representative.user?.email}
                  />
                </div>

                <div className="carpet-color-bg">
                  <div style={{ margin: "1em", padding: "0.5px 0" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <ValueLabel
                            label="Gender"
                            value={
                              representative.user?.gender == "F"
                                ? "Female"
                                : "Male"
                            }
                          />
                        </td>
                        <td>
                          <ValueLabel
                            label="Rating"
                            value={
                              representative.user?.ratingAverage?.toString() ??
                              "No Rating"
                            }
                          />
                        </td>
                        <td>
                          <span style={{ cursor: "pointer", float: "right" }}>
                            <IoOpenOutline size={sc.IconSize20} />
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>{" "}
                </div>

                <div style={{ margin: "1em" }}>
                  <div style={Object.assign({})}>
                    <ul className="listview">
                      {representative.properties?.map((item) => (
                        <li key={item._id}>
                          <div className="item">
                            <div className="in">
                              <div className="text-muted-dark">
                                {item.title}
                                <br />
                                <span className="text-muted">
                                  {item?.location?.city} {item?.location?.state}
                                </span>
                              </div>
                            </div>
                          </div>
                          <span
                            className="text-muted"
                            style={{ cursor: "pointer" }}
                            onClick={() => OpenProperty(item._id ?? "")}
                          >
                            <IoArrowForwardOutline size={sc.IconSize15} />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />

      {!dataLoaded && (
        <>
          <br />
          <LoadingTextPlaceHolder />
        </>
      )}
    </>
  );
};
export default Representative;
