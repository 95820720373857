import React from "react";
import util from "../Utilities/UtilityHelper";
import moment from "moment";
import sc from "../services/stringconst";
import { useState } from "react";
import { Client } from "../services/apiclient";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import auth from "../services/authservice";
import ModelBuilder from "../services/modelbuilder";
import LoanDetails from "./loandetails";
import CustomButton from "./childcomponents/custombutton";

const PaymentDetails = (props) => {
  const client = new Client(null, null);
  const navigate = new useNavigate();
  const location = useLocation();

  const [note, setNote] = useState();

  let loanData = { collateralType: [] };
  let item = {};
  let scheduleItemAmount = 0;
  let isLender = false;
  let paymentDueDate = new Date();
  let loanPackage = {};

  const [httpProcessing, setHttpProcessing] = useState(false);

  if (!props.paymentDetails) {
    loanData = location.state.loanData;
    item = location.state.paymentDetails;
    scheduleItemAmount = location.state.scheduleItemAmount;
    isLender = location.state.isLender;
    paymentDueDate = location.state.repaymentScheduleDueDate;
  } else {
    if (props.paymentDetails) {
      loanData = props.loanData;
      loanPackage = props.loanPackage;
      item = props.paymentDetails;
      scheduleItemAmount = props.scheduleItemAmount;
      isLender = props.isLender;
      paymentDueDate = props.repaymentScheduleDueDate;
    } else {
      navigate(sc.homeroute);
      return;
    }
  }

  const setNoteValue = (e) => {
    setNote(e.target.value);
  };
  const rejectPayment = (id) => {
    if (!note) {
      props.ShowAlert("Note is required", sc.warning);
      return;
    }

    setHttpProcessing(true);
    client.rejectrepayment(id, { lenderPaymentNote: note }).then(
      (data) => {
        setHttpProcessing(false);
        props.ShowAlert("Payment rejected", sc.success);
        navigate(sc.paymentsroute);
      },
      (error) => {
        props.ShowAlert(error.response, sc.danger);
      }
    );
  };

  const setPaymentStatusClass = (status) => {
    let classname = "iconbox bg-";
    if (status === sc.PaymentSent) {
      return classname + "warning";
    }
    if (status === sc.PaymentConfirmed) {
      return classname + "success";
    }
    if (status === sc.PaymentRejected) {
      return classname + "danger";
    }
  };
  const setIconClass = (status) => {
    if (status === sc.PaymentSent) {
      return "arrow-forward-outline";
    }
    if (status === sc.PaymentConfirmed) {
      return "checkmark-outline";
    }
    if (status === sc.PaymentRejected) {
      return "close-outline";
    }
  };

  const acceptPayment = (id) => {
    setHttpProcessing(true);
    client
      .receiverepayment(id, {
        lenderPaymentNote: note,
        borrower: loanData.borrower,
      })
      .then(
        (data) => {
          setHttpProcessing(false);
          navigate(sc.repaymentconfirmedroute, {
            state: { paymentRef: data, stateData: props.stateData },
          });
        },
        (error) => {
          setHttpProcessing(false);
          props.ShowAlert(error.response, sc.danger);
        }
      );
  };
  return (
    <>
      {!props.paymentDetails && (
        <>
          {" "}
          <div
            style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
            className="appHeader bg-primary-main  bg-primary text-light"
          >
            <div className="left">
              <a onClick={() => navigate(-1)} className="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
              </a>
            </div>
            <div className="pageTitle">Payment Details</div>
          </div>
          <br />
        </>
      )}

      <div
        id="appCapsule"
        className="extra-header-active full-height"
        style={{ padding: "0em 2em", background: "white" }}
      >
        <br />
        <div class="section mt-2 mb-2">
          <div class="listed-detail mt-3">
            <div class="icon-wrapper">
              <div className={setPaymentStatusClass(item.status)}>
                <ion-icon name={setIconClass(item.status)}></ion-icon>
              </div>
            </div>
            <h3 class="text-center mt-2">{item.status}</h3>
          </div>
        </div>

        <ul className="listview flush transparent simple-listview no-space mt-3">
          <li>
            <strong>Ref#</strong>
            <span>{item.paymentId}</span>
          </li>
          <li>
            <strong>Loan</strong>
            <a
              data-bs-toggle="modal"
              data-bs-target={"#detailModal" + item.paymentId}
              className="text-primary"
            >
              {" "}
              <strong>{loanData.title}</strong>
            </a>
          </li>
          <li>
            <strong>Payment Type</strong>
            <span>
              {item.amount < scheduleItemAmount
                ? "Part Payment"
                : "Full Payment"}
            </span>
          </li>
          <li>
            <strong>Amount Paid</strong>
            <span className="badge badge-outline-primary">
              {util.commaCurrencyFormatter(item.amount)}
            </span>
          </li>

          <li>
            <strong>Schedule Amount</strong>
            <span>{util.commaCurrencyFormatter(scheduleItemAmount)}</span>
          </li>

          <li>
            <strong>Due Date</strong>
            <span>{moment(paymentDueDate).format("DD.MMM.YY")}</span>
          </li>
          <li>
            <strong>Payment Date</strong>
            <span>{moment(item.paymentdate).format("DD.MMM.YY HH:mm:ss")}</span>
          </li>
          {item.paymentConfirmationDate && (
            <li>
              <strong>
                {item.status.indexOf("Confirmed") > -1
                  ? "Confirmation Date"
                  : "Rejection Date"}
              </strong>
              <span>
                {moment(item.paymentConfirmationDate).format(
                  "DD.MMM.YY hh:mm:ss"
                )}
              </span>
            </li>
          )}
          {item.borrowerPaymentNote && (
            <li>
              <strong>Borrower Note</strong>
              <span>{item.borrowerPaymentNote}</span>
            </li>
          )}

          {item.receiptPhoto && (
            <li>
              <strong>Payment Receipt</strong>
              <span>
                <a href={item.receiptPhoto} target="_blank">
                  Open
                </a>
              </span>
            </li>
          )}

          {item.lenderPaymentNote && (
            <li>
              <strong>Lender Note</strong>
              <span>{item.lenderPaymentNote}</span>
            </li>
          )}
          {item.status === sc.PaymentSent &&
            auth.getuserProfileId() === loanData.lender && (
              <li>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="note">
                      Note
                    </label>
                    <textarea
                      value={note}
                      required
                      onChange={setNoteValue}
                      type="number"
                      className="form-control"
                      id="note"
                      placeholder="Payment note"
                    ></textarea>
                  </div>
                </div>
              </li>
            )}
        </ul>
        {item.status === sc.PaymentSent &&
          auth.getuserProfileId() === loanData.lender && (
            <div>
              <table style={{ width: "100%" }}>
                <tr>
                  <td>
                    <CustomButton
                      bgColor="danger"
                      httpProcessing={httpProcessing}
                      text=" Reject"
                      onClick={() => rejectPayment(item._id)}
                    />
                  </td>
                  <td>
                    <CustomButton
                      bgColor="success"
                      httpProcessing={httpProcessing}
                      text=" Accept"
                      onClick={() => acceptPayment(item._id)}
                    />
                  </td>
                </tr>
              </table>

              <br />
              <br />
            </div>
          )}
      </div>

      <div
        className="modal fade modalbox"
        id={"detailModal" + item.paymentId}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div
            className="modal-content"
            style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
          >
            <div
              className="modal-header"
              style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
            >
              <h5 className="modal-title">Details</h5>
              <a href="#" data-bs-dismiss="modal">
                Close
              </a>
            </div>
            <div style={{ overflow: "scroll" }}>
              <LoanDetails
                loanData={ModelBuilder.buildLoanDetailsData(loanData).loanData}
                loanPackage={loanPackage}
                isLender={isLender}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
};
export default PaymentDetails;
