import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import moment from "moment";
import {
  IoArrowForwardOutline,
  IoChevronBackOutline,
  IoCloseOutline,
  IoConstruct,
  IoExpandOutline,
  IoInformationOutline,
  IoList,
  IoNavigateOutline,
  IoOpenOutline,
  IoVideocamOffOutline,
} from "react-icons/io5";
import auth from "../../../services/authservice";
import ValueLabel from "../../childcomponents/valuelabel";
import LoadingTextPlaceHolder from "../../shared/Preload/loadingtextplaceholder";
import propertyClient from "../../../httpClients/clients/propertyClient";
import PropertyDetailsDto from "../../../models/Property/propertyDetailsDto";
import UtilityHelper from "../../../Utilities/UtilityHelper";
import ValueLabelArray from "../../childcomponents/valuelabelarray";
import SwipeableViews from "react-swipeable-views";
import FileManager from "../../../services/FileManager";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "video-react/dist/video-react.css";
import { Player } from "video-react";
import transactionClient from "../../../httpClients/clients/transactionClient";
import PropertyCostingRequestDto from "../../../models/Transaction/PropertyCostingRequestDto";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import ErrorDto from "../../../models/AppFeature/ErrorDto";
import PropertyRequestPaymentDto from "../../../models/Property/propertyRequestPaymentDto";

const spanStyle = {
  padding: "20px",
  background: "#efefef",
  color: "#000000",
};

const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  height: "400px",
};

type Props = {
  ShowAlert(message: string, title: string): any;
};
const Property = ({ ShowAlert }: Props) => {
  let location = useLocation();
  const propertyId = location.state.propertyId;
  let tabSelection = location.state.tabSelection;
  let backTabSelection = location.state.backTabSelection;

  let propertyTemp: PropertyDetailsDto = {};
  const [property, setProperty] = useState(propertyTemp);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [displayTab, setDisplayTab] = useState(
    tabSelection ?? backTabSelection ?? 1
  );

  const openRequestForm = () => {
    let data: PropertyRequestPaymentDto = {
      propertyId: property.property?._id,
      title: property.property?.title,
      description: property.property?.description,
      isFullPayment: isFullPayment,
      equityAmount: equityAmount,
      installmentAmount: installmentAmount,
      paymentDuration: paymentDuration,
      totalPayment: isFullPayment
        ? property.property?.realtorPrice
        : equityAmount + installmentAmount * paymentDuration,
      availableUnits: property.property?.availableCount ?? 1,
      propertyType: property.property?.propertyType,
      requestOption: property.property?.requestOption,
      location: property.property?.location,
    };
    navigate(sc.submitpropertyrequestroute, { state: data });
  };

  let mediasTemp: MediaDto[] = [];
  const [medias, setMedias] = useState(mediasTemp);

  const handleSwipeChange = (e: number) => {
    setTimeout(function () {
      setDisplayTab(e + 1);
    }, 1);
  };

  const [equityAmount, setEquityAmount] = useState(
    property.property?.minEquityAmount ?? 0
  );

  const [paymentDuration, setPaymentDuration] = useState(1);

  const [maxPaymentDuration, setMaxPaymentDuration] = useState(0);
  const [minEquityAmount, setMinEquityAmount] = useState(0);
  const [maxEquityAmount, setMaxEquityAmount] = useState(0);
  const [installmentAmount, setInstallmentAmount] = useState(0);

  const CalculatePricing = () => {
    let request: PropertyCostingRequestDto = {
      propertyId: propertyId,
      equityAmount: equityAmount,
      paymentDuration: paymentDuration,
      quantity: 1,
    };
    CalculatePricingValue(request);
  };
  const CalculatePricingValue = (request: PropertyCostingRequestDto) => {
    setInstallmentAmount(0);
    transactionClient.GetPropertyCosting(request).then(
      (data) => {
        setInstallmentAmount(data.paymentPerPeriod);
      },
      (error: ErrorDto) => {
        setInstallmentAmount(0);
        ShowAlert(error.message, sc.danger);
      }
    );
  };

  const [showFlexiblePayment, setShowFlexiblePayment] = useState(false);

  const setShowFlexiblePaymentValue = (showIsFlexiblePayment: boolean) => {
    setShowFlexiblePayment(showIsFlexiblePayment);
    setIsFullPayment(!showIsFlexiblePayment);
  };

  const [isVideoMedia, setIsVideoMedia] = useState(false);

  const SetIsVideoMediaValue = (thumbnail: string, url: string) => {
    setVideoData({ thumbnail: thumbnail, url: url });
    setIsVideoMedia(!isVideoMedia);
  };
  const [videoData, setVideoData] = useState({ thumbnail: "", url: "" });

  let navigate = useNavigate();

  useEffect(() => {
    GetProperty();
  }, []);

  const GetProperty = () => {
    propertyClient.GetPropertyDetails(propertyId).then(
      (data) => {
        setDataLoaded(true);
        setProperty(data);

        if (data.property?.media && data.property.media?.length > 0) {
          const mediasTemp: MediaDto[] = [];
          data.property?.media.forEach((item) => {
            const url = FileManager.getPropertyMediaUrl(
              item.mediaId ?? "",
              propertyId,
              item.type ?? ""
            );
            const thumbnail = FileManager.getPropertyMediaUrl(
              item.mediaId ?? "",
              propertyId,
              sc.image
            );

            mediasTemp.push({
              url: url,
              mediaId: item.mediaId,
              thumbnail: thumbnail,
              type: item.type,
              default: item.default,
            });
          });

          setMedias(mediasTemp);
          setEquityAmount(data.property?.minEquityAmount ?? 0);
          let pmtDuration = Math.ceil(
            (data.property?.maxPaymentDuration ?? 2) / 2
          );
          setPaymentDuration(pmtDuration);
          setMaxPaymentDuration(data.property?.maxPaymentDuration ?? 0);
          setMinEquityAmount(data.property?.minEquityAmount ?? 0);
          setMaxEquityAmount(data.property?.realtorPrice ?? 0);

          let request: PropertyCostingRequestDto = {
            propertyId: propertyId,
            equityAmount: data.property?.minEquityAmount ?? 0,
            paymentDuration: pmtDuration,
            quantity: 1,
          };
          CalculatePricingValue(request);
        }
      },
      (error) => {
        setDataLoaded(true);
        ShowAlert(error.response, sc.danger);
      }
    );
  };

  const back = () => {
    navigate(-1);
  };

  const setDisplay = (tab: number) => {
    setDisplayTab(tab);
  };

  const openContactPerson = () => {
    navigate(sc.representativeroute, {
      state: {
        representativeId: property.contactPerson?._id,
      },
    });
  };

  const viewPropertyDevelopment = (propertyDevelopmentId: string) => {
    navigate(sc.propertydevelopmentroute, {
      state: {
        propertyId: propertyId,
        propertyDevelopmentId: propertyDevelopmentId,
      },
    });
  };

  const [isFullPayment, setIsFullPayment] = useState(true);

  return (
    <>
      {dataLoaded && (
        <div>
          <div
            style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
            className="appHeader bg-primary-main  bg-primary text-light"
          >
            <div className="left">
              <a onClick={back} className="headerButton goBack">
                <IoChevronBackOutline size={sc.IconSize15} /> Back
              </a>
            </div>
            <div className="text-muted-medium">Property</div>
          </div>
          <div id="appCapsule" className="extra-header-active full-height">
            <div className="slide-container">
              {medias?.length > 0 && (
                <>
                  {" "}
                  {!isVideoMedia && (
                    <Fade>
                      {medias.map((slideImage, index) => (
                        <div key={index}>
                          <div
                            style={{
                              ...divStyle,
                              backgroundImage: `url(${slideImage.thumbnail})`,
                            }}
                          >
                            {slideImage.type == sc.video && (
                              <span
                                style={spanStyle}
                                onClick={() =>
                                  SetIsVideoMediaValue(
                                    slideImage.thumbnail ?? "",
                                    slideImage.url ?? ""
                                  )
                                }
                              >
                                <IoVideocamOffOutline size={sc.IconSize20} />{" "}
                                Watch
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                    </Fade>
                  )}
                  {isVideoMedia && (
                    <div>
                      <span
                        style={{ float: "right", paddingRight: "10px" }}
                        onClick={() => SetIsVideoMediaValue("", "")}
                      >
                        <IoCloseOutline size={sc.IconSize25} />
                        Close
                      </span>
                      <div
                        style={{
                          clear: "both",
                        }}
                      >
                        <Player
                          aspectRatio="4:3"
                          muted={false}
                          autoPlay={true}
                          poster={videoData.thumbnail}
                          src={videoData.url}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            <div>
              <div>
                <div style={{ margin: "1em" }}>
                  {" "}
                  <ValueLabel
                    value={property.property?.title ?? ""}
                    label={""}
                  />
                  <label className="text-muted-dark">
                    {`${property.property?.propertyType ?? ""} ${
                      property.property?.requestOption ?? ""
                    }`}
                  </label>
                  <br />
                  <span className="text-muted">
                    {property.property?.description ?? ""}
                  </span>
                </div>
                <div className="carpet-color-bg">
                  <div style={{ margin: "1em", padding: "0.5px 0" }}>
                    <ValueLabelArray
                      values={[
                        property.property?.location?.address ?? "",
                        `${property.property?.location?.city ?? ""}, ${
                          property.property?.location?.state ?? ""
                        }`,
                      ]}
                      label={"Address"}
                    />
                    <a
                      target="blank"
                      style={{ float: "right", marginTop: "-3.3em" }}
                      href={`http://maps.google.com/maps?z=12&t=m&q=loc:${property.property?.location?.latitude}+${property.property?.location?.longitude}`}
                    >
                      <span className="text-primary-main">
                        <IoNavigateOutline size={sc.IconSize25} />
                      </span>
                    </a>
                  </div>
                </div>
                <div>
                  <div style={{ margin: "0em 1em" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>
                          <ValueLabel
                            value={`${
                              property.contactPerson?.firstname ?? ""
                            } ${property.contactPerson?.lastname ?? ""}`}
                            label={"Contact Person"}
                          />
                        </td>
                        <td>
                          <span
                            className="text-primary-main"
                            style={{
                              float: "right",
                              cursor: "pointer",
                            }}
                            onClick={openContactPerson}
                          >
                            <IoOpenOutline size={sc.IconSize25} />
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div>
                  <div
                    className="carpet-color-bg text-muted"
                    style={{ margin: "7px 0px" }}
                  >
                    <div className="card-body">
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td>
                            <div
                              className="form-check"
                              onClick={() => setIsFullPayment(true)}
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="radioPaymentOption"
                                id="radioFullPayment"
                                checked
                              />
                              <label
                                className="form-check-label"
                                htmlFor="radioFullPayment"
                              >
                                Full Payment
                              </label>
                            </div>
                          </td>
                          <td>
                            <span style={{ float: "right" }}>
                              <b className="badge badge-primary text-primary-main">
                                <span className="text-muted-medium-dark">
                                  {UtilityHelper.commaCurrencyFormatter(
                                    property?.property?.realtorPrice?.toString() ??
                                      "0".toString()
                                  )}
                                </span>
                              </b>
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                {!showFlexiblePayment && (
                  <div
                    className="text-muted"
                    style={{ margin: "1em" }}
                    onClick={() => setShowFlexiblePaymentValue(true)}
                  >
                    <label className="label text-muted-medium-dark">
                      <IoExpandOutline size={sc.IconSize20} /> Show Flexible
                      Payment Option
                      <br />
                    </label>
                  </div>
                )}
                <div>
                  <div style={{ margin: "1em", padding: "0.5px 0" }}>
                    {showFlexiblePayment && (
                      <div>
                        <div
                          style={{ width: "100%" }}
                          onClick={() => setIsFullPayment(false)}
                        >
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="radioPaymentOption"
                              id="radioFlexiblePayment"
                              checked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="radioFlexiblePayment"
                            >
                              Flexible Payment
                            </label>
                            <br />
                          </div>
                        </div>
                        <div className="slider">
                          <Slider
                            min={minEquityAmount}
                            max={maxEquityAmount}
                            step={(maxEquityAmount - minEquityAmount) / 20}
                            value={equityAmount}
                            onChange={setEquityAmount}
                            onChangeComplete={CalculatePricing}
                          />
                          <span className="text-muted">
                            <label className="label">
                              I have
                              <b className="text-muted-dark">
                                {" "}
                                {UtilityHelper.commaCurrencyFormatter(
                                  equityAmount.toString()
                                )}
                              </b>{" "}
                              to deposit
                            </label>
                          </span>
                        </div>
                        <div className="slider">
                          <Slider
                            min={1}
                            max={maxPaymentDuration}
                            value={paymentDuration}
                            onChange={setPaymentDuration}
                            onChangeComplete={CalculatePricing}
                          />
                          <span className="text-muted">
                            {" "}
                            <label className="label">
                              I will pay the balance monthly for{" "}
                              <b className="text-muted-dark">
                                {" "}
                                {paymentDuration} Month(s)
                              </b>
                            </label>
                          </span>
                          <br />
                          <br />
                          <div className="text-muted">
                            <div
                              className="card"
                              style={{ marginBottom: "7px" }}
                            >
                              <div
                                className="card-body"
                                style={{ padding: "9px" }}
                              >
                                <table style={{ width: "100%" }}>
                                  <tr>
                                    <td>Initial Deposit</td>
                                    <td>
                                      <span
                                        className="text-muted-dark"
                                        style={{ float: "right" }}
                                      >
                                        {UtilityHelper.commaCurrencyFormatter(
                                          equityAmount.toString()
                                        )}
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div
                              className="card"
                              style={{ marginBottom: "7px" }}
                            >
                              <div
                                className="card-body"
                                style={{ padding: "9px" }}
                              >
                                <table style={{ width: "100%" }}>
                                  <tr>
                                    <td>Monthly Payment</td>
                                    <td>
                                      <span
                                        className="text-muted-dark"
                                        style={{ float: "right" }}
                                      >
                                        {UtilityHelper.commaCurrencyFormatter(
                                          installmentAmount.toString()
                                        )}
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div
                              className="card"
                              style={{ marginBottom: "7px" }}
                            >
                              <div
                                className="card-body"
                                style={{ padding: "9px" }}
                              >
                                <table style={{ width: "100%" }}>
                                  <tr>
                                    <td>Payment Duration</td>
                                    <td>
                                      <span
                                        className="text-muted-dark"
                                        style={{ float: "right" }}
                                      >
                                        {paymentDuration} Month(s)
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div
                              className="card"
                              style={{ marginBottom: "7px" }}
                            >
                              <div
                                className="card-body"
                                style={{ padding: "9px" }}
                              >
                                <table style={{ width: "100%" }}>
                                  <tr>
                                    <td>Total Payment</td>
                                    <td>
                                      <b
                                        className="badge badge-primary text-primary-main"
                                        style={{ float: "right" }}
                                      >
                                        <span className="text-muted-medium-dark">
                                          {UtilityHelper.commaCurrencyFormatter(
                                            (
                                              equityAmount +
                                              installmentAmount *
                                                paymentDuration
                                            ).toString()
                                          )}
                                        </span>
                                      </b>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="carpet-color-bg">
                  <div style={{ marginLeft: "1em", padding: "0.5px 0" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td style={{ width: "50%" }}>
                          {property.property?.estimatedProjectEndDate &&
                            !property.property?.projectEndDate && (
                              <ValueLabel
                                value={moment(
                                  UtilityHelper.unixTimeStampToDate(
                                    property.property?.estimatedProjectEndDate
                                  )
                                ).format("DD.MMM.YY")}
                                label={"Earliest Moving Date"}
                              />
                            )}
                          {property.property?.projectEndDate && (
                            <ValueLabel
                              value={moment(
                                UtilityHelper.unixTimeStampToDate(
                                  property.property?.projectEndDate
                                )
                              ).format("DD.MMM.YY")}
                              label={"Earliest Moving Date"}
                            />
                          )}
                        </td>
                        <td style={{ width: "50%" }}>
                          <ValueLabel
                            value={
                              `${UtilityHelper.formatToYearMonth(
                                property.property?.stayDuration
                              )}` ?? "Max"
                            }
                            label={"Stay Duration"}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="carpet-color-bg">
              <br />{" "}
              <div
                className="horizontal-menu-bg"
                style={{
                  borderRadius: 25,
                  width: "90%",
                  marginLeft: "5%",
                }}
              >
                <ul
                  className="nav nav-tabs lined horizontal-menu-bg"
                  role="tablist"
                  style={{
                    borderRadius: 25,
                    width: "90%",
                    marginLeft: "5%",
                  }}
                >
                  <li className="nav-item">
                    <a
                      className={
                        displayTab === 1 ? "nav-link active" : "nav-link"
                      }
                      onClick={() => setDisplay(1)}
                    >
                      <span
                        className={`text-muted-small ${
                          displayTab === 1 ? "nav-link-active-text" : ""
                        }`}
                      >
                        <IoList size={sc.IconSize20} />
                        <br />
                        <span style={{ fontSize: sc.IconLabel9px }}>
                          Facilities
                        </span>
                      </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        displayTab === 2 ? "nav-link active" : "nav-link"
                      }
                      onClick={() => setDisplay(2)}
                    >
                      <span
                        className={`text-muted-small ${
                          displayTab === 2 ? "nav-link-active-text" : ""
                        }`}
                      >
                        <IoInformationOutline size={sc.IconSize20} />
                        <br />
                        <span style={{ fontSize: sc.IconLabel9px }}>
                          Additional Info
                        </span>
                      </span>
                    </a>
                  </li>

                  <li className="nav-item text-muted-small">
                    <a
                      className={
                        displayTab === 3 ? "nav-link active" : "nav-link"
                      }
                      onClick={() => setDisplay(3)}
                    >
                      <span
                        className={`text-muted-small ${
                          displayTab === 3 ? "nav-link-active-text" : ""
                        }`}
                      >
                        <IoConstruct size={sc.IconSize20} />
                        <br />
                        <span style={{ fontSize: sc.IconLabel9px }}>
                          Developments
                        </span>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <br />
              <div className="carpet-color-bg">
                <SwipeableViews
                  index={displayTab - 1}
                  onChangeIndex={handleSwipeChange}
                  style={{ clear: "both", margin: "0 5%" }}
                >
                  <div style={Object.assign({})}>
                    <ul className="listview image-listview">
                      {property.property?.facilities?.map((item) => (
                        <li key={item.facilityId} className=" carpet-color-bg">
                          <div className="item">
                            <img
                              src={
                                item.icon ? item.icon : auth.getFacilityUrl()
                              }
                              alt="image"
                              className="image"
                            />
                            <div className="in">
                              <div>
                                <span className="text-muted-dark">
                                  {item.name}{" "}
                                </span>
                                <br />
                                <span className="text-muted">{item.value}</span>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div style={Object.assign({})}>
                    <ul className="listview">
                      {property.property?.attributes?.map((item) => (
                        <li key={item.attributeId} className="carpet-color-bg">
                          <div className="item">
                            <div className="in">
                              <div>
                                <span className="text-muted-dark">
                                  {item.name}{" "}
                                </span>

                                <br />
                                <span className="text-muted">{item.value}</span>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div style={Object.assign({})}>
                    <ul className="listview">
                      {property.property?.propertyDevelopments?.map((item) => (
                        <li
                          key={item.propertyDevelopmentId}
                          className="carpet-color-bg"
                        >
                          <div className="item">
                            <div className="in">
                              <div>
                                <span className="text-muted-dark">
                                  {item.title}{" "}
                                </span>

                                <br />
                                <span className="text-muted">
                                  {item.description}
                                </span>
                              </div>
                            </div>
                          </div>
                          <span
                            className="text-muted"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              viewPropertyDevelopment(
                                item.propertyDevelopmentId ?? ""
                              )
                            }
                          >
                            <IoArrowForwardOutline size={sc.IconSize15} />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </SwipeableViews>
                <br />
                <br /> <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      )}
      {!dataLoaded && (
        <>
          <br />
          <LoadingTextPlaceHolder />
        </>
      )}

      <div
        style={{
          width: auth.appWidthFooter(),
          marginLeft: auth.appMarginFooter(),
          background: "#2d1f3b",
          borderRadius: 25,
          marginBottom: "2%",
          color: "white",
        }}
        className="appBottomMenu"
      >
        <>
          <div onClick={openRequestForm}>
            Send {property.property?.requestOption} Request
          </div>
        </>
      </div>
    </>
  );
};

export default Property;
