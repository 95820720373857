import React from "react";

export const containerStyles = {
  width: "100%",
  height: "100%",
  position: "relative",
};
type Props = {
  container?: any;
  extraStyles?: any;
};
function LoadingViewPlaceHolder({ container, extraStyles }: Props) {
  const loaderStyles = {
    backgroundColor: "#eee",
    width: "100%",
    overflow: "hidden",
    position: container ? "absolute" : "relative",
    ...extraStyles,
  };

  return (
    <div style={loaderStyles}>
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          background:
            "linear-gradient(to right, #eeeeee 10%, #dddddd 50%, #eeeeee 90%)",
          animation:
            "loaderSwipeAnim 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite",
          height: "100%",
        }}
      ></div>
    </div>
  );
}

export default LoadingViewPlaceHolder;
