import util from "../../Utilities/UtilityHelper";
import React, { useEffect, useState } from "react";
import * as moment from 'moment'


 const LoanSchedule =(props)=> {

  
  let items=props.scheduleData.items;


    let sum=props.scheduleData.sum;



    return (
      <div>
      
      
      <div className="">

      {/* <div className="card-header">
      <div ><span >Payment Schedule</span> </div>
                
                </div> */}
                <div>
                  <span className="text-muted">Assumes loan is disbursed {props.disbursementDate?props.disbursementDate:'today'}</span>
              <table className="table table-responsive">
                  <thead>
     <tr>
                          <th scope="col">Due Date</th>
                          <th scope="col" className="text-end">Principal</th>
                          <th scope="col" className="text-end">Interest</th>
                          <th scope="col" className="text-end">Total</th>
                      </tr>
                  </thead>
                  <tbody>

                  {items && items.map(item=>{
                     return <tr>
                      <td scope="row">{moment(item.date).format("DD.MMM.YY")}</td>
                      <td className="text-end">{item.principal}</td>
                      <td className="text-end">{item.interest}</td>
                      <td className="text-end">{util.commaCurrencyFormatter(item.total)}</td>
                  </tr>
                }) 
            }
                     
                  </tbody>
                  <thead>
                  {sum &&  <tr>
                          <th scope="row">Sum</th>
                          <th  scope="col" className="text-end">{sum.principal}</th>
                          <th  scope="col" className="text-end">{sum.interest}</th>
                          <th  scope="col" className="text-end">{util.commaCurrencyFormatter(sum.total)}</th>
                          </tr>}
                      </thead>
              </table>
          </div>

                </div>

      </div>
      
  
    );

}
export default LoanSchedule