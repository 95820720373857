import React from "react";
import { useNavigate } from "react-router-dom";
import sc from "../services/stringconst";
import auth from "../services/authservice";
import { useEffect } from "react";

const UpdateAvailable = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!auth.getPlatformIsNative()) {
      //navigate(sc.loginroute);
    }
  }, []);

  return (
    <>
      {/* <!-- App Header --> */}
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main extra-header-active full-height"
      >
        <div>New Update Available</div>
      </div>

      {/* <!-- * App Header -->

    <!-- App Capsule --> */}
      <div id="appCapsule" className="extra-header-active full-height">
        <div className="section mt-2 text-center">
          <div className="card">
            <div className="card-body pt-3 pb-3">
              <img
                src="assets/img/sample/photo/lend.png"
                alt="image"
                className="imaged w-50 "
              />
            </div>
          </div>
        </div>

        <div className="section mt-3 mb-3">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Update App</h2>
              For you to get the most out of LoanHub, we recommend you update
              from {auth.getPlatform() === "Android" ? "Pay" : "App"} store.
            </div>
          </div>
        </div>
      </div>
      {/* <!-- * App Capsule --> */}
    </>
  );
};
export default UpdateAvailable;
