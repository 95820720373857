import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import auth from "../../../services/authservice";
import { IoAddOutline, IoArrowForwardOutline } from "react-icons/io5";
import ErrorDto from "../../../models/AppFeature/ErrorDto";
import LoadingTextPlaceHolder from "../../shared/Preload/loadingtextplaceholder";
import propertyClient from "../../../httpClients/clients/propertyClient";
import PropertyDto from "../../../models/Property/PropertyDto";

type Props = {
  ShowAlert(message: string, title: string): any;
};

const PropertyList = ({ ShowAlert }: Props) => {
  const location = useLocation();

  let result: PropertyDto[] = [];
  const [data, setData] = useState(result);
  const [dataLoaded, setDataLoaded] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    GetProperties();
  }, []);

  const openProperty = (propertyId: string) => {
    navigate(sc.propertydetailsroute, {
      state: { propertyId: propertyId },
    });
  };

  const GetProperties = () => {
    propertyClient.GetProperties(true, "", 0, 10000).then(
      (result: PropertyDto[]) => {
        setDataLoaded(true);
        setData(result);
      },
      (error: ErrorDto) => {
        setDataLoaded(true);
        ShowAlert(error.message, sc.warning);
      }
    );
  };
  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="text-muted-medium">Properties</div>
      </div>

      <div id="appCapsule" className="extra-header-active full-height">
        <div>
          <ul className="listview">
            {data.map((item) => (
              <li key={item._id}>
                <div className="item">
                  <div className="in">
                    <div>
                      <span className="text-muted-dark">{item.title} </span>
                      <span className="text-muted">
                        - {item.availableCount} available
                      </span>

                      <br />
                      <span className="text-muted">
                        {item.location?.city}, {item.location?.state}
                      </span>
                    </div>
                  </div>
                </div>
                <span
                  className="text-muted"
                  style={{ cursor: "pointer" }}
                  onClick={() => openProperty(item._id ?? "")}
                >
                  <IoArrowForwardOutline size={sc.IconSize15} />
                </span>
              </li>
            ))}
          </ul>
        </div>

        <br />

        {!dataLoaded && (
          <>
            <br />
            <LoadingTextPlaceHolder />
          </>
        )}
      </div>
    </>
  );
};
export default PropertyList;
