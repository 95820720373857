import React from "react";
import { useLocation } from "react-router-dom";
import sc from "../services/stringconst";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import auth from "../services/authservice";

const RepaymentConfirmed = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [scheduleStateData, setScheduleStateData] = useState();

  useEffect(() => {
    if (!location.state) {
      navigate(sc.homeroute);
      return;
    }

    if (location.state.stateData) {
      setScheduleStateData(location.state.stateData);
    }
  }, []);

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div>Repayment Confirmation</div>
        <div className="left"></div>
      </div>

      <div id="appCapsule" className="extra-header-active full-height">
        <div
          className="section mt-2 text-center"
          style={{ padding: "20% 10%" }}
        >
          <div className="card">
            <div className="card-body">
              <h3> Repayment Confirmed</h3>
            </div>
            <div className="card-body">
              <ion-icon
                name="checkmark-circle-outline"
                style={{ fontSize: "5em", color: "green" }}
              ></ion-icon>
              <br />
              <span className="text-muted">Ref# </span>{" "}
              <span style={{ fontSize: "1.1em" }}>
                {" "}
                {location.state.paymentRef}
              </span>
            </div>
          </div>
          <br />

          <div className="form-group transparent">
            <button
              type="submit"
              className="btn btn-primary btn-block"
              onClick={() =>
                navigate(
                  location.state.stateData
                    ? sc.loanrepaymentscheduleroute
                    : sc.paymentsroute,
                  { state: location.state.stateData }
                )
              }
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default RepaymentConfirmed;
