import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import auth from "../../../services/authservice";
import CustomButton from "../../childcomponents/custombutton";
import { IoChevronBackOutline } from "react-icons/io5";
import propertyClient from "../../../httpClients/clients/propertyClient";
import FacilityDto from "../../../models/Property/FacilityDto";
import ErrorDto from "../../../models/AppFeature/ErrorDto";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const AddFacilityType = ({ ShowAlert }: Props) => {
  const navigate = useNavigate();

  const [httpProcessing, setHttpProcessing] = useState(false);

  const back = () => {
    navigate(sc.facilitytypelistroute);
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setFacility((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  let facilityTemp: FacilityDto = {};
  const [facility, setFacility] = useState(facilityTemp);

  const addFacility = async () => {
    try {
      if (!facility.name) {
        ShowAlert(sc.namerequired, sc.warning);
        return;
      }

      if (!facility.icon) {
        ShowAlert(sc.iconrequired, sc.warning);
        return;
      }

      setHttpProcessing(true);

      propertyClient.CreateFacility(facility).then(
        () => {
          setHttpProcessing(false);
        },
        (error: ErrorDto) => {
          setHttpProcessing(false);
          ShowAlert(error.message, sc.warning);
        }
      );

      setHttpProcessing(false);

      navigate(sc.facilitytypelistroute);
    } catch (e: any) {
      setHttpProcessing(false);
      ShowAlert(e.message, sc.warning);
    }
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <IoChevronBackOutline size={sc.IconSize15} /> Back
          </a>
        </div>
        <div className="text-muted-medium">Facility</div>
      </div>

      <div
        id="appCapsule"
        className="extra-header-active full-height"
        style={{ background: "white" }}
      >
        <div>
          <div className="card-body pb-1">
            <div className="form-group basic">
              <label className="label" htmlFor="name">
                Name
              </label>
              <input
                required
                value={facility.name}
                onChange={handleChange}
                id="name"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>

            <div className="form-group basic">
              <label className="label" htmlFor="icon">
                Icon
              </label>
              <input
                required
                value={facility.icon}
                onChange={handleChange}
                id="icon"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>

            <br />

            <div>
              <CustomButton
                httpProcessing={httpProcessing}
                text="Add"
                onClick={addFacility}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddFacilityType;
