import React, { useState } from "react";
import CustomButton from "./custombutton";
import auth from "../../services/authservice";

type Props = {
  button1: string;
  button2: string;
  httpProcessing: boolean;
  action1(value: string): any;
  action2: any;
  color: string;
  label: string;
  value: string;
  inputType: string;
};
const InputEditModal = ({
  button1,
  button2,
  httpProcessing,
  action1,
  action2,
  color,
  label,
  value,
  inputType,
}: Props) => {
  const setValue = (e: any) => {
    setSelectedValue(e.target.value);
  };
  const [selectedValue, setSelectedValue] = useState(value);
  return (
    <div>
      <div
        style={{
          top: "35%",
          zIndex: 1001,
          textAlign: "center",
          width: auth.appWidth(),
          padding: "20px",
          position: "fixed",
        }}
      >
        <div
          className="card"
          style={{
            textAlign: "center",
            width: "100%",
            boxShadow:
              "0 8px 30px 0 rgba(0, 0, 0, 0.2), 0 16px 90px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <div className="modal-content">
            <div
              style={{
                paddingLeft: "1em",
                paddingRight: "1em",
                paddingTop: "2em",
              }}
            >
              <div className="form-group basic">
                <label className="label" htmlFor="title">
                  {label}
                </label>
                <input
                  onChange={setValue}
                  id="value"
                  value={selectedValue}
                  style={{ width: "100%" }}
                  className="form-control"
                  type={inputType}
                />
              </div>
              <div className="btn-list">
                {button1 && (
                  <CustomButton
                    httpProcessing={httpProcessing}
                    text={button1}
                    onClick={() => action1(selectedValue)}
                    bgColor={color}
                    outline={true}
                  />
                )}
                {button2 && (
                  <a
                    onClick={action2}
                    className="btn btn-text-secondary btn-block text-muted"
                    style={{ padding: "2em", fontWeight: "lighter" }}
                  >
                    {button2}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputEditModal;
