import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import moment from "moment";
import {
  IoAddOutline,
  IoCheckmarkOutline,
  IoChevronBackOutline,
  IoCloseOutline,
  IoConstructOutline,
  IoImages,
  IoImagesOutline,
  IoListOutline,
} from "react-icons/io5";
import auth from "../../../services/authservice";
import ValueLabel from "../../childcomponents/valuelabel";
import LoadingTextPlaceHolder from "../../shared/Preload/loadingtextplaceholder";
import ConfirmModal from "../../childcomponents/confirmmodal";
import { useSelector } from "react-redux";
import { getUser } from "../../../store/store";
import propertyClient from "../../../httpClients/clients/propertyClient";
import UtilityHelper from "../../../Utilities/UtilityHelper";
import SwipeableViews from "react-swipeable-views";
import ErrorDto from "../../../models/AppFeature/ErrorDto";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";
import FileManager from "../../../services/FileManager";
import PropertyMediaDto from "../../../models/Property/PropertyMediaDto";
import { Buffer } from "buffer";
import PropertyDevelopmentDto from "../../../models/Property/PropertyDevelopmentDto";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const PropertyDevelopmentDetails = ({ ShowAlert }: Props) => {
  let location = useLocation();
  const propertyId = location.state.propertyId;
  const developmentId = location.state.propertyDevelopmentId;

  const user = useSelector(getUser);

  let developmentTemp: PropertyDevelopmentDto = {};
  const [development, setPropertyDevelopment] = useState(developmentTemp);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [displayTab, setDisplayTab] = useState(1);

  const addOption = [1];

  let mediasTemp: MediaDto[] = [];
  const [medias, setMedias] = useState(mediasTemp);
  let imageHolder: {
    url: string;
    mediaId: string | undefined;
    thumbnail: string;
    type: string | undefined;
  }[] = [];

  const [showDeleteMediaConfirmModal, setShowDeleteMediaConfirmModal] =
    useState(false);

  const [activeMediaId, setActiveMediaId] = useState("");

  const cancelDeleteMediaModal = () => {
    setShowDeleteMediaConfirmModal(false);
    setActiveMediaId("");
  };

  const openDeleteMediaModal = async (mediaId: string) => {
    if (!mediaId) {
      ShowAlert("Media is invalid", sc.danger);
      return;
    }

    setShowDeleteMediaConfirmModal(true);
    setActiveMediaId(mediaId);
  };

  const handleSwipeChange = (e: number) => {
    setTimeout(function () {
      setDisplayTab(e + 1);
    }, 1);
  };

  let navigate = useNavigate();
  const [httpProcessing, setHttpProcessing] = useState(false);

  useEffect(() => {
    GetPropertyDevelopment();
  }, []);

  const GetPropertyDevelopment = () => {
    propertyClient.GetProperty(propertyId).then(
      (data) => {
        setDataLoaded(true);

        let development = data.propertyDevelopments?.find(
          (o) => o.propertyDevelopmentId === developmentId
        );

        if (!development) {
          navigate(sc.propertydetailsroute, {
            state: { propertyId: propertyId, tabSelection: 4 },
          });
          return;
        }
        console.log(JSON.stringify(development));

        setPropertyDevelopment(development);

        if (development?.media && development?.media?.length > 0) {
          const mediasTemp: MediaDto[] = [];
          development?.media.forEach((item) => {
            const url = FileManager.getPropertyMediaUrl(
              item.mediaId ?? "",
              propertyId,
              item.type ?? ""
            );
            const thumbnail = FileManager.getPropertyMediaUrl(
              item.mediaId ?? "",
              propertyId,
              sc.image
            );

            mediasTemp.push({
              url: url,
              mediaId: item.mediaId,
              thumbnail: thumbnail,
              type: item.type,
            });
          });
          setMedias(mediasTemp);
        }
      },
      (error) => {
        setDataLoaded(true);
        ShowAlert(error.response, sc.danger);
      }
    );
  };
  const selectMediaFiles = async (e: any) => {
    mediasTemp = [];
    imageHolder = [];
    if (e.target.files) {
      let batchCount = e.target.files.length;
      for (let i = 0; i < e.target.files.length; i++) {
        const mediaType =
          e.target.files[i].type.indexOf(sc.video) > -1 ? sc.video : sc.image;
        const mediaId = UtilityHelper.UniqueIdGenerator();
        if (mediaType == sc.video) {
          FileManager.uploadMediaAsync(
            e.target.files[i],
            mediaId + sc.mp4Ext,
            FileManager.getPropertyMediaPath(propertyId)
          ).then((result) => {
            addPropertyMedia(mediaId, mediaType, batchCount, true);
          });

          generateVideoThumbnails(e.target.files[i], 1, "base64").then(
            (thumbs) => {
              const file = Buffer.from(
                thumbs[0].replace(/^data:image\/\w+;base64,/, ""),
                "base64"
              );

              FileManager.uploadMediaAsync(
                file,
                mediaId + sc.jpgExt,
                FileManager.getPropertyMediaPath(propertyId)
              ).then((result) => {
                addPropertyMedia(mediaId, sc.image, batchCount, false);
              });
            }
          );
        } else {
          FileManager.uploadMediaAsync(
            e.target.files[i],
            mediaId + sc.jpgExt,
            FileManager.getPropertyMediaPath(propertyId)
          ).then((result) => {
            addPropertyMedia(mediaId, mediaType, batchCount, true);
          });
        }
      }
    }
  };

  const addPropertyMedia = (
    mediaId: string,
    mediaType: string,
    batchCount: number,
    saveToDb: boolean
  ) => {
    const url = FileManager.getPropertyMediaUrl(
      mediaId ?? "",
      propertyId,
      mediaType ?? ""
    );
    const thumbnail = FileManager.getPropertyMediaUrl(
      mediaId ?? "",
      propertyId,
      sc.image
    );

    let propertyMedia: PropertyMediaDto = {
      mediaId: mediaId,
      type: mediaType,
      default: false,
    };

    if (saveToDb) {
      imageHolder.push({
        url: url,
        mediaId: mediaId,
        thumbnail: thumbnail,
        type: mediaType,
      });
      mediasTemp.push(propertyMedia);
    }

    if (batchCount == mediasTemp.length) {
      propertyClient
        .AddPropertyDevelopmentMedia(propertyId, developmentId, mediasTemp)
        .then((data: PropertyMediaDto[]) => {
          mediasTemp = [];
        });
    }

    if (batchCount == imageHolder.length) {
      let tempMedias = [...medias];
      imageHolder.forEach((element) => {
        tempMedias.push(element);
      });
      setMedias(tempMedias);
      imageHolder = [];
    }
  };

  const deleteMedia = () => {
    propertyClient
      .RemovePropertyDevelopmentMedia(propertyId, developmentId, activeMediaId)
      .then(
        () => {
          setHttpProcessing(false);

          let tempMedias = [...medias];
          let tempMediaIndex =
            tempMedias.findIndex((n) => n.mediaId == activeMediaId) ?? -1;
          if (tempMediaIndex > -1) {
            tempMedias.splice(tempMediaIndex, 1);
          }
          setMedias(tempMedias);
          development.media = tempMedias;

          setActiveMediaId("");
          setShowDeleteMediaConfirmModal(false);
          ShowAlert("Media Removed", sc.success);
        },
        (error: ErrorDto) => {
          setHttpProcessing(false);
          ShowAlert(error.message, sc.warning);
        }
      );
  };

  const back = () => {
    navigate(-1);
  };
  const setDisplay = (tab: number) => {
    setDisplayTab(tab);
  };

  const openMedia = (media: MediaDto) => {
    if (media.type === sc.image) return;
  };

  const addItem = () => {
    if (displayTab === 1) {
      $("#mediaInput").click();
    }
  };

  return (
    <>
      {dataLoaded && (
        <div>
          <div
            style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
            className="appHeader bg-primary-main  bg-primary text-light"
          >
            <div className="left">
              <a onClick={back} className="headerButton goBack">
                <IoChevronBackOutline size={sc.IconSize15} /> Back
              </a>
            </div>
            <div className="text-muted-medium">Development</div>
          </div>
          <div id="appCapsule" className="extra-header-actives">
            <div>
              <div>
                <div style={{ margin: "1em" }}>
                  {" "}
                  <ValueLabel
                    value={development?.title ?? ""}
                    label={"Title"}
                  />
                  <ValueLabel
                    value={development?.description ?? ""}
                    label={"Description"}
                  />
                  <ValueLabel
                    value={moment(
                      UtilityHelper.unixTimeStampToDate(
                        development?.developmentDate ?? 0
                      )
                    ).format("DD.MMM.YY")}
                    label={"Project Date"}
                  />
                </div>
              </div>
            </div>

            <div
              className="horizontal-menu-bg"
              style={{
                borderRadius: 25,
                width: "90%",
                marginLeft: "5%",
              }}
            >
              <ul
                className="nav nav-tabs lined horizontal-menu-bg"
                role="tablist"
                style={{
                  borderRadius: 25,
                  width: "90%",
                  marginLeft: "5%",
                }}
              >
                <li className="nav-item text-muted-small">
                  <a
                    className={
                      displayTab === 1 ? "nav-link active" : "nav-link"
                    }
                    onClick={() => setDisplay(1)}
                  >
                    <span className="text-muted-small">
                      <IoImages size={sc.IconSize20} />
                      <br />
                      <span style={{ fontSize: sc.IconLabel9px }}>Media</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <input
              type="file"
              hidden
              onChange={selectMediaFiles}
              multiple
              accept="image/*,video/*"
              id="mediaInput"
            ></input>
            {addOption.indexOf(displayTab) > -1 && (
              <span
                className="btn btn-primary rounded"
                onClick={addItem}
                style={{
                  padding: 16,
                  float: "right",
                  position: "fixed",
                  right: 10,
                  top: "87%",
                  zIndex: 1001,
                }}
              >
                <IoAddOutline size={sc.IconSize25} />{" "}
                <span className="text-muted">
                  {displayTab === 1 && <IoImagesOutline size={sc.IconSize15} />}
                </span>
              </span>
            )}
            <br />
            <SwipeableViews
              index={displayTab - 1}
              onChangeIndex={handleSwipeChange}
              style={{ clear: "both", margin: "0 5%" }}
            >
              <div className="row">
                {medias.map((media) => (
                  <div
                    className="col-md-6"
                    style={{
                      marginBottom: "20px",
                      overflow: "hidden",
                    }}
                    onClick={() => openMedia(media)}
                  >
                    <IoCloseOutline
                      size={sc.IconSize20}
                      style={{
                        float: "right",
                        color: "purple",
                        background: "gold",
                        cursor: "pointer",
                        zIndex: "1",
                        position: "relative",
                      }}
                      onClick={() => openDeleteMediaModal(media.mediaId ?? "")}
                    />

                    {media.type == sc.image && (
                      <img
                        className=""
                        src={media.thumbnail}
                        style={{
                          width: "100%",
                          marginTop: "-20px",
                        }}
                      />
                    )}
                    {media.type == sc.video && (
                      <video width="320" height="240" controls>
                        <source src={media.url} type="video/mp4" />
                        <source src={media.url} type="video/ogg" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>
                ))}
              </div>
            </SwipeableViews>
          </div>
        </div>
      )}
      {!dataLoaded && (
        <>
          <br />
          <LoadingTextPlaceHolder />
        </>
      )}

      {showDeleteMediaConfirmModal && (
        <div>
          <div>
            <ConfirmModal
              button1="Remove"
              button2="Cancel"
              action1={deleteMedia}
              action2={cancelDeleteMediaModal}
              httpProcessing={httpProcessing}
              color={sc.dangerBorder}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PropertyDevelopmentDetails;
