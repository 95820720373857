import React, { useEffect, useState } from "react";
import auth from "../services/authservice";
import { useNavigate } from "react-router-dom";
import { Client } from "../services/apiclient";
import sc from "../services/stringconst";
import CustomButton from "./childcomponents/custombutton";

const Contact = (props) => {
  const client = new Client();
  const navigate = useNavigate();

  const [name, setName] = useState(
    `${auth.getUserProfileFirstname()} ${auth.getUserProfileLastname()}`
  );
  const [email, setEmail] = useState(auth.getUserProfileEmail());
  const [message, setMessage] = useState();
  const [httpProcessing, setHttpProcessing] = useState(false);

  const setNameValue = (e) => {
    setName(e.target.value);
  };
  const setEmailValue = (e) => {
    setName(e.target.value);
  };
  const setMessageValue = (e) => {
    setMessage(e.target.value);
  };

  const sendMessage = () => {
    if (!message) {
      props.ShowAlert("message is required", sc.warning);
      return;
    }
    let payload = {
      name,
      email,
      message,
    };

    setHttpProcessing(true);
    client.createcontactusmessage(payload).then(
      (data) => {
        setHttpProcessing(false);
        let tempMsg = [...message];
        tempMsg = "";
        setMessage(tempMsg);
        props.ShowAlert("message sent", sc.success);
      },
      (error) => {
        props.ShowAlert(error.response, sc.danger);
      }
    );
  };

  return (
    <>
      {/* <!-- App Header --> */}
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main bg-primary text-light"
      >
        <div className="left">
          <a onClick={() => navigate(-1)} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div>Contact</div>
      </div>
      {/* <!-- * App Header -->


    <!-- App Capsule --> */}
      <div id="appCapsule" className="extra-header-active full-height">
        <div className="section mt-2">
          <div className="card">
            <div className="card-body">
              <div className="p-1">
                <div className="text-center">
                  <h2 className="text-primary">Get in Touch</h2>
                  <p>Fill the form to contact us</p>
                </div>

                <div className="form-group basic animated">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="name2">
                      Your name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={setNameValue}
                      id="name2"
                      placeholder="Your name"
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>

                <div className="form-group basic animated">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="email2">
                      E-mail
                    </label>
                    <input
                      type="email"
                      value={email}
                      className="form-control"
                      onChange={setEmailValue}
                      id="email2"
                      placeholder="E-mail"
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>

                <div className="form-group basic animated">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="textarea2">
                      Message
                    </label>
                    <textarea
                      value={message}
                      id="textarea2"
                      rows="4"
                      className="form-control"
                      onChange={setMessageValue}
                      placeholder="Message"
                    ></textarea>
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>

                <div className="mt-2">
                  <CustomButton
                    httpProcessing={httpProcessing}
                    text="Send"
                    onClick={sendMessage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div className="section mt-2">
            <div className="card">
                <div className="card-body">
                    <div className="p-1">
                        <div className="text-center">
                            <h2 className="text-primary">Our Address</h2>
                            <p className="card-text">
                                PO Box 16122 Collins Street West<br>
                                Victoria 8007 Australia
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

        <div className="section mt-2 mb-2">
          <div className="card">
            <div className="card-body">
              <div className="p-1">
                <div className="text-center">
                  <h4 className="text-primary mb-2">Social Profiles</h4>

                  <a
                    href="https://www.facebook.com/LoanHub-106651795479408"
                    target="_blank"
                    className="btn btn-facebook btn-icon me-05"
                  >
                    <ion-icon name="logo-facebook"></ion-icon>
                  </a>
                  <a
                    href="https://twitter.com/LoanHub2"
                    target="_blank"
                    className="btn btn-twitter btn-icon me-05"
                  >
                    <ion-icon name="logo-twitter"></ion-icon>
                  </a>
                  <a
                    href="https://www.instagram.com/loanhubng"
                    target="_blank"
                    className="btn btn-instagram btn-icon me-05"
                  >
                    <ion-icon name="logo-instagram"></ion-icon>
                  </a>

                  <a
                    href="https://www.linkedin.com/company/loanhubng"
                    target="_blank"
                    className="btn btn-linkedin btn-icon me-05"
                  >
                    <ion-icon name="logo-linkedin"></ion-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- * App Capsule --> */}
    </>
  );
};
export default Contact;
