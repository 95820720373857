import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import auth from "../services/authservice";
import sc from "../services/stringconst";
import { Capacitor } from "@capacitor/core";
import { IoHomeOutline } from "react-icons/io5";
import UtilityHelper from "../Utilities/UtilityHelper";

const Welcome = () => {
  const navigate = useNavigate();

  useEffect(() => {
    try {
      let currentUser = auth.getCurrentUser();

      if (currentUser !== null && currentUser !== undefined) {
        navigate(UtilityHelper.getHomeRoute(currentUser.userRole));
      }

      auth.setPlatform(Capacitor.getPlatform());
      let platform = auth.getPlatform();
      if (!platform) {
        platform = "unknown";
      }
    } catch (e) {
      alert(e);
    }
  }, []);

  const getLoan = () => {
    navigate(sc.findpropertyroute);
  };

  const login = () => {
    navigate(sc.loginroute);
  };

  return (
    <div>
      <div>
        {/* <!-- 1. The <iframe> (video player) will replace this <div> tag. --> */}

        <div className="video-wrapper">
          {/* <img
            src="/assets/img/bg.png"
            style={{
              left: 0,
              height: "100%",
              width: "auto",
              position: "fixed",
            }}
          /> */}
          {/* <video
            autoPlay
            loop={true}
            muted
            playsInline
            onContextMenu={() => {
              return false;
            }}
            preload="auto"
            id="myVideo"
            style={{ height: "100%" }}
          >
            <source src="/assets/video/house.mp4" type="video/mp4" />
          </video> */}
        </div>
        <div
          className="col-md-12 text-center content"
          style={{ padding: auth.appWelcomeButtonPadding() }}
        >
          <br />
          <br />
          <br />
          <br />
          <img
            src="assets/img/sample/photo/lend.png"
            className="img-fluid"
            style={{ maxWidth: auth.appWelcomeLogoWidth() }}
          />
          <br />
          <br />
          <br />
          <button
            onClick={getLoan}
            type="button"
            className="btn btn-primary me-1 mb-1 btn-block"
            style={{
              padding: auth.appWelcomeButtonPadding(),
              maxWidth: auth.appWelcomeButtonWidth(),
              background: "#2d1f3b",
            }}
          >
            Find a Place &nbsp;&nbsp;
            <IoHomeOutline size={20} />
          </button>

          <br />
          <br />
        </div>

        <div></div>
      </div>
    </div>
  );
};

export default Welcome;
