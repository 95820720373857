import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import sc from "../services/stringconst";
import { Client } from "../services/apiclient";
import auth from "../services/authservice";

const FAQ = (props) => {
  const navigate = useNavigate();
  const client = new Client();

  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    client.getfaq().then(
      (data) => {
        setFaqs(data);
      },
      (error) => {
        props.ShowMessage(error.response, sc.danger);
      }
    );
  }, []);

  return (
    <>
      {/* <!-- App Header --> */}
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main bg-primary text-light"
      >
        <div className="left">
          <a
            onClick={() => navigate(sc.homeroute)}
            className="headerButton goBack"
          >
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div>FAQ</div>
      </div>
      {/* <!-- * App Header -->

    <!-- App Capsule --> */}
      <div id="appCapsule" className="extra-header-active full-height">
        <div className="section mt-2 text-center">
          <div className="">
            <div className="card-body pt-3 pb-3">
              <img
                src="assets/img/logo_transparent.png"
                alt="image"
                className="imaged"
                style={{ maxWidth: auth.appLoginLogoWidth() }}
              />
              <h2 className="mt-2">Frequently Asked Questions</h2>
            </div>
          </div>
        </div>

        {faqs.map((o) => (
          <div className="section inset mt-2">
            <div className="accordion" id={"faq" + o._id}>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  style={{ background: "#f9f9f9" }}
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#acc" + o._id}
                  >
                    {o.question}
                  </button>
                </h2>
                <div
                  id={"acc" + o._id}
                  className="accordion-collapse collapse"
                  data-bs-parent={"faq" + o._id}
                >
                  <div className="accordion-body">{o.answer}</div>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="section mt-3 mb-3">
          <div className="card bg-primary">
            <div className="card-body text-center">
              <h5 className="card-title">Still have question?</h5>
              <p className="card-text">Feel free to contact us</p>
              <a
                onClick={() => navigate(sc.contactroute)}
                className="btn btn-dark"
              >
                <ion-icon name="mail-open-outline"></ion-icon> Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FAQ;
