import AdminDashboardDto from "../../models/Dashboard/AdminDashboardDto";
import RealtorDashboardDto from "../../models/Dashboard/RealtorDashboardDto";
import UserDashboardDto from "../../models/Dashboard/UserDashboardDto";
import HttpClient from "../httpClient";
import v from "../variables";

let baseRoute = "dashboard/";

const AdminDashboard = async (): Promise<AdminDashboardDto> => {
  let route = v.baseUrl + baseRoute + "admin";

  return <AdminDashboardDto>await HttpClient.getRequest(route);
};
const UserDashboard = async (userId: string): Promise<UserDashboardDto> => {
  let route = v.baseUrl + baseRoute + "user/" + userId;

  return <UserDashboardDto>await HttpClient.getRequest(route);
};
const RealtorDashboard = async (
  realtorId: string
): Promise<RealtorDashboardDto> => {
  let route = v.baseUrl + baseRoute + "realtor/" + realtorId;

  return <RealtorDashboardDto>await HttpClient.getRequest(route);
};

const dashboardClient = {
  AdminDashboard,
  UserDashboard,
  RealtorDashboard,
};

export default dashboardClient;
