import React, { useEffect } from "react";
import { Client } from "../services/apiclient";
import { useState } from "react";
import sc from "../services/stringconst";
import util from "../Utilities/UtilityHelper";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as moment from "moment";
import ConfirmModal from "./childcomponents/confirmmodal";
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from "@capacitor/camera";
import auth from "../services/authservice";
import ImageKit from "imagekit-javascript";
import CustomButton from "./childcomponents/custombutton";
import AppVer from "../services/apiclient";

const NewPayment = (props) => {
  const navigate = useNavigate();
  const client = new Client(null, null);
  const location = new useLocation();

  const [httpProcessing, setHttpProcessing] = useState(false);

  const imagekit = new ImageKit({
    urlEndpoint: sc.imagekitUrlEndPoint,
    publicKey: sc.imagekitPublicKey,
    authenticationEndpoint:
      sc.appbaseroute +
      sc.cloudImageRoute +
      auth.getSessionToken() +
      "/" +
      AppVer.getAppVer(),
  });

  const [dueAmount, setDueAmount] = useState(0);
  const [dueDate, setDueDate] = useState();
  const [loan, setLoan] = useState({});

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [amount, setAmount] = useState();
  const [note, setNote] = useState();
  const [receiptPhoto, setReceiptPhoto] = useState();
  const [ReceiptLabel, setReceiptLabel] = useState("Attach Receipt (optional)");
  const [scheduleStateData, setScheduleStateData] = useState();

  useEffect(() => {
    if (!location.state) {
      navigate(sc.homeroute);
      return;
    }

    setLoan(location.state.loan);
    setDueAmount(location.state.dueAmount);
    setDueDate(location.state.dueDate);

    if (location.state.stateData) {
      setScheduleStateData(location.state.stateData);
    }
  }, []);

  const attachReceipt = async () => {
    setReceiptLabel("Attach Receipt (optional)");
    const photo = await Camera.getPhoto({
      source: CameraSource.Prompt,
      resultType: CameraResultType.Base64,
      quality: 70,
    });
    if (photo.base64String) {
      setReceiptPhoto(sc.base64ImagePrefixPng + photo.base64String);
      setReceiptLabel("Receipt Attached");
    }
  };

  const attachWebReceipt = (e) => {
    setReceiptLabel("Attach Receipt (optional)");

    if (e.target.files.length > 0) {
      if (e.target.files[0].type.indexOf("image") === -1) {
        props.ShowAlert(sc.invalidImageFileSelected, sc.danger);
        return;
      }

      if (e.target.files[0].size > 5000000) {
        // in bytes equvalent to 5mb
        props.ShowAlert(sc.fileTooLargeError, sc.danger);
        return;
      }

      setReceiptPhoto(e.target.files[0]);
      setReceiptLabel("Receipt Attached");
    }
  };

  const setAmountValue = (e) => {
    setAmount(e.target.value);
  };
  const setNoteValue = (e) => {
    setNote(e.target.value);
  };

  const submitPayment = () => {
    if (!amount) {
      props.ShowAlert(sc.AmountRequired, sc.danger);
      return;
    }
    if (amount < 0) {
      props.ShowAlert(sc.InvalidAmount, sc.danger);
      return;
    }

    setHttpProcessing(true);
    if (receiptPhoto) {
      imagekit.upload(
        {
          file: receiptPhoto,
          fileName: sc.getUUId(),
          tags: ["paymentreceipt"],
          extensions: [
            {
              name: "aws-auto-tagging",
              minConfidence: 80,
              maxTags: 10,
            },
          ],
        },
        function (err, result) {
          makePayment(amount, note, result.url);
        }
      );
    } else {
      makePayment(amount, note, null);
    }
  };

  const makePayment = (amount, note, fileUrl) => {
    client
      .makerepayment(loan._id, {
        amount: amount,
        note: note,
        receiptPhoto: fileUrl,
      })
      .then(
        (data) => {
          setHttpProcessing(false);
          navigate(sc.paymentsubmissionsuccessfulroute, { state: data });
        },
        (error) => {
          setHttpProcessing(false);
          props.ShowAlert(error.response, sc.danger);
        }
      );
  };
  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div>New Payment</div>
        <div className="left">
          <a
            onClick={() =>
              navigate(sc.loanrepaymentscheduleroute, {
                state: scheduleStateData,
              })
            }
            className="headerButton goBack"
          >
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
      </div>

      <div id="appCapsule" className="extra-header-active full-height">
        <div className="section mt-2">
          <div className="card">
            <div className="card-body item">
              <div className="detail" style={{ width: "100%" }}>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <h4>{loan.title}</h4>
                  </div>
                </div>

                <table
                  className="table table-responsive"
                  style={{ width: "100%" }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <div className="form-group basic">
                          <div className="input-wrapper">
                            <label className="label" htmlFor="amount">
                              Amount Due
                            </label>
                            <h5>{util.commaCurrencyFormatter(dueAmount)}</h5>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="form-group basic">
                          <div className="input-wrapper">
                            <label className="label" htmlFor="dueDate">
                              Due Date
                            </label>
                            <h5>{moment(dueDate).format("DD.MMM.YY")}</h5>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="amount">
                      Amount
                    </label>
                    <input
                      value={amount}
                      onChange={setAmountValue}
                      type="number"
                      className="form-control"
                      id="amount"
                      placeholder="Enter amount here"
                    />
                  </div>
                </div>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="amount">
                      Note
                    </label>
                    <textarea
                      value={note}
                      onChange={setNoteValue}
                      className="form-control"
                      id="amount"
                      placeholder="Payment note"
                    ></textarea>
                  </div>
                </div>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <input
                      type="file"
                      hidden
                      onChange={attachWebReceipt}
                      id="myInput"
                    ></input>

                    <ul class="listview image-listview inset mb-2">
                      <li>
                        {auth.getPlatformIsNative() && (
                          <a class="item">
                            <span className="icon-box bg-primary">
                              <ion-icon
                                onClick={attachReceipt}
                                name="add-outline"
                              ></ion-icon>
                            </span>
                            <span className="text-muted">
                              {ReceiptLabel}
                              <br />
                              Max size - 5mb
                            </span>
                          </a>
                        )}
                        {!auth.getPlatformIsNative() && (
                          <a class="item">
                            <span className="icon-box bg-primary">
                              <ion-icon
                                onclick="$('#myInput').click();"
                                name="add-outline"
                              ></ion-icon>
                            </span>
                            <span className="text-muted">
                              {ReceiptLabel}
                              <br />
                              Max size - 5mb
                            </span>
                          </a>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="form-group transparent">
                  <CustomButton
                    httpProcessing={httpProcessing}
                    text="Submit Payment"
                    onClick={submitPayment}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showConfirmModal && (
        <ConfirmModal
          title="Confirm Payment"
          message="Proceed to submit payment?"
          buttons={["Proceed", "Cancel"]}
          action1={submitPayment}
          action2={() => setShowConfirmModal(false)}
        />
      )}
    </>
  );
};
export default NewPayment;
