import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import sc from "../../../services/stringconst";
import DashboardClient from "../../../httpClients/clients/dashboardClient";
import AdminDashboardDto from "../../../models/Dashboard/AdminDashboardDto";
import RealtorDashboardDto from "../../../models/Dashboard/RealtorDashboardDto";
import authservice from "../../../services/authservice";

type Props = {
  ShowAlert(message: string, title: string): any;
  setNotifications(messageCount: number, notificationCount: number): any;
};

const RealtorDashboard = ({ ShowAlert, setNotifications }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    GetDashboardData();
  }, []);

  let temp: RealtorDashboardDto = {};
  const [data, setData] = useState(temp);

  const GetDashboardData = async () => {
    setNotifications(3, 4);

    setData(
      await DashboardClient.RealtorDashboard(
        authservice.getCurrentUser()?.realtorId ?? ""
      )
    );
  };
  return (
    <div id="appCapsule" className="extra-header-active full-height">
      <div className="section wallet-card-section pt-1">
        <div className="wallet-card">
          <div className="section">
            <div className="row mt-2">
              <div className="col-6">
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>
                    {data.newPropertyRequestCount ?? 0}
                  </span>
                  <h6>New Request</h6>
                </div>
              </div>
              <div className="col-6">
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>
                    {data.activePropertyRequestCount ?? 0}
                  </span>
                  <h6>Active Request</h6>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>
                    {data.propertyRequestCount ?? 0}
                  </span>
                  <h6>All Request</h6>
                </div>
              </div>
              <div className="col-6">
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>
                    {data.propertyCount ?? 0}
                  </span>
                  <h6>Property</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};
export default RealtorDashboard;
