import React, { useState } from "react";
import auth from "../../services/authservice";



const RatingModal =(props)=>{

  const [comment, setComment]= useState();
  const [rating, setRating]= useState(0);

  const [iconOneColor, setIconOneColor]=useState();
  const [iconTwoColor, setIconTwoColor]=useState();
  const [iconThreeColor, setIconThreeColor]=useState();
  const [iconFourColor, setIconFourColor]=useState();
  const [iconFiveColor, setIconFiveColor]=useState();

  const setCommentValue=(e)=>{
    setComment(e.target.value);
  }

  const setRatingValue=(rating)=>{
    let activeRating="text-warning";
    setRating(rating);
      setIconOneColor(rating>=1?activeRating:"");
      setIconTwoColor(rating>=2?activeRating:"");
      setIconThreeColor(rating>=3?activeRating:"");
      setIconFourColor(rating>=4?activeRating:"");
      setIconFiveColor(rating>=5?activeRating:"");

  }

return(
  <div>

<div className="card" style={{width:auth.appWidth(), position:"fixed",top:"25%", textAlign:"center"}}>
<div className="modal-content" >
    <div className="modal-header" style={{width:auth.appWidth(), marginLeft:auth.appMargin()}}>
                          <h4 style={{width:"100%"}} className="modal-title text-primary"><b>Rating</b></h4>
                      </div>
                      <div className="modal-body">
                      <span className={iconOneColor}><ion-icon style={{fontSize:"2.3em"}} onClick={()=>setRatingValue(1)} name="star-outline"></ion-icon></span>
                      <span className={iconTwoColor}><ion-icon style={{fontSize:"2.3em"}} onClick={()=>setRatingValue(2)} name="star-outline"></ion-icon></span>
                      <span className={iconThreeColor}><ion-icon style={{fontSize:"2.3em"}} onClick={()=>setRatingValue(3)} name="star-outline"></ion-icon></span>
                      <span className={iconFourColor}><ion-icon style={{fontSize:"2.3em"}} onClick={()=>setRatingValue(4)} name="star-outline"></ion-icon></span>
                      <span className={iconFiveColor}><ion-icon style={{fontSize:"2.3em"}} onClick={()=>setRatingValue(5)} name="star-outline"></ion-icon></span>
                      
                      <div className="form-group basic">
                        <div className="input-wrapper">
                        <br/>
                          <textarea cols="5" rows="3"
                            type="text"
                            value={comment}
                            onChange={setCommentValue}
                            className="form-control"
                            id="comment"
                            placeholder="type comment here..."
                          />
                        </div>
                      </div>
                      <div className="btn-list">
                        <br/>
                         <a onClick={()=>props.submitRating(rating,comment)} className="btn btn-primary">Submit</a>
                         <a onClick={props.cancelShowRatingModal} className="btn btn-text-primary" >Cancel</a>
                          </div>
                      </div>
                    
      </div>
      
      </div>
</div>
)
};

export default RatingModal;

