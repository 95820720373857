import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import realtorClient from "../../../httpClients/clients/realtorClient";
import RealtorDetailDto from "../../../models/Realtor/RealtorDetailDto";
import sc from "../../../services/stringconst";
import {
  IoAddOutline,
  IoArrowForwardOutline,
  IoBusiness,
  IoBusinessOutline,
  IoChevronBackOutline,
  IoList,
  IoMap,
  IoMapOutline,
  IoNavigateOutline,
  IoPerson,
  IoPersonOutline,
  IoTrashOutline,
  IoWallet,
  IoWalletOutline,
} from "react-icons/io5";
import SwipeableViews from "react-swipeable-views";

import auth from "../../../services/authservice";
import ValueLabel from "../../childcomponents/valuelabel";
import LoadingTextPlaceHolder from "../../shared/Preload/loadingtextplaceholder";
import ConfirmModal from "../../childcomponents/confirmmodal";
import { useSelector } from "react-redux";
import { getUser } from "../../../store/store";
import ValueLabelArray from "../../childcomponents/valuelabelarray";

type Props = {
  ShowAlert(message: string, title: string): any;
};

const styles = {
  slide: {
    padding: 15,
    minHeight: 100,
    color: "#fff",
  },
  slide1: {
    background: "#FEA900",
  },
  slide2: {
    background: "#B3DC4A",
  },
  slide3: {
    background: "#6AC0FF",
  },
};
const RealtorDetail = ({ ShowAlert }: Props) => {
  let location = useLocation();
  const realtorId = location.state.realtorId;
  let tabSelection = location.state.tabSelection;
  let backTabSelection = location.state.backTabSelection;
  const user = useSelector(getUser);

  let realtorTemp: RealtorDetailDto = {};
  const [realtor, setRealtor] = useState(realtorTemp);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [displayTab, setDisplayTab] = useState(
    tabSelection ?? backTabSelection ?? 1
  );

  const [showDeleteAccountConfirmModal, setShowDeleteAccountConfirmModal] =
    useState(false);
  const addOption = [2, 3, 4];

  const handleSwipeChange = (e: number) => {
    setTimeout(function () {
      setDisplayTab(e + 1);
    }, 1);
  };

  const [activeBankAccountNumber, setActiveBankAccountNumber] = useState("");

  const cancelDeleteBankAccountModal = () => {
    setShowDeleteAccountConfirmModal(false);
    setActiveBankAccountNumber("");
  };
  const deleteBankAccount = async () => {
    setShowDeleteAccountConfirmModal(false);
    await realtorClient.RemoveRealtorBankAccount(
      realtorId,
      activeBankAccountNumber
    );
    setActiveBankAccountNumber("");
    GetRealtor();
    ShowAlert("Account Removed", sc.secondary);
  };
  const openDeleteBankAccountModal = async (accountNumber: string) => {
    if (!accountNumber) {
      ShowAlert("Account Number is invalid", sc.danger);
      return;
    }

    setShowDeleteAccountConfirmModal(true);
    setActiveBankAccountNumber(accountNumber);
  };

  let navigate = useNavigate();
  const [httpProcessing, setHttpProcessing] = useState(false);

  useEffect(() => {
    GetRealtor();
  }, []);

  const GetRealtor = () => {
    realtorClient.GetRealtorDetail(realtorId).then(
      (data) => {
        setDataLoaded(true);
        setRealtor(data);
      },
      (error) => {
        setDataLoaded(true);
        ShowAlert(error.response, sc.danger);
      }
    );
  };

  const back = () => {
    navigate(-1);
  };
  const setDisplay = (tab: number) => {
    setDisplayTab(tab);
  };

  const contactPersonDetail = (representativeId?: string) => {
    navigate(sc.representativedetailroute, {
      state: {
        route: sc.realtordetailroute,
        realtorId: realtorId,
        representativeId: representativeId,
        tabSelection: 3,
      },
    });
  };

  const openProperty = (propertyId: string) => {
    navigate(sc.propertydetailsroute, {
      state: {
        propertyId: propertyId,
        realtorId: realtorId,
        tabSelection: 1,
        backTabSelection: 2,
        route: sc.realtordetailroute,
      },
    });
  };

  const addItem = () => {
    if (displayTab === 2) {
      navigate(sc.addpropertyroute, {
        state: { realtorId: realtorId },
      });
    }
    if (displayTab === 3) {
      navigate(sc.addRepresentativeRoute, {
        state: { realtorId: realtorId },
      });
    }
    if (displayTab === 4) {
      navigate(sc.addbankaccountroute, {
        state: { realtorId: realtorId },
      });
    }
  };

  return (
    <>
      {dataLoaded && (
        <div>
          <div
            style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
            className="appHeader bg-primary-main  bg-primary text-light"
          >
            <div className="left">
              <a onClick={back} className="headerButton goBack">
                <IoChevronBackOutline size={sc.IconSize15} /> Realtors
              </a>
            </div>
            <div className="text-muted-medium">Realtor</div>
          </div>
          <div id="appCapsule" className="extra-header-active">
            <div style={{ margin: "1em" }}>
              <div>
                <ValueLabel
                  value={realtor.realtor?.name ?? ""}
                  label={"Name"}
                />

                <ValueLabelArray
                  values={[
                    realtor.realtor?.location?.address ?? "",
                    `${realtor.realtor?.location?.city ?? ""}, ${
                      realtor.realtor?.location?.state ?? ""
                    }`,
                  ]}
                  label={"Address"}
                />

                <a
                  target="blank"
                  style={{ float: "right", marginTop: "-3.3em" }}
                  href={`http://maps.google.com/maps?z=12&t=m&q=loc:${realtor.realtor?.location?.latitude}+${realtor.realtor?.location?.longitude}`}
                >
                  <span className="text-primary-main">
                    <IoNavigateOutline size={sc.IconSize25} />
                  </span>
                </a>
              </div>
            </div>

            <div
              className="horizontal-menu-bg"
              style={{
                borderRadius: 25,
                width: "90%",
                marginLeft: "5%",
              }}
            >
              <ul
                className="nav nav-tabs lined horizontal-menu-bg"
                role="tablist"
                style={{
                  borderRadius: 25,
                  width: "90%",
                  marginLeft: "5%",
                }}
              >
                <li className="nav-item">
                  <a
                    className={
                      displayTab === 1 ? "nav-link active" : "nav-link"
                    }
                    onClick={() => setDisplay(1)}
                  >
                    <span
                      className={`text-muted-small ${
                        displayTab === 1 ? "nav-link-active-text" : ""
                      }`}
                    >
                      <IoList size={sc.IconSize20} />
                      <br />
                      <span style={{ fontSize: sc.IconLabel9px }}>Request</span>
                    </span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      displayTab === 2 ? "nav-link active" : "nav-link"
                    }
                    onClick={() => setDisplay(2)}
                  >
                    <span
                      className={`text-muted-small ${
                        displayTab === 2 ? "nav-link-active-text" : ""
                      }`}
                    >
                      <IoBusiness size={sc.IconSize20} />
                      <br />
                      <span style={{ fontSize: sc.IconLabel9px }}>
                        Properties
                      </span>
                    </span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      displayTab === 3 ? "nav-link active" : "nav-link"
                    }
                    onClick={() => setDisplay(3)}
                  >
                    <span
                      className={`text-muted-small ${
                        displayTab === 3 ? "nav-link-active-text" : ""
                      }`}
                    >
                      <IoPerson size={sc.IconSize20} />
                      <br />
                      <span style={{ fontSize: sc.IconLabel9px }}>
                        Representatives
                      </span>
                    </span>
                  </a>
                </li>
                {user.isAdmin && (
                  <li className="nav-item text-muted-small">
                    <a
                      className={
                        displayTab === 4 ? "nav-link active" : "nav-link"
                      }
                      onClick={() => setDisplay(4)}
                    >
                      <span
                        className={`text-muted-small ${
                          displayTab === 4 ? "nav-link-active-text" : ""
                        }`}
                      >
                        <IoWallet size={sc.IconSize20} />
                        <br />
                        <span style={{ fontSize: sc.IconLabel9px }}>
                          Accounts
                        </span>
                      </span>
                    </a>
                  </li>
                )}
              </ul>
            </div>
            {addOption.indexOf(displayTab) > -1 && (
              <span
                className="btn btn-primary rounded"
                onClick={addItem}
                style={{
                  padding: 16,
                  float: "right",
                  position: "fixed",
                  right: 10,
                  top: "87%",
                  zIndex: 1001,
                }}
              >
                <IoAddOutline size={sc.IconSize25} />{" "}
                <span className="text-muted">
                  {" "}
                  {displayTab === 2 && (
                    <IoBusinessOutline size={sc.IconSize15} />
                  )}
                  {displayTab === 3 && <IoPersonOutline size={sc.IconSize15} />}
                  {displayTab === 4 && <IoWalletOutline size={sc.IconSize15} />}
                </span>
              </span>
            )}
            <br />
            <SwipeableViews
              index={displayTab - 1}
              onChangeIndex={handleSwipeChange}
              style={{ clear: "both", margin: "0 5%" }}
            >
              <div style={Object.assign({})}>
                <ul className="listview image-listview">
                  {realtor.contactPersons?.map((item) => (
                    <li key={item._id}>
                      <div className="item">
                        <img
                          src={
                            item.photoUrl
                              ? item.photoUrl
                              : auth.getDefaultImageUrl()
                          }
                          alt="image"
                          className="image"
                        />
                        <div
                          className="in"
                          onClick={() => contactPersonDetail(item._id)}
                        >
                          <div>
                            {item.firstname} {item.lastname}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div style={Object.assign({})}>
                <ul className="listview">
                  {realtor.properties?.map((item) => (
                    <li key={item._id}>
                      <div className="item">
                        <div className="in">
                          <div>
                            <span className="text-muted-dark">
                              {item.title}{" "}
                            </span>
                            <span className="text-muted">
                              - {item.availableCount} available
                            </span>

                            <br />
                            <span className="text-muted">
                              {item.location?.city}, {item.location?.state}
                            </span>
                          </div>
                        </div>
                      </div>
                      <span
                        className="text-muted"
                        style={{ cursor: "pointer" }}
                        onClick={() => openProperty(item._id ?? "")}
                      >
                        <IoArrowForwardOutline size={sc.IconSize15} />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <div style={Object.assign({})}>
                <ul className="listview image-listview">
                  {realtor.contactPersons?.map((item) => (
                    <li key={item._id}>
                      <div className="item">
                        <img
                          src={auth.loadUserImage(item.photoUrl)}
                          alt="image"
                          className="image"
                        />
                        <div className="in">
                          <div className="text-muted-dark">
                            {item.firstname} {item.lastname}
                            <br />
                            <span className="text-muted">
                              {item.mobileNumber}
                              <br />
                              {item.email}
                            </span>
                          </div>
                        </div>
                      </div>
                      <span
                        className="text-muted"
                        style={{ cursor: "pointer" }}
                        onClick={() => contactPersonDetail(item._id)}
                      >
                        <IoArrowForwardOutline size={sc.IconSize15} />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              {user.isAdmin && (
                <div style={Object.assign({})}>
                  <ul className="listview">
                    {realtor.realtor?.bankAccounts?.map((item) => (
                      <li key={item.accountNumber}>
                        <div className="item">
                          <div className="in">
                            <div>
                              <span className="text-muted-dark">
                                {item.accountNumber} {item.bank}
                              </span>
                              <br />
                              <span className="text-muted">
                                {item.accountName}
                              </span>
                            </div>
                          </div>
                        </div>
                        <span
                          className="text-muted"
                          onClick={() =>
                            openDeleteBankAccountModal(item.accountNumber ?? "")
                          }
                          style={{ padding: 0, float: "right" }}
                        >
                          <IoTrashOutline size={sc.IconSize15} />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </SwipeableViews>
          </div>
          <br />
        </div>
      )}
      {!dataLoaded && (
        <>
          <br />
          <LoadingTextPlaceHolder />
        </>
      )}

      {showDeleteAccountConfirmModal && (
        <div>
          <div>
            <ConfirmModal
              button1="Remove"
              button2="Cancel"
              action1={deleteBankAccount}
              action2={cancelDeleteBankAccountModal}
              httpProcessing={httpProcessing}
              color={sc.dangerBorder}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default RealtorDetail;
