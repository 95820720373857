import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import auth from "../../../services/authservice";
import CustomButton from "../../childcomponents/custombutton";
import appFeaturesClient from "../../../httpClients/clients/appFeaturesClient";
import StateDto from "../../../models/AppFeature/StateDto";
import CityDto from "../../../models/AppFeature/CityDto";
import { IoChevronBack, IoCloseCircle } from "react-icons/io5";
import sharedStyles from "./../../shared/app.style";
import ErrorDto from "../../../models/AppFeature/ErrorDto";
import RealtorDto from "../../../models/Realtor/RealtorDto";
import realtorClient from "../../../httpClients/clients/realtorClient";
import geoLocationClient from "../../../httpClients/clients/geoLocationClient";

type Props = {
  ShowAlert(message: string, title: string): any;
};

const RealtorList = ({ ShowAlert }: Props) => {
  const location = useLocation();

  const handleChange = (e: any) => {
    if (e.target.id !== "name" && e.target.id !== "address")
      e.target.value = e.target?.value.trim();
    const { id, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  let result = {
    name: "",
    address: "",
    city: "",
    state: "",
  };

  const [data, setData] = useState(result);

  let navigate = useNavigate();

  const [httpProcessing, setHttpProcessing] = useState(false);

  const CreateRealtor = async () => {
    //Validation Code here

    if (!data.name) {
      ShowAlert(sc.namerequired, sc.warning);
      return;
    }
    if (!data.address) {
      ShowAlert(sc.addressrequired, sc.warning);
      return;
    }
    if (!data.state) {
      ShowAlert(sc.staterequired, sc.warning);
      return;
    }
    if (!data.city) {
      ShowAlert(sc.cityrequired, sc.warning);
      return;
    }

    setHttpProcessing(true);

    let realtor: RealtorDto = {
      name: data.name,
      location: {
        address: data.address,
        city: data.city,
        state: data.state,
      },
    };
    realtorClient.CreateRealtor(realtor).then(
      (data: RealtorDto) => {
        setHttpProcessing(false);

        if (data) {
          navigate(sc.realtordetailroute, { state: data._id });
        }
      },
      (error: ErrorDto) => {
        setHttpProcessing(false);
        ShowAlert(error.message, sc.warning);
      }
    );
  };

  let statesInit: StateDto[] = [];
  let citiesInit: CityDto[] = [];

  let [states, setStates] = useState(statesInit);
  let [cities, setCities] = useState(citiesInit);

  useEffect(() => {
    appFeaturesClient.GetStates().then((result) => {
      setStates(result);
    });
  }, []);

  const getCities = (e: any) => {
    handleChange(e);
    appFeaturesClient.GetCities(e.target.value).then((result) => {
      setCities(result);
      setData((prevState) => ({
        ...prevState,
        city: "",
      }));
    });
  };

  return (
    <>
      <div>
        <div id="appCapsule" className="extra-header-active full-height">
          <div className="section mb-5 p-2">
            <div className="col-md-12">
              <div>
                <div
                  style={sharedStyles.pageFrame}
                  className="appHeader bg-primary-main  bg-primary text-light"
                >
                  <div className="left">
                    <a
                      onClick={() => navigate(-1)}
                      className="headerButton goBack"
                    >
                      <IoChevronBack size={sc.IconSize25} /> Back
                    </a>
                  </div>
                  <div className="text-muted-medium">Realtor</div>
                  <div className="right"></div>
                </div>
                <div>
                  <div>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="name">
                          Name
                        </label>
                        <input
                          type="text"
                          value={data.name}
                          onChange={handleChange}
                          className="form-control"
                          id="name"
                        />
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="address">
                          Address
                        </label>
                        <input
                          type="text"
                          value={data.address}
                          onChange={handleChange}
                          className="form-control"
                          id="address"
                        />
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="state">
                          {data.state ? "Select State" : <span>&nbsp;</span>}
                        </label>

                        <select
                          required
                          onChange={getCities}
                          value={data.state}
                          className="form-control custom-select"
                          id="state"
                        >
                          <option key="" value="" selected disabled hidden>
                            Select State
                          </option>
                          {states.map((state) => (
                            <option key={state.title} value={state.title}>
                              {state.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="city">
                          {data.city ? "Select City" : <span>&nbsp;</span>}
                        </label>

                        <select
                          required
                          onChange={handleChange}
                          value={data.city}
                          className="form-control custom-select"
                          id="city"
                        >
                          <option key="" value="" selected disabled hidden>
                            Select City
                          </option>
                          {cities.map((city) => (
                            <option value={city.title} key={city.title}>
                              {city.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <br />
                    <div className="form-group transparent">
                      <CustomButton
                        httpProcessing={httpProcessing}
                        text="Save"
                        onClick={CreateRealtor}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RealtorList;
