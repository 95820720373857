import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import auth from "../../../services/authservice";
import CustomButton from "../../childcomponents/custombutton";
import realtorClient from "../../../httpClients/clients/realtorClient";
import BankAccountDto from "../../../models/Shared/BankAccountDto";
import transactionClient from "../../../httpClients/clients/transactionClient";
import BankDto from "../../../models/Transaction/BankDto";
import { IoChevronBackOutline } from "react-icons/io5";
import ErrorDto from "../../../models/AppFeature/ErrorDto";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const AddBankAccount = ({ ShowAlert }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  if (!location.state) {
    navigate(sc.realtorsroute);
  }
  let realtorId = location.state.realtorId;
  let banksTemp: BankDto[] = [];
  const [banks, setBanks] = useState(banksTemp);
  const [httpProcessing, setHttpProcessing] = useState(false);

  const back = () => {
    navigate(sc.realtordetailroute, {
      state: { realtorId: realtorId, tabSelection: 4 },
    });
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setUserAccount((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  useEffect(() => {
    transactionClient.GetBanks().then(
      (data) => {
        setBanks(data);
      },
      (error) => {
        ShowAlert(error.response, sc.danger);
      }
    );
  }, []);
  let userAccounntTemp: BankAccountDto = {};
  const [userAccount, setUserAccount] = useState(userAccounntTemp);

  const addUpdateUserAccount = async () => {
    try {
      if (!userAccount.bankCode) {
        ShowAlert(sc.bankrequired, sc.warning);
        return;
      }

      if (!userAccount.accountName) {
        ShowAlert(sc.accountnamerequired, sc.warning);
        return;
      }

      if (!userAccount.accountNumber) {
        ShowAlert(sc.accountnumberrequired, sc.warning);
        return;
      }

      userAccount.bank = banks.filter(
        (o) => o.code === userAccount.bankCode
      )[0].name;

      setHttpProcessing(true);

      realtorClient
        .AddRealtorBankAccount({
          realtorId: realtorId,
          bankAccount: userAccount,
        })
        .then(
          () => {
            setHttpProcessing(false);
          },
          (error: ErrorDto) => {
            setHttpProcessing(false);
            ShowAlert(error.message, sc.warning);
          }
        );

      setHttpProcessing(false);

      navigate(sc.bankaccountsroute);
    } catch (e: any) {
      setHttpProcessing(false);
      ShowAlert(e.message, sc.warning);
    }
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <IoChevronBackOutline size={sc.IconSize15} /> Accounts
          </a>
        </div>
        <div className="text-muted-medium">Add Bank Account</div>
      </div>

      <div
        id="appCapsule"
        className="extra-header-active full-height"
        style={{ background: "white" }}
      >
        <div>
          <div className="card-body pb-1">
            <div className="form-group basic">
              <select
                value={userAccount.bankCode}
                onChange={handleChange}
                id="bankCode"
                style={{ width: "100%" }}
                className="form-control custom-select"
              >
                <option>Select Bank</option>
                {banks.map((value) => (
                  <option value={value.code} key={value.code}>
                    {value.name}
                  </option>
                ))}
              </select>
            </div>
            <br />
            <div className="form-group basic">
              <label className="label" htmlFor="accountName">
                Account Name
              </label>
              <input
                required
                value={userAccount.accountName}
                onChange={handleChange}
                id="accountName"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>
            <div className="form-group basic">
              <label className="label" htmlFor="accountNumber">
                Account Number
              </label>
              <input
                required
                value={userAccount.accountNumber}
                onChange={handleChange}
                id="accountNumber"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>

            <br />

            <div>
              <CustomButton
                httpProcessing={httpProcessing}
                text="Add"
                onClick={addUpdateUserAccount}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddBankAccount;
