// validate Otp
// resend otp

// account status should be enum
// Pending,
// Active,
// Disabled,

// If pending acount tries to reregister. send em to otp validation with will have their email displayed  asking them to enter their Option
// Same UI will be used if you creat account
import React, { useState, useEffect } from "react";
import Keypad from "../../childcomponents/keypad";
import UserClient from "../../../httpClients/clients/userClient";
import auth from "../../../services/authservice";
import sc from "../../../services/stringconst";
import { useLocation, useNavigate } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import sharedStyles from "./../../shared/app.style";
import BaseResponseDto from "../../../models/Shared/baseResponseDto";
import ErrorDto from "../../../models/AppFeature/ErrorDto";
import SigninResponseDto from "../../../models/User/Response/signinResponseDto";
import { useDispatch } from "react-redux";
import { setUserData } from "./../../../store/slices/userSlice";
import Cookies from "js-cookie";
import UtilityHelper from "../../../Utilities/UtilityHelper";

type Props = {
  ShowAlert(message: string, title: string): any;
  setUser(isUser: boolean): any;
};

const VerifyRegistration = ({ ShowAlert, setUser }: Props) => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [clearPin, setClearPin] = useState(false);

  const verifyOtp = async (pin: string) => {
    UserClient.validateRegistration(location.state, pin).then(
      (data: SigninResponseDto) => {
        dispatch(
          setUserData({
            userId: data.session.id,
            firstname: data.user.firstname,
            lastname: data.user.lastname,
            username: data.user.username,
            userRole: data.user.userRole,
            realtorId: data.user.realtorId,
            isAdmin: data.user.userRole == sc.Admin,
          })
        );
        Cookies.remove(sc.session);
        Cookies.set(
          sc.session,
          JSON.stringify({
            userId: data.session.id,
            timeout: Date.now() + data.session.timeout,
            token: data.accessToken,
            refreshToken: data.session.key,
          })
        );
        setUser(true);

        auth.setCurrentUser(data);

        navigate(routeAfterOtpValidation(data.user.userRole));
      },
      (error: ErrorDto) => {
        ShowAlert(error.message, sc.warning);
      }
    );
  };

  const routeAfterOtpValidation = (userRole: string | undefined) => {
    if (userRole == sc.Admin) return sc.adminhomeroute;
    if (userRole == sc.Realtor) return sc.realtorhomeroute;
    if (userRole == sc.Customer) return sc.findpropertyroute;

    return sc.findpropertyroute;
  };
  const resendOtp = async () => {
    setClearPin(!clearPin);
    UserClient.resendOtp(location.state, sc.UserRegistration).then(
      (data: BaseResponseDto) => {
        data.status
          ? ShowAlert("OTP resent", sc.success)
          : ShowAlert(data.message ?? "", sc.danger);
      },
      (error: ErrorDto) => {
        ShowAlert(error.message, sc.warning);
      }
    );
  };

  return (
    <>
      <div>
        <div id="appCapsule" className="extra-header-active full-height">
          <div className="section mb-5 p-2">
            <div className="col-md-12">
              <div
                style={sharedStyles.pageFrame}
                className="appHeader bg-primary-main  bg-primary text-light"
              >
                <div className="left">
                  <a
                    onClick={() => navigate(-1)}
                    className="headerButton goBack"
                  >
                    <IoChevronBack size={sc.IconSize25} /> Back
                  </a>
                </div>
                <div className="text-muted-medium">Verify OTP</div>
                <div className="right"></div>
              </div>

              <div
                style={{ padding: "2%", textAlign: "center" }}
                className="text-muted"
              >
                Enter OTP sent to your email{" "}
                <b className="text-link" onClick={resendOtp}>
                  Resend OTP
                </b>
              </div>
              <div style={{ marginTop: "5%" }}>
                <Keypad submitPin={verifyOtp} clearPin={clearPin} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyRegistration;
