import React, { useState } from "react";
import LoanSchedule from "./loanSchedule.js";
import { Client } from "../../services/apiclient.js";
import { useNavigate } from "react-router-dom";
import sc from "../../services/stringconst";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import util from "../../Utilities/UtilityHelper";
import CustomButton from "./custombutton.tsx";
import auth from "../../services/authservice";

const LoanPackage = (props) => {
  const location = useLocation();

  let loanPackage = {};

  let showTitle = false;
  let isOwner = false;
  let scheduleAmountValue = 0;
  if (props.loanPackage) {
    showTitle = false;
    loanPackage = props.loanPackage;
    isOwner = props.isOwner;
    scheduleAmountValue = props.amount ? props.amount : loanPackage.maxAmount;
  } else {
    if (location.state) {
      loanPackage = location.state;
      showTitle = true;
      isOwner = false;
      scheduleAmountValue = loanPackage.maxAmount;
    }
  }

  const navigate = useNavigate();
  let client = new Client(null, null);

  const [httpProcessing, setHttpProcessing] = useState(false);

  const [isActive, setIsActive] = useState(loanPackage.isActive);

  const [scheduleAmount, setScheduleAmount] = useState(scheduleAmountValue);
  const [scheduleDisbursementDate, setScheduleDisbursementDate] = useState("");

  const [scheduleData, setScheduleData] = useState(undefined);

  useEffect(() => {
    const date = new Date();
    const defaultValue = date.toLocaleDateString();
    setScheduleDisbursementDate(scheduleDisbursementDate ?? defaultValue);
  }, [scheduleData]);

  const setVisibility = () => {
    let newIsActiveValue = !isActive;
    setIsActive(newIsActiveValue);
    client
      .setloanpackagestatus({
        lpId: loanPackage.lpId,
        isActive: newIsActiveValue,
      })
      .then((data) => {});
  };

  const setScheduleDisbursementDateValue = (e) => {
    setScheduleDisbursementDate(e.target.value);
  };

  const setScheduleAmountValue = (e) => {
    setScheduleAmount(e.target.value);
  };

  const generateSchedule = () => {
    if (
      scheduleAmount < loanPackage.minAmount ||
      scheduleAmount > loanPackage.maxAmount
    ) {
      props.ShowAlert("Amount not in range");
      return;
    }

    let xDate = new Date(scheduleDisbursementDate);
    let payloadDate = scheduleDisbursementDate;

    if (xDate.getFullYear().toString() == "NaN") {
      const date = new Date();
      const defaultValue = date.toLocaleDateString();
      payloadDate = defaultValue;
    }

    let payload = {
      lpId: loanPackage.lpId,
      amount: scheduleAmount,
      disbursementDate: payloadDate,
    };

    setHttpProcessing(true);
    client.generateSchedule(payload).then((data) => {
      setHttpProcessing(false);
      setScheduleData(data);
    });
  };

  const editLoanPackage = () => {
    navigate(sc.updateloanpackageroute, { state: loanPackage });
  };

  const back = () => {
    navigate(-1);
  };

  return (
    <>
      {showTitle && (
        <div
          style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
          className="appHeader bg-primary-main  bg-primary text-light"
        >
          <div className="left">
            <a onClick={back} className="headerButton goBack">
              <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
          </div>
          <div>{loanPackage.title}</div>
        </div>
      )}

      <div
        id={showTitle ? "appCapsule" : ""}
        className={showTitle ? "extra-header-active full-height" : ""}
      >
        <div className="card">
          <div className="card-body">
            <ul className="listview flush transparent image-listview">
              <li>
                <div className="item">
                  <div className="icon-box badge-outline-primary">
                    <ion-icon name="arrow-up-outline"></ion-icon>{" "}
                  </div>
                  <div className="in">
                    <div className="form-links mt-1" style={{ width: "100%" }}>
                      <div>Max Amount</div>

                      <div>
                        {util.commaCurrencyFormatter(loanPackage.maxAmount)}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="item">
                  <div className="icon-box badge-outline-primary">
                    <ion-icon name="arrow-down-outline"></ion-icon>
                  </div>
                  <div className="in">
                    <div className="form-links mt-1" style={{ width: "100%" }}>
                      <div>Min Amount</div>

                      <div>
                        {util.commaCurrencyFormatter(loanPackage.minAmount)}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="item">
                  <div className="icon-box badge-outline-primary">
                    <ion-icon name="add-outline"></ion-icon>
                  </div>
                  <div className="in">
                    <div className="form-links mt-1" style={{ width: "100%" }}>
                      <div>Interest</div>

                      <div>{loanPackage.interestPercentage}%</div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="item">
                  <div className="icon-box badge-outline-primary">
                    <ion-icon name="time-outline"></ion-icon>
                  </div>
                  <div className="in">
                    <div className="form-links mt-1" style={{ width: "100%" }}>
                      <div>Tenure</div>

                      <div>
                        {loanPackage.durationValue} {loanPackage.durationType}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="item">
                  <div className="icon-box badge-outline-primary">
                    <ion-icon name="repeat-outline"></ion-icon>
                  </div>
                  <div className="in">
                    <div className="form-links mt-1" style={{ width: "100%" }}>
                      <div>Repayment</div>

                      <div>
                        Every {loanPackage.repaymentFrequency}{" "}
                        {loanPackage.repaymentType}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="item">
                  <div className="icon-box badge-outline-primary">
                    <ion-icon name="swap-horizontal-outline"></ion-icon>
                  </div>
                  <div className="in">
                    <div className="form-links mt-1" style={{ width: "100%" }}>
                      <div>Collateral</div>

                      <div>
                        {loanPackage.collateralType.map((value) => (
                          <span
                            className="badge badge-outline-secondary marginLeft5px"
                            key={value}
                          >
                            {value}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              {isOwner && (
                <li>
                  <div className="item">
                    <div className="icon-box badge-outline-primary">
                      <ion-icon name="eye-outline"></ion-icon>
                    </div>
                    <div className="in">
                      <div
                        className="form-links mt-1"
                        style={{ width: "100%" }}
                      >
                        <div>Visible to borrowers</div>

                        <div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={setVisibility}
                              id={loanPackage.lpId}
                              checked={isActive}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={loanPackage.lpId}
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
          <div className="card-footer">
            {!isOwner && (
              <a
                onClick={() =>
                  navigate(sc.loanrequestroute, { state: loanPackage.lpId })
                }
                style={{ width: "70%" }}
                className="btn btn-primary marginLeft5px"
                data-bs-dismiss="modal"
              >
                Apply
              </a>
            )}
            {isOwner && (
              <a
                style={{ width: "70%" }}
                className="btn btn-primary marginLeft5px"
                onClick={editLoanPackage}
              >
                Edit
              </a>
            )}
            <a
              style={{ width: "25%", float: "right" }}
              data-bs-toggle="modal"
              data-bs-target={"#m" + loanPackage.lpId}
              className="btn btn-secondary marginLeft5px"
            >
              <ion-icon name="calculator-outline"></ion-icon>
            </a>
          </div>
        </div>
      </div>

      {/* <!-- Calculator Modal --> */}
      <div
        className="modal fade modalbox"
        id={"m" + loanPackage.lpId}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div
            className="modal-content"
            style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
          >
            <div
              className="modal-header"
              style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
            >
              <h5 className="modal-title">{loanPackage.title}</h5>
              <a data-bs-dismiss="modal">Close</a>
            </div>
            <div className="modal-body">
              <div className="form-group basic">
                <div className="input-wrapper">
                  <label className="label" htmlFor="amount3">
                    Amount
                  </label>
                  <input
                    onChange={setScheduleAmountValue}
                    type="text"
                    value={scheduleAmount}
                    className="form-control"
                    id="amount3"
                    placeholder="Enter an amount"
                  />
                </div>
                <div className="input-info">
                  Min {loanPackage.minAmount} - Max {loanPackage.maxAmount}
                </div>
              </div>
              <div className="input-wrapper">
                <label className="label" htmlFor="startdate">
                  Disburement Date
                </label>
                <input
                  type="date"
                  onChange={setScheduleDisbursementDateValue}
                  defaultValue={scheduleDisbursementDate}
                  className="form-control"
                  id="startdate"
                  placeholder="Loan Disburement date"
                />
              </div>
              <br />

              <CustomButton
                bgColor="primary"
                httpProcessing={httpProcessing}
                text="Calculate"
                onClick={generateSchedule}
              />

              <hr />
              {scheduleData && (
                <div>
                  <LoanSchedule
                    scheduleData={scheduleData}
                    disbursementDate={scheduleDisbursementDate}
                  />
                  <br />
                  {!isOwner && (
                    <a
                      style={{ width: "100%" }}
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={() =>
                        navigate(sc.loanrequestroute, {
                          state: loanPackage.lpId,
                        })
                      }
                    >
                      Apply
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoanPackage;
