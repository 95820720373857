import React, { useEffect, useState } from "react";
import sc from "../../../services/stringconst";
import { useNavigate } from "react-router-dom";
import auth from "../../../services/authservice";
import { IoAddOutline, IoChevronBackOutline } from "react-icons/io5";
import LoadingTextPlaceHolder from "../../shared/Preload/loadingtextplaceholder";
import propertyClient from "../../../httpClients/clients/propertyClient";
import FacilityDto from "../../../models/Property/FacilityDto";
type Props = {
  ShowAlert(message: string, title: string): any;
};
const FacilityTypeList = ({ ShowAlert }: Props) => {
  const navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);

  let facilityTypesTemp: FacilityDto[] = [];
  const [facilityTypes, setFacilityTypes] = useState(facilityTypesTemp);

  useEffect(() => {
    GetPropertyTypes();
  }, []);

  const GetPropertyTypes = () => {
    propertyClient.GetFacilities().then(
      (data) => {
        setDataLoaded(true);
        setFacilityTypes(data);
      },
      (error) => {
        setDataLoaded(true);
        ShowAlert(error.response, sc.danger);
      }
    );
  };

  const back = () => {
    navigate(sc.settingsroute);
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <IoChevronBackOutline size={sc.IconSize15} /> Settings
          </a>
        </div>
        <div className="text-muted-medium">Facility Types</div>
      </div>

      {dataLoaded && (
        <div id="appCapsule" className="extra-header-active full-height">
          <span
            className="btn btn-primary rounded"
            onClick={() => navigate(sc.addfacilitytyperoute)}
            style={{
              padding: 12,
              float: "right",
              position: "fixed",
              right: 10,
              top: "87%",
              zIndex: 1001,
            }}
          >
            <IoAddOutline size={sc.IconSize25} />
          </span>
          <div className="section">
            <ul className="listview image-listview">
              {facilityTypes?.map((item) => (
                <li key={item.facilityId}>
                  <div className="item">
                    <img
                      src={auth.loadUserImage(item.icon)}
                      alt="image"
                      className="image"
                    />
                    <div className="in">
                      <div>{item.name}</div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {!dataLoaded && (
        <>
          <br />
          <LoadingTextPlaceHolder />
        </>
      )}
    </>
  );
};
export default FacilityTypeList;
