import React, { useEffect, useState } from "react";
import util from "../Utilities/UtilityHelper";
import LoanSchedule from "./childcomponents/loanSchedule";
import * as moment from 'moment'
import sc from './../services/stringconst';
import { Client } from "../services/apiclient";
import auth from "../services/authservice";

 const LoanDetails =(props)=> {

let loanPackage= props.loanPackage;
let loanData= props.loanData;
let isLender= props.isLender;


    useEffect(()=>{


      },[]);

      // const setScheduleDataValue=()=>{
      //   if(props.loanData.loanPackageId){
      //     let payload = {
      //       lpId: props.loanData.loanPackageId,
      //       amount: parseInt(props.loanData.principal),
      //       disbursementDate: new Date(),
      //     };
      //     setLoanSchedulePayload(payload);
      //   }
      // }

    return (
      <>

<div className="card"   >
<div className="card-header">
<span className="text-muted">Loan Type </span><span style={{fontSize:"1em"}}> {loanData.title}</span> 
                   
                </div>
                <div className="card-header">
                {isLender && <> <span className="text-muted">Borrower </span>  <span style={{fontSize:"0.85em"}}>{loanData.borrowerFirstname} {loanData.borrowerLastname}</span></> }
                {!isLender && <><span className="text-muted">Lender </span>  <span style={{fontSize:"0.85em"}}>{loanPackage.firstname} {loanPackage.lastname}</span></> }
                </div>
                <div className="card-header">
                <span className="text-muted">Package </span> <span style={{fontSize:"0.85em"}}>{loanPackage.title} </span> 
                </div>
              
                <div className="card-body">

<span style={{fontSize:"0.7em"}}>{loanData.description}</span> 
  <br/>

{(loanData.status === "Open" ||
                            loanData.status === "Closed") && (
                            <>
                              <span
                                className="text-muted"
                                style={{ color: "white", width: "100%" }}
                              >
                                Repayment{" "}
                                {util.commaCurrencyFormatter(
                                  loanData.repaidAmount
                                )}{" "}
                                ({Math.trunc(loanData.paymentProgress*100)/100}%)
                              </span>
                              <div className="progress mb-2">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: loanData.paymentProgress + "%",
                                  }}
                                ></div>
                              </div>
                            </>
                          )}
                          <hr/>
                <ul className="listview flush transparent image-listview">
                
                    <li>
                        <a className="item">
                            <div className="icon-box badge-outline-primary">
                                <ion-icon name="cash-outline"></ion-icon>
                            </div>
                            <div className="in">
                            <table style={{width:"100%"}}>
                              <tr>
                                <td>Principal</td>
                                <td style={{float: "right"}}>{util.commaCurrencyFormatter(loanData.principal)}</td>
                              </tr></table></div>
                        </a>
                    </li>
                    <li>
                        <a className="item">
                            <div className="icon-box badge-outline-primary" >
                            <ion-icon name="add-outline"></ion-icon>
                            </div>
                            <div className="in">
                            <table style={{width:"100%"}}>
                              <tr>
                                <td>Interest <span className="badge badge-outline-warning">{loanPackage.interestPercentage}%</span></td>
                                <td style={{float: "right"}}> {util.commaCurrencyFormatter(loanData.interest)}</td>
                              </tr></table></div>
                        </a>
                    </li>
                    <li>
                        <a className="item">
                            <div className="icon-box badge-outline-primary">
                            <ion-icon name="time-outline"></ion-icon>
                            </div>
                            <div className="in">
                            
                  <table style={{width:"100%"}}>
                              <tr>
                                <td>Tenure</td>
                                <td style={{float: "right"}}>{loanPackage.durationValue} {loanPackage.durationType}</td>
                              </tr></table>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a className="item">
                            <div className="icon-box badge-outline-primary">
                            <ion-icon name="repeat-outline"></ion-icon>
                            </div>
                            <div className="in">
                            
                  <table style={{width:"100%"}}>
                              <tr>
                                <td>Repayment</td>
                                <td style={{float: "right"}}>Every {loanPackage.repaymentFrequency} {loanPackage.repaymentFrequency==1?loanPackage.repaymentType.substring(0, loanPackage.repaymentType.length-1):loanPackage.repaymentType}</td>
                              </tr></table>
                            </div>
                        </a>
                    </li>
                     {(props.loanData.status && props.loanData.status !==sc.Disapproved && props.loanData.status!==sc.Pending) && <>
                      <li>
                        <a className="item">
                            <div className="icon-box badge-outline-primary">
                            <ion-icon name="calendar-outline"></ion-icon>
                            </div>
                            <div className="in">
                            <table style={{width:"100%"}}>
                              <tr>
                                <td>Disbursement</td>
                                <td style={{float: "right"}}>{moment(loanData.disbursementDate).format("DD.MMM.YY")}</td>
                              </tr></table>
                            </div>
                           
                        </a>
                    </li>
                  </>}
                  {(props.loanData.status === sc.Open) && <>
                  <li>
                        <a className="item">
                            <div className="icon-box badge-outline-primary">
                            <ion-icon name="calendar-outline"></ion-icon>
                            </div>
                            <div className="in">
                              <table style={{width:"100%"}}>
                              <tr>
                                <td>Next Due</td>
                                <td style={{float: "right"}}>{moment(loanData.nextDueDate).format("DD.MMM.YY")}</td>
                              </tr></table>
                                </div>
                        </a>
                    </li>
                      
                  </>}
                    <li>
                        <a className="item">
                            <div className="icon-box badge-outline-primary">
                            <ion-icon name="swap-horizontal-outline"></ion-icon>
                            </div>
                            <div className="in">
                            <table style={{width:"100%"}}>
                              <tr>
                                <td>Available Collateral</td>
                                <td style={{float: "right"}}>{loanData.collateralType.map(value=>(<span className="badge badge-outline-warning">{value}</span>))}</td>
                              </tr></table>
                            </div>
                        </a>
                    </li>  
                    {loanData.lenderNote &&<li>
                        <a className="item">
                            <div className="icon-box badge-outline-primary">
                            <ion-icon name="chatbox-ellipses-outline"></ion-icon>
                            </div>
                            <div className="in">
                            <table style={{width:"100%"}}>
                              <tr>
                                <td>Lender Note</td>
                                <td className="text-muted" style={{float: "right"}}>{loanData.lenderNote}</td>
                              </tr></table>
                              </div>
                        </a>
                    </li>}
                    {loanData.borrowerNote && <li>
                        <a className="item">
                            <div className="icon-box badge-outline-primary">
                            <ion-icon name="chatbox-ellipses-outline"></ion-icon>
                            </div>
                            <div className="in">
                            <table style={{width:"100%"}}>
                              <tr>
                                <td>Borrower Note</td>
                                <td className="text-muted"  style={{float: "right"}}>{loanData.borrowerNote}</td>
                              </tr></table>
                                
                            </div>
                        </a>
                    </li>}
                    {(loanData.status=== sc.Pending || !loanData.status) &&  <li>
                    <div className="in">
                        <br/>
                        <a
            
            data-bs-toggle="modal"
            data-bs-target='#schedule'
            className="btn btn-text-primary"
           // onClick={setScheduleData}
          >View Repayment Schedule</a>
                  
                  
                    </div>
                    </li>}
                    
                </ul>
                    
                </div>
             
            </div>


            <div
        className="modal fade modalbox"
        id='schedule'
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content"style={{width:auth.appWidth(), marginLeft:auth.appMargin()}}>
            <div className="modal-header"style={{width:auth.appWidth(), marginLeft:auth.appMargin()}}>
              <h5 className="modal-title">Payment Schedule</h5>
              <h5 className="modal-title">{ }</h5>
              <a data-bs-dismiss="modal">Close</a>
            </div>
            <div className="modal-body">

            {(props.loanData.status=== sc.Pending || !props.loanData.status) && props.loanData.scheduleData &&  <div><LoanSchedule scheduleData={props.loanData.scheduleData} />
              <br />
</div>
}
              </div>

            </div>
          </div>
        </div>
      </>
    );

}
export default LoanDetails