import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import sc from "../../../services/stringconst";
import CustomButton from "./../../childcomponents/custombutton";
import sharedStyles from "./../../shared/app.style";
import UserClient from "../../../httpClients/clients/userClient";
import { IoChevronBack } from "react-icons/io5";
import ErrorDto from "../../../models/AppFeature/ErrorDto";

type Props = {
  ShowAlert(message: string, title: string): any;
};

const ForgotPassword = ({ ShowAlert }: Props) => {
  let navigate = useNavigate();

  const goToLogin = () => {
    navigate("/login");
  };

  const [email, setEmail] = useState();
  const [code, setCode] = useState();
  const [newPassword, setNewPassword] = useState();
  const [retypePassword, setRetypePassword] = useState();
  const [codeSent, setCodeSent] = useState(false);
  const [httpProcessing, setHttpProcessing] = useState(false);

  const setEmailValue = (e: any) => {
    setEmail(e.target.value);
  };

  const setCodeValue = (e: any) => {
    setCode(e.target.value);
  };

  const setNewPasswordValue = (e: any) => {
    setNewPassword(e.target.value);
  };

  const setRetypeNewPasswordValue = (e: any) => {
    setRetypePassword(e.target.value);
  };

  const resetPassword = () => {
    if (!email) {
      ShowAlert(sc.emailrequired, sc.warning);
      return;
    }
    setHttpProcessing(true);
    UserClient.resetPassword(email).then(
      (data) => {
        setHttpProcessing(false);
        setCodeSent(true);
        ShowAlert(data.message ?? "", sc.success);
      },
      (error: ErrorDto) => {
        setHttpProcessing(false);
        ShowAlert(error.message, sc.danger);
      }
    );
  };

  const completeResetPassword = () => {
    if (!email) {
      ShowAlert(sc.emailrequired, sc.warning);
      return;
    }
    if (!code) {
      ShowAlert(sc.coderequired, sc.warning);
      return;
    }
    if (!newPassword) {
      ShowAlert(sc.passwordrequired, sc.warning);
      return;
    }
    if (newPassword !== retypePassword) {
      ShowAlert(sc.newpasswordmismatch, sc.warning);
      return;
    }

    setHttpProcessing(true);
    UserClient.confirmResetPassword({
      email: email,
      resetToken: code,
      newPassword: newPassword,
    }).then(
      (data) => {
        setHttpProcessing(false);
        ShowAlert(sc.passwordResetSuccessful, sc.success);
        navigate(sc.loginroute);
      },
      (error: ErrorDto) => {
        setHttpProcessing(false);
        ShowAlert(error.message, sc.danger);
      }
    );
  };

  return (
    <div className="col-md-12">
      {/* <!-- App Header --> */}
      <div
        style={sharedStyles.pageFrame}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={goToLogin} className="headerButton goBack">
            <IoChevronBack size={sc.IconSize25} /> Login
          </a>
        </div>
        <div className="text-muted-medium">Forgot Password</div>
        <div className="right"></div>
      </div>
      <div id="appCapsule" className="extra-header-active full-height">
        <div className="section mb-5 p-2">
          <div className="" style={{ marginTop: "5%" }}>
            <div className="">
              <div className="pb-1">
                <br />
                <br />
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="email1">
                      Username | Email | Number
                    </label>
                    <input
                      type="email"
                      onChange={setEmailValue}
                      className="form-control"
                      id="email1"
                      placeholder=""
                    />
                    <i className="clear-input"></i>
                  </div>
                  {codeSent && (
                    <>
                      <br />
                      <div className="input-wrapper">
                        <label className="label" htmlFor="code">
                          OTP Code
                        </label>
                        <input
                          type="password"
                          onChange={setCodeValue}
                          className="form-control"
                          id="code"
                        />
                        <i className="clear-input"></i>
                      </div>
                      <br />
                      <div className="input-wrapper">
                        <label className="label" htmlFor="newPassword">
                          New Password{" "}
                          <span className="text-muted">6-12 Character</span>
                        </label>
                        <input
                          type="password"
                          onChange={setNewPasswordValue}
                          className="form-control"
                          id="newPassword"
                        />
                        <i className="clear-input"></i>
                      </div>
                      <br />
                      <div className="input-wrapper">
                        <label className="label" htmlFor="retypeNewPassword">
                          Repeat New Password
                        </label>
                        <input
                          type="password"
                          onChange={setRetypeNewPasswordValue}
                          className="form-control"
                          id="retypeNewPassword"
                        />
                        <i className="clear-input"></i>
                      </div>
                    </>
                  )}
                </div>
                <br />
                <div className="form-group transparent">
                  {!codeSent && (
                    <CustomButton
                      httpProcessing={httpProcessing}
                      text="Get OTP"
                      onClick={resetPassword}
                    />
                  )}
                  {codeSent && (
                    <CustomButton
                      httpProcessing={httpProcessing}
                      text="Reset Password"
                      onClick={completeResetPassword}
                    />
                  )}
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
