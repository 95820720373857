class sc {
  //string constant

  primary = "alert alert-primary alert-dismissible fade show mb-2";
  //success = "alert alert-success alert-dismissible fade show mb-2";
  success = "alert alert-success alert-dismissible fade show mb-2";
  info = "alert alert-info alert-dismissible fade show mb-2";
  secondary = "alert alert-secondary alert-dismissible fade show mb-2";
  danger = "alert alert-warning alert-dismissible fade show mb-2";
  //danger = "alert alert-danger alert-dismissible fade show mb-2";
  warning = "alert alert-warning alert-dismissible fade show mb-2";

  dangerBorder = "danger";
  primaryBorder = "primary";

  passwordrequired = "password is required!";
  usernamerequired = "username is required!";

  oldpasswordrequired = "old password is required!";
  newpasswordrequired = "new password is required!";
  repeatedpasswordrequired = "repeated new password is required!";
  currentpasswordcannotbesameasnew = "Current password cannot be same as new!";
  stateSaved = "State Saved, select city";
  citySaved = "City Saved";

  passwordResetSuccessful = "Password reset successful!";
  passwordChangeSuccessful = "Password change successful!";
  //enums- payment Schedule | Loan
  Pending = "Pending";
  Open = "Open";
  Approved = "Approved";
  Disapproved = "Disapproved";
  Abandoned = "Abandoned";
  Closed = "Closed";
  PartPaymentRaised = "Part Pmt Raised";
  PaymentRaised = "Pmt Raised";
  PartPaymentReceived = "Part Pmt Received";
  PartPaymentRejected = "Part Pmt Rejected";
  PaymentRejected = "Pmt Rejected";
  Overdue = "Overdue";
  Defaulted = "Defaulted";
  PaymentReceived = "Pmt Received";

  // entities
  Payment = "Payment";
  RatingEntity = "Rating";
  UserEntity = "User";

  // view status
  Active = "Active";
  Disabled = "Disabled";
  Unreceived = "Unreceived";

  Admin = "Admin";
  Realtor = "Realtor";
  Customer = "Customer";

  successful = "successful";

  loaderViewCountThree = [0, 0, 0];
  loaderViewCountFour = [0, 0, 0, 0];
  getUUId() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  PlayStoreUrl = "http://play.google.com";
  AppStoreUrl = "https://www.apple.com/store";
  WebUrl = "https://myplace.ng";

  web = "web";
  android = "android";
  ios = "ios";
  shareMessage = "Borrow money at flexible interest rate packages on LoanHub";
  confirmDisbursementBeforeRepayment = "Confirm disbursement before repayment";
  //enums- payments
  PaymentSent = "Pmt Sent";
  PaymentConfirmed = "Pmt Confirmed";
  // payment rejected - pick from payment schedule

  DisbursementConfirmed = "Disbursement Confirmed";
  CancellationConfirmed = "Cancellation Confirmed";
  isRequired = " is required!";
  isInvalid = " is valid!";

  lendernoterequired = "lender note is required!";
  disburementdaterequired = "disbursement date is required!";

  disburementdatecannotbelessthantodayrequired =
    "disbursement date cannot be less than today!";

  newpasswordmismatch = "New password mismatch!";
  tandcrequired = "Terms & Condition required";
  namerequired = "Name is required";
  valuerequired = "Value is required";
  iconrequired = "Icon is required";
  firstnamerequired = "Firstname is required";
  lastnamerequired = "Lastname is required";
  addressrequired = "Address is required";
  staterequired = "State is required";
  cityrequired = "City is required";
  genderRequired = "Gender is required";
  emailrequired = "Enter required data";
  dateofbirthrequired = "Date of birth is required";
  invaliddateofbirth = "Wrong Date of birth";
  mobilenumberrequired = "Mobile number is required";
  identityidrequired = "BVN is required";
  photorequired = "Photo is required";

  firstnameinvalidcharacter = "Firstname has invalid character";
  lastnameinvalidcharacter = "Lastname has invalid character";
  usernameinvalidcharacter = "Username has invalid character";
  emailinvalidcharacter = "Email has invalid character";
  phoneinvalidcharacter = "Mobile number has invalid character";
  bvninvalidcharacter = "BVN has invalid character";

  Months = "Months";
  Weeks = "Weeks";
  Days = "Days";

  titlerequired = "Title is required";
  minamountrequired = "Min amount is required";
  maxamountrequired = "Max amount is required";
  invalidTenureAndRepayment = "Invalid Tenure and Repayment selection";

  accountnamerequired = "Account name is required";
  accountnumberrequired = "Account number is required";
  bankrequired = "Bank is required";

  coderequired = "Code is required";
  retypePasswordrequired = "Retype Password is required";

  CheckEmailForResetToken =
    "Check your email for reset token, expires in 10 minutes";
  ratingrequired = "Rating is required";
  commentrequired = "Comment is required";
  baseroute = "";
  serverbaseroute = "https://ltapploanapi.herokuapp.com"; //'http://localhost:5000';//
  appbaseroute = `${this.serverbaseroute}`;
  chatbaseroute = `${this.serverbaseroute}`;
  cloudImageRoute = "/imageuploadauth/";

  loginroute = "/login";
  homeroute = "/home";
  adminhomeroute = "/adminhome";
  realtorhomeroute = "/realtorhome";
  welcomeroute = "/welcome";
  shareroute = "/share";
  registerroute = "/register";
  verifyregistrationroute = "/verifyregistration";
  realtordetailroute = "/realtordetail";
  registeroptionroute = "/registeroption";
  forgotpasswordroute = "/forgotpassword";
  changepasswordroute = "/changepassword";
  updatepropertyroute = "/updateproperty";
  findpropertyroute = "/findproperty";
  propertydetailsroute = "/propertydetails";
  propertyroute = "/property";
  submitpropertyrequestroute = "/submitpropertyrequest";
  customerdetailroute = "/customerdetail";
  realtorroute = "/realtor";
  requestroute = "/request";
  propertiesroute = "/properties";
  customersroute = "/customers";
  realtorsroute = "/realtors";
  createrealtorroute = "/createrealtor";
  requestsroute = "/requests";
  loanapprovalroute = "/loanapproval";
  loanapprovalcompletedroute = "/loanapprovalcompleted";
  loanpackageroute = "/loanpackage";
  representativedetailroute = "/representativedetail";
  representativeroute = "/representative";
  useraccountroute = "/useraccount";
  loanpackagedetailsroute = "/loanpackagedetails";
  newloanpackageroute = "/newloanpackage";
  updateloanpackageroute = "/updateloanpackage";
  ratingroute = "/rating";
  updateuseraccountroute = "/updateuseraccount";
  newuseraccountroute = "/newuseraccount";
  bankaccountsroute = "/bankaccounts";
  addbankaccountroute = "/addbankaccount";
  propertytypesroute = "/propertytypes";
  addpropertytyperoute = "/addpropertytype";
  facilitytypelistroute = "/facilitytypes";
  addfacilitytyperoute = "/addfacilitytype";
  addRepresentativeRoute = "/addRepresentative";
  addpropertyroute = "/addproperty";

  addfacilityroute = "/addfacility";
  addattributeroute = "/addattribute";
  addpropertydevelopmentroute = "/addpropertydevelopment";
  propertydevelopmentdetailsroute = "/propertydevelopmentdetails";
  propertydevelopmentroute = "/propertydevelopment";

  loanrequestroute = "/loanrequest";
  repaymentsviewroute = "/repaymentsview";
  loanrequestreviewroute = "/loanrequestreview";
  loanrequestsuccessfulroute = "/loanrequestsuccessful";
  paymentsubmissionsuccessfulroute = "/paymentsubmissionsuccessful";
  loanrepaymentscheduleroute = "/loanrepaymentschedule";
  loansroute = "/loans";
  notificationroute = "/notification";
  messageroute = "/message";
  userchatroute = "/userchat";
  chatmessageroute = "/chatmessage";
  paymentroute = "/payment";
  paymentsroute = "/payments";
  paymentdetailsroute = "/paymentdetails";
  newPaymentRoute = "/newpayment";
  profileroute = "/profile";
  settingsroute = "/settings";
  photoverificationroute = "/photoverification";
  identificationverificationroute = "/identificationverification";
  setloginpinroute = "/setloginpin";
  borrowercreditcheckroute = "/borrowercreditcheck";
  lendercreditcheckroute = "/lendercreditcheck";
  profileviewroute = "/profileview";
  smsverificationroute = "/smsverification";
  repaymentsentroute = "/repaymentsent";
  repaymentconfirmedroute = "/repaymentconfirmed";
  faqroute = "/faq";
  aboutroute = "/about";
  contactroute = "/contact";
  updateavailableroute = "/updateavailable";
  notfoundroute = "*";
  toastShow3000 = 3000;
  toastShow5000 = 5000;

  // ONE_SIGNAL_API_KEY = "OTlhZTQ1OGUtYTQwMC00YTc5LThlNWQtZWI2OThjYmM1Yjcx";
  // ONE_SIGNAL_APP_ID = "87e498fd-7ecc-4aa4-a883-3bf39cf77a4a";
  // ONE_SIGNAL_BASE_URL = "https://onesignal.com/api/v1";

  CloudinaryUploadPath =
    "https://api.cloudinary.com/v1_1/osaroalbert_nolongerneeded/image/upload";
  CloudinaryUpload_Preset = "loanhub"; // ltapp // ltapploan
  CloudinaryCloud_name = "osaroalbert";
  base64ImagePrefixPng = "data:image/png;base64,";

  imagekitUrlEndPoint = "https://ik.imagekit.io/ltapploan";
  imagekitPublicKey = "public_PBDlgGmw0PZWsE/qTwCXbYHfqE0=";

  PushNotificationUserToken = "PushNotificationUserToken";

  LoanTypeRequired = "Loan type is required";
  AmountRequired = "Amount is required";
  InvalidAmount = "Invalid is amount";
  CollateralRequired = "collateral is required";
  DescriptionRequired = "Description is Required";
  AmountNotWithinAllowedRange = "Amount not within allowed range";
  invalidImageFileSelected = "Invalid image file selected";
  fileTooLargeError = "File size limit exceeded";

  signInToContinue = "Sign in to continue!";
  updateRequired = "New features available. Please update to continue!";

  Approve = "Approve";
  Disapprove = "Disapprove";

  paystack_public_key_test = "pk_test_5db72c3a7dd170591ce5eb5510ac3d2c26b69b6b";
  paystack_public_key_live = "pk_live_263228ef7bf2bcd2993596b5d8d1f7719b96eb0e";

  approxErrorValue = 0.8;

  verified = "verified";
  processing = "processing";
  failed = "failed";

  M = "M";
  F = "F";

  Male = "Male";
  Female = "Female";

  UserRegistration = "UserRegistration";
  PasswordReset = "PasswordReset";

  undefined = "undefined";

  verification = "verification";

  IconSize40 = 40;
  IconSize35 = 35;
  IconSize25 = 25;
  IconSize20 = 20;
  IconSize15 = 15;

  IconLabel9px = "9px";

  session = "session";

  UserPhoto = "UserPhoto";
  Property = "Property";
  PropertyDevelopment = "PropertyDevelopment";

  Lease = "Lease";
  Purchase = "Purchase";
  Rent = "Rent";

  video = "video";
  image = "image";

  jpgExt = ".jpg";
  mp4Ext = ".mp4";

  onlinePayment = "onlinePayment";
  accountPayment = "accountPayment";
}

export default new sc(); //string constant
