import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import { IoChevronBackOutline, IoNavigateOutline } from "react-icons/io5";
import auth from "../../../services/authservice";
import ValueLabel from "../../childcomponents/valuelabel";
import UtilityHelper from "../../../Utilities/UtilityHelper";
import ValueLabelArray from "../../childcomponents/valuelabelarray";
import "react-slideshow-image/dist/styles.css";
import "video-react/dist/video-react.css";
import "react-rangeslider/lib/index.css";
import PropertyRequestPaymentDto from "../../../models/Property/propertyRequestPaymentDto";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const SubmitPropertyRequest = ({ ShowAlert }: Props) => {
  let location = useLocation();

  let data: PropertyRequestPaymentDto = {};

  let units: number[] = [];
  const [unitsAvailable, setUnitsAvailable] = useState(units);

  const [paymentOption, setPaymentOption] = useState(sc.onlinePayment);

  const setPaymentOptionValue = (value: string) => {
    setPaymentOption(value);
  };
  const onlinePayment = () => {
    paystackPayment();
  };

  const receiveInvoice = () => {};

  let navigate = useNavigate();

  const [unitsSelected, setUnitsSelected] = useState(1);

  useEffect(() => {
    const requestData: PropertyRequestPaymentDto = location.state;
    setRequest(requestData);
    if (requestData?.availableUnits)
      for (let n = 0; n <= requestData?.availableUnits ?? 1; n++) {
        units.push(n + 1);
        setUnitsAvailable(units);
      }
  }, []);

  const setUnitsSelectedValue = (e: any) => {
    setUnitsSelected(e.target.value);
  };

  const [request, setRequest] = useState(data);

  const back = () => {
    navigate(-1);
  };
  const paystackPayment = () => {
    var PaystackPop = require("@paystack/inline-js");

    let handler = PaystackPop.setup({
      // key: sc.paystack_public_key_test, // Replace with your public key
      key: sc.paystack_public_key_live,
      email: auth.getUserProfileEmail(),
      amount: 50 * 100,
      name: `${auth.getUserProfileFirstname()} ${auth.getUserProfileLastname()}`,
      ref:
        Math.floor(Math.random() * 1000000000 + 1) +
        "-" +
        auth.getuserProfileId(), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
      // label: "Optional string that replaces customer email"
      onClose: function () {
        //setHttpProcessing(false);
      },
      callback: function (response: any) {
        // client
        //   .completeverificationpayment({
        //     reference: response.reference,
        //     accountNumber: account,
        //   })
        //   .then(
        //     (data) => {
        //       props.ShowAlert(
        //         data.verified,
        //         data.verified === sc.verified ? sc.success : sc.danger
        //       );
        //       auth.updateBankVerifyStatus(data);
        //       setHttpProcessing(false);
        //       if (auth.getLoanPackageId()) {
        //         navigate(sc.loanrequestroute);
        //       }
        //       navigate(sc.settingsroute);
        //     },
        //     (error) => {
        //       props.ShowAlert(error.response, sc.danger);
        //       setHttpProcessing(false);
        //     }
        //   );
      },
    });
    handler.openIframe();
  };

  return (
    <>
      <div>
        <div
          style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
          className="appHeader bg-primary-main  bg-primary text-light"
        >
          <div className="left">
            <a onClick={back} className="headerButton goBack">
              <IoChevronBackOutline size={sc.IconSize15} /> Back
            </a>
          </div>
          <div className="text-muted-medium">Payment</div>
        </div>
        <div id="appCapsule" className="extra-header-active full-height">
          <div>
            <div>
              <div style={{ margin: "1em" }}>
                {" "}
                <ValueLabel value={request.title} label={""} />
                <label className="text-muted-dark">
                  {`${request.propertyType ?? ""} ${
                    request.requestOption ?? ""
                  }`}
                </label>
                <br />
                <span className="text-muted">{request.description ?? ""}</span>
              </div>

              <div className="carpet-color-bg">
                <div
                  className="carpet-color-bg text-muted-dark"
                  style={{ margin: "7px 0px" }}
                >
                  <div className="card-body">
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>
                          <div
                            onClick={() =>
                              setPaymentOptionValue(sc.onlinePayment)
                            }
                          >
                            <label className="label">Number of Units</label>
                          </div>
                        </td>
                        <td>
                          <select
                            className="form-control"
                            onChange={setUnitsSelectedValue}
                          >
                            {unitsAvailable.map((o) => (
                              <option>{o}</option>
                            ))}
                          </select>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                <div style={{ margin: "1em", padding: "0.5px 0" }}>
                  <div className="text-muted">
                    {!request.isFullPayment && (
                      <>
                        <div className="card" style={{ marginBottom: "7px" }}>
                          <div className="card-body" style={{ padding: "9px" }}>
                            <table style={{ width: "100%" }}>
                              <tr>
                                <td>Initial Deposit</td>
                                <td>
                                  <span
                                    className="text-muted-dark"
                                    style={{ float: "right" }}
                                  >
                                    {UtilityHelper.commaCurrencyFormatter(
                                      (
                                        (request.equityAmount ?? 0) *
                                        unitsSelected
                                      )?.toString()
                                    )}
                                  </span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div className="card" style={{ marginBottom: "7px" }}>
                          <div className="card-body" style={{ padding: "9px" }}>
                            <table style={{ width: "100%" }}>
                              <tr>
                                <td>Monthly Payment</td>
                                <td>
                                  <span
                                    className="text-muted-dark"
                                    style={{ float: "right" }}
                                  >
                                    {UtilityHelper.commaCurrencyFormatter(
                                      (
                                        (request.installmentAmount ?? 0) *
                                        unitsSelected
                                      )?.toString()
                                    )}
                                  </span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div className="card" style={{ marginBottom: "7px" }}>
                          <div className="card-body" style={{ padding: "9px" }}>
                            <table style={{ width: "100%" }}>
                              <tr>
                                <td>Payment Duration</td>
                                <td>
                                  <span
                                    className="text-muted-dark"
                                    style={{ float: "right" }}
                                  >
                                    {request.paymentDuration} Month(s)
                                  </span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="card" style={{ marginBottom: "7px" }}>
                      <div className="card-body" style={{ padding: "9px" }}>
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td>Total Payment</td>
                            <td>
                              <b
                                className="badge badge-primary text-primary-main"
                                style={{ float: "right" }}
                              >
                                <span className="text-muted-medium-dark">
                                  {UtilityHelper.commaCurrencyFormatter(
                                    (
                                      (request.totalPayment ?? 0) *
                                      unitsSelected
                                    )?.toString()
                                  )}
                                </span>
                              </b>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="carpet-color-bg">
                <div
                  className="carpet-color-bg text-muted"
                  style={{ margin: "7px 0px" }}
                >
                  <div className="card-body">
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>
                          <div
                            className="form-check"
                            onClick={() =>
                              setPaymentOptionValue(sc.onlinePayment)
                            }
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="radioPaymentOption"
                              id="radioOnlinePayment"
                              checked={paymentOption == sc.onlinePayment}
                              onClick={() =>
                                setPaymentOptionValue(sc.onlinePayment)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="radioOnlinePayment"
                            >
                              Pay Online
                            </label>
                          </div>
                        </td>
                        <td>
                          <div
                            className="form-check"
                            onClick={() =>
                              setPaymentOptionValue(sc.accountPayment)
                            }
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="radioPaymentOption"
                              id="radioAccountPayment"
                              checked={paymentOption == sc.accountPayment}
                              onClick={() =>
                                setPaymentOptionValue(sc.accountPayment)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="radioAccountPayment"
                            >
                              Transfer to Account
                            </label>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: auth.appWidthFooter(),
          marginLeft: auth.appMarginFooter(),
          background: "#2d1f3b",
          borderRadius: 25,
          marginBottom: "2%",
          color: "white",
        }}
        className="appBottomMenu"
      >
        {/* 'Pay' for e payment */}
        {/* 'Receive Invoice' for account payment */}

        <>
          {paymentOption === sc.onlinePayment && (
            <div onClick={onlinePayment}>Pay</div>
          )}
          {paymentOption === sc.accountPayment && (
            <div onClick={receiveInvoice}>Receive Invoice</div>
          )}
        </>
      </div>
    </>
  );
};
export default SubmitPropertyRequest;
