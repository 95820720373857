import v from "./variables";
import SigninResponseDto from "../models/User/Response/signinResponseDto";
import Cookies from "js-cookie";
import sc from "../services/stringconst";
import SessionDto from "../models/User/SessionDto";
import auth from "../services/authservice";

const processRequest = async (
  route: string,
  httpMethod: string,
  data: any,
  token: string
) => {
  let url = route.replace(/[?&]$/, "");

  var options = {
    body: httpMethod == v.httpGet ? undefined : JSON.stringify(data),
    method: httpMethod,
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
  };

  return window.fetch(url, options).then(function (response) {
    return processResponse(response);
  });
  // .catch((e) => {
  //   return throwException("No Connection", 999, "No connection", []);
  // });
};

const getToken = async () => {
  let sessionString = Cookies.get(sc.session);

  if (!sessionString) return "";

  const session = <SessionDto>JSON.parse(sessionString);

  if (Date.now() < session.timeout - 10000) return session.token;

  let body = {
    userId: session.userId,
    refreshToken: session.refreshToken,
  };

  let data = <SigninResponseDto>(
    await processRequest(v.baseUrl + "user/refreshtoken", v.httpPost, body, "")
  );

  Cookies.set(
    sc.session,
    JSON.stringify({
      userId: data.session.id,
      timeout: Date.now() + data.session.timeout,
      token: data.accessToken,
      refreshToken: data.session.key,
    })
  );

  return data.accessToken;
};

const postRequest = async (
  route: string,
  data: any,
  useToken: boolean = true
) => {
  let token = "";
  if (useToken) token = await getToken();
  return processRequest(route, v.httpPost, data, token);
};
const getRequest = async (route: string) => {
  const token = await getToken();
  return processRequest(route, v.httpGet, undefined, token);
};
const getRequestNoAuth = async (route: string) => {
  return processRequest(route, v.httpGet, undefined, "");
};

const processResponse = async (response: any) => {
  var status = response.status;
  var _headers: [];
  _headers = response.headers;

  if (status === 200) {
    return response.text().then(function (_responseText: any) {
      return JSON.parse(_responseText);
    });
  }
  if (status === 201) {
    return response.text().then(function (_responseText: any) {
      return JSON.parse(_responseText);
    });
  }
  if (status === 204) {
    return response.text().then(function (_responseText: any) {
      return JSON.parse(_responseText);
    });
  }
  if (status === 400) {
    return response.text().then(function (_responseText: any) {
      return throwException("Bad Request", status, _responseText, _headers);
    });
  }
  if (status === 401) {
    return response.text().then(function (_responseText: any) {
      if (_responseText == sc.signInToContinue) {
        auth.logout();
        Cookies.remove(sc.session);
        window.location.href = "/";
      }
      return throwException("Unauthorized", status, _responseText, _headers);
    });
  }
  if (status === 403) {
    return response.text().then(function (_responseText: any) {
      return throwException("Forbidden", status, _responseText, _headers);
    });
  }
  if (status === 404) {
    return response.text().then(function (_responseText: any) {
      return throwException("Not Found", status, _responseText, _headers);
    });
  }
  if (status === 409) {
    return response.text().then(function (_responseText: any) {
      return throwException("Conflict", status, _responseText, _headers);
    });
  }
  if (status === 500) {
    return response.text().then(function (_responseText: any) {
      return throwException(
        "Internal Server Error",
        status,
        _responseText,
        _headers
      );
    });
  }
  if (status !== 200 && status !== 201 && status !== 204) {
    return response.text().then(function (_responseText: any) {
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    });
  }
  return Promise.resolve(null);
};

const throwException = (
  message: any,
  status: any,
  response: any,
  headers: []
) => {
  throw new Error(response);
};

const HttpClient = {
  postRequest,
  getRequest,
  getRequestNoAuth,
};

export default HttpClient;
