import AttributeDto from "../../models/Property/AttributeDto";
import FacilityDto from "../../models/Property/FacilityDto";
import PropertyDevelopmentDto from "../../models/Property/PropertyDevelopmentDto";
import PropertyDto from "../../models/Property/PropertyDto";
import PropertyListingRequestDtoDto from "../../models/Property/PropertyListingRequestDto";
import PropertyMediaDto from "../../models/Property/PropertyMediaDto";
import PropertyTypeDto from "../../models/Property/PropertyTypeDto";
import PropertyDetailsDto from "../../models/Property/propertyDetailsDto";
import KeyValueDto from "../../models/Shared/KeyValueDto";
import BaseResponseDto from "../../models/Shared/baseResponseDto";
import UserViewDto from "../../models/User/UserViewDto";
import sc from "../../services/stringconst";
import HttpClient from "../httpClient";
import v from "../variables";

let baseRoute = "property/";

const CreateFacility = async (request: FacilityDto): Promise<FacilityDto> => {
  let route = v.baseUrl + baseRoute + "createfacility";

  return <FacilityDto>await HttpClient.postRequest(route, request);
};
const GetFacilities = async (): Promise<FacilityDto[]> => {
  let route = v.baseUrl + baseRoute + "getfacilities";

  return <FacilityDto[]>await HttpClient.getRequest(route);
};
const GetProperty = async (propertyId: string): Promise<PropertyDto> => {
  let route = v.baseUrl + baseRoute + "getproperty/" + propertyId;

  return <PropertyDto>await HttpClient.getRequest(route);
};

const GetPropertyDetails = async (
  propertyId: string
): Promise<PropertyDetailsDto> => {
  let route = v.baseUrl + baseRoute + "getgropertydetails/" + propertyId;

  return <PropertyDetailsDto>await HttpClient.getRequest(route);
};
const GetProperties = async (
  includeUnavailable: boolean,
  realtorId: string,
  skip: number,
  limit: number
): Promise<PropertyDto[]> => {
  let route =
    v.baseUrl +
    baseRoute +
    `getproperties?realtorId=${realtorId}&skip=${skip}&limit=${limit}&includeUnavailable=${includeUnavailable}`;

  return <PropertyDto[]>await HttpClient.getRequest(route);
};

const PropertyListing = async (
  minPrice: number,
  maxPrice: number,
  state: string,
  requestOptions: string[],
  propertyTypes: string[],
  skip: number
): Promise<PropertyDto[]> => {
  let route = v.baseUrl + baseRoute + `propertylisting`;

  let request: PropertyListingRequestDtoDto = {
    minPrice: minPrice,
    maxPrice: maxPrice,
    state: state,
    requestOptions: requestOptions,
    propertyTypes: propertyTypes,
    skip: skip,
    limit: 20,
  };

  return <PropertyDto[]>await HttpClient.postRequest(route, request);
};

const CreateProperty = async (request: PropertyDto): Promise<PropertyDto> => {
  let route = v.baseUrl + baseRoute + "createproperty";

  return <PropertyDto>await HttpClient.postRequest(route, request);
};
const UpdatePropertyAvailableQty = async (
  propertyId: string,
  availableCount: number
): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "updatepropertyavailableqty";

  return <BaseResponseDto>await HttpClient.postRequest(route, {
    propertyId: propertyId,
    availableCount: availableCount,
  });
};
const AddPropertyDevelopment = async (
  propertyId: string,
  propertyDevelopment: PropertyDevelopmentDto
): Promise<PropertyDto> => {
  let route = v.baseUrl + baseRoute + "addpropertydevelopment";

  return <PropertyDto>await HttpClient.postRequest(route, {
    propertyId: propertyId,
    propertyDevelopment: propertyDevelopment,
  });
};

const RemovePropertyDevelopment = async (
  propertyId: string,
  propertyDevelopmentId: string
): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "removepropertydevelopment";

  return <BaseResponseDto>await HttpClient.postRequest(route, {
    propertyId: propertyId,
    propertyDevelopmentId: propertyDevelopmentId,
  });
};

const CreatePropertyType = async (
  request: PropertyTypeDto
): Promise<PropertyTypeDto> => {
  let route = v.baseUrl + baseRoute + "createpropertytype";

  return <PropertyDto>await HttpClient.postRequest(route, request);
};
const GetPropertyTypes = async (): Promise<PropertyTypeDto[]> => {
  let route = v.baseUrl + baseRoute + "getpropertytypes";

  return <PropertyTypeDto[]>await HttpClient.getRequest(route);
};
const AddPropertyDevelopmentMedia = async (
  propertyId: string,
  propertyDevelopmentId: string,
  medias: PropertyMediaDto[]
): Promise<PropertyMediaDto[]> => {
  let route = v.baseUrl + baseRoute + "addpropertydevelopmentmedia";

  return <PropertyMediaDto[]>await HttpClient.postRequest(route, {
    propertyId: propertyId,
    propertyDevelopmentId: propertyDevelopmentId,
    medias: medias,
  });
};
const RemovePropertyDevelopmentMedia = async (
  propertyId: string,
  propertyDevelopmentId: string,
  mediaId: string
): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "removepropertydevelopmentmedia";

  return <BaseResponseDto>await HttpClient.postRequest(route, {
    propertyId: propertyId,
    propertyDevelopmentId: propertyDevelopmentId,
    mediaId: mediaId,
  });
};

const AddPropertyMedia = async (
  propertyId: string,
  propertyMedias: PropertyMediaDto[]
): Promise<PropertyMediaDto[]> => {
  let route = v.baseUrl + baseRoute + "addpropertymedia";

  return <PropertyMediaDto[]>await HttpClient.postRequest(route, {
    propertyId: propertyId,
    propertyMedias: propertyMedias,
  });
};
const RemovePropertyMedia = async (
  propertyId: string,
  mediaId: string
): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "removepropertymedia";

  return <BaseResponseDto>await HttpClient.postRequest(route, {
    propertyId: propertyId,
    mediaId: mediaId,
  });
};
const AddPropertyFacility = async (
  propertyId: string,
  facility: FacilityDto
): Promise<PropertyDto> => {
  let route = v.baseUrl + baseRoute + "addpropertyfacility";

  return <PropertyDto>await HttpClient.postRequest(route, {
    propertyId: propertyId,
    facility: facility,
  });
};
const AddPropertyAttribute = async (
  propertyId: string,
  attribute: AttributeDto
): Promise<PropertyDto> => {
  let route = v.baseUrl + baseRoute + "addpropertyattribute";

  return <PropertyDto>await HttpClient.postRequest(route, {
    propertyId: propertyId,
    attribute: attribute,
  });
};
const UpdateContactPersonId = async (
  propertyId: string,
  contactPersonId: string
): Promise<UserViewDto> => {
  let route = v.baseUrl + baseRoute + "updatecontactpersonid";

  return <UserViewDto>await HttpClient.postRequest(route, {
    propertyId: propertyId,
    contactPersonId: contactPersonId,
  });
};
const UpdateProjectEndDate = async (
  propertyId: string,
  projectEndDate: number
): Promise<PropertyDto> => {
  let route = v.baseUrl + baseRoute + "updateprojectenddate";

  return <PropertyDto>await HttpClient.postRequest(route, {
    propertyId: propertyId,
    projectEndDate: projectEndDate,
  });
};

const RemoveFacility = async (
  propertyId: string,
  facilityId: string
): Promise<PropertyDto> => {
  let route = v.baseUrl + baseRoute + "removefacility";

  return <PropertyDto>await HttpClient.postRequest(route, {
    propertyId: propertyId,
    facilityId: facilityId,
  });
};

const RemoveAttribute = async (
  propertyId: string,
  facilityId: string
): Promise<PropertyDto> => {
  let route = v.baseUrl + baseRoute + "removeattribute";

  return <PropertyDto>await HttpClient.postRequest(route, {
    propertyId: propertyId,
    attributeId: facilityId,
  });
};
const SetDefaultMedia = async (
  propertyId: string,
  mediaId: string
): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "setdefaultmedia";

  return <BaseResponseDto>await HttpClient.postRequest(route, {
    propertyId: propertyId,
    mediaId: mediaId,
  });
};

const GetPropertyRequestOptions = (): KeyValueDto[] => {
  let data: KeyValueDto[] = [
    { _id: sc.Lease, value: sc.Lease },
    { _id: sc.Purchase, value: sc.Purchase },
    { _id: sc.Rent, value: sc.Rent },
  ];
  return data;
};
const GetPropertyRequestOptionsValues = (): any[] => {
  let data = [
    { value: sc.Lease, label: sc.Lease },
    { value: sc.Purchase, label: sc.Purchase },
    { value: sc.Rent, label: sc.Rent },
  ];
  return data;
};

const GetMaxAmount = async (): Promise<KeyValueDto> => {
  let route = v.baseUrl + baseRoute + "getmaxamount";

  return <KeyValueDto>await HttpClient.getRequest(route);
};

const propertyClient = {
  CreateFacility,
  GetFacilities,
  GetProperty,
  GetPropertyDetails,
  GetProperties,
  CreateProperty,
  UpdatePropertyAvailableQty,
  AddPropertyDevelopment,
  RemovePropertyDevelopment,
  CreatePropertyType,
  GetPropertyTypes,
  AddPropertyDevelopmentMedia,
  RemovePropertyDevelopmentMedia,
  AddPropertyMedia,
  RemovePropertyMedia,
  AddPropertyFacility,
  AddPropertyAttribute,
  UpdateContactPersonId,
  UpdateProjectEndDate,
  RemoveFacility,
  RemoveAttribute,
  GetPropertyRequestOptions,
  SetDefaultMedia,
  GetPropertyRequestOptionsValues,
  GetMaxAmount,
  PropertyListing,
};

export default propertyClient;
