import React from "react";
import { useEffect } from "react";
import { Client } from "../services/apiclient";
import { useState } from "react";
import sc from "../services/stringconst";
import util from "../Utilities/UtilityHelper";
import { useLocation } from "react-router-dom";
import auth from "../services/authservice";
import { useNavigate } from "react-router-dom";
import * as moment from "moment";

const LoanRepaymentSchedule = (props) => {
  const client = new Client(null, null);
  const navigate = useNavigate();
  const location = useLocation();

  const [paymentSchedule, setPaymentSchedule] = useState([{ payments: [] }]);
  const [loan, setLoan] = useState({});
  const [loanDisplayTab, setLoanDisplayTab] = useState();
  const [loanPagePosition, setLoanPagePosition] = useState();
  const [stateData, setStateData] = useState();

  const [dueDate, setDueDate] = useState();
  const [amountDue, setAmountDue] = useState(0);

  const getDueAmountValue = (paymentScheduleData) => {
    let periodPassDue = paymentScheduleData.filter(
      (o) => new Date(o.dueDate) <= new Date()
    );

    let passDueAmount = periodPassDue.reduce((a, b) => +a + +b.amount, 0);

    let passDueAmountPaid = 0;
    for (let i = 0; i < periodPassDue.length; i++) {
      let periodPayments = periodPassDue[i].payments.filter(
        (o) => o.status !== sc.PaymentRejected
      );
      passDueAmountPaid =
        passDueAmountPaid + periodPayments.reduce((a, b) => +a + +b.amount, 0);
    }

    if (periodPassDue.length > 0) {
      setDueDate(periodPassDue[periodPassDue.length - 1].dueDate);
    } else {
      if (paymentScheduleData.length > 0) {
        setDueDate(paymentScheduleData[0].dueDate);
      }
    }

    let amountDueValue = passDueAmount - passDueAmountPaid;

    if (amountDueValue > 0) {
      setAmountDue(amountDueValue);
    }
  };

  useEffect(() => {
    if (!location.state) {
      navigate(sc.homeroute);
      return;
    }

    setStateData(location.state);

    setLoanDisplayTab(location.state.loanDisplayTab);
    setLoanPagePosition(location.state.loanPagePosition);
    let loanData = location.state.loan;
    setLoan(loanData);

    client.getLoanPaymentSchedule({ loanId: loanData.loanId }).then(
      (data) => {
        setPaymentSchedule(data);
        getDueAmountValue(data);
      },
      (error) => {
        props.ShowAlert(error.response, sc.danger);
      }
    );
  }, []);

  const openPayments = (scheduleItem) => {
    navigate(sc.paymentroute, {
      state: {
        scheduleItem: scheduleItem,
        stateData: stateData,
        loanPagePosition: window.scrollY,
      },
    });
  };

  const getPaymentStatusCSSClass = (status, isPaymentButton) => {
    let holder = "[color]";

    let pmtClassName = "btn-text-[color]";

    if (isPaymentButton) {
      pmtClassName = "badge badge-[color]";
    }

    if (
      status === sc.Pending ||
      status === sc.PartPaymentRaised ||
      status === sc.PaymentRaised ||
      status === sc.PartPaymentReceived
    ) {
      return pmtClassName.replace(holder, "warning");
    }
    if (status === sc.PaymentReceived) {
      return pmtClassName.replace(holder, "success");
    }

    if (status === sc.PaymentReceived) {
      if (!isPaymentButton) {
        return pmtClassName.replace(holder, "success");
      } else {
        return pmtClassName.replace(holder, "primary");
      }
    }
    if (
      status === sc.PartPaymentRejected ||
      status === sc.PaymentRejected ||
      status === sc.Overdue ||
      status === sc.Defaulted
    ) {
      return pmtClassName.replace(holder, "danger");
    }
  };
  const back = () => {
    navigate(loanDisplayTab ? sc.loansroute : sc.repaymentsviewroute, {
      state: {
        loanId: loan.loanId,
        isLender: loanDisplayTab === 2,
        loanPagePosition: loanPagePosition,
        status: location.state ? location.state.status : null,
      },
    });
  };
  const newPayment = () => {
    if (!stateData.loan.disbursed) {
      props.ShowAlert(sc.confirmDisbursementBeforeRepayment, sc.warning);
      back();
      return;
    }

    navigate(sc.newPaymentRoute, {
      state: {
        loan: loan,
        dueAmount: amountDue,
        dueDate: dueDate,
        stateData: stateData,
        loanPagePosition: window.scrollY,
      },
    });
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div>{loan.title}</div>
        {loan.status === sc.Open &&
          loan.borrower === auth.getuserProfileId() && (
            <a className="right" onClick={newPayment}>
              <div className="headerButton">
                <ion-icon name="add-circle-outline"></ion-icon>&nbsp;Repay
              </div>
            </a>
          )}
      </div>

      <div id="appCapsule" className="extra-header-active full-height">
        <div>
          <div className="card">
            <table
              className="table table-responsive"
              style={{
                position: "fixed",
                zIndex: "1001",
                width: auth.appWidth(),
              }}
            >
              <thead>
                <tr>
                  <th scope="col">Due</th>
                  <th scope="col" className="text-end">
                    Principal
                  </th>
                  <th scope="col" className="text-center">
                    Int
                  </th>
                  <th scope="col" className="text-end">
                    Total
                  </th>
                </tr>
              </thead>
            </table>

            <div>
              <table className="table table-responsive">
                <thead
                  style={{
                    position: "-webkit-sticky",
                    position: "sticky",
                    left: "0",
                    zIndex: "1",
                  }}
                >
                  <tr>
                    <th scope="col">&nbsp;</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {paymentSchedule.map((item) => (
                    <tr className="">
                      <td scope="row">
                        {moment(item.dueDate).format("DD.MMM.YY")}
                        <br />
                        <span className={getPaymentStatusCSSClass(item.status)}>
                          {item.status}
                        </span>
                      </td>
                      <td className="text-end">{item.principal}</td>
                      <td className="text-end">{item.interest}</td>
                      <td className="text-end">
                        {util.commaCurrencyFormatter(item.amount)}
                        {item.payments.length > 0 && (
                          <>
                            <br />
                            <span
                              className={getPaymentStatusCSSClass(
                                item.status,
                                true
                              )}
                              onClick={() => openPayments(item)}
                              data-bs-toggle="modal"
                              data-bs-target="#repaymentModal"
                            >
                              {item.payments.length} payment &nbsp;{" "}
                              <ion-icon name="open-outline"></ion-icon>
                            </span>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <thead>
                  <tr>
                    <th scope="row">Sum</th>
                    <th scope="col" className="text-end">
                      {loan.principal}
                    </th>
                    <th scope="col" className="text-end">
                      {loan.interest}
                    </th>
                    <th scope="col" className="text-end">
                      {util.commaCurrencyFormatter(loan.amount)}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoanRepaymentSchedule;
