import React from "react";
import sc from "../../services/stringconst";
import { useState } from "react";
import CustomButton from "./custombutton";
import auth from "../../services/authservice";

const LoanApprovalModal =(props)=>{

  let loanId=props.loanId;
  let action=props.action;
  
  
  const [lenderNote, setLenderNote] = useState();
  const [disbursementDate, setDisbursementDate] = useState();

  const setLenderNoteValue = (e) => {
    setLenderNote(e.target.value);
  };

  const setDisbursementDateValue = (e) => {
    setDisbursementDate(e.target.value);
  };


return(
  <div>


<div className="card" style={{width:auth.appWidth(), position:"fixed",top:"25%", textAlign:"center"}}>
<div className="modal-content" >
    <div className="modal-header" style={{width:auth.appWidth(), marginLeft:auth.appMargin()}}>
                          <h5 className="modal-title text-primary"><b>{action} Loan</b></h5>
                      </div>
                      <div className="modal-body">
                        
                      { (
                            <>
                             {action===sc.Approve && <div className="form-group basic">
                                <div className="input-wrapper">
                                  <label
                                    className="label"
                                    style={{ color: action===sc.Approve?"red":"" }}
                                  >
                                    {" "}
                                    Disbursement Date
                                  </label>
                                  <input
                                    type="date"
                                    value={disbursementDate}
                                    onChange={setDisbursementDateValue}
                                    className="form-control"
                                  />
                                </div>
                              </div>}
                              <div className="form-group basic">
                                <div className="input-wrapper">
                                  <label
                                    className="label"
                                    style={{ color: action===sc.Disapprove?"red":"" }}
                                  >
                                    Lender's Note
                                  </label>
                                  <input
                                    value={lenderNote}
                                    onChange={setLenderNoteValue}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter note to borrower here"
                                  />
                                </div>
                              </div>
                            </>
                          )}

</div>
                      <div className="modal-footer">
                          <div>
                          <CustomButton inline="btn-inline" httpProcessing={props.httpProcessing} text="Proceed" onClick={()=>action===sc.Approve?props.action1(loanId,disbursementDate,lenderNote, false):props.action2(loanId,lenderNote)} />
                          <a onClick={props.action3} className="btn btn-text-primary">Cancel</a>
                           
                          </div>
                      </div>
      </div>
      
      </div>
</div>
)
};

export default LoanApprovalModal;

