import React from "react";
import { useNavigate } from "react-router-dom";
import sc from "../services/stringconst";
import auth from "../services/authservice";
import { Client } from "../services/apiclient";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const LenderCreditCheck = (props) => {
  const navigate = useNavigate();
  const client = new Client();
  const location = useLocation();

  const [data, setData] = useState({});

  useEffect(() => {
    if (!location.state.lenderId) {
      navigate(-1);
    }
    client.lendercreditcheck(location.state.lenderId).then(
      (data) => {
        setData(data);
      },
      (error) => {
        props.ShowAlert(error.response, sc.danger);
      }
    );
  }, []);

  return (
    <>
      {/* <!-- App Header --> */}
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main   bg-primary text-light"
      >
        <div className="left">
          <a onClick={() => navigate(-1)} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div>Lender Credit Check</div>
      </div>

      {/* <!-- * App Header -->

    <!-- App Capsule --> */}
      <div id="appCapsule" className="extra-header-active full-height">
        <div className="card" style={{ margin: "1em" }}>
          <ul className="listview image-listview transparent flush">
            <li>
              <div className="item">
                <div className="icon-box">
                  <ion-icon name="person-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>Lender</div>
                  <div>{location.state.name}</div>
                </div>
              </div>
            </li>
            {/* <li>
              <div className="item">
                <div className="icon-box text-info">
                <ion-icon name="checkmark-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>Approved Loans<br/><span className="text-muted">Loans that has been approved but not yet open for disbursement and repayments</span></div>
                  <div>
                  <div className="icon-box bg-info">{data.approved}
                    </div>
                  </div>
                </div>
              </div>
            </li> */}
            <li>
              <div className="item">
                <div className="icon-box text-secondary">
                  <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>
                    Disbursed Loans
                    <br />
                    <span className="text-muted">
                      Loans that the borrower has confirmed receipt of principal
                    </span>
                  </div>
                  <div>
                    <div className="icon-box bg-secondary">
                      {data.disbursed}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="icon-box text-primary">
                  <ion-icon name="folder-open-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>
                    Opened Loans
                    <br />
                    <span className="text-muted">
                      Loans that are active and still within repayment tenure
                    </span>
                  </div>
                  <div>
                    <div className="icon-box bg-primary">{data.open}</div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="icon-box text-success">
                  <ion-icon name="checkmark-done-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>
                    Closed Loans
                    <br />
                    <span className="text-muted">
                      Loans that all repayments have been completed
                    </span>
                  </div>
                  <div>
                    <div className="icon-box bg-success">{data.closed}</div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="icon-box text-warning">
                  <ion-icon name="folder-open-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>
                    Unclosed Loans
                    <br />
                    <span className="text-muted">
                      Loans that are still open after repayment tenure
                    </span>
                  </div>
                  <div>
                    <div className="icon-box bg-warning">{data.unclosed}</div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {/* <!-- * App Capsule --> */}
    </>
  );
};
export default LenderCreditCheck;
