import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import auth from "../../../services/authservice";
import sc from "../../../services/stringconst";
import LoadingImagePlaceHolder from "./../../childcomponents/loadingimageplaceholder";
import CustomButton from "./../../childcomponents/custombutton";
import appFeaturesClient from "../../../httpClients/clients/appFeaturesClient";
import StateDto from "../../../models/AppFeature/StateDto";
import propertyClient from "../../../httpClients/clients/propertyClient";
import ErrorDto from "../../../models/AppFeature/ErrorDto";
import PropertyDto from "../../../models/Property/PropertyDto";
import {
  IoArrowDownOutline,
  IoClose,
  IoHomeOutline,
  IoLocationOutline,
  IoSearchOutline,
} from "react-icons/io5";
import Select from "react-select";
import "react-rangeslider/lib/index.css";
import UtilityHelper from "../../../Utilities/UtilityHelper";
import LabelValueDto from "../../../models/Shared/LabelValueDto";
import FileManager from "../../../services/FileManager";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const FindProperty = ({ ShowAlert }: Props) => {
  let navigate = useNavigate();

  let statesTemp: StateDto[] = [];
  let [states, setStates] = useState(statesTemp);
  let [state, setState] = useState("");
  const setStateValue = (e: any) => {
    setState(e.target.value);
    setSkip(0);
  };

  let propertiesTemp: PropertyDto[] = [];
  let [properties, setProperties] = useState(propertiesTemp);

  const [httpProcessing, setHttpProcessing] = useState(false);

  let [dataLoaded, setDataLoaded] = useState(true);

  let [pageHeader, setPageHeader] = useState("Find Property");

  const gotoWelcome = () => {
    navigate(sc.welcomeroute);
  };

  useEffect(() => {
    getPropertyTypes();
    search();
    appFeaturesClient.GetStates().then((result) => {
      setStates(result);
    });
  }, []);

  const [searchBoxVisible, setSearchBoxVisible] = useState(true);

  const setSearchBoxVisibility = () => {
    setSearchBoxVisible(!searchBoxVisible);
  };

  const [skip, setSkip] = useState(0);
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  const search = () => {
    setDataLoaded(false);

    setHttpProcessing(true);

    const selectedRequestOptionValue: LabelValueDto[] = selectedRequestOption;
    let requestOptionValues: string[] = [];
    if (selectedRequestOptionValue)
      requestOptionValues = selectedRequestOptionValue.map((n) => n["value"]);

    const selectedPropertyTypeValue: LabelValueDto[] = selectedPropertyType;
    let propertyTypeValues: string[] = [];

    if (selectedPropertyTypeValue)
      propertyTypeValues = selectedPropertyTypeValue.map((n) => n["value"]);

    propertyClient
      .PropertyListing(
        minPrice ?? 0,
        maxPrice ?? 0,
        state,
        requestOptionValues,
        propertyTypeValues,
        skip
      )
      .then(
        (data) => {
          setHttpProcessing(false);
          setDataLoaded(true);
          if (data.length > 0) {
            setSearchBoxVisible(false);
          } else {
            setAllDataLoaded(true);
            if (properties.length == 0)
              ShowAlert(`0 property found`, sc.warning);
          }
          if (skip > 0) propertiesTemp = [...properties];

          data.forEach((o) => {
            propertiesTemp.push(o);
          });
          setProperties(propertiesTemp);

          setSkip(propertiesTemp.length);
        },
        (error: ErrorDto) => {
          setHttpProcessing(false);
          ShowAlert(error.message, sc.danger);
        }
      );
  };

  const propertyTypesTemp: any = [];
  const [propertyTypes, setPropertyTypes] = useState(propertyTypesTemp);
  let selectedPropertyTypeTemp: any;
  const [selectedPropertyType, setSelectedPropertyType] = useState(
    selectedPropertyTypeTemp
  );

  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();

  const getPropertyTypes = () => {
    propertyClient.GetPropertyTypes().then(
      (data) => {
        data.forEach((o) => {
          propertyTypesTemp.push({ value: o.title, label: o.title });
        });
        setPropertyTypes(propertyTypesTemp);
      },
      (error) => {
        ShowAlert(error.response, sc.danger);
      }
    );
  };
  const setPropertyTypeValue = (e: any) => {
    setSelectedPropertyType(e);
    setSkip(0);
  };

  let selectedRequestOptionTemp: any;
  const [selectedRequestOption, setSelectedRequestOption] = useState(
    selectedRequestOptionTemp
  );

  const setRequestOptionValue = (e: any) => {
    setSelectedRequestOption(e);
    setSkip(0);
  };

  const handleMinPriceChange = (e: any) => {
    setMinPrice(e.target.value);
    setSkip(0);
  };
  const handleMaxPriceChange = (e: any) => {
    setMaxPrice(e.target.value);
    setSkip(0);
  };

  const openProperty = (propertyId: string) => {
    navigate(sc.propertyroute, { state: { propertyId: propertyId } });
  };
  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light highZIndex"
      >
        <div className="left">
          <a onClick={gotoWelcome} className="headerButton goBack">
            <IoHomeOutline size={sc.IconSize20} />
          </a>
        </div>
        <div>{pageHeader}</div>
        <div className="right">
          <a className="headerButton toggle-searchbox">
            {!searchBoxVisible && (
              <IoSearchOutline
                id="searchbox-toggle"
                size={sc.IconSize20}
                onClick={setSearchBoxVisibility}
              />
            )}
          </a>
        </div>
      </div>

      {searchBoxVisible && (
        <div
          style={{
            background: "white",
            position: "fixed",
            zIndex: "1001",
            width: auth.appWidth(),
            paddingBottom: "10px",
          }}
        >
          <div
            style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
            className="appHeader bg-primary-main  bg-primary text-light"
          >
            <div>{pageHeader}</div>
            <div className="right">
              <a className="headerButton toggle-searchbox">
                {searchBoxVisible && (
                  <IoClose
                    size={sc.IconSize20}
                    id="searchbox-toggle"
                    name="close"
                    onClick={setSearchBoxVisibility}
                  />
                )}
              </a>
            </div>
          </div>
          <br />
          <div className="extraHeaderSearch">
            <div className="form-group basic">
              <table style={{ width: "100%" }}>
                <tr>
                  <td colSpan={2}>
                    <span className="text-muted">Price Filter</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>
                    <span>
                      <input
                        type="number"
                        value={minPrice}
                        onChange={handleMinPriceChange}
                        id="minPrice"
                        style={{ width: "100%" }}
                        className="form-control"
                        placeholder="Min"
                      />
                    </span>
                  </td>
                  <td style={{ width: "50%" }}>
                    <span>
                      <input
                        type="number"
                        value={maxPrice}
                        onChange={handleMaxPriceChange}
                        id="maxPrice"
                        style={{ width: "100%" }}
                        className="form-control"
                        placeholder="Max"
                      />
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <br />
          <div className="extraHeaderSearch">
            <div className="form-group basic">
              <span className="text-muted">Property Type</span>
              <Select
                value={selectedPropertyType}
                onChange={setPropertyTypeValue}
                className="custom-select"
                id="requestOption"
                options={propertyTypes}
                isMulti
              />
            </div>
          </div>
          <br />
          <div className="extraHeaderSearch">
            <div className="form-group basic">
              <span className="text-muted">Request Option</span>
              <Select
                value={selectedRequestOption}
                onChange={setRequestOptionValue}
                className="custom-select"
                id="requestOption"
                options={propertyClient.GetPropertyRequestOptionsValues()}
                isMulti
              />
            </div>
          </div>
          <br />
          <div className="extraHeaderSearch">
            <div className="form-group basic">
              <div className="input-wrapper">
                <label className="label" htmlFor="state">
                  {state ? "State" : <span>&nbsp;</span>}
                </label>

                <select
                  onChange={setStateValue}
                  value={state}
                  className="form-control custom-select"
                  id="state"
                >
                  <option key="" value="" selected disabled hidden>
                    State
                  </option>
                  {states.map((state) => (
                    <option key={state.title} value={state.title}>
                      {state.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <br />
          <div className="extraHeaderSearch">
            <CustomButton
              bgColor="primary"
              httpProcessing={httpProcessing}
              text="Search"
              onClick={search}
            />
          </div>{" "}
          <br />
          &nbsp;
        </div>
      )}

      <div id="appCapsule" className="extra-header-active full-height">
        <div>
          {properties.map((property) => (
            <>
              <div
                className="card"
                style={{ marginBottom: "10px", cursor: "pointer" }}
                onClick={() => openProperty(property._id ?? "")}
              >
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ width: "40%" }}>
                      <img
                        src={auth.loadPropertyImage(
                          FileManager.buildPropertyMedaiUrl(property)
                        )}
                        alt="image"
                        style={{ width: "100%" }}
                      />
                    </td>
                    <td>
                      <div
                        style={{ width: "100%", padding: "10px 0px 10px 10px" }}
                        className="text-muted"
                      >
                        <b className="text-primary-main">{property.title}</b>
                        <br />
                        <span>
                          {property.propertyType} {property.requestOption}
                        </span>

                        <br />
                        {(property.availableCount ?? 0) > 0 && (
                          <span>
                            {property.availableCount} Unit
                            {(property?.availableCount ?? 0) > 1
                              ? "s"
                              : ""}{" "}
                            Left
                          </span>
                        )}
                        {(property.availableCount ?? 0) == 0 && (
                          <span>Out of stock</span>
                        )}
                        <br />
                        <span>
                          <IoLocationOutline size={sc.IconSize20} />
                          {property.location?.city} {property.location?.state}
                        </span>
                        <br />
                        <span>
                          <span>
                            {property.previousRealtorPrice && (
                              <span
                                style={{
                                  color: "red",
                                  textDecorationLine: "line-through",
                                }}
                              >
                                {UtilityHelper.commaCurrencyFormatter(
                                  property.previousRealtorPrice?.toString() ??
                                    ""
                                )}{" "}
                                <br />
                              </span>
                            )}
                            <b className="badge badge-primary text-primary-main">
                              <span className="text-muted-medium-dark">
                                {UtilityHelper.commaCurrencyFormatter(
                                  property.realtorPrice?.toString() ?? ""
                                )}
                              </span>
                            </b>
                            <br />
                            Flexible payment is available
                          </span>
                        </span>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </>
          ))}
          {dataLoaded === false && (
            <>
              <LoadingImagePlaceHolder />
            </>
          )}
          {!allDataLoaded && (
            <div className="loadMore">
              <a className="text" onClick={() => search()}>
                <IoArrowDownOutline size={sc.IconSize25} />
              </a>
            </div>
          )}
        </div>
      </div>
      <br />
      <br />
    </>
  );
};

export default FindProperty;
