import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import sc from "../services/stringconst";
import auth from "../services/authservice";

const LoanRequestSuccessful = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let loanId = location.state;

  useEffect(() => {
    if (!location.state) {
      navigate(sc.homeroute);
    }
  }, []);

  const gotoLoans = () => {
    navigate(sc.loansroute, { state: { isLender: false } });
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div>Loan</div>
      </div>
      <div id="appCapsule" className="extra-header-active full-height">
        <div
          className="section mt-2 text-center"
          style={{ padding: "20% 10%" }}
        >
          <div className="card">
            <div className="card-body">
              <h3> Loan Request Sent</h3>
            </div>
            <div className="card-body">
              <ion-icon
                name="checkmark-circle-outline"
                style={{ fontSize: "5em", color: "green" }}
              ></ion-icon>
              <br />
              <span className="text-muted"># </span>{" "}
              <span style={{ fontSize: "1.1em" }}> {loanId}</span>
            </div>
          </div>
          <br />

          <div className="form-group transparent">
            <button
              type="submit"
              onClick={gotoLoans}
              className="btn btn-primary btn-block"
            >
              View loans
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoanRequestSuccessful;
