import Stylesheet from "reactjs-stylesheet";
import auth from "../../services/authservice";

const appStyles = Stylesheet.create({
  pageFrame: {
    width: auth.appWidth(),
    marginLeft: auth.appMargin(),
  },
  appLoginLogoWidth: {
    width: auth.appWidth(),
    marginLeft: auth.appMargin(),
  },
});
export default appStyles;
