import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import sc from "../../../../services/stringconst";
import auth from "../../../../services/authservice";
import {
  IoArrowForwardOutline,
  IoBusiness,
  IoChevronBackOutline,
  IoList,
  IoOpenOutline,
} from "react-icons/io5";
import FileManager from "../../../../services/FileManager";
import ErrorDto from "../../../../models/AppFeature/ErrorDto";
import ValueLabel from "../../../childcomponents/valuelabel";
import LoadingTextPlaceHolder from "../../../shared/Preload/loadingtextplaceholder";
import realtorClient from "../../../../httpClients/clients/realtorClient";
import SwipeableViews from "react-swipeable-views";
import RepresentativeDetailDto from "../../../../models/User/RepresentativeDetailDto";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const RepresentativeDetails = ({ ShowAlert }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  if (!location.state) {
    navigate(sc.customersroute);
  }
  let representativeId = location.state.representativeId;
  let realtorId = location.state.realtorId;
  let tabSelection = location.state.tabSelection;
  let backTabSelection = location.state.backTabSelection;
  let route = location.state.route;
  const [displayTab, setDisplayTab] = useState(
    tabSelection ?? backTabSelection ?? 1
  );

  let representativeTemp: RepresentativeDetailDto = {};
  const [representative, setRepresentative] = useState(representativeTemp);
  const [httpProcessing, setHttpProcessing] = useState(false);

  const back = () => {
    navigate(route, {
      state: {
        realtorId: realtorId,
        tabSelection: tabSelection,
      },
    });
  };

  const handleSwipeChange = (e: number) => {
    setTimeout(function () {
      setDisplayTab(e + 1);
    }, 1);
  };

  const setDisplay = (tab: number) => {
    setDisplayTab(tab);
  };

  const [url, setUrl] = useState(auth.getUserProfileImageUrl());

  useEffect(() => {
    GetRepresentative();
  }, []);

  const [dataLoaded, setDataLoaded] = useState(false);

  const GetRepresentative = () => {
    realtorClient.getRepresentativeDetails(representativeId).then(
      (result: RepresentativeDetailDto) => {
        setDataLoaded(true);
        setRepresentative(result);
      },
      (error: ErrorDto) => {
        setDataLoaded(true);
        ShowAlert(error.message, sc.warning);
      }
    );
  };

  const OpenPropertyRequest = (propertyRequestId: string) => {};
  const OpenProperty = (propertyId: string) => {
    navigate(sc.propertydetailsroute, {
      state: {
        propertyId: propertyId,
        representativeId: representativeId,
        tabSelection: 1,
        backTabSelection: 2,
      },
    });
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <IoChevronBackOutline size={sc.IconSize15} />
          </a>
        </div>
        <div className="text-muted-medium">Representative</div>
      </div>

      <div id="appCapsule" className="extra-header-active full-height">
        <div>
          <div className="section mt-3 text-center">
            <div className="avatar-section">
              <a>
                <img
                  src={auth.loadUserImage(representative.user?.photoUrl)}
                  alt="avatar"
                  className="imaged rounded"
                  style={{ width: "8em", height: "8em" }}
                  data-bs-toggle="modal"
                  data-bs-target="#DialogImage"
                />
              </a>
            </div>
          </div>
          <div>
            <div>
              <div>
                <div className="carpet-color-bg">
                  <div style={{ margin: "1em", padding: "0.5px 0" }}>
                    <ValueLabel
                      label="Firstname"
                      value={representative.user?.firstname}
                    />

                    <ValueLabel
                      label="Lastname"
                      value={representative.user?.lastname}
                    />
                  </div>
                </div>
                <div style={{ margin: "1em" }}>
                  <ValueLabel
                    label="Mobile Number"
                    value={representative.user?.mobileNumber}
                  />

                  <ValueLabel
                    label="Email"
                    value={representative.user?.email}
                  />
                </div>

                <div className="carpet-color-bg">
                  <div style={{ margin: "1em", padding: "0.5px 0" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <ValueLabel
                            label="Gender"
                            value={
                              representative.user?.gender == "F"
                                ? "Female"
                                : "Male"
                            }
                          />
                        </td>
                        <td>
                          <ValueLabel
                            label="Rating"
                            value={
                              representative.user?.ratingAverage?.toString() ??
                              "No Rating"
                            }
                          />
                        </td>
                        <td>
                          <span style={{ cursor: "pointer", float: "right" }}>
                            <IoOpenOutline size={sc.IconSize20} />
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>{" "}
                </div>
                <br />

                <div
                  className=""
                  style={{
                    background: "#ececec",
                    borderRadius: 25,
                    width: "90%",
                    marginLeft: "5%",
                  }}
                >
                  <ul
                    className="nav nav-tabs lined"
                    role="tablist"
                    style={{
                      background: "#ececec",
                      borderRadius: 25,
                      width: "90%",
                      marginLeft: "5%",
                    }}
                  >
                    <li className="nav-item">
                      <a
                        className={
                          displayTab === 1 ? "nav-link active" : "nav-link"
                        }
                        onClick={() => setDisplay(1)}
                      >
                        <span className="text-muted-small">
                          <IoList size={sc.IconSize20} />
                          <br />
                          <span style={{ fontSize: sc.IconLabel9px }}>
                            Request
                          </span>
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          displayTab === 2 ? "nav-link active" : "nav-link"
                        }
                        onClick={() => setDisplay(2)}
                      >
                        <span className="text-muted-small">
                          <IoBusiness size={sc.IconSize20} />
                          <br />
                          <span style={{ fontSize: sc.IconLabel9px }}>
                            Properties
                          </span>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div style={{ margin: "1em" }}>
                  <SwipeableViews
                    index={displayTab - 1}
                    onChangeIndex={handleSwipeChange}
                    style={{ clear: "both", margin: "0 5%" }}
                  >
                    <div style={Object.assign({})}>
                      <ul className="listview image-listview">
                        {representative.propertyRequests?.map((item) => (
                          <li key={item._id}>
                            <div className="item">
                              <img
                                src={FileManager.getPropertyMediaPath(
                                  item.propertyId
                                )}
                                alt="image"
                                className="image"
                              />
                              <div className="in">
                                <div className="text-muted-dark">
                                  {item.title}
                                  <br />
                                  <span className="text-muted-dark">
                                    {item.customerName}
                                  </span>
                                  <br />
                                  <span className="text-muted">
                                    {item.location.city} {item.location.state}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <span
                              className="text-muted"
                              style={{ cursor: "pointer" }}
                              onClick={() => OpenPropertyRequest(item._id)}
                            >
                              <IoArrowForwardOutline size={sc.IconSize15} />
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div style={Object.assign({})}>
                      <ul className="listview">
                        {representative.properties?.map((item) => (
                          <li key={item._id}>
                            <div className="item">
                              <div className="in">
                                <div className="text-muted-dark">
                                  {item.title}
                                  <br />
                                  <span className="text-muted">
                                    {item?.location?.city}{" "}
                                    {item?.location?.state}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <span
                              className="text-muted"
                              style={{ cursor: "pointer" }}
                              onClick={() => OpenProperty(item._id ?? "")}
                            >
                              <IoArrowForwardOutline size={sc.IconSize15} />
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </SwipeableViews>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />

      {!dataLoaded && (
        <>
          <br />
          <LoadingTextPlaceHolder />
        </>
      )}
    </>
  );
};
export default RepresentativeDetails;
