import React from "react";
import LoanDetails from "./loandetails";
import { useEffect } from "react";
import { Client } from "../services/apiclient";
import { useState } from "react";
import sc from "../services/stringconst";
import util from "../Utilities/UtilityHelper";
import { json, useLocation } from "react-router-dom";
import auth from "../services/authservice";
import { useNavigate } from "react-router-dom";
import * as moment from "moment";
import ConfirmModal from "../components/childcomponents/confirmmodal";
import { useScrollBy } from "react-use-window-scroll";
import ModelBuilder from "../services/modelbuilder";
import LoadingTextPlaceHolder from "./childcomponents/loadingtextplaceholder";

const Payments = (props) => {
  const navigate = useNavigate();
  const client = new Client(null, null);
  const location = new useLocation();
  const scrollBy = useScrollBy();

  let [dataLoaded, setDataLoaded] = useState(false);

  const [paidPayments, setPaidPayments] = useState([]);
  const [receivedPayments, setReceivedPayments] = useState([]);
  const [displayTab, setDisplayTab] = useState(1);

  const [isLender, setIsLender] = useState(false);

  let loanId = undefined;
  if (location.state) {
    loanId = location.state.loanId;
  }

  let displayTabValue = 1;
  useEffect(() => {
    if (location.state) {
      setIsLender(location.state.isLender);
      if (location.state.isLender) {
        displayTabValue = 2;
      } else {
        displayTabValue = 1;
      }
    } else {
      if (auth.userIsLender()) {
        setIsLender(true);
        displayTabValue = 2;
      }
    }

    init(displayTabValue);
  }, []);

  const init = (displayTabValue) => {
    setDisplayTab(displayTabValue);

    client.paidpayments().then(
      (data) => {
        setDataLoaded(true);
        if (location.state) {
          if (location.state.status) {
            data = data.filter(
              (o) => location.state.status.indexOf(o.status) > -1
            );
          }
        }
        setPaidPayments(data);

        if (location.state) {
          if (!isLender && location.state.loanPagePosition) {
            scrollBy({
              top: parseInt(location.state.loanPagePosition.toString()),
              left: 0,
              behavior: "smooth",
            });
          }
        }
      },
      (error) => {
        setDataLoaded(true);
        props.ShowAlert(error.response, sc.danger);
      }
    );

    client.receivedpayments().then(
      (data) => {
        setDataLoaded(true);
        if (location.state) {
          if (location.state.status) {
            data = data.filter(
              (o) => location.state.status.indexOf(o.status) > -1
            );
          }
        }
        setReceivedPayments(data);

        if (location.state) {
          if (location.state.loanPagePosition) {
            scrollBy({
              top: parseInt(location.state.loanPagePosition.toString()),
              left: 0,
              behavior: "smooth",
            });
          }
        }
      },
      (error) => {
        props.ShowAlert(error.response, sc.danger);
      }
    );
  };

  const setDisplay = () => {
    let newIsLenderValue = !isLender;

    setDisplayTab(newIsLenderValue ? 2 : 1);
    setIsLender(newIsLenderValue);
  };

  const openPaymentDetails = (payment) => {
    navigate(sc.paymentdetailsroute, {
      state: {
        loanData: payment.loanData,
        paymentDetails: payment,
        scheduleItemAmount: payment.scheduleItemAmount,
        repaymentScheduleDueDate: payment.repaymentScheduleDueDate,
      },
    });
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div>Payments</div>
      </div>

      <div id="appCapsule" className="extra-header-active full-height">
        <div className="section mt-2">
          <div className="card">
            <div className="card-body pt-0" style={{ background: "#f9f9f9" }}>
              <ul className="nav nav-tabs lined" role="tablist">
                <li className="nav-item">
                  <a
                    className={
                      displayTab === 1 ? "nav-link active" : "nav-link"
                    }
                    onClick={setDisplay}
                  >
                    Paid
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      displayTab === 2 ? "nav-link active" : "nav-link"
                    }
                    onClick={setDisplay}
                  >
                    Received
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {(displayTab === 1 ? paidPayments : receivedPayments).map((value) => (
            <div>
              <ul
                className="listview image-listview"
                style={{ marginTop: "0.5em" }}
              >
                <li>
                  <a className="item">
                    <div className="in">
                      <div>
                        <a
                          data-bs-toggle="modal"
                          data-bs-target={"#detailModal" + value._id}
                          className="text-primary"
                        >
                          <b>
                            <ion-icon name="document-outline"></ion-icon>{" "}
                            {value.loanTitle}
                          </b>
                        </a>
                        <header style={{ margin: "0.5em 0em" }}>
                          <span className="text-default me-1 mb-1">
                            <ion-icon name="person-outline"></ion-icon>
                            {displayTab === 1 ? (
                              <span
                                onClick={() =>
                                  navigate(sc.profileviewroute, {
                                    state: value.lender,
                                  })
                                }
                              >
                                {value.lenderFirstname} {value.lenderLastname}
                              </span>
                            ) : (
                              <span
                                onClick={() =>
                                  navigate(sc.profileviewroute, {
                                    state: value.borrower,
                                  })
                                }
                              >
                                {value.borrowerFirstname}{" "}
                                {value.borrowerLastname}
                              </span>
                            )}
                          </span>
                          <br />
                          <footer>
                            Due Date{" "}
                            {moment(value.repaymentScheduleDueDate).format(
                              "DD.MMM.YY"
                            )}
                          </footer>
                        </header>
                      </div>
                      <span
                        onClick={() => openPaymentDetails(value)}
                        style={{ textAlign: "right", width: "50%" }}
                      >
                        <span
                          className={util.getcssClassByStatus(value.status)}
                          style={{ width: "100%" }}
                        >
                          {util.commaCurrencyFormatter(value.amount)}&nbsp;{" "}
                          <ion-icon name="open-outline"></ion-icon>
                        </span>
                        <footer>
                          <ion-icon name="calendar-outline"></ion-icon>{" "}
                          {value.dateLabel}{" "}
                          {moment(value.sortValue).format("DD.MMM.YY")}
                        </footer>
                      </span>
                    </div>
                  </a>
                </li>
              </ul>

              <div
                className="modal fade modalbox"
                id={"detailModal" + value._id}
                tabIndex="-1"
                role="dialog"
              >
                <div className="modal-dialog" role="document">
                  <div
                    className="modal-content"
                    style={{
                      width: auth.appWidth(),
                      marginLeft: auth.appMargin(),
                    }}
                  >
                    <div
                      className="modal-header"
                      style={{
                        width: auth.appWidth(),
                        marginLeft: auth.appMargin(),
                      }}
                    >
                      <h5 className="modal-title">Details</h5>
                      <a href="#" data-bs-dismiss="modal">
                        Close
                      </a>
                    </div>
                    <div style={{ overflow: "scroll" }}>
                      <LoanDetails
                        loanData={
                          ModelBuilder.buildLoanDetailsData(value.loanData)
                            .loanData
                        }
                        loanPackage={
                          ModelBuilder.buildLoanDetailsData(value.loanData)
                            .loanPackage
                        }
                        isLender={isLender}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <br />

          {!dataLoaded && (
            <>
              <br />
              <LoadingTextPlaceHolder />
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Payments;
