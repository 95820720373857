import React, { useState } from "react";

type Props = {
  label: string;
  value?: string;
};
const ValueLabel = ({ label, value }: Props) => {
  return (
    <div className="form-group basic">
      <div className="input-wrapper">
        <label className="label text-muted" htmlFor="input">
          {label}
        </label>
        <label className="text-muted-medium-dark" htmlFor="input">
          {value}
        </label>
      </div>
    </div>
  );
};
export default ValueLabel;
