import React from "react";

type Props = {
  url: string;
};
const ImageModal = ({ url }: Props) => {
  return (
    <>
      {/* <!-- Dialog Image --> */}
      <div
        className="modal fade dialogbox"
        id="DialogImage"
        data-bs-backdrop="static"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <img src={url} alt="image" className="img-fluid" />
            <div className="modal-footer">
              <div className="btn-inline">
                <a
                  href="#"
                  className="btn btn-text-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- * Dialog Image --> */}
    </>
  );
};
export default ImageModal;
