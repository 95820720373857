import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import sc from "../../../../services/stringconst";
import auth from "../../../../services/authservice";
import CustomButton from "../../../childcomponents/custombutton";
import realtorClient from "../../../../httpClients/clients/realtorClient";
import { IoCameraOutline, IoChevronBackOutline } from "react-icons/io5";
import UserViewDto from "../../../../models/User/UserViewDto";
import FileManager from "../../../../services/FileManager";
import BaseResponseDto from "../../../../models/Shared/baseResponseDto";
import ErrorDto from "../../../../models/AppFeature/ErrorDto";
import UtilityHelper from "../../../../Utilities/UtilityHelper";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const AddRepresentative = ({ ShowAlert }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  if (!location.state) {
    navigate(sc.realtorsroute);
  }
  let realtorId = location.state.realtorId;
  let userTemp: UserViewDto = {};
  const [user, setUser] = useState(userTemp);
  const [httpProcessing, setHttpProcessing] = useState(false);

  const handleIsDefaultChange = (e: any) => {
    setIsDefault(!isDefault);
  };
  const handleIsAdminChange = (e: any) => {
    setIsAdmin(!isAdmin);
  };

  const [isDefault, setIsDefault] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const [image, setImage] = useState();

  const back = () => {
    navigate(sc.realtordetailroute, {
      state: { realtorId: realtorId, tabSelection: 3 },
    });
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const [url, setUrl] = useState(auth.getUserProfileImageUrl());

  useEffect(() => {}, []);

  const AddUser = async () => {
    try {
      if (!image) {
        ShowAlert(sc.photorequired, sc.warning);
        return;
      }

      if (!user.firstname) {
        ShowAlert(sc.firstnamerequired, sc.warning);
        return;
      }

      if (!user.lastname) {
        ShowAlert(sc.lastnamerequired, sc.warning);
        return;
      }

      if (!user.email) {
        ShowAlert(sc.emailrequired, sc.warning);
        return;
      }

      if (!user.mobileNumber) {
        ShowAlert(sc.mobilenumberrequired, sc.warning);
        return;
      }

      setHttpProcessing(true);

      const result = await realtorClient
        .AddRealtorContactPerson(realtorId, user, isDefault, isAdmin)
        .then(
          (data: BaseResponseDto) => {
            setHttpProcessing(false);

            if (data) {
              if (image) {
                let resp = FileManager.uploadMedia(
                  image,
                  `${data.key}.jpg`,
                  sc.UserPhoto
                );
                navigate(sc.realtordetailroute, {
                  state: { realtorId: realtorId, tabSelection: 3 },
                });
              }
            }
          },
          (error: ErrorDto) => {
            setHttpProcessing(false);
            ShowAlert(error.message, sc.warning);
          }
        );
    } catch (e: any) {
      setHttpProcessing(false);
      ShowAlert(e.message, sc.warning);
    }
  };

  const selectImage = async (e: any) => {
    if (e.target) {
      if (e.target.files.length > 0) {
        if (e.target.files[0].type.indexOf("image") === -1) {
          ShowAlert(sc.invalidImageFileSelected, sc.danger);
          return;
        }

        if (e.target.files[0].size > 5000000) {
          // in bytes equvalent to 5mb
          ShowAlert(sc.fileTooLargeError, sc.danger);
          return;
        }
        let b64 = await UtilityHelper.toBase64(e.target.files[0]);

        setUrl(b64);
        setImage(e.target.files[0]);
      }
    }
  };

  const onClickBtn = async () => {
    $("#myInput").click();
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <IoChevronBackOutline size={sc.IconSize15} /> Property
          </a>
        </div>
        <div className="text-muted-medium">Representative</div>
      </div>

      <div
        id="appCapsule"
        className="extra-header-active full-height"
        style={{ background: "white" }}
      >
        <div>
          <input type="file" hidden onChange={selectImage} id="myInput"></input>
          <div className="section mt-3 text-center">
            <div className="avatar-section">
              <a>
                <img
                  src={url}
                  alt="avatar"
                  className="imaged rounded"
                  style={{ width: "8em", height: "8em" }}
                  data-bs-toggle="modal"
                  data-bs-target="#DialogImage"
                />

                <>
                  <span className="button">
                    <IoCameraOutline
                      name="camera-outline"
                      onClick={onClickBtn}
                    />
                  </span>
                </>
              </a>
            </div>
          </div>
          <div className="card-body pb-1">
            <div>
              <div>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="firstname">
                      Firstname
                    </label>
                    <input
                      type="text"
                      value={user.firstname}
                      onChange={handleChange}
                      className="form-control"
                      id="firstname"
                    />
                  </div>
                </div>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="lastname">
                      Lastname
                    </label>
                    <input
                      type="text"
                      value={user.lastname}
                      onChange={handleChange}
                      className="form-control"
                      id="lastname"
                    />
                  </div>
                </div>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="gender">
                      {user.gender ? "Select Gender" : <span>&nbsp;</span>}
                    </label>

                    <select
                      required
                      onChange={handleChange}
                      value={user.gender}
                      className="form-control custom-select"
                      id="gender"
                    >
                      <option key="" value="" selected disabled hidden>
                        Select Gender
                      </option>
                      <option key={sc.F} value={sc.F}>
                        {sc.Female}
                      </option>
                      <option key={sc.M} value={sc.M}>
                        {sc.Male}
                      </option>
                    </select>
                  </div>
                </div>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="email1">
                      E-mail
                    </label>
                    <input
                      value={user.email}
                      onChange={handleChange}
                      type="email"
                      className="form-control"
                      id="email"
                    />
                  </div>
                </div>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="mobilenumber">
                      Mobile Number
                    </label>
                    <input
                      value={user.mobileNumber}
                      onChange={handleChange}
                      type="number"
                      className="form-control"
                      id="mobileNumber"
                    />
                  </div>
                </div>
                <div className="custom-control custom-checkbox mt-2 mb-1">
                  <div className="form-check">
                    <input
                      checked={isAdmin}
                      onChange={handleIsAdminChange}
                      id="isAdmin"
                      type="checkbox"
                      className="form-check-input"
                    />
                    <label
                      className="form-check-label text-muted"
                      htmlFor="isAdmin"
                    >
                      Is Admin Representative
                    </label>
                  </div>
                </div>

                <div className="custom-control custom-checkbox mt-2 mb-1">
                  <div className="form-check">
                    <input
                      checked={isDefault}
                      onChange={handleIsDefaultChange}
                      id="isDefault"
                      type="checkbox"
                      className="form-check-input"
                    />
                    <label
                      className="form-check-label text-muted"
                      htmlFor="isDefault"
                    >
                      Is Default Representative
                    </label>
                  </div>
                </div>
                <br />

                <div>
                  <CustomButton
                    httpProcessing={httpProcessing}
                    text="Add"
                    onClick={AddUser}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
    </>
  );
};
export default AddRepresentative;
