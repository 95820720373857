import React, { useState, useEffect } from "react";
import sc from "../../../services/stringconst";
import { useNavigate } from "react-router-dom";
import { Share } from "@capacitor/share";
import { Capacitor } from "@capacitor/core";
import auth from "../../../services/authservice";
import { requestFirebaseForToken, onMessageListener } from "../../../firebase";
import Cookies from "js-cookie";
import {
  IoClose,
  IoEllipsisVerticalOutline,
  IoHelpOutline,
  IoInformationOutline,
  IoLogOutOutline,
  IoMailOutline,
  IoNotificationsOutline,
  IoOptionsOutline,
  IoPersonOutline,
  IoSettingsOutline,
  IoShareSocialOutline,
} from "react-icons/io5";

type Props = {
  loginStatus: boolean;
  messageCount: number;
  notificationCount: number;
};

const Header = ({ loginStatus, messageCount, notificationCount }: Props) => {
  var navigate = useNavigate();

  const [notification, setNotification] = useState({ title: "", body: "" });

  useEffect(() => {
    if (notification?.title) {
      notify();
    }

    let currentUser = auth.getCurrentUser();
    if (currentUser === null || currentUser === undefined) {
      navigate(sc.welcomeroute);
      return;
    }
  }, [notification]);

  const [openNotification, setOpenNotification] = useState(false);
  const closeNotification = () => {
    setOpenNotification(false);
  };

  requestFirebaseForToken();

  onMessageListener()
    .then((payload: any) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err: any) => console.log("failed: ", err));

  const notify = () => {
    SetNotificationTitle(notification?.title);
    SetNotificationDescription(notification?.body);
    setOpenNotification(true);
  };

  const [NotificationTitle, SetNotificationTitle] = useState("");
  const [NotificationDescription, SetNotificationDescription] = useState("");

  const gotoNotification = () => {
    navigate(sc.notificationroute);
  };
  const gotoMessage = () => {
    navigate(sc.userchatroute);
  };
  const logout = () => {
    auth.logout();
    Cookies.remove(sc.session);
  };

  const shareApp = async () => {
    await Share.share({
      title: sc.shareMessage,
      text: sc.shareMessage,
      url: getStoreUrl(),
      dialogTitle: sc.shareMessage,
    });
  };

  const getStoreUrl = () => {
    return sc.WebUrl;
  };

  return (
    <div>
      {loginStatus && (
        <div
          style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
          className="appHeader bg-primary-main text-muted "
        >
          <div
            className="pageTitle left"
            onClick={() => navigate(sc.profileroute)}
          >
            <img
              src={
                auth.getUserProfileImageUrl()
                  ? auth.getUserProfileImageUrl()
                  : auth.getDefaultImageUrl()
              }
              alt="logo"
              className="logo imaged rounded"
            />
            &nbsp;&nbsp;
            <span className="text-muted">{auth.getUserProfileFirstname()}</span>
          </div>
          <div className="right">
            <a onClick={gotoMessage} className="headerButton">
              <IoMailOutline size={sc.IconSize25} />
              {messageCount > 0 && (
                <span className="badge badge-header-notification">
                  <span>{messageCount}</span>
                </span>
              )}
            </a>
            <a onClick={gotoNotification} className="headerButton">
              <IoNotificationsOutline size={sc.IconSize25} />
              {notificationCount > 0 && (
                <span className="badge badge-header-notification">
                  <span>{notificationCount}</span>
                </span>
              )}
            </a>
            <a
              data-bs-toggle="modal"
              data-bs-target="#actionSheetIconed"
              className="headerButton item"
            >
              {/* <a id="profileIcon" onClick={showActionSheet}> */}
              <IoEllipsisVerticalOutline size={sc.IconSize25} />
            </a>
          </div>
        </div>
      )}

      {/*<!-- * App Header --> */}

      {/* <!-- Iconed Action Sheet --> */}
      <div
        className="modal fade action-sheet"
        id="actionSheetIconed"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div
            className="modal-content"
            style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
          >
            <div className="modal-body" style={{ padding: "20px" }}>
              <div className="row mt-2" data-bs-dismiss="modal">
                <div
                  className="col-4"
                  data-bs-dismiss="modal"
                  onClick={() => navigate(sc.settingsroute)}
                >
                  <div className="bill-box-no-border  text-muted">
                    <span style={{ fontSize: "1.5em" }}>
                      <IoOptionsOutline />
                    </span>
                    <div style={{ fontSize: "0.8em" }}>Settings</div>
                  </div>
                </div>

                <div
                  className="col-4"
                  data-bs-dismiss="modal"
                  onClick={() => navigate(sc.profileroute)}
                >
                  <div className="bill-box-no-border  text-muted">
                    <span style={{ fontSize: "1.5em" }}>
                      <IoPersonOutline />
                    </span>
                    <div style={{ fontSize: "0.8em" }}>Profile</div>
                  </div>
                </div>

                <a
                  href={sc.welcomeroute}
                  className="item col-4 text-muted"
                  onClick={logout}
                >
                  <div className="bill-box-no-border">
                    <span style={{ fontSize: "1.5em" }}>
                      <IoLogOutOutline />
                    </span>
                    <div style={{ fontSize: "0.8em" }}>Log out</div>
                  </div>
                </a>
              </div>

              <div className="row mt-2">
                <div
                  className="col-4"
                  data-bs-dismiss="modal"
                  onClick={() => navigate(sc.faqroute)}
                >
                  <div className="bill-box-no-border  text-muted">
                    <span style={{ fontSize: "1.5em" }}>
                      <IoHelpOutline />
                    </span>
                    <div style={{ fontSize: "0.8em" }}>FAQ</div>
                  </div>
                </div>
                <div
                  className="col-4"
                  data-bs-dismiss="modal"
                  onClick={() => navigate(sc.aboutroute)}
                >
                  <div className="bill-box-no-border  text-muted">
                    <span style={{ fontSize: "1.5em" }}>
                      <IoInformationOutline />
                    </span>
                    <div style={{ fontSize: "0.8em" }}>About</div>
                  </div>
                </div>
                <div className="col-4" onClick={shareApp}>
                  <div className="bill-box-no-border  text-muted">
                    <span style={{ fontSize: "1.5em" }}>
                      <IoShareSocialOutline />
                    </span>
                    <div style={{ fontSize: "0.8em" }}>Share</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- * Iconed Action Sheet --> */}

      {/*<!-- Modal for notification --> */}

      {openNotification && (
        <div className="pwa-add-button-container-ios-parent">
          <div
            className="modal"
            style={{
              background: "#FFF",
              padding: "1em",
              margin: "1em",
              width: "92.7%",
              borderRadius: "5px",
              maxHeight: "28%",
              display: "block",
            }}
          >
            <div className="modal-header">
              <h4 className="modal-title">{NotificationTitle}</h4>
              <a
                className="close-pwa-add-button-container"
                onClick={closeNotification}
              >
                <IoClose />
              </a>
            </div>
            <div className="modal-body">{NotificationDescription}</div>
          </div>
        </div>
      )}

      {/* <!-- * Modal for notification --> */}
    </div>
  );
};

export default Header;
