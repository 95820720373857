import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import auth from "../../../services/authservice";
import Alert from "../../../services/alert";
import sc from "../../../services/stringconst";
import { NativeBiometric, BiometryType } from "capacitor-native-biometric";
import CustomButton from "../../childcomponents/custombutton";
import Keypad from "../../childcomponents/keypad";
import { Device } from "@capacitor/device";
import ErrorDto from "../../../models/AppFeature/ErrorDto";
import SignInDataDto from "../../../models/User/Request/SignInDataDto";
import { IoCloseCircle, IoHomeOutline } from "react-icons/io5";
import UserClient from "../../../httpClients/clients/userClient";
import SigninResponseDto from "../../../models/User/Response/signinResponseDto";
import { useDispatch } from "react-redux";
import { setUserData } from "./../../../store/slices/userSlice";
import styles from "./login.styles";
import Cookies from "js-cookie";
import UserDto from "../../../models/User/UserDto";
import UtilityHelper from "../../../Utilities/UtilityHelper";

type Props = {
  setUser(isUser: UserDto): any;
  ShowAlert(message: string, title: string): any;
};

const Login = ({ setUser, ShowAlert }: Props) => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const register = () => {
    navigate(sc.registerroute);
  };

  const forgotpassword = () => {
    navigate(sc.forgotpasswordroute);
  };

  const [httpProcessing, setHttpProcessing] = useState(false);
  const [usePin, setUsePin] = useState(auth.getUserHasLoggedInOnThisDevice());

  let deviceInformation = "";

  const login = async () => {
    if (!logindata.email) {
      messageModal(sc.usernamerequired, sc.warning);
      return;
    }
    if (!logindata.password) {
      messageModal(sc.passwordrequired, sc.warning);
      return;
    }

    apilogin({ username: logindata.email, password: logindata.password });
  };

  const apilogin = (signinData: SignInDataDto) => {
    setHttpProcessing(true);
    signinData.pushNotificationUserToken = auth.getPushNotificationUserToken();
    signinData.deviceId = auth.getDeviceId();
    signinData.deviceInfo = auth.getDeviceInfo();

    UserClient.signIn(signinData).then(
      (data: SigninResponseDto) => {
        if (data?.user?.status === sc.Pending) {
          navigate(sc.verifyregistrationroute, { state: data.user.email });
        }

        if (signinData.deviceId) {
          auth.setDeviceIsRegistered();
        }

        dispatch(
          setUserData({
            userId: data.session.id,
            firstname: data.user.firstname,
            lastname: data.user.lastname,
            username: data.user.username,
            userRole: data.user.userRole,
            realtorId: data.user.realtorId,
            isAdmin: data.user.userRole == sc.Admin,
          })
        );
        Cookies.remove(sc.session);
        Cookies.set(
          sc.session,
          JSON.stringify({
            userId: data.session.id,
            timeout: Date.now() + data.session.timeout,
            token: data.accessToken,
            refreshToken: data.session.key,
          })
        );
        setUser(data.user);
        auth.setCurrentUser(data);

        if (signinData.username) {
          // Delete user's credentials
          NativeBiometric.deleteCredentials({
            server: "www.myplace.ng",
          }).then();
          // Save user's credentials
          NativeBiometric.setCredentials({
            username: signinData.username,
            password: signinData.password ?? "",
            server: "www.myplace.ng",
          }).then();
        }

        setHttpProcessing(false);

        auth.setUserHasLoggedInOnThisDevice();

        navigate(UtilityHelper.getHomeRoute(data.user.userRole));
      },
      (error: ErrorDto) => {
        setHttpProcessing(false);
        let msg = error.message;
        if (signinData.pin) {
          msg =
            msg +
            " - " +
            "Do not have PIN? Login with username and set PIN in settings";
        }
        messageModal(msg, sc.warning);
      }
    );
  };

  const getDeviceInfo = async () => {
    await Device.getInfo().then((deviceInfo) => {
      deviceInformation = `${deviceInfo.platform}|${deviceInfo.manufacturer}|${deviceInfo.osVersion}|${deviceInfo.model}`;
      auth.setDeviceInfo(deviceInformation);
    });
  };

  useEffect(() => {
    // await Device.getId().then(data => {

    //   deviceId =data.uuid;
    //   alert(deviceId);
    //   auth.setDeviceId(deviceId);
    // });

    if (!auth.getDeviceId()) {
      auth.setDeviceId(sc.getUUId());
    }

    let currentUser = auth.getCurrentUser();
    if (currentUser !== null && currentUser !== undefined) {
      navigate(UtilityHelper.getHomeRoute(currentUser.userRole));
    }
    getDeviceInfo();
    //startFingerPrint();
  }, []);

  const [alertMessage, setAlertMessage] = useState({
    show: false,
    message: "",
    type: "",
  });
  const messageModal = (message: string, type: string) => {
    setAlertMessage({ show: true, message: message, type: type });
    setTimeout(() => {
      setAlertMessage({ show: false, message: "", type: "" });
    }, sc.toastShow3000);
  };

  const [logindata, setLogindata] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setLogindata((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const gotoWelcome = () => {
    navigate(sc.welcomeroute);
  };

  const pinSignIn = async (pin: string) => {
    if (!auth.getDeviceIsRegistered()) {
      ShowAlert("login with username & password to register device", sc.danger);
      return;
    }
    if (pin.length === 6) {
      apilogin({ pin: pin });
    }
  };

  return (
    <div className="col-md-12">
      {/* <!-- App Header --> */}
      {/* <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={gotoWelcome} className="headerButton goBack">
            <IoHomeOutline size={sc.IconSize25} />
          </a>
        </div>
        <div >Signin</div>
        <div className="right"></div>
      </div> */}
      {/* <!-- * App Header --> */}

      {/* <!-- App Capsule --> */}
      <div
        id="appCapsule"
        className="extra-header-active full-height"
        style={styles.loginOption}
      >
        <div className="section mb-5 p-2">
          {usePin && (
            <>
              <div
                style={{ padding: "2%", textAlign: "center" }}
                className="text-muted"
              >
                Don't have PIN? login with username, goto settings, tap{" "}
                <b>Set Login PIN</b>
              </div>
              <div style={{ marginTop: "5%" }}>
                <Keypad submitPin={pinSignIn} />
              </div>
            </>
          )}

          {!usePin && (
            <>
              <br />
              <div className="text-center">
                <img
                  src="assets/img/logo_transparent.png"
                  className="img-fluid"
                  style={{ maxWidth: auth.appLoginLogoWidth() }}
                />
              </div>
              <br />
              <div style={{ marginTop: "5%" }}>
                <div className="card-body pb-1">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="email">
                        Username | Email | Number
                      </label>
                      <input
                        required
                        type="email"
                        value={logindata.email}
                        onChange={handleChange}
                        className="form-control"
                        id="email"
                        autoComplete="off"
                        placeholder=""
                      />
                      <i className="clear-input">
                        <IoCloseCircle size={sc.IconSize25} />
                      </i>
                    </div>
                  </div>

                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="password">
                        Password
                      </label>
                      <input
                        required
                        value={logindata.password}
                        onChange={handleChange}
                        type="password"
                        className="form-control"
                        id="password"
                        autoComplete="off"
                        placeholder=""
                      />
                      <i className="clear-input">
                        <IoCloseCircle size={sc.IconSize25} />
                      </i>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="form-group  transparent">
                    <CustomButton
                      bgColor="primary"
                      httpProcessing={httpProcessing}
                      text="Sign in"
                      onClick={login}
                    />
                  </div>
                </div>
                <br />
              </div>
            </>
          )}
          <br />
          <br />
          <div className="form-links mt-1">
            <div>
              {/* <a onClick={register} className="text-muted">Register</a> */}
            </div>
            {usePin && (
              <a
                style={styles.loginOption}
                className="text-muted"
                onClick={() => setUsePin(!usePin)}
              >
                Signin with <b>Username</b>
              </a>
            )}
            {!usePin && (
              <a
                style={styles.loginOption}
                className="text-muted"
                onClick={() => setUsePin(!usePin)}
              >
                Signin with <b>PIN</b>
              </a>
            )}
            <div>
              {/* <a onClick={forgotpassword} className="text-muted">
                Forgot Password
              </a> */}
            </div>
          </div>

          <div className="appBottomMenu_login">
            <div>
              <a onClick={register} className="text-muted">
                Register
              </a>
            </div>
            <div className="text-muted-medium">&nbsp; | &nbsp;</div>
            <div>
              <a onClick={forgotpassword} className="text-muted">
                Forgot Password
              </a>
            </div>
          </div>
        </div>
        {
          <div style={styles.fingerScan}>
            {/* {bioIsAvailable && <><a style={styles.bioLogin} className="text-primary" onClick={startFingerPrint} >
            <IoFingerPrintOutline size={sc.headerIconSize}/>
          </a>  &nbsp;
          </>} */}
          </div>
        }
      </div>
      {/* <!-- * App Capsule --> */}

      {alertMessage.show && (
        <Alert message={alertMessage.message} alertType={alertMessage.type} />
      )}
    </div>
  );
};
export default Login;
