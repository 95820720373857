import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import auth from "../../../services/authservice";
import RealtorDto from "../../../models/Realtor/RealtorDto";
import {
  IoAddOutline,
  IoArrowForwardCircleOutline,
  IoArrowForwardOutline,
  IoBusinessOutline,
} from "react-icons/io5";
import realtorClient from "../../../httpClients/clients/realtorClient";
import ErrorDto from "../../../models/AppFeature/ErrorDto";
import LoadingTextPlaceHolder from "../../shared/Preload/loadingtextplaceholder";

type Props = {
  ShowAlert(message: string, title: string): any;
};

const RealtorList = ({ ShowAlert }: Props) => {
  const location = useLocation();

  let result: RealtorDto[] = [];
  const [data, setData] = useState(result);
  const [dataLoaded, setDataLoaded] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    getRealtors();
  }, []);

  const openRealtor = (realtorId: string) => {
    navigate(sc.realtordetailroute, {
      state: { realtorId: realtorId, tabSelection: 1 },
    });
  };

  const getRealtors = () => {
    realtorClient.GetRealtors().then(
      (result: RealtorDto[]) => {
        setDataLoaded(true);
        setData(result);
      },
      (error: ErrorDto) => {
        setDataLoaded(true);
        ShowAlert(error.message, sc.warning);
      }
    );
  };
  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="text-muted-medium">Realtors</div>
        <div className="right">
          <a
            onClick={() => navigate(sc.createrealtorroute)}
            className="headerButton"
          >
            <IoAddOutline size={sc.IconSize25} />
          </a>
        </div>
      </div>

      <div id="appCapsule" className="extra-header-active full-height">
        <div>
          <ul className="listview">
            {data.map((value) => (
              <li key={value._id}>
                <div className="item">
                  <div className="in">
                    <div>
                      {value.name}
                      <br />
                      <span className="text-muted">
                        {value.location?.city} {value.location?.state}
                      </span>
                    </div>
                  </div>
                </div>
                <span
                  className="text-muted"
                  style={{ cursor: "pointer" }}
                  onClick={() => openRealtor(value._id ?? "")}
                >
                  <IoArrowForwardOutline size={sc.IconSize15} />
                </span>
              </li>
            ))}
          </ul>
        </div>

        <br />

        {!dataLoaded && (
          <>
            <br />
            <LoadingTextPlaceHolder />
          </>
        )}
      </div>
    </>
  );
};
export default RealtorList;
