import React, { MouseEventHandler } from "react";
import sc from "../../services/stringconst";

type Props = {
  bgColor?: string;
  inline?: boolean;
  httpProcessing: boolean;
  text: string;
  outline?: boolean;
  onClick: MouseEventHandler;
};

const CustomButton = ({
  bgColor,
  inline,
  httpProcessing,
  text,
  onClick,
  outline,
}: Props) => {
  bgColor = bgColor ?? "primary";
  let block = "btn-block";

  if (inline) {
    block = "";
  }
  return (
    <>
      {!httpProcessing && (
        <button
          onClick={onClick}
          className={`btn btn-${outline ? "outline-" : ""}${bgColor} ${block}`}
          style={{
            borderWidth: `${outline ? "0.8px" : "0px"}`,
            fontWeight: "lighter",
          }}
        >
          {text}
        </button>
      )}
      {httpProcessing && (
        <button
          disabled
          className={`btn btn-${outline ? "outline-" : ""}${bgColor} ${block}`}
        >
          {sc.loaderViewCountThree.map((o) => (
            <>
              <div
                style={{ color: "white" }}
                className="spinner-grow spinner-grow-sm"
                role="status"
              >
                <span className="sr-only"></span>
              </div>{" "}
              &nbsp;
            </>
          ))}
        </button>
      )}
    </>
  );
};

export default CustomButton;
