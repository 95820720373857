import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import auth from "../../../services/authservice";
import sc from "../../../services/stringconst";
import Keypad from "../../childcomponents/keypad";
import UserClient from "../../../httpClients/clients/userClient";
import { IoChevronBackOutline } from "react-icons/io5";
import ErrorDto from "../../../models/AppFeature/ErrorDto";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const SetLoginPIN = ({ ShowAlert }: Props) => {
  const navigate = useNavigate();

  const setMobilePIN = (pin: string) => {
    if (pin.length < 6) {
      ShowAlert("PIN must be 6 characters", sc.secondary);
      return;
    }

    UserClient.setMobilePin(parseInt(pin)).then(
      (data) => {
        ShowAlert("Login PIN updated", sc.success);
        navigate(sc.settingsroute);
      },
      (error: ErrorDto) => {
        ShowAlert(error.message, sc.secondary);
      }
    );
  };
  const back = () => {
    navigate(-1);
  };
  useEffect(() => {}, []);

  return (
    <div className="col-md-12">
      {/* <!-- App Header --> */}
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <IoChevronBackOutline size={sc.IconSize15} /> Settings
          </a>
        </div>
        <div className="text-muted-medium">Set Login PIN</div>
        <div className="right"></div>
      </div>
      {/* <!-- * App Header --> */}

      {/* <!-- App Capsule --> */}
      <div id="appCapsule" className="extra-header-active full-height">
        <div className="section mb-5 p-2">
          <br />

          <Keypad submitPin={setMobilePIN} />
        </div>
      </div>
    </div>
  );
};
export default SetLoginPIN;
