import React, { useEffect, useState } from "react";
import sc from "../../../services/stringconst";
import { useNavigate } from "react-router-dom";
import auth from "../../../services/authservice";
import {
  IoAddOutline,
  IoChevronBackOutline,
  IoCloseOutline,
} from "react-icons/io5";
import BankAccountDto from "../../../models/Shared/BankAccountDto";
import realtorClient from "../../../httpClients/clients/realtorClient";
import ConfirmModal from "../../childcomponents/confirmmodal";
import LoadingTextPlaceHolder from "../../shared/Preload/loadingtextplaceholder";
import { useSelector } from "react-redux";
import { getUser } from "../../../store/store";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const BankAccountList = ({ ShowAlert }: Props) => {
  const navigate = useNavigate();
  const [httpProcessing, setHttpProcessing] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const user = useSelector(getUser);

  let accountsTemp: BankAccountDto[] = [];
  const [accounts, setAccounts] = useState(accountsTemp);
  const [showDeleteAccountConfirmModal, setShowDeleteAccountConfirmModal] =
    useState(false);

  const [activeBankAccountNumber, setActiveBankAccountNumber] = useState("");

  const cancelDeleteBankAccountModal = () => {
    setShowDeleteAccountConfirmModal(false);
    setActiveBankAccountNumber("");
  };
  const deleteBankAccount = async () => {
    setShowDeleteAccountConfirmModal(false);
    await realtorClient.RemoveRealtorBankAccount(
      user.realtorId ?? "",
      activeBankAccountNumber
    );
    setActiveBankAccountNumber("");
    GetRealtor();
    ShowAlert("Account Removed", sc.secondary);
  };
  useEffect(() => {
    GetRealtor();
  }, []);

  const GetRealtor = () => {
    realtorClient.GetRealtorDetail(user.realtorId ?? "").then(
      (data) => {
        setDataLoaded(true);
        setAccounts(data?.realtor?.bankAccounts ?? []);
      },
      (error) => {
        setDataLoaded(true);
        ShowAlert(error.response, sc.danger);
      }
    );
  };

  const back = () => {
    navigate(sc.settingsroute);
  };

  const openDeleteBankAccountModal = async (accountNumber: string) => {
    if (!accountNumber) {
      ShowAlert("Account Number is invalid", sc.danger);
      return;
    }

    setShowDeleteAccountConfirmModal(true);
    setActiveBankAccountNumber(accountNumber);
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <IoChevronBackOutline size={sc.IconSize15} /> Settings
          </a>
        </div>
        <div className="text-muted-medium">Account</div>
      </div>

      {dataLoaded && (
        <div id="appCapsule" className="extra-header-active full-height">
          <span
            className="btn btn-primary rounded"
            onClick={() =>
              navigate(sc.addbankaccountroute, {
                state: { realtorId: user.realtorId },
              })
            }
            style={{
              padding: 12,
              float: "right",
              position: "fixed",
              right: 10,
              top: "87%",
              zIndex: 1001,
            }}
          >
            <IoAddOutline size={sc.IconSize25} />
          </span>
          <div className="section">
            <ul className="listview">
              {accounts?.map((item) => (
                <li key={item.accountNumber}>
                  <div className="item">
                    <div className="in">
                      <div>
                        {item.accountNumber} {item.bank}
                        <br />
                        <span className="text-muted">{item.accountName}</span>
                      </div>
                    </div>
                  </div>
                  <span
                    className="badge badge-secondary rounded"
                    onClick={() =>
                      openDeleteBankAccountModal(item.accountNumber ?? "")
                    }
                    style={{ padding: 0, float: "right" }}
                  >
                    <IoCloseOutline size={sc.IconSize15} />
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {!dataLoaded && (
        <>
          <br />
          <LoadingTextPlaceHolder />
        </>
      )}
      {showDeleteAccountConfirmModal && (
        <div>
          <div>
            <ConfirmModal
              button1="Remove"
              button2="Cancel"
              action1={deleteBankAccount}
              action2={cancelDeleteBankAccountModal}
              httpProcessing={httpProcessing}
              color={sc.dangerBorder}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default BankAccountList;
