import AdvertDto from "../../models/AppFeature/AdvertDto";
import CityDto from "../../models/AppFeature/CityDto";
import ContactUsDto from "../../models/AppFeature/ContactusDto";
import FAQDto from "../../models/AppFeature/FAQDto";
import ReportCommentDto from "../../models/AppFeature/ReportCommentDto";
import StateDto from "../../models/AppFeature/StateDto";
import BaseResponseDto from "../../models/Shared/baseResponseDto";
import HttpClient from "../httpClient";
import v from "../variables";

let baseRoute = "internal/";

const getFAQ = async (): Promise<FAQDto[]> => {
  let route = v.baseUrl + baseRoute + "getfaq";

  return <FAQDto[]>await HttpClient.getRequest(route);
};
const GetAdvert = async (): Promise<string> => {
  let route = v.baseUrl + baseRoute + "getadvert";

  return <string>await HttpClient.getRequest(route);
};
const GetStates = async (): Promise<StateDto[]> => {
  let route = v.baseUrl + baseRoute + "states";

  return <StateDto[]>await HttpClient.getRequest(route);
};

const GetCities = async (stateId: string): Promise<CityDto[]> => {
  let route = v.baseUrl + baseRoute + "cities/" + stateId;

  return <CityDto[]>await HttpClient.getRequest(route);
};
const GetReportComment = async (): Promise<ReportCommentDto[]> => {
  let route = v.baseUrl + baseRoute + "states";

  return <ReportCommentDto[]>await HttpClient.getRequest(route);
};

const SaveContactUsMessage = async (
  request: ContactUsDto
): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "savecontactusmessage";

  return <BaseResponseDto>await HttpClient.postRequest(route, request);
};
const SaveReportComment = async (id: string): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "savereportcomment";

  return <BaseResponseDto>await HttpClient.postRequest(route, { id: id });
};
const appFeaturesClient = {
  getFAQ,
  GetAdvert,
  GetStates,
  GetCities,
  GetReportComment,
  SaveContactUsMessage,
  SaveReportComment,
};

export default appFeaturesClient;
