import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import auth from "../../../services/authservice";
import CustomButton from "./../../childcomponents/custombutton";
import realtorClient from "../../../httpClients/clients/realtorClient";
import { IoChevronBackOutline } from "react-icons/io5";
import UserViewDto from "../../../models/User/UserViewDto";
import propertyClient from "../../../httpClients/clients/propertyClient";
import PropertyTypeDto from "../../../models/Property/PropertyTypeDto";
import PropertyDto from "../../../models/Property/PropertyDto";
import UtilityHelper from "../../../Utilities/UtilityHelper";
import AddressDto from "../../../models/Shared/AddressDto";
import StateDto from "../../../models/AppFeature/StateDto";
import appFeaturesClient from "../../../httpClients/clients/appFeaturesClient";
import CityDto from "../../../models/AppFeature/CityDto";
import ErrorDto from "../../../models/AppFeature/ErrorDto";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const AddProperty = ({ ShowAlert }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  if (!location.state) {
    navigate(sc.realtorsroute);
  }
  let realtorId = location.state.realtorId;
  let propertyTypesTemp: PropertyTypeDto[] = [];
  const [propertyTypes, setPropertyTypes] = useState(propertyTypesTemp);
  let contactPersonsTemp: UserViewDto[] = [];
  const [contactPersons, setContactPersons] = useState(contactPersonsTemp);
  const [requestOptions, setRequestOptions] = useState(
    propertyClient.GetPropertyRequestOptions()
  );
  const [interestPercentages, setInterestPercentages] = useState(
    UtilityHelper.numberRange(1, 30)
  );
  const [paymentDuration, setPaymentDuration] = useState(
    UtilityHelper.numberRange(0, 24)
  );

  const [stayDuration, setStayDuration] = useState(
    UtilityHelper.numberRange(0, 300)
  );

  const [httpProcessing, setHttpProcessing] = useState(false);

  const back = () => {
    navigate(sc.realtordetailroute, {
      state: { realtorId: realtorId, tabSelection: 2 },
    });
  };

  let statesInit: StateDto[] = [];
  let citiesInit: CityDto[] = [];

  let [states, setStates] = useState(statesInit);
  let [cities, setCities] = useState(citiesInit);

  useEffect(() => {
    appFeaturesClient.GetStates().then((result) => {
      setStates(result);
    });
    getPropertyTypes();
    getContactPersons(realtorId);
  }, []);

  const getCities = (e: any) => {
    handleChangeAddress(e);
    appFeaturesClient.GetCities(e.target.value).then((result) => {
      setCities(result);
      setAddress((prevState) => ({
        ...prevState,
        city: "",
      }));
    });
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setProperty((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const handleChangeAddress = (e: any) => {
    const { id, value } = e.target;
    setAddress((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const getPropertyTypes = () => {
    propertyClient.GetPropertyTypes().then(
      (data) => {
        setPropertyTypes(data);
      },
      (error) => {
        ShowAlert(error.response, sc.danger);
      }
    );
  };

  const getContactPersons = (realorId: string) => {
    realtorClient.GetRealtorContactPersons(realorId).then(
      (data) => {
        setContactPersons(data);
      },
      (error) => {
        ShowAlert(error.response, sc.danger);
      }
    );
  };

  let propertyTemp: PropertyDto = {};
  const [property, setProperty] = useState(propertyTemp);

  let addressTemp: AddressDto = {};
  const [address, setAddress] = useState(addressTemp);

  const addProperty = async () => {
    try {
      if (!property.title) {
        ShowAlert("Title" + sc.isRequired, sc.warning);
        return;
      }
      if (!property.description) {
        ShowAlert("Description" + sc.isRequired, sc.warning);
        return;
      }
      if (!property.propertyType || property.propertyType == "-1") {
        ShowAlert("Description" + sc.isRequired, sc.warning);
        return;
      }
      if (!property.requestOption || property.requestOption == "-1") {
        ShowAlert("Request Option" + sc.isRequired, sc.warning);
        return;
      }
      if (!property.realtorPrice) {
        ShowAlert("Realtor Price" + sc.isRequired, sc.warning);
        return;
      }
      if (!property.maxInterestPecentage) {
        ShowAlert("Max Interest % " + sc.isRequired, sc.warning);
        return;
      }
      if (!property.maxPaymentDuration) {
        ShowAlert("Max Payment Duration" + sc.isRequired, sc.warning);
        return;
      }
      if (!property.minEquityAmount) {
        ShowAlert("Min Equity Amount" + sc.isRequired, sc.warning);
        return;
      }
      if (!property.Quantity) {
        ShowAlert("Unit " + sc.isRequired, sc.warning);
        return;
      }
      if (!property.availableCount) {
        ShowAlert("Available Unit" + sc.isRequired, sc.warning);
        return;
      }
      if (!property.projectStartDate) {
        ShowAlert("Project start date" + sc.isRequired, sc.warning);
        return;
      }
      if (!property.estimatedProjectEndDate) {
        ShowAlert("Estimated end date" + sc.isRequired, sc.warning);
        return;
      }
      if (!property.contactPersonId || property.contactPersonId == "-1") {
        ShowAlert("Representative" + sc.isRequired, sc.warning);
        return;
      }

      property.location = address;
      if (!property.location.address) {
        ShowAlert("Address" + sc.isRequired, sc.warning);
        return;
      }
      if (!property.location.city || property.location.city == "-1") {
        ShowAlert("City" + sc.isRequired, sc.warning);
        return;
      }
      if (!property.location.state || property.location.state == "-1") {
        ShowAlert("State" + sc.isRequired, sc.warning);
        return;
      }

      if (
        (property.requestOption == sc.Rent ||
          property.requestOption == sc.Lease) &&
        (property.stayDuration ?? 0) < 1
      ) {
        ShowAlert("Stay duration" + sc.isRequired, sc.warning);
        return;
      }

      property.realtorId = realtorId;

      let projectStartDateTemp = property.projectStartDate;
      let estimatedProjectEndDateTemp = property.estimatedProjectEndDate;

      property.projectStartDate = UtilityHelper.dateToUnixTimeStamp(
        new Date(property.projectStartDate.toString())
      );
      property.estimatedProjectEndDate = UtilityHelper.dateToUnixTimeStamp(
        new Date(property.estimatedProjectEndDate.toString())
      );

      if (!property.projectStartDate > property.estimatedProjectEndDate) {
        property.projectStartDate = projectStartDateTemp;
        property.estimatedProjectEndDate = estimatedProjectEndDateTemp;
        ShowAlert("Project start date" + sc.isInvalid, sc.warning);
        return;
      }
      propertyClient.CreateProperty(property).then(
        (data: PropertyDto) => {
          setHttpProcessing(false);

          if (data) {
            navigate(sc.realtordetailroute, {
              state: { realtorId: realtorId, tabSelection: 2 },
            });
          }
        },
        (error: ErrorDto) => {
          property.projectStartDate = projectStartDateTemp;
          property.estimatedProjectEndDate = estimatedProjectEndDateTemp;

          setHttpProcessing(false);
          ShowAlert(error.message, sc.success);
        }
      );
    } catch (e: any) {
      setHttpProcessing(false);
      ShowAlert(e.message, sc.warning);
    }
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <IoChevronBackOutline size={sc.IconSize15} /> Realtor
          </a>
        </div>
        <div className="text-muted-medium">Add Property</div>
      </div>

      <div
        id="appCapsule"
        className="extra-header-active full-height"
        style={{ background: "white" }}
      >
        <div>
          <div className="card-body pb-1">
            <div className="form-group basic">
              <label className="label" htmlFor="title">
                Title
              </label>
              <input
                required
                value={property.title}
                onChange={handleChange}
                id="title"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>
            <div className="form-group basic">
              <label className="label" htmlFor="description">
                Description
              </label>
              <textarea
                required
                value={property.description}
                onChange={handleChange}
                id="description"
                style={{ width: "100%" }}
                className="form-control"
              ></textarea>
            </div>
            <div className="form-group basic">
              <label className="label" htmlFor="propertyType">
                {property.propertyType ? "Property Type" : <span>&nbsp;</span>}
              </label>

              <select
                required
                onChange={handleChange}
                value={property.propertyType}
                className="form-control custom-select"
                id="propertyType"
              >
                <option key="" value="" selected disabled hidden>
                  Property Type
                </option>
                {propertyTypes.map((value) => (
                  <option value={value.title} key={value.title}>
                    {value.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group basic">
              <label className="label" htmlFor="requestOption">
                {property.requestOption ? "Property Type" : <span>&nbsp;</span>}
              </label>

              <select
                required
                onChange={handleChange}
                value={property.requestOption}
                className="form-control custom-select"
                id="requestOption"
              >
                <option key="" value="" selected disabled hidden>
                  Request Option
                </option>
                {requestOptions.map((value) => (
                  <option value={value.value} key={value._id}>
                    {value.value}
                  </option>
                ))}
              </select>
            </div>
            {(property.requestOption == sc.Rent ||
              property.requestOption == sc.Lease) && (
              <>
                <div className="form-group basic">
                  <label className="label" htmlFor="stayDuration">
                    {property.stayDuration ? (
                      "Stay Duration"
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </label>

                  <select
                    required
                    onChange={handleChange}
                    value={property.stayDuration}
                    className="form-control custom-select"
                    id="stayDuration"
                  >
                    <option key="" value="" selected disabled hidden>
                      Stay Duration
                    </option>
                    {stayDuration.map((value) => (
                      <option value={value} key={value}>
                        {`${value} Month`}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
            <br />
            <div className="form-group basic">
              <label className="label" htmlFor="realtorPrice">
                Realtor Price
              </label>
              <input
                required
                value={property.realtorPrice}
                type="number"
                onChange={handleChange}
                id="realtorPrice"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>
            <div className="form-group basic">
              <label className="label" htmlFor="previousRealtorPrice">
                Previous Realtor Price
              </label>
              <input
                required
                value={property.previousRealtorPrice}
                type="number"
                onChange={handleChange}
                id="previousRealtorPrice"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>
            <div className="form-group basic">
              <label className="label" htmlFor="maxInterestPecentage">
                {property.maxInterestPecentage ? (
                  "Max Interest %"
                ) : (
                  <span>&nbsp;</span>
                )}
              </label>

              <select
                required
                onChange={handleChange}
                value={property.maxInterestPecentage}
                className="form-control custom-select"
                id="maxInterestPecentage"
              >
                <option key="" value="" selected disabled hidden>
                  Max. Interest %
                </option>
                {interestPercentages.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group basic">
              <label className="label" htmlFor="maxPaymentDuration">
                {property.maxPaymentDuration ? (
                  "Max. Payment Duration (Month)"
                ) : (
                  <span>&nbsp;</span>
                )}
              </label>

              <select
                required
                onChange={handleChange}
                value={property.maxPaymentDuration}
                className="form-control custom-select"
                id="maxPaymentDuration"
              >
                <option key="" value="" selected disabled hidden>
                  Max. Payment Duration (Month)
                </option>
                {paymentDuration.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <br />
            <div className="form-group basic">
              <label className="label" htmlFor="minEquityAmount">
                Min. Equity Amount
              </label>
              <input
                required
                value={property.minEquityAmount}
                type="number"
                onChange={handleChange}
                id="minEquityAmount"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>
            <div className="form-group basic">
              <label className="label" htmlFor="Quantity">
                Units
              </label>
              <input
                required
                value={property.Quantity}
                type="number"
                onChange={handleChange}
                id="Quantity"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>
            <br />
            <div className="form-group basic">
              <label className="label" htmlFor="availableCount">
                Available Units
              </label>
              <input
                required
                value={property.availableCount}
                type="number"
                onChange={handleChange}
                id="availableCount"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>
            <br />
            <div className="form-group basic">
              <label className="label" htmlFor="projectStartDate">
                Project Start Date
              </label>
              <input
                required
                type="date"
                value={property.projectStartDate}
                onChange={handleChange}
                id="projectStartDate"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>
            <br />
            <div className="form-group basic">
              <label className="label" htmlFor="estimatedProjectEndDate">
                Estimated End Date
              </label>
              <input
                required
                type="date"
                value={property.estimatedProjectEndDate}
                onChange={handleChange}
                id="estimatedProjectEndDate"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>
            <div className="form-group basic">
              <label className="label" htmlFor="contactPersonId">
                {property.contactPersonId ? (
                  "Representative"
                ) : (
                  <span>&nbsp;</span>
                )}
              </label>

              <select
                required
                onChange={handleChange}
                value={property.contactPersonId}
                className="form-control custom-select"
                id="contactPersonId"
              >
                <option key="" value="" selected disabled hidden>
                  Representative
                </option>
                {contactPersons.map((value) => (
                  <option value={value._id} key={value._id}>
                    {`${value.firstname} ${value.lastname}`}
                  </option>
                ))}
              </select>
            </div>
            <br />
            <div className="form-group basic">
              <div className="input-wrapper">
                <label className="label" htmlFor="address">
                  Address
                </label>
                <input
                  type="text"
                  value={address.address}
                  onChange={handleChangeAddress}
                  className="form-control"
                  id="address"
                />
              </div>
            </div>
            <div className="form-group basic">
              <div className="input-wrapper">
                <label className="label" htmlFor="state">
                  {address.state ? "Select State" : <span>&nbsp;</span>}
                </label>

                <select
                  required
                  onChange={getCities}
                  value={address.state}
                  className="form-control custom-select"
                  id="state"
                >
                  <option key="" value="" selected disabled hidden>
                    Select State
                  </option>
                  {states.map((state) => (
                    <option key={state.title} value={state.title}>
                      {state.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group basic">
              <div className="input-wrapper">
                <label className="label" htmlFor="city">
                  {address.city ? "Select City" : <span>&nbsp;</span>}
                </label>

                <select
                  required
                  onChange={handleChangeAddress}
                  value={address.city}
                  className="form-control custom-select"
                  id="city"
                >
                  <option key="" value="" selected disabled hidden>
                    Select City
                  </option>
                  {cities.map((city) => (
                    <option value={city.title} key={city.title}>
                      {city.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <br />
            <div>
              <CustomButton
                httpProcessing={httpProcessing}
                text="Add"
                onClick={addProperty}
              />
            </div>
            <br /> <br />
          </div>
        </div>
      </div>
    </>
  );
};
export default AddProperty;
