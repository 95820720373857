import AWS from "aws-sdk";
import sc from "./stringconst";
import PropertyDto from "../models/Property/PropertyDto";

class FileManager {
  uploadMedia = (file: any, filename: string, path: string) => {
    const S3_BUCKET = `myplaceng/${path}`;
    const REGION = "eu-west-3";

    AWS.config.update({
      accessKeyId: "AKIA5TUNTDJELTR6H5CT",
      secretAccessKey: "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",
    });

    const myBucket = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: filename,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt: any) => {
        // setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err: any) => {
        if (err) console.log(err);
        return true;
      });
    return false;
  };

  uploadMediaAsync = (
    file: any,
    filename: string,
    path: string
  ): Promise<boolean | void | null> => {
    const S3_BUCKET = `myplaceng/${path}`;
    const REGION = "eu-west-3";

    AWS.config.update({
      accessKeyId: "AKIA5TUNTDJELTR6H5CT",
      secretAccessKey: "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",
    });

    const myBucket = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: filename,
    };
    let myBucketPromise = myBucket.putObject(params).promise();

    return Promise.resolve(myBucketPromise).then(function (values) {
      console.log(values);
      return true;
    });
  };

  getPropertyMediaUrl = (
    filename: string,
    propertyId: string,
    type: string
  ) => {
    return `https://myplaceng.s3.eu-west-3.amazonaws.com/${this.getPropertyMediaPath(
      propertyId
    )}/${filename}.${type == sc.video ? "mp4" : "jpg"}`;
  };
  getPropertyMediaPath = (propertyId: string) => {
    return `properties/p-${propertyId}`;
  };

  buildPropertyMedaiUrl = (property: PropertyDto) => {
    return this.getPropertyMediaUrl(
      property.media?.find((o) => o.default)?.mediaId ?? "",
      property._id ?? "",
      property.media?.find((o) => o.default)?.type ?? ""
    );
  };
}
export default new FileManager();
