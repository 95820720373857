import UserDto from "../models/User/UserDto";
import sc from "./stringconst";
var CryptoJS = require("crypto-js");
class AuthService {
  setCurrentUser(data: any) {
    this.setItem("user", JSON.stringify(data.user));
    this.setItem("advert", JSON.stringify(data.advert));
    this.setItem("photoUrl", data.user.photoUrl);
  }

  getCipherSecretKey() {
    return "a3cd17a9-6735-4d04-83ee-b8c426c61545";
  }

  clearAdvert() {
    localStorage.removeItem("advert");
  }
  getAdvert() {
    let advert = this.getItem("advert");
    if (advert) {
      let ad = JSON.parse(advert);
      if (ad?.showAd) {
        return ad;
      }
    }
    return { showAd: false, adUrl: "" };
  }

  setLocalCurrentUser(data: any) {
    this.setItem("user", JSON.stringify(data));
    this.setItem("advert", JSON.stringify(data.advert));
    this.setItem("photoUrl", data.photoUrl);
  }

  getLocalCurrentUser() {
    let user = this.getItem("user");
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  getUserToken() {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile.token) {
        return profile.token;
      }
    return null;
  }

  getSessionToken() {
    return localStorage.getItem("token");
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  }

  getCurrentUser() {
    let user = <UserDto>this.getLocalCurrentUser();
    return user;
  }
  IsUserLoggedIn() {
    let user = this.getLocalCurrentUser();
    if (user) return true;

    return false;
  }

  getDefaultImageUrl() {
    let defaultImageUrl = "https://loanhub.ng/appimages/loanhubdefault.jpg";
    return defaultImageUrl;
  }
  getFacilityUrl() {
    let defaultImageUrl = "https://loanhub.ng/appimages/loanhubdefault.jpg";
    return defaultImageUrl;
  }

  getDefaultPropertyImageUrl() {
    let defaultImageUrl =
      "https://myplaceng.s3.eu-west-3.amazonaws.com/App/propertyicon.png";
    return defaultImageUrl;
  }

  urlExist(url: string) {
    try {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url, false);

      xhr.send();
      return xhr.status != 404;
    } catch (e: any) {
      if (JSON.stringify(e).indexOf("404") > -1) return false;
      return true;
    }
  }

  loadPropertyImage(url: string) {
    if (!url) return this.getDefaultPropertyImageUrl();
    return this.urlExist(url ?? "") ? url : this.getDefaultPropertyImageUrl();
  }
  loadPropertyVideo(imageId: string) {
    let url = "aws" + "/property/" + imageId + ".mp4";
    return this.urlExist(url) ? url : this.getDefaultPropertyImageUrl();
  }
  loadUserImage(url?: string) {
    if (!url) return this.getDefaultPropertyImageUrl();
    return this.urlExist(url ?? "") ? url : this.getDefaultPropertyImageUrl();
  }

  getItem(name: string): string {
    let ciphertext = localStorage.getItem(name);
    if (!ciphertext) {
      return "";
    }

    var bytes = CryptoJS.AES.decrypt(ciphertext, this.getCipherSecretKey());
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8);

    return decryptedData;
  }

  setItem(name: string, itemValue: string) {
    var dataUserCipherText = CryptoJS.AES.encrypt(
      itemValue,
      this.getCipherSecretKey()
    ).toString();
    localStorage.setItem(name, dataUserCipherText);
  }

  getUserProfileImageUrl() {
    let photoUrl = this.getItem("photoUrl");
    if (photoUrl === sc.undefined) {
      photoUrl = "";
    }
    if (photoUrl) {
      return photoUrl;
    }
    return this.getDefaultImageUrl();
  }

  getUserProfileIdentityId() {
    let profile = this.getLocalCurrentUser();
    if (profile) {
      if (profile.identityId) {
        return profile.identityId;
      }
    }

    return "";
  }

  displayPhotoVerified() {
    let profile = this.getLocalCurrentUser();
    if (profile?.displayPhotoVerification?.verified) {
      return profile.displayPhotoVerification?.verified;
    }
    return "";
  }

  displayPhotoVerifiedComment() {
    let profile = this.getLocalCurrentUser();
    return profile?.displayPhotoVerification?.comment;
  }

  getUserProfileIdentityIdVerified() {
    let profile = this.getLocalCurrentUser();
    if (profile) {
      if (profile.identityIdVerified) {
        return profile.identityIdVerified;
      }
    }

    return "";
  }

  getUserProfileUsername() {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile.username) {
        return profile.username;
      }
    return "";
  }
  getPhotoVerified() {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile.photoVerification?.verified === sc.verified) {
        return true;
      }
    return false;
  }
  getBankVerified() {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile.bankVerification?.verified === sc.verified) {
        return true;
      }
    return false;
  }
  removeBankVerified() {
    let profile = this.getLocalCurrentUser();
    if (profile) {
      if (profile.bankVerification) {
        profile.bankVerification.verified = "";
        this.setItem("user", JSON.stringify(profile));
      }
    }
  }

  getPhotoVerifyStatus() {
    let profile = this.getLocalCurrentUser();
    if (profile) {
      if (!profile.photoVerification?.verified) {
        return "";
      }
      return profile.photoVerification?.verified;
    }
    return "";
  }
  updatePhotoVerifyStatus() {
    let profile = this.getLocalCurrentUser();
    if (profile) {
      if (profile.photoVerification) {
        profile.photoVerification.verified = sc.processing;
        this.setLocalCurrentUser(profile);
      }
    }
  }

  updateBankVerifyStatus(data: any) {
    let profile = this.getLocalCurrentUser();
    if (profile) {
      if (profile.bankVerification) {
        profile.bankVerification = data;
        this.setLocalCurrentUser(profile);
      }
    }
  }
  getBankVerifyStatus() {
    let profile = this.getLocalCurrentUser();
    if (profile) {
      if (!profile.bankVerification?.verified) {
        return "";
      }
      return profile.bankVerification?.verified;
    }
    return "";
  }
  getBankVerifyData() {
    let profile = this.getLocalCurrentUser();
    if (profile) {
      return profile.bankVerification?.parameters;
    }
    return null;
  }

  getRatingAverage() {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile.ratingAverage) {
        return profile.ratingAverage;
      }
    return 0;
  }

  getuserProfileId() {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile._id) {
        return profile._id;
      }
    return "";
  }
  getuserProfile() {
    let profile = this.getLocalCurrentUser();
    if (profile) return profile;
    return {};
  }

  getUserProfileFirstname(): string {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile.firstname) {
        return profile.firstname;
      }
    return "";
  }
  getUserProfileLastname(): string {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile.lastname) {
        return profile.lastname;
      }
    return "";
  }
  getUserProfileEmail(): string {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile.email) {
        return profile.email;
      }
    return "";
  }
  getUserProfileDisplayName(): string {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile.displayName) {
        return profile.displayName;
      }
    return "";
  }
  getUserProfileMobileNumber(): string {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile.mobileNumber) {
        return profile.mobileNumber;
      }
    return "";
  }
  getUserProfileAddress(): string {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile.address) {
        return profile.address;
      }
    return "";
  }
  getUserProfileState(): string {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile.state) {
        return profile.state;
      }
    return "";
  }
  getUserProfileGender(): string {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile.gender) {
        return profile.gender;
      }
    return "M";
  }
  getUserProfileDateOfBirth(): string {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile.dateOfBirth) {
        return profile.dateOfBirth;
      }
    return "";
  }
  getUserProfileCity(): string {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile.city) {
        return profile.city;
      }
    return "";
  }

  userIsLender(): boolean {
    let profile = this.getLocalCurrentUser();
    if (profile)
      if (profile.isLender) {
        return true;
      }
    return false;
  }
  updateprofileimage(url: string) {
    this.setItem("photoUrl", url);
  }

  updateislender(islender: boolean) {
    let user = this.getLocalCurrentUser();
    if (user) {
      user.isLender = islender;
      this.setLocalCurrentUser(user);
    }
  }

  setDisplayPhotoVerified(status: boolean, comment: string) {
    let user = this.getLocalCurrentUser();
    if (user) {
      user.displayPhotoVerification.verified = status;
      user.displayPhotoVerification.comment = comment;
      this.setLocalCurrentUser(user);
    }
  }
  setIdentityId(value: string) {
    let user = this.getLocalCurrentUser();
    if (user) {
      user.identityId = value;
      this.setLocalCurrentUser(user);
    }
  }
  setLoanPackageId(value: string) {
    this.setItem("loanPackageId", value);
  }
  getLoanPackageId() {
    return this.getItem("loanPackageId");
  }
  clearLoanPackageId() {
    localStorage.removeItem("loanPackageId");
  }

  setFirstname(value: string) {
    let user = this.getLocalCurrentUser();
    if (user) {
      user.firstname = value;
      this.setLocalCurrentUser(user);
    }
  }
  setLastname(value: string) {
    let user = this.getLocalCurrentUser();
    if (user) {
      user.lastname = value;
      this.setLocalCurrentUser(user);
    }
  }

  setEmail(value: string) {
    let user = this.getLocalCurrentUser();
    if (user) {
      user.email = value;
      this.setLocalCurrentUser(user);
    }
  }
  setDisplayName(value: string) {
    let user = this.getLocalCurrentUser();
    if (user) {
      user.displayName = value;
      this.setLocalCurrentUser(user);
    }
  }

  setMobileNumber(value: string) {
    let user = this.getLocalCurrentUser();
    if (user) {
      user.mobileNumber = value;
      this.setLocalCurrentUser(user);
    }
  }

  setAddress(value: string) {
    let user = this.getLocalCurrentUser();
    if (user) {
      user.address = value;
      this.setLocalCurrentUser(user);
    }
  }

  setState(value: string) {
    let user = this.getLocalCurrentUser();
    if (user) {
      user.state = value;
      this.setLocalCurrentUser(user);
    }
  }

  setCity(value: string) {
    let user = this.getLocalCurrentUser();
    if (user) {
      user.city = value;
      this.setLocalCurrentUser(user);
    }
  }

  appAlertWidth() {
    this.setItem("AppAlertWidth", "80%");
    if (window.screen.width > 800) {
      this.setItem("AppAlertWidth", "37.5%");
    }
    if (window.screen.width > 1200) {
      this.setItem("AppAlertWidth", "24%");
    }
    if (window.screen.width > 1600) {
      this.setItem("AppAlertWidth", "20%");
    }
    return this.getItem("AppAlertWidth");
  }

  appWelcomeLogoWidth() {
    this.setItem("AppLogoWidth", "60%");
    if (window.screen.width > 800) {
      this.setItem("AppLogoWidth", "45%");
    }
    if (window.screen.width > 1200) {
      this.setItem("AppLogoWidth", "30%");
    }
    if (window.screen.width > 1600) {
      this.setItem("AppLogoWidth", "15%");
    }
    return this.getItem("AppLogoWidth");
  }

  appLoginLogoWidth() {
    this.setItem("AppLogoWidth", "30%");
    if (window.screen.width > 800) {
      this.setItem("AppLogoWidth", "20%");
    }
    if (window.screen.width > 1200) {
      this.setItem("AppLogoWidth", "20%");
    }
    if (window.screen.width > 1600) {
      this.setItem("AppLogoWidth", "10%");
    }
    return this.getItem("AppLogoWidth");
  }

  appWelcomeButtonWidth() {
    this.setItem("AppButtonWidth", "80%");
    if (window.screen.width > 800) {
      this.setItem("AppButtonWidth", "60%");
    }
    if (window.screen.width > 1200) {
      this.setItem("AppButtonWidth", "40%");
    }
    if (window.screen.width > 1600) {
      this.setItem("AppButtonWidth", "20%");
    }
    return this.getItem("AppButtonWidth");
  }
  appWelcomeButtonPadding() {
    this.setItem("AppLogoButtonPadding", "10% 5%");
    if (window.screen.width > 800) {
      this.setItem("AppLogoButtonPadding", "7% 4%");
    }
    if (window.screen.width > 1200) {
      this.setItem("AppLogoButtonPadding", "4% 2%");
    }
    if (window.screen.width > 1600) {
      this.setItem("AppLogoButtonPadding", "2% 1%");
    }
    return this.getItem("AppLogoButtonPadding");
  }

  appWidth() {
    this.setItem("AppWidth", "100%");
    if (window.screen.width > 800) {
      this.setItem("AppWidth", "75%");
    }
    if (window.screen.width > 1200) {
      this.setItem("AppWidth", "50%");
    }
    if (window.screen.width > 1600) {
      this.setItem("AppWidth", "40%");
    }
    return this.getItem("AppWidth");
  }

  appMargin() {
    this.appWidth();

    let value = "0%";
    if (this.getItem("AppWidth") === "100%") {
      value = "0%";
    }

    if (this.getItem("AppWidth") === "75%") {
      value = "12.5%";
    }

    if (this.getItem("AppWidth") === "50%") {
      value = "25%";
    }
    if (this.getItem("AppWidth") === "40%") {
      value = "30%";
    }
    return value;
  }
  appWidthFooter() {
    let appWidth = this.appWidth();
    let appWidthValue = parseInt(appWidth.substring(0, appWidth.length - 1));
    appWidthValue = appWidthValue - (10 / 100) * appWidthValue;
    return `${appWidthValue}%`;
  }

  appMarginFooter() {
    let appMargin = this.appMargin();
    let appMarginValue = parseInt(appMargin.substring(0, appMargin.length - 1));

    let appWidth = this.appWidth();
    let appWidthValue = parseInt(appWidth.substring(0, appWidth.length - 1));
    let appWidthMarginValue = (5 / 100) * appWidthValue;

    appMarginValue = appMarginValue + appWidthMarginValue; // + (5 / 100) * footerWidthValue;
    return `${appMarginValue}%`;
  }

  setPlatformIsNative(value: any) {
    this.setItem("PlatformIsNative", value.toString());
  }
  setPlatform(value: any) {
    this.setItem("Platform", value.toString());
  }
  getPlatform() {
    return this.getItem("Platform");
  }
  getPlatformIsNative() {
    let value = this.getItem("PlatformIsNative");
    if (value == "true") {
      return true;
    }
    return false;
  }
  setIsBorrower(value: any) {
    this.setItem("isBorrower", value.toString());
  }

  getIsBorrower() {
    let value = this.getItem("isBorrower");
    if (value === "true") {
      return true;
    }
    if (value === "false") {
      return false;
    }
    return null;
  }

  setDepMode(value: any) {
    this.setItem("depMode", value.toString());
  }

  // getDepMode(){
  //   let value=this.getItem("depMode");
  //   if(value==="true"){
  //     return true;
  //   }
  //   if(value==="false"){
  //     return false;
  //   }
  //   return true;
  // }

  getDeviceIsRegistered() {
    let value = this.getItem("deviceIsRegistered");
    if (value === "true") {
      return true;
    }
    return false;
  }

  setDeviceIsRegistered() {
    this.setItem("deviceIsRegistered", true.toString());
  }

  getUserHasLoggedInOnThisDevice() {
    let value = this.getItem("userHasLoggedInOnThisDevice");
    if (value === "true") {
      return true;
    }
    return false;
  }

  setUserHasLoggedInOnThisDevice() {
    this.setItem("userHasLoggedInOnThisDevice", true.toString());
  }

  getPushNotificationUserToken() {
    let value = this.getItem(sc.PushNotificationUserToken);
    return value;
  }

  setPushNotificationUserToken(value: string) {
    this.setItem(sc.PushNotificationUserToken, value);
  }

  getDeviceId() {
    let value = this.getItem("deviceId");
    return value;
  }

  setDeviceId(value: string) {
    this.setItem("deviceId", value);
  }
  getDeviceInfo() {
    let value = this.getItem("deviceInfo");
    return value;
  }

  setDeviceInfo(value: string) {
    this.setItem("deviceInfo", value);
  }
}

export default new AuthService();
