import NotificationDto from "../../models/AppFeature/NotificationDto";
import BaseResponseDto from "../../models/Shared/baseResponseDto";
import ChangePasswordDto from "../../models/User/Request/ChangePasswordDto";
import ConfirmResetPasswordDto from "../../models/User/Request/ConfirmResetPasswordDto";
import PhotoUpdateRequestDto from "../../models/User/Request/PhotoUpdateRequestDto";
import SignInDataDto from "../../models/User/Request/SignInDataDto";
import RefreshTokenResponseDto from "../../models/User/Response/RefreshTokenResponseDto";
import SigninResponseDto from "../../models/User/Response/signinResponseDto";
import UserDetailDto from "../../models/User/UserDetailDto";
import UserDto from "../../models/User/UserDto";
import HttpClient from "../httpClient";
import v from "../variables";

let baseRoute = "user/";
const getCustomers = async (
  skip: number,
  searchParam: string
): Promise<UserDto[]> => {
  let route =
    v.baseUrl +
    baseRoute +
    `getcustomers?skip=${skip}&searchParam=${searchParam}`;

  return <UserDto[]>await HttpClient.getRequest(route);
};
const getCustomerDetails = async (
  customerId: string
): Promise<UserDetailDto> => {
  let route = v.baseUrl + baseRoute + `getcustomerdetails/${customerId}`;

  return <UserDetailDto>await HttpClient.getRequest(route);
};
const signIn = async (data: SignInDataDto): Promise<SigninResponseDto> => {
  let route = v.baseUrl + baseRoute + "signin";
  return <SigninResponseDto>await HttpClient.postRequest(route, data, false);
};

const register = async (data: UserDto): Promise<UserDto> => {
  let route = v.baseUrl + baseRoute + "register";

  return <UserDto>await HttpClient.postRequest(route, data, false);
};

const getUser = async (): Promise<UserDto> => {
  let route = v.baseUrl + baseRoute + "getuser";

  return <UserDto>await HttpClient.getRequest(route);
};

const updateProfileImage = async (
  photoUrl: string,
  photoId: string
): Promise<UserDto> => {
  let route = v.baseUrl + baseRoute + "updateprofileimage";

  return <UserDto>await HttpClient.postRequest(route, {
    photoUrl: photoUrl,
    photoId: photoId,
  });
};
const updateFirstname = async (firstname: string): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "updatefirstname";

  return <BaseResponseDto>(
    await HttpClient.postRequest(route, { firstname: firstname })
  );
};
const updateLastname = async (firstname: string): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "updatelastname";

  return <BaseResponseDto>(
    await HttpClient.postRequest(route, { firstname: firstname })
  );
};
const updateEmail = async (email: string): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "updateemail";

  return <BaseResponseDto>await HttpClient.postRequest(route, { email: email });
};
const setMobilePin = async (pin: number): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "setmobilepin";

  return <BaseResponseDto>(
    await HttpClient.postRequest(route, { pin: pin.toString() })
  );
};
const updateMobileNumber = async (
  mobilenumber: string
): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "updatemobilenumber";

  return <BaseResponseDto>(
    await HttpClient.postRequest(route, { mobilenumber: mobilenumber })
  );
};
const changePassword = async (
  data: ChangePasswordDto
): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "changepassword";

  return <BaseResponseDto>await HttpClient.postRequest(route, data);
};
const refreshToken = async (
  refreshToken: string
): Promise<RefreshTokenResponseDto> => {
  let route = v.baseUrl + baseRoute + "refreshtoken";

  return <RefreshTokenResponseDto>(
    await HttpClient.postRequest(route, { refreshToken: refreshToken })
  );
};
const resetPassword = async (email: string): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "resetpassword";

  return <BaseResponseDto>(
    await HttpClient.postRequest(route, { email: email }, false)
  );
};
const confirmResetPassword = async (
  data: ConfirmResetPasswordDto
): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "confirmresetpassword";

  return <BaseResponseDto>await HttpClient.postRequest(route, data, false);
};
const disableUser = async (id: string): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "disableuser";

  return <BaseResponseDto>await HttpClient.postRequest(route, { id: id });
};
const activateUser = async (id: string): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "activateuser";

  return <BaseResponseDto>await HttpClient.postRequest(route, { id: id });
};
const validateRegistration = async (
  email: string,
  otp: string
): Promise<SigninResponseDto> => {
  let route = v.baseUrl + baseRoute + "validateregistration";

  return <SigninResponseDto>(
    await HttpClient.postRequest(route, { email: email, otp: otp }, false)
  );
};
const resendOtp = async (
  email: string,
  otpType: string
): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "resendotp";

  return <BaseResponseDto>(
    await HttpClient.postRequest(
      route,
      { email: email, otpType: otpType },
      false
    )
  );
};
const getNotifications = async (skip: number): Promise<NotificationDto[]> => {
  let limit = 20;
  let route = v.baseUrl + baseRoute + "getnotifications/" + skip + "/" + limit;

  return <NotificationDto[]>await HttpClient.getRequest(route);
};

const addUserPhoto = async (
  data: PhotoUpdateRequestDto
): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "adduserPhoto";

  return <BaseResponseDto>await HttpClient.postRequest(route, data);
};

const removeUserPhoto = async (
  userPhotoId: string
): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "removeuserphoto";

  return <BaseResponseDto>(
    await HttpClient.postRequest(route, { userPhotoId: userPhotoId })
  );
};

const UserClient = {
  signIn,
  register,
  getCustomers,
  getCustomerDetails,
  getUser,
  updateProfileImage,
  updateFirstname,
  updateLastname,
  updateEmail,
  setMobilePin,
  updateMobileNumber,
  changePassword,
  refreshToken,
  resetPassword,
  confirmResetPassword,
  disableUser,
  activateUser,
  getNotifications,
  addUserPhoto,
  removeUserPhoto,
  validateRegistration,
  resendOtp,
};

export default UserClient;
