import React, { useState, useEffect } from "react";
import { Client } from "../services/apiclient";
import { useNavigate } from "react-router-dom";
import sc from "../services/stringconst";
import ImageModal from "./childcomponents/imagemodal";
import { useLocation } from "react-router-dom";
import ValueLabel from "./childcomponents/valuelabel";
import auth from "../services/authservice";
import util from "../Utilities/UtilityHelper";

const ProfileView = (props) => {
  let location = useLocation();
  let userId = location.state;

  const [user, setUser] = useState({});
  const [ratingAverage, setRatingAverage] = useState(0);
  let navigate = useNavigate();
  let client = new Client(null, null);

  useEffect(() => {
    client.user(userId).then(
      (data) => {
        let tempRatingAverage = data.ratingAverage;
        data.ratingAverage = data.ratingAverage ?? 0;
        setUser(data);

        tempRatingAverage = util.normalizeAverageRating(tempRatingAverage);
        setRatingAverage(tempRatingAverage);
      },
      (error) => {
        props.ShowAlert(error.response, sc.danger);
      }
    );
  }, []);

  const back = () => {
    navigate(-1);
  };
  const openratings = () => {
    navigate(sc.ratingroute, { state: user });
  };

  const loanPackage = () => {
    navigate(sc.loanpackageroute, {
      state: { username: user.username, userId: userId },
    });
  };
  const useraccount = () => {
    navigate(sc.useraccountroute, { state: userId });
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div>
          {user.displayName}{" "}
          {user.photoVerification?.verified === sc.verified && (
            <img
              width="20"
              src="https://loanhub.ng/appimages/photoverified.png"
            />
          )}
          {user.bankVerification?.bankVerified === sc.verified && (
            <img
              width="20"
              src="https://loanhub.ng/appimages/bankverified.png"
            />
          )}
        </div>
      </div>
      <div id="appCapsule" className="extra-header-active full-height">
        <div className="section mt-3 text-center">
          <div className="avatar-section">
            <a>
              <img
                src={
                  user.photoUrl && user.photoUrl !== sc.undefined
                    ? user.photoUrl
                    : auth.getDefaultImageUrl()
                }
                alt="avatar"
                className="imaged rounded"
                style={{ width: "8em", height: "8em" }}
                data-bs-toggle="modal"
                data-bs-target="#DialogImage"
              />
            </a>
          </div>
        </div>
        <div className="card" style={{ margin: "1em" }}>
          <ul className="listview image-listview transparent flush">
            {user.isLender && (
              <li>
                <div className="item">
                  <div className="icon-box bg-primaryxx">
                    <ion-icon name="grid-outline"></ion-icon>
                  </div>
                  <div className="in">
                    <div>Loan Packages</div>
                    <div className="form-check" onClick={loanPackage}>
                      <span
                        className="right text-primary"
                        style={{ fontSize: "2em" }}
                      >
                        <ion-icon name="open-outline"></ion-icon>
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            )}
            <li>
              <div className="item">
                <div className="icon-box bg-primaryxx">
                  <ion-icon name="star-half-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>
                    Rating{" "}
                    <img
                      style={{ height: "1.5em" }}
                      src={
                        "https://loanhub.ng/appimages/rating" +
                        ratingAverage +
                        ".jpg"
                      }
                    />
                  </div>

                  <div className="form-check" onClick={openratings}>
                    <span
                      className="right text-primary"
                      style={{ fontSize: "2em" }}
                    >
                      <ion-icon name="open-outline"></ion-icon>
                    </span>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="icon-box bg-primaryxx">
                  <ion-icon name="wallet-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>Bank Accounts</div>
                  <div className="form-check" onClick={useraccount}>
                    <span
                      className="right text-primary"
                      style={{ fontSize: "2em" }}
                    >
                      <ion-icon name="open-outline"></ion-icon>
                    </span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        {/* Chat profile owner from here */}

        <div className="card" style={{ margin: "1em" }}>
          <div className="card-body">
            <ValueLabel value={user.firstname} label={"Firstname"} />
            <ValueLabel value={user.lastname} label={"Lastname"} />
            <ValueLabel
              value={user.gender === sc.M ? sc.Male : sc.Female}
              label={"Gender"}
            />
            <ValueLabel value={`${user.age} years`} label={"Age"} />
            {/* <ValueLabel value={user.email} label={'Email'} />
            <ValueLabel value={user.mobileNumber} label={'Mobile Number'} /> */}
          </div>
        </div>

        <div className="card" style={{ margin: "1em" }}>
          <div className="card-body">
            <ValueLabel value={user.address} label={"Address"} />
            <ValueLabel value={user.city} label={"City"} />
            <ValueLabel value={user.state} label={"State"} />
          </div>
        </div>
      </div>

      <ImageModal
        url={user.photoUrl ? user.photoUrl : auth.getDefaultImageUrl()}
      />
    </>
  );
};
export default ProfileView;
