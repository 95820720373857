import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import moment from "moment";
import {
  IoAddOutline,
  IoCheckmarkOutline,
  IoChevronBackOutline,
  IoCloseOutline,
  IoConstructOutline,
  IoImages,
  IoImagesOutline,
  IoListOutline,
  IoVideocamOffOutline,
} from "react-icons/io5";
import auth from "../../../services/authservice";
import ValueLabel from "../../childcomponents/valuelabel";
import LoadingTextPlaceHolder from "../../shared/Preload/loadingtextplaceholder";
import ConfirmModal from "../../childcomponents/confirmmodal";
import { useSelector } from "react-redux";
import { getUser } from "../../../store/store";
import propertyClient from "../../../httpClients/clients/propertyClient";
import UtilityHelper from "../../../Utilities/UtilityHelper";
import SwipeableViews from "react-swipeable-views";
import ErrorDto from "../../../models/AppFeature/ErrorDto";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";
import FileManager from "../../../services/FileManager";
import PropertyMediaDto from "../../../models/Property/PropertyMediaDto";
import { Buffer } from "buffer";
import PropertyDevelopmentDto from "../../../models/Property/PropertyDevelopmentDto";
import { Fade } from "react-slideshow-image";
import { Player } from "video-react";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const PropertyDevelopment = ({ ShowAlert }: Props) => {
  let location = useLocation();
  const propertyId = location.state.propertyId;
  const developmentId = location.state.propertyDevelopmentId;

  const user = useSelector(getUser);

  let developmentTemp: PropertyDevelopmentDto = {};
  const [development, setPropertyDevelopment] = useState(developmentTemp);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [displayTab, setDisplayTab] = useState(1);

  const addOption = [1];

  let mediasTemp: MediaDto[] = [];
  const [medias, setMedias] = useState(mediasTemp);
  let imageHolder: {
    url: string;
    mediaId: string | undefined;
    thumbnail: string;
    type: string | undefined;
  }[] = [];

  const [activeMediaId, setActiveMediaId] = useState("");

  const handleSwipeChange = (e: number) => {
    setTimeout(function () {
      setDisplayTab(e + 1);
    }, 1);
  };

  let navigate = useNavigate();
  const [httpProcessing, setHttpProcessing] = useState(false);

  useEffect(() => {
    GetPropertyDevelopment();
  }, []);

  const GetPropertyDevelopment = () => {
    propertyClient.GetProperty(propertyId).then(
      (data) => {
        setDataLoaded(true);

        let development = data.propertyDevelopments?.find(
          (o) => o.propertyDevelopmentId === developmentId
        );

        if (!development) {
          navigate(sc.propertydetailsroute, {
            state: { propertyId: propertyId, tabSelection: 4 },
          });
          return;
        }
        console.log(JSON.stringify(development));

        setPropertyDevelopment(development);

        if (development?.media && development?.media?.length > 0) {
          const mediasTemp: MediaDto[] = [];
          development?.media.forEach((item) => {
            const url = FileManager.getPropertyMediaUrl(
              item.mediaId ?? "",
              propertyId,
              item.type ?? ""
            );
            const thumbnail = FileManager.getPropertyMediaUrl(
              item.mediaId ?? "",
              propertyId,
              sc.image
            );

            mediasTemp.push({
              url: url,
              mediaId: item.mediaId,
              thumbnail: thumbnail,
              type: item.type,
            });
          });
          setMedias(mediasTemp);
        }
      },
      (error) => {
        setDataLoaded(true);
        ShowAlert(error.response, sc.danger);
      }
    );
  };
  const selectMediaFiles = async (e: any) => {
    mediasTemp = [];
    imageHolder = [];
    if (e.target.files) {
      let batchCount = e.target.files.length;
      for (let i = 0; i < e.target.files.length; i++) {
        const mediaType =
          e.target.files[i].type.indexOf(sc.video) > -1 ? sc.video : sc.image;
        const mediaId = UtilityHelper.UniqueIdGenerator();
        if (mediaType == sc.video) {
          FileManager.uploadMediaAsync(
            e.target.files[i],
            mediaId + sc.mp4Ext,
            FileManager.getPropertyMediaPath(propertyId)
          ).then((result) => {
            addPropertyMedia(mediaId, mediaType, batchCount, true);
          });

          generateVideoThumbnails(e.target.files[i], 1, "base64").then(
            (thumbs) => {
              const file = Buffer.from(
                thumbs[0].replace(/^data:image\/\w+;base64,/, ""),
                "base64"
              );

              FileManager.uploadMediaAsync(
                file,
                mediaId + sc.jpgExt,
                FileManager.getPropertyMediaPath(propertyId)
              ).then((result) => {
                addPropertyMedia(mediaId, sc.image, batchCount, false);
              });
            }
          );
        } else {
          FileManager.uploadMediaAsync(
            e.target.files[i],
            mediaId + sc.jpgExt,
            FileManager.getPropertyMediaPath(propertyId)
          ).then((result) => {
            addPropertyMedia(mediaId, mediaType, batchCount, true);
          });
        }
      }
    }
  };

  const addPropertyMedia = (
    mediaId: string,
    mediaType: string,
    batchCount: number,
    saveToDb: boolean
  ) => {
    const url = FileManager.getPropertyMediaUrl(
      mediaId ?? "",
      propertyId,
      mediaType ?? ""
    );
    const thumbnail = FileManager.getPropertyMediaUrl(
      mediaId ?? "",
      propertyId,
      sc.image
    );

    let propertyMedia: PropertyMediaDto = {
      mediaId: mediaId,
      type: mediaType,
      default: false,
    };

    if (saveToDb) {
      imageHolder.push({
        url: url,
        mediaId: mediaId,
        thumbnail: thumbnail,
        type: mediaType,
      });
      mediasTemp.push(propertyMedia);
    }

    if (batchCount == mediasTemp.length) {
      propertyClient
        .AddPropertyDevelopmentMedia(propertyId, developmentId, mediasTemp)
        .then((data: PropertyMediaDto[]) => {
          mediasTemp = [];
        });
    }

    if (batchCount == imageHolder.length) {
      let tempMedias = [...medias];
      imageHolder.forEach((element) => {
        tempMedias.push(element);
      });
      setMedias(tempMedias);
      imageHolder = [];
    }
  };

  const back = () => {
    navigate(-1);
  };

  const spanStyle = {
    padding: "20px",
    background: "#efefef",
    color: "#000000",
  };

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    height: "400px",
  };

  const [isVideoMedia, setIsVideoMedia] = useState(false);

  const SetIsVideoMediaValue = (thumbnail: string, url: string) => {
    setVideoData({ thumbnail: thumbnail, url: url });
    setIsVideoMedia(!isVideoMedia);
  };
  const [videoData, setVideoData] = useState({ thumbnail: "", url: "" });

  return (
    <>
      {dataLoaded && (
        <div>
          <div
            style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
            className="appHeader bg-primary-main  bg-primary text-light"
          >
            <div className="left">
              <a onClick={back} className="headerButton goBack">
                <IoChevronBackOutline size={sc.IconSize15} /> Back
              </a>
            </div>
            <div className="text-muted-medium">Development</div>
          </div>
          <div id="appCapsule">
            <div className="slide-container">
              {medias?.length > 0 && (
                <>
                  {" "}
                  {!isVideoMedia && (
                    <Fade>
                      {medias.map((slideImage, index) => (
                        <div key={index}>
                          <div
                            style={{
                              ...divStyle,
                              backgroundImage: `url(${slideImage.thumbnail})`,
                            }}
                          >
                            {slideImage.type == sc.video && (
                              <span
                                style={spanStyle}
                                onClick={() =>
                                  SetIsVideoMediaValue(
                                    slideImage.thumbnail ?? "",
                                    slideImage.url ?? ""
                                  )
                                }
                              >
                                <IoVideocamOffOutline size={sc.IconSize20} />{" "}
                                Watch
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                    </Fade>
                  )}
                  {isVideoMedia && (
                    <div>
                      <span
                        style={{ float: "right", paddingRight: "10px" }}
                        onClick={() => SetIsVideoMediaValue("", "")}
                      >
                        <IoCloseOutline size={sc.IconSize25} /> Close
                      </span>
                      <div
                        style={{
                          clear: "both",
                        }}
                      >
                        <Player
                          aspectRatio="4:3"
                          muted={false}
                          autoPlay={true}
                          poster={videoData.thumbnail}
                          src={videoData.url}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            <div>
              <div>
                <div style={{ margin: "1em" }}>
                  {" "}
                  <ValueLabel
                    value={development?.title ?? ""}
                    label={"Title"}
                  />
                </div>
                <div className="carpet-color-bg">
                  <div style={{ margin: "1em" }}>
                    <ValueLabel
                      value={development?.description ?? ""}
                      label={"Description"}
                    />
                  </div>
                </div>
                <div style={{ margin: "1em" }}>
                  <ValueLabel
                    value={moment(
                      UtilityHelper.unixTimeStampToDate(
                        development?.developmentDate ?? 0
                      )
                    ).format("DD.MMM.YY")}
                    label={"Date"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!dataLoaded && (
        <>
          <br />
          <LoadingTextPlaceHolder />
        </>
      )}
    </>
  );
};

export default PropertyDevelopment;
