import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import sc from './../services/stringconst';
import auth from "../services/authservice";

 const LoanApprovalCompleted =()=> {
const location= new useLocation();
const navigate= new useNavigate();

if(!location.state){
    navigate(sc.homeroute);
}

    return (
<>

<div className="section mt-2 text-center" style={{padding: "20% 10%"}}>
<div className="card">
            <div className="card-body">
                <h3> Loan Request Approved</h3> 
                </div>
                <div className="card-body">

                <ion-icon name="checkmark-circle-outline" style={{fontSize:"5em", color: "green"}}></ion-icon><br/>
                <span className="text-muted">Application Number</span><br/>#<span style={{fontSize:"1.1em"}}>{location.state}</span> 
                </div>
                </div>
<br/>

                <div className="form-group transparent">
                    <button type="submit" onClick={()=>navigate(sc.loansroute, {state:{isLender:true}})} className="btn btn-info btn-block">View Loans</button>
                </div>    
                </div>
</>
    );

}
export default LoanApprovalCompleted