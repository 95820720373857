import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import sc from "../../../services/stringconst";
import DashboardClient from "../../../httpClients/clients/dashboardClient";
import AdminDashboardDto from "../../../models/Dashboard/AdminDashboardDto";
import authservice from "../../../services/authservice";
import UserDashboardDto from "../../../models/Dashboard/UserDashboardDto";

type Props = {
  ShowAlert(message: string, title: string): any;
  setNotifications(messageCount: number, notificationCount: number): any;
};

const CustomerDashboard = ({ ShowAlert, setNotifications }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    GetDashboardData();
  }, []);

  let temp: UserDashboardDto = {};
  const [data, setData] = useState(temp);

  const GetDashboardData = async () => {
    setNotifications(3, 4);

    setData(
      await DashboardClient.UserDashboard(
        authservice.getCurrentUser()?._id ?? ""
      )
    );
  };

  return (
    <div id="appCapsule" className="extra-header-active full-height" style={{}}>
      <span style={{ color: "red" }}> a</span>
      <br />
    </div>
  );
};

export default CustomerDashboard;
