import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import moment from "moment";
import {
  IoAddOutline,
  IoArrowForwardOutline,
  IoCheckmark,
  IoCheckmarkOutline,
  IoChevronBackOutline,
  IoCloseOutline,
  IoConstruct,
  IoConstructOutline,
  IoCreateOutline,
  IoImages,
  IoImagesOutline,
  IoList,
  IoListOutline,
  IoMapOutline,
  IoNavigateOutline,
  IoTrashOutline,
} from "react-icons/io5";
import auth from "../../../services/authservice";
import ValueLabel from "../../childcomponents/valuelabel";
import LoadingTextPlaceHolder from "../../shared/Preload/loadingtextplaceholder";
import ConfirmModal from "../../childcomponents/confirmmodal";
import { useSelector } from "react-redux";
import { getUser } from "../../../store/store";
import propertyClient from "../../../httpClients/clients/propertyClient";
import PropertyDetailsDto from "../../../models/Property/propertyDetailsDto";
import UtilityHelper from "../../../Utilities/UtilityHelper";
import ValueLabelArray from "../../childcomponents/valuelabelarray";
import SwipeableViews from "react-swipeable-views";
import ErrorDto from "../../../models/AppFeature/ErrorDto";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";
import FileManager from "../../../services/FileManager";
import PropertyMediaDto from "../../../models/Property/PropertyMediaDto";
import { Buffer } from "buffer";
import InputEditModal from "../../childcomponents/inputeditmodal";
import SelectEditModal from "../../childcomponents/selecteditmodal";
import realtorClient from "../../../httpClients/clients/realtorClient";
import KeyValueDto from "../../../models/Shared/KeyValueDto";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const PropertyDetail = ({ ShowAlert }: Props) => {
  let location = useLocation();
  const propertyId = location.state.propertyId;
  let tabSelection = location.state.tabSelection;
  let backTabSelection = location.state.backTabSelection;
  let representativeId = location.state.representativeId;
  let realtorId = location.state.realtorId;

  let route = location.state.route;

  const user = useSelector(getUser);

  let propertyTemp: PropertyDetailsDto = {};
  const [property, setProperty] = useState(propertyTemp);
  const [dataLoaded, setDataLoaded] = useState(false);

  const addOption = [1, 2, 3, 4];

  const [displayTab, setDisplayTab] = useState(
    tabSelection ?? backTabSelection ?? 1
  );

  let mediasTemp: MediaDto[] = [];
  const [medias, setMedias] = useState(mediasTemp);
  let imageHolder: {
    url: string;
    mediaId: string | undefined;
    thumbnail: string;
    type: string | undefined;
  }[] = [];

  const [showDeleteAttributeConfirmModal, setShowDeleteAttributeConfirmModal] =
    useState(false);

  const [activeAttributeId, setActiveAttributeId] = useState("");

  const [showDeleteMediaConfirmModal, setShowDeleteMediaConfirmModal] =
    useState(false);

  const [activeMediaId, setActiveMediaId] = useState("");

  const cancelDeleteAttributeModal = () => {
    setShowDeleteAttributeConfirmModal(false);
    setActiveAttributeId("");
  };
  const cancelDeleteMediaModal = () => {
    setShowDeleteMediaConfirmModal(false);
    setActiveMediaId("");
  };
  const deleteAttribute = async () => {
    setShowDeleteAttributeConfirmModal(false);
    propertyClient.RemoveAttribute(propertyId, activeAttributeId).then(
      () => {
        setHttpProcessing(false);
        let attributeIndex =
          property.property?.attributes?.findIndex(
            (n) => n.attributeId == activeAttributeId
          ) ?? -1;
        if (attributeIndex > -1) {
          property.property?.attributes?.splice(attributeIndex, 1);
        }
        setActiveAttributeId("");

        ShowAlert("Attribute Removed", sc.success);
      },
      (error: ErrorDto) => {
        setHttpProcessing(false);
        ShowAlert(error.message, sc.warning);
      }
    );
  };
  const openDeleteAttributeModal = async (attributeId: string) => {
    if (!attributeId) {
      ShowAlert("Attribute is invalid", sc.danger);
      return;
    }

    setShowDeleteAttributeConfirmModal(true);
    setActiveAttributeId(attributeId);
  };
  const openDeleteMediaModal = async (mediaId: string) => {
    if (!mediaId) {
      ShowAlert("Media is invalid", sc.danger);
      return;
    }

    setShowDeleteMediaConfirmModal(true);
    setActiveMediaId(mediaId);
  };

  const handleSwipeChange = (e: number) => {
    setTimeout(function () {
      setDisplayTab(e + 1);
    }, 1);
  };

  const [showDeleteFacilityConfirmModal, setShowDeleteFacilityConfirmModal] =
    useState(false);

  const [activeFacilityId, setActiveFacilityId] = useState("");

  const [projectEndDate, setProjectEndDate] = useState(
    property?.property?.projectEndDate
  );
  const [availableCount, setAvailableCount] = useState(
    property?.property?.availableCount
  );
  const [contactPersonId, setContactPersonId] = useState(
    property?.contactPerson?._id
  );

  const [showContactPersonEditModal, setShowContactPersonEditModal] =
    useState(false);
  const [showProjectEndDateEditModal, setShowProjectEndDateEditModal] =
    useState(false);
  const [showUpdateUnitsAvailableModal, setShowUpdateUnitsAvailableModal] =
    useState(false);

  const cancelContactPersonEditModal = () => {
    setShowContactPersonEditModal(false);
  };
  const cancelProjectEndDateEditModal = () => {
    setShowProjectEndDateEditModal(false);
  };
  const cancelUpdateUnitsAvailableModal = () => {
    setShowUpdateUnitsAvailableModal(false);
  };
  const updateProjectEndDate = (projectEndDate: string) => {
    let projectEndDateValue = UtilityHelper.dateToUnixTimeStamp(
      new Date(projectEndDate)
    );
    propertyClient
      .UpdateProjectEndDate(propertyId, projectEndDateValue ?? 0)
      .then(
        () => {
          setHttpProcessing(false);
          if (property.property != null)
            property.property.projectEndDate = projectEndDateValue;
          setProperty(property);
          setProjectEndDate(projectEndDate);
          setShowProjectEndDateEditModal(false);
        },
        (error: ErrorDto) => {
          setHttpProcessing(false);
          ShowAlert(error.message, sc.warning);
        }
      );
  };
  const updateContactPerson = (contactPersonId: string) => {
    propertyClient
      .UpdateContactPersonId(propertyId, contactPersonId ?? "")
      .then(
        (data) => {
          setHttpProcessing(false);
          if (property.property != null) {
            property.property.contactPersonId = contactPersonId;
            property.contactPerson = data;
            setProperty(property);
          }

          setContactPersonId(contactPersonId);
          setShowContactPersonEditModal(false);
        },
        (error: ErrorDto) => {
          setHttpProcessing(false);
          setShowContactPersonEditModal(false);
          ShowAlert(error.message, sc.warning);
        }
      );
  };
  const updateUnitsAvailable = (unitsAvailable: string) => {
    const unitsAvailableValue = parseInt(unitsAvailable ?? "0");
    propertyClient
      .UpdatePropertyAvailableQty(propertyId, unitsAvailableValue)
      .then(
        (data) => {
          setHttpProcessing(false);
          if (property.property != null) {
            property.property.availableCount = unitsAvailableValue;
            setProperty(property);
          }
          setAvailableCount(unitsAvailableValue);
          setShowUpdateUnitsAvailableModal(false);
        },
        (error: ErrorDto) => {
          setHttpProcessing(false);
          setShowContactPersonEditModal(false);
          ShowAlert(error.message, sc.warning);
        }
      );
  };

  const cancelDeleteFacilityModal = () => {
    setShowDeleteFacilityConfirmModal(false);
    setActiveFacilityId("");
  };
  const deleteFacility = async () => {
    setShowDeleteFacilityConfirmModal(false);
    propertyClient.RemoveFacility(propertyId, activeFacilityId).then(
      () => {
        setHttpProcessing(false);
        let FacilityIndex =
          property.property?.facilities?.findIndex(
            (n) => n.facilityId == activeFacilityId
          ) ?? -1;
        if (FacilityIndex > -1) {
          property.property?.facilities?.splice(FacilityIndex, 1);
        }
        setActiveFacilityId("");

        ShowAlert("Facility Removed", sc.success);
      },
      (error: ErrorDto) => {
        setHttpProcessing(false);
        ShowAlert(error.message, sc.warning);
      }
    );
  };
  const openDeleteFacilityModal = async (FacilityId: string) => {
    if (!FacilityId) {
      ShowAlert("Facility is invalid", sc.danger);
      return;
    }

    setShowDeleteFacilityConfirmModal(true);
    setActiveFacilityId(FacilityId);
  };

  let navigate = useNavigate();
  const [httpProcessing, setHttpProcessing] = useState(false);

  useEffect(() => {
    GetProperty();
  }, []);

  const GetProperty = () => {
    propertyClient.GetPropertyDetails(propertyId).then(
      (data) => {
        setDataLoaded(true);
        setProperty(data);
        setContactPersonId(data.contactPerson?._id);
        setProjectEndDate(
          UtilityHelper.unixTimeStampToDate(data.property?.projectEndDate)
        );

        getContactPersons(data.property?.realtorId ?? "");

        if (data.property?.media && data.property.media?.length > 0) {
          const mediasTemp: MediaDto[] = [];
          data.property?.media.forEach((item) => {
            const url = FileManager.getPropertyMediaUrl(
              item.mediaId ?? "",
              propertyId,
              item.type ?? ""
            );
            const thumbnail = FileManager.getPropertyMediaUrl(
              item.mediaId ?? "",
              propertyId,
              sc.image
            );

            mediasTemp.push({
              url: url,
              mediaId: item.mediaId,
              thumbnail: thumbnail,
              type: item.type,
              default: item.default,
            });
          });
          setMedias(mediasTemp);
        }
      },
      (error) => {
        setDataLoaded(true);
        ShowAlert(error.response, sc.danger);
      }
    );
  };
  const selectMediaFiles = async (e: any) => {
    mediasTemp = [];
    imageHolder = [];
    if (e.target.files) {
      let batchCount = e.target.files.length;
      for (let i = 0; i < e.target.files.length; i++) {
        const mediaType =
          e.target.files[i].type.indexOf(sc.video) > -1 ? sc.video : sc.image;
        const mediaId = UtilityHelper.UniqueIdGenerator();
        if (mediaType == sc.video) {
          FileManager.uploadMediaAsync(
            e.target.files[i],
            mediaId + sc.mp4Ext,
            FileManager.getPropertyMediaPath(propertyId)
          ).then((result) => {
            addPropertyMedia(mediaId, mediaType, batchCount, true);
          });

          generateVideoThumbnails(e.target.files[i], 1, "base64").then(
            (thumbs) => {
              const file = Buffer.from(
                thumbs[0].replace(/^data:image\/\w+;base64,/, ""),
                "base64"
              );

              FileManager.uploadMediaAsync(
                file,
                mediaId + sc.jpgExt,
                FileManager.getPropertyMediaPath(propertyId)
              ).then((result) => {
                addPropertyMedia(mediaId, sc.image, batchCount, false);
              });
            }
          );
        } else {
          FileManager.uploadMediaAsync(
            e.target.files[i],
            mediaId + sc.jpgExt,
            FileManager.getPropertyMediaPath(propertyId)
          ).then((result) => {
            addPropertyMedia(mediaId, mediaType, batchCount, true);
          });
        }
      }
    }
  };

  const addPropertyMedia = (
    mediaId: string,
    mediaType: string,
    batchCount: number,
    saveToDb: boolean
  ) => {
    const url = FileManager.getPropertyMediaUrl(
      mediaId ?? "",
      propertyId,
      mediaType ?? ""
    );
    const thumbnail = FileManager.getPropertyMediaUrl(
      mediaId ?? "",
      propertyId,
      sc.image
    );
    if (mediaType == sc.image) {
      imageHolder.push({
        url: url,
        mediaId: mediaId,
        thumbnail: thumbnail,
        type: mediaType,
      });
    }

    let propertyMedia: PropertyMediaDto = {
      mediaId: mediaId,
      type: mediaType,
      default: false,
    };

    if (saveToDb) {
      mediasTemp.push(propertyMedia);
    }

    if (batchCount == mediasTemp.length) {
      propertyClient
        .AddPropertyMedia(propertyId, mediasTemp)
        .then((data: PropertyMediaDto[]) => {
          mediasTemp = [];
        });
    }

    if (batchCount == imageHolder.length) {
      let tempMedias = [...medias];
      imageHolder.forEach((element) => {
        tempMedias.push(element);
      });
      setMedias(tempMedias);
      imageHolder = [];
    }
  };

  const deleteMedia = () => {
    propertyClient.RemovePropertyMedia(propertyId, activeMediaId).then(
      () => {
        setHttpProcessing(false);

        let tempMedias = [...medias];
        let tempMediaIndex =
          tempMedias.findIndex((n) => n.mediaId == activeMediaId) ?? -1;
        if (tempMediaIndex > -1) {
          tempMedias.splice(tempMediaIndex, 1);
        }
        setMedias(tempMedias);
        property.property = property.property ?? {};
        property.property.media = tempMedias;

        setActiveMediaId("");
        setShowDeleteMediaConfirmModal(false);
        ShowAlert("Media Removed", sc.success);
      },
      (error: ErrorDto) => {
        setHttpProcessing(false);
        ShowAlert(error.message, sc.warning);
      }
    );
  };

  const back = () => {
    navigate(-1);
  };
  const showUpdateContactPerson = () => {
    setShowContactPersonEditModal(true);
  };
  const showUpdateProjectEndDate = () => {
    setShowProjectEndDateEditModal(true);
  };
  const showUpdateUnitsAvailable = () => {
    setShowUpdateUnitsAvailableModal(true);
  };

  const setDisplay = (tab: number) => {
    setDisplayTab(tab);
  };

  const openMedia = (media: MediaDto) => {
    if (media.type === sc.image) return;
  };

  const addItem = () => {
    if (displayTab === 1) {
      navigate(sc.addfacilityroute, {
        state: { propertyId: propertyId },
      });
    }
    if (displayTab === 2) {
      navigate(sc.addattributeroute, {
        state: { propertyId: propertyId },
      });
    }
    if (displayTab === 3) {
      $("#mediaInput").click();
    }
    if (displayTab === 4) {
      navigate(sc.addpropertydevelopmentroute, {
        state: { propertyId: propertyId },
      });
    }
  };

  const viewPropertyDevelopment = (propertyDevelopmentId: string) => {
    navigate(sc.propertydevelopmentdetailsroute, {
      state: {
        propertyId: propertyId,
        propertyDevelopmentId: propertyDevelopmentId,
      },
    });
  };

  const setDefaultMedia = (mediaId: string) => {
    let mediasTemp = [...medias];
    mediasTemp.forEach((o) => {
      o.default = o.mediaId == mediaId;
    });
    setMedias(mediasTemp);
    propertyClient.SetDefaultMedia(propertyId, mediaId).then(
      () => {},
      (error: ErrorDto) => {
        ShowAlert(error.message, sc.warning);
      }
    );
  };

  let contactPersonsKvpTemp: KeyValueDto[] = [];
  const [contactPersonsKvp, setContactPersonsKvp] = useState(
    contactPersonsKvpTemp
  );

  const getContactPersons = (realorId: string) => {
    realtorClient.GetRealtorContactPersons(realorId).then(
      (data) => {
        data.forEach((item) => {
          contactPersonsKvpTemp.push({
            _id: item._id ?? "",
            value: `${item.firstname} ${item.lastname}`,
          });
        });
        setContactPersonsKvp(contactPersonsKvpTemp);
      },
      (error) => {
        ShowAlert(error.response, sc.danger);
      }
    );
  };

  return (
    <>
      {dataLoaded && (
        <div>
          <div
            style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
            className="appHeader bg-primary-main  bg-primary text-light"
          >
            <div className="left">
              <a onClick={back} className="headerButton goBack">
                <IoChevronBackOutline size={sc.IconSize15} /> Back
              </a>
            </div>
            <div className="text-muted-medium">Property</div>
          </div>
          <div id="appCapsule" className="extra-header-actives">
            <div>
              <div>
                <div style={{ margin: "1em" }}>
                  {" "}
                  <ValueLabel
                    value={property.property?.title ?? ""}
                    label={"Title"}
                  />
                  <ValueLabel
                    value={property.property?.description ?? ""}
                    label={"Description"}
                  />
                  <ValueLabelArray
                    values={[
                      property.property?.location?.address ?? "",
                      `${property.property?.location?.city ?? ""}, ${
                        property.property?.location?.state ?? ""
                      }`,
                    ]}
                    label={"Address"}
                  />
                  <a
                    target="blank"
                    style={{ float: "right", marginTop: "-3.3em" }}
                    href={`http://maps.google.com/maps?z=12&t=m&q=loc:${property.property?.location?.latitude}+${property.property?.location?.longitude}`}
                  >
                    <span className="text-primary-main">
                      <IoNavigateOutline size={sc.IconSize25} />
                    </span>{" "}
                  </a>
                </div>
                <div className="carpet-color-bg">
                  <div style={{ margin: "1em", padding: "0.5px 0" }}>
                    <table>
                      <tr>
                        <td>
                          <ValueLabel
                            value={`${
                              property.contactPerson?.firstname ?? ""
                            } ${property.contactPerson?.lastname ?? ""}`}
                            label={"Contact Person"}
                          />
                        </td>
                        <td>
                          <IoCreateOutline
                            style={{ marginTop: "22px", marginLeft: "10px" }}
                            size={sc.IconSize15}
                            onClick={showUpdateContactPerson}
                          />
                        </td>
                      </tr>
                    </table>

                    <ValueLabel
                      value={property.realtor ?? ""}
                      label={"Realtor"}
                    />
                  </div>
                </div>
                <div style={{ margin: "1em", padding: "0.5px 0" }}>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td style={{ width: "50%" }}>
                        <ValueLabel
                          value={property.property?.propertyType ?? ""}
                          label={"Property Type"}
                        />
                      </td>
                      <td style={{ width: "50%" }}>
                        <ValueLabel
                          value={property.property?.requestOption ?? ""}
                          label={"Request Option"}
                        />
                      </td>
                    </tr>
                  </table>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td style={{ width: "50%" }}>
                        <ValueLabel
                          value={moment(
                            UtilityHelper.unixTimeStampToDate(
                              property.property?.projectStartDate
                            )
                          ).format("DD.MMM.YY")}
                          label={"Project Start"}
                        />
                      </td>
                      <td style={{ width: "50%" }}>
                        {property.property?.estimatedProjectEndDate &&
                          !property.property?.projectEndDate && (
                            <table>
                              <tr>
                                <td>
                                  <ValueLabel
                                    value={moment(
                                      UtilityHelper.unixTimeStampToDate(
                                        property.property
                                          ?.estimatedProjectEndDate
                                      )
                                    ).format("DD.MMM.YY")}
                                    label={"Estimated Completion"}
                                  />
                                </td>
                                <td>
                                  <IoCreateOutline
                                    style={{
                                      marginTop: "20px",
                                      marginLeft: "10px",
                                    }}
                                    size={sc.IconSize15}
                                    onClick={showUpdateProjectEndDate}
                                  />
                                </td>
                              </tr>
                            </table>
                          )}
                        {property.property?.projectEndDate && (
                          <ValueLabel
                            value={moment(
                              UtilityHelper.unixTimeStampToDate(
                                property.property?.projectEndDate
                              )
                            ).format("DD.MMM.YY")}
                            label={"Completion Date"}
                          />
                        )}
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="carpet-color-bg">
                  <div style={{ margin: "1em", padding: "0.5px 0" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td style={{ width: "50%" }}>
                          {!property.property?.previousRealtorPrice && (
                            <ValueLabel
                              value={UtilityHelper.commaCurrencyFormatter(
                                property.property?.realtorPrice?.toString() ??
                                  "0"
                              )}
                              label={"Realtor Price"}
                            />
                          )}
                          {property.property?.previousRealtorPrice && (
                            <ValueLabelArray
                              values={[
                                `Prev ${UtilityHelper.commaCurrencyFormatter(
                                  property.property?.previousRealtorPrice?.toString() ??
                                    "0"
                                )}`,
                                `New ${UtilityHelper.commaCurrencyFormatter(
                                  property.property?.realtorPrice?.toString() ??
                                    "0"
                                )}`,
                              ]}
                              label={"Realtor Price"}
                            />
                          )}
                        </td>
                        <td style={{ width: "50%" }}>
                          <ValueLabel
                            value={UtilityHelper.commaCurrencyFormatter(
                              property.property?.minEquityAmount?.toString() ??
                                "0"
                            )}
                            label={"Min Equity Amount"}
                          />
                        </td>
                      </tr>
                    </table>

                    <table style={{ width: "100%" }}>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <ValueLabel
                            value={`${
                              property.property?.maxInterestPecentage?.toString() ??
                              "0"
                            } %`}
                            label={"Max Interest"}
                          />
                        </td>
                        <td style={{ width: "50%" }}>
                          <ValueLabel
                            value={`${
                              property.property?.maxPaymentDuration?.toString() ??
                              "0"
                            } Month(s)`}
                            label={"Max Payment Duration"}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div style={{ margin: "1em", padding: "0.5px 0" }}>
                  {" "}
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td style={{ width: "50%" }}>
                        <table>
                          <tr>
                            <td>
                              <ValueLabel
                                value={`${
                                  property.property?.availableCount?.toString() ??
                                  "0"
                                } Available`}
                                label={`Units (${
                                  property.property?.Quantity?.toString() ?? "0"
                                })`}
                              />
                            </td>
                            <td>
                              <IoCreateOutline
                                style={{
                                  marginTop: "22px",
                                  marginLeft: "10px",
                                }}
                                size={sc.IconSize15}
                                onClick={showUpdateUnitsAvailable}
                              />
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td style={{ width: "50%" }}>
                        <ValueLabel
                          value={
                            `${UtilityHelper.formatToYearMonth(
                              property.property?.stayDuration
                            )}` ?? "Max"
                          }
                          label={"Stay Duration"}
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div
              className="horizontal-menu-bg"
              style={{
                borderRadius: 25,
                width: "90%",
                marginLeft: "5%",
              }}
            >
              <ul
                className="nav nav-tabs lined horizontal-menu-bg"
                role="tablist"
                style={{
                  borderRadius: 25,
                  width: "90%",
                  marginLeft: "5%",
                }}
              >
                <li className="nav-item">
                  <a
                    className={
                      displayTab === 1 ? "nav-link active" : "nav-link"
                    }
                    onClick={() => setDisplay(1)}
                  >
                    <span
                      className={`text-muted-small ${
                        displayTab === 1 ? "nav-link-active-text" : ""
                      }`}
                    >
                      <IoCheckmark size={sc.IconSize20} />
                      <br />
                      <span style={{ fontSize: sc.IconLabel9px }}>
                        Facilities
                      </span>
                    </span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      displayTab === 2 ? "nav-link active" : "nav-link"
                    }
                    onClick={() => setDisplay(2)}
                  >
                    <span
                      className={`text-muted-small ${
                        displayTab === 2 ? "nav-link-active-text" : ""
                      }`}
                    >
                      <IoList size={sc.IconSize20} />
                      <br />
                      <span style={{ fontSize: sc.IconLabel9px }}>
                        Attributes
                      </span>
                    </span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      displayTab === 3 ? "nav-link active" : "nav-link"
                    }
                    onClick={() => setDisplay(3)}
                  >
                    <span
                      className={`text-muted-small ${
                        displayTab === 3 ? "nav-link-active-text" : ""
                      }`}
                    >
                      <IoImages size={sc.IconSize20} />
                      <br />
                      <span style={{ fontSize: sc.IconLabel9px }}>Media</span>
                    </span>
                  </a>
                </li>
                <li className="nav-item text-muted-small">
                  <a
                    className={
                      displayTab === 4 ? "nav-link active" : "nav-link"
                    }
                    onClick={() => setDisplay(4)}
                  >
                    <span
                      className={`text-muted-small ${
                        displayTab === 4 ? "nav-link-active-text" : ""
                      }`}
                    >
                      <IoConstruct size={sc.IconSize20} />
                      <br />
                      <span style={{ fontSize: sc.IconLabel9px }}>
                        Developments
                      </span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <input
              type="file"
              hidden
              onChange={selectMediaFiles}
              multiple
              accept="image/*,video/*"
              id="mediaInput"
            ></input>
            {addOption.indexOf(displayTab) > -1 && (
              <span
                className="btn btn-primary rounded"
                onClick={addItem}
                style={{
                  padding: 16,
                  float: "right",
                  position: "fixed",
                  right: 10,
                  top: "87%",
                  zIndex: 1001,
                }}
              >
                <IoAddOutline size={sc.IconSize25} />{" "}
                <span className="text-muted">
                  {" "}
                  {displayTab === 1 && (
                    <IoCheckmarkOutline size={sc.IconSize15} />
                  )}
                  {displayTab === 2 && <IoListOutline size={sc.IconSize15} />}
                  {displayTab === 3 && <IoImagesOutline size={sc.IconSize15} />}
                  {displayTab === 4 && (
                    <IoConstructOutline size={sc.IconSize15} />
                  )}
                </span>
              </span>
            )}
            <br />
            <SwipeableViews
              index={displayTab - 1}
              onChangeIndex={handleSwipeChange}
              style={{ clear: "both", margin: "0 5%" }}
            >
              <div style={Object.assign({})}>
                <ul className="listview image-listview">
                  {property.property?.facilities?.map((item) => (
                    <li key={item.facilityId}>
                      <div className="item">
                        <img
                          src={item.icon ? item.icon : auth.getFacilityUrl()}
                          alt="image"
                          className="image"
                        />
                        <div className="in">
                          <div>
                            <span className="text-muted-dark">
                              {item.name}{" "}
                            </span>
                            <br />
                            <span className="text-muted">{item.value}</span>
                          </div>
                        </div>
                      </div>
                      <span
                        className="text-muted"
                        onClick={() =>
                          openDeleteFacilityModal(item.facilityId ?? "")
                        }
                        style={{ padding: 0, float: "right" }}
                      >
                        <IoTrashOutline size={sc.IconSize15} />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <div style={Object.assign({})}>
                <ul className="listview">
                  {property.property?.attributes?.map((item) => (
                    <li key={item.attributeId}>
                      <div className="item">
                        <div className="in">
                          <div>
                            <span className="text-muted-dark">
                              {item.name}{" "}
                            </span>

                            <br />
                            <span className="text-muted">{item.value}</span>
                          </div>
                        </div>
                      </div>
                      <span
                        className="text-muted"
                        onClick={() =>
                          openDeleteAttributeModal(item.attributeId ?? "")
                        }
                        style={{ padding: 0, float: "right" }}
                      >
                        <IoTrashOutline size={sc.IconSize15} />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="row">
                {medias.map((media) => (
                  <div
                    className="col-md-6"
                    style={{
                      marginBottom: "20px",
                      overflow: "hidden",
                    }}
                    onClick={() => openMedia(media)}
                  >
                    <table
                      className="appCapsule-bg"
                      style={{
                        zIndex: "1",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <tr>
                        <td>
                          {media.type == sc.image && (
                            <div>
                              {" "}
                              <span className="text-muted">Default Image </span>
                              <input
                                id="mediaId"
                                type="checkbox"
                                checked={media.default}
                                readOnly={media.default}
                                onChange={() =>
                                  setDefaultMedia(media.mediaId ?? "")
                                }
                              ></input>
                            </div>
                          )}
                        </td>
                        <td>
                          <IoCloseOutline
                            size={sc.IconSize20}
                            style={{
                              float: "right",
                              color: "purple",
                              background: "gold",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              openDeleteMediaModal(media.mediaId ?? "")
                            }
                          />
                        </td>
                      </tr>
                    </table>

                    {media.type == sc.image && (
                      <img
                        className=""
                        src={media.thumbnail}
                        style={{
                          width: "100%",
                          marginTop: "-20px",
                        }}
                      />
                    )}
                    {media.type == sc.video && (
                      <video width="320" height="240" controls>
                        <source src={media.url} type="video/mp4" />
                        <source src={media.url} type="video/ogg" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>
                ))}
              </div>
              {user.isAdmin && (
                <div style={Object.assign({})}>
                  <ul className="listview">
                    {property.property?.propertyDevelopments?.map((item) => (
                      <li key={item.propertyDevelopmentId}>
                        <div className="item">
                          <div className="in">
                            <div>
                              <span className="text-muted-dark">
                                {item.title}{" "}
                              </span>

                              <br />
                              <span className="text-muted">
                                {item.description}
                              </span>
                            </div>
                          </div>
                        </div>
                        <span
                          className="text-muted"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            viewPropertyDevelopment(
                              item.propertyDevelopmentId ?? ""
                            )
                          }
                        >
                          <IoArrowForwardOutline size={sc.IconSize15} />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </SwipeableViews>
          </div>
        </div>
      )}
      {!dataLoaded && (
        <>
          <br />
          <LoadingTextPlaceHolder />
        </>
      )}

      {showDeleteFacilityConfirmModal && (
        <div>
          <div>
            <ConfirmModal
              button1="Remove"
              button2="Cancel"
              action1={deleteFacility}
              action2={cancelDeleteFacilityModal}
              httpProcessing={httpProcessing}
              color={sc.dangerBorder}
            />
          </div>
        </div>
      )}
      {showDeleteAttributeConfirmModal && (
        <div>
          <div>
            <ConfirmModal
              button1="Remove"
              button2="Cancel"
              action1={deleteAttribute}
              action2={cancelDeleteAttributeModal}
              httpProcessing={httpProcessing}
              color={sc.dangerBorder}
            />
          </div>
        </div>
      )}
      {showDeleteMediaConfirmModal && (
        <div>
          <div>
            <ConfirmModal
              button1="Remove"
              button2="Cancel"
              action1={deleteMedia}
              action2={cancelDeleteMediaModal}
              httpProcessing={httpProcessing}
              color={sc.dangerBorder}
            />
          </div>
        </div>
      )}
      {showContactPersonEditModal && (
        <div>
          <div>
            <SelectEditModal
              button1="Update"
              button2="Cancel"
              action1={updateContactPerson}
              action2={cancelContactPersonEditModal}
              httpProcessing={httpProcessing}
              color={sc.primaryBorder}
              value={contactPersonId ?? ""}
              label="Select Contact Person"
              items={contactPersonsKvp}
            />
          </div>
        </div>
      )}
      {showUpdateUnitsAvailableModal && (
        <div>
          <div>
            <InputEditModal
              button1="Update"
              button2="Cancel"
              action1={updateUnitsAvailable}
              action2={cancelUpdateUnitsAvailableModal}
              httpProcessing={httpProcessing}
              color={sc.primaryBorder}
              value={property.property?.availableCount?.toString() ?? ""}
              label="Available Unit"
              inputType="number"
            />
          </div>
        </div>
      )}
      {showProjectEndDateEditModal && (
        <div>
          <div>
            <InputEditModal
              button1="Update"
              button2="Cancel"
              action1={updateProjectEndDate}
              action2={cancelProjectEndDateEditModal}
              httpProcessing={httpProcessing}
              color={sc.primaryBorder}
              value={projectEndDate}
              label="Project end date"
              inputType="date"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PropertyDetail;
