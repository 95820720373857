import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import auth from "../../../services/authservice";
import CustomButton from "../../childcomponents/custombutton";
import { IoChevronBackOutline } from "react-icons/io5";
import propertyClient from "../../../httpClients/clients/propertyClient";
import FacilityDto from "../../../models/Property/FacilityDto";
import ValueLabel from "../../childcomponents/valuelabel";
import PropertyTypeDto from "../../../models/Property/PropertyTypeDto";
import ErrorDto from "../../../models/AppFeature/ErrorDto";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const AddPropertyFacility = ({ ShowAlert }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const propertyId = location.state.propertyId;
  const [httpProcessing, setHttpProcessing] = useState(false);

  const back = () => {
    navigate(sc.propertydetailsroute, {
      state: { propertyId: propertyId, tabSelection: 1 },
    });
  };

  useEffect(() => {
    propertyClient.GetFacilities().then((result) => {
      console.log(result);
      setFacilities(result);
    });
  }, []);

  let facilitiesTemp: FacilityDto[] = [];
  const [facilities, setFacilities] = useState(facilitiesTemp);

  const [facilityId, setFacilityId] = useState();
  const [facilityText, setFacilityText] = useState();

  const [position, setPosition] = useState();

  const setFacilityIdValue = (e: any) => {
    setFacilityId(e.target.value);
  };
  const setFacilityTextValue = (e: any) => {
    setFacilityText(e.target.value);
  };
  const setPositionValue = (e: any) => {
    setPosition(e.target.value);
  };

  const addFacility = async () => {
    let facility = facilities.find((n) => n._id == facilityId);

    if (!facility) {
      ShowAlert("Facility" + sc.isRequired, sc.warning);
      return;
    }

    facility.facilityId = facilityId;
    try {
      if (!facility?.facilityId) {
        ShowAlert("Facility" + sc.isRequired, sc.warning);
        return;
      }
      facility.value = facilityText;
      facility.position = position;

      if (!facility.name) {
        ShowAlert("Facility name" + sc.isRequired, sc.warning);
        return;
      }
      if (!facility.value) {
        ShowAlert("Facility value" + sc.isRequired, sc.warning);
        return;
      }
      if (!facility.icon) {
        ShowAlert("Facility icon is missing in facility setup", sc.warning);
        return;
      }
      if (!facility.position) {
        ShowAlert("Facility position" + sc.isRequired, sc.warning);
        return;
      }

      setHttpProcessing(true);

      propertyClient.AddPropertyFacility(propertyId, facility).then(
        () => {
          setHttpProcessing(false);
        },
        (error: ErrorDto) => {
          setHttpProcessing(false);
          ShowAlert(error.message, sc.warning);
        }
      );

      setHttpProcessing(false);

      navigate(sc.propertydetailsroute, {
        state: { propertyId: propertyId, tabSelection: 1 },
      });
    } catch (e: any) {
      setHttpProcessing(false);
      ShowAlert(e.message, sc.warning);
    }
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <IoChevronBackOutline size={sc.IconSize15} /> Back
          </a>
        </div>
        <div className="text-muted-medium">Facility</div>
      </div>

      <div
        id="appCapsule"
        className="extra-header-active full-height"
        style={{ background: "white" }}
      >
        <div>
          <div className="card-body pb-1">
            <div className="form-group basic">
              <div className="input-wrapper">
                <label className="label" htmlFor="facility">
                  {facilityId ? "Select Facility" : <span>&nbsp;</span>}
                </label>

                <select
                  required
                  onChange={setFacilityIdValue}
                  value={facilityId}
                  className="form-control custom-select"
                  id="facilityId"
                >
                  <option key="" value="" selected disabled hidden>
                    Select Facility
                  </option>
                  {facilities.map((facility) => (
                    <option key={facility._id} value={facility._id}>
                      {facility.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group basic">
              <label className="label" htmlFor="name">
                Value
              </label>
              <input
                required
                onChange={setFacilityTextValue}
                value={facilityText}
                id="value"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>

            <br />
            <div className="form-group basic">
              <label className="label" htmlFor="name">
                Position
              </label>
              <input
                required
                type="number"
                onChange={setPositionValue}
                value={position}
                id="position"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>

            <br />

            <div>
              <CustomButton
                httpProcessing={httpProcessing}
                text="Add"
                onClick={addFacility}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddPropertyFacility;
