import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import sc from "../../../services/stringconst";
import DashboardClient from "../../../httpClients/clients/dashboardClient";
import AdminDashboardDto from "../../../models/Dashboard/AdminDashboardDto";

type Props = {
  ShowAlert(message: string, title: string): any;
  setNotifications(messageCount: number, notificationCount: number): any;
};

const AdminDashboard = ({ ShowAlert, setNotifications }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    GetDashboardData();
  }, []);

  let temp: AdminDashboardDto = {};
  const [data, setData] = useState(temp);

  const GetDashboardData = async () => {
    setNotifications(3, 4);

    setData(await DashboardClient.AdminDashboard());
  };

  return (
    <div id="appCapsule" className="extra-header-active full-height">
      <div className="section wallet-card-section pt-1">
        <div className="wallet-card">
          <div className="section">
            <div className="row mt-2">
              <div
                className="col-6"
                onClick={() => navigate(sc.findpropertyroute)}
              >
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>{data.userCount ?? 0}</span>
                  <h6>Users</h6>
                </div>
              </div>
              <div
                className="col-6"
                onClick={() =>
                  navigate(sc.loansroute, {
                    state: { isLender: false, isActive: true },
                  })
                }
              >
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>
                    {data.realtorCount ?? 0}
                  </span>
                  <h6>Realtors</h6>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div
                className="col-6"
                onClick={() =>
                  navigate(sc.loansroute, {
                    state: { isLender: false, isPending: true },
                  })
                }
              >
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>
                    {data.propertyCount ?? 0}
                  </span>
                  <h6>Properties</h6>
                </div>
              </div>
              <div
                className="col-6"
                onClick={() =>
                  navigate(sc.loansroute, {
                    state: { isLender: false, isRejected: true },
                  })
                }
              >
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>
                    {data.propertyRequestCount ?? 0}
                  </span>
                  <h6>Requests</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div>
          <div className="section">
            Property Request
            <hr />
            <div className="row mt-2">
              <div
                className="col-6"
                onClick={() => navigate(sc.findpropertyroute)}
              >
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>
                    {data.newPropertyRequestCount ?? 0}
                  </span>
                  <h6>New</h6>
                </div>
              </div>
              <div
                className="col-6"
                onClick={() =>
                  navigate(sc.loansroute, {
                    state: { isLender: false, isActive: true },
                  })
                }
              >
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>
                    {data.cancelledPropertyRequestCount ?? 0}
                  </span>
                  <h6>Request</h6>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div
                className="col-6"
                onClick={() =>
                  navigate(sc.loansroute, {
                    state: { isLender: false, isPending: true },
                  })
                }
              >
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>
                    {data.contractSignedPropertyRequestCount ?? 0}
                  </span>
                  <h6>Contract</h6>
                </div>
              </div>
              <div
                className="col-6"
                onClick={() =>
                  navigate(sc.loansroute, {
                    state: { isLender: false, isRejected: true },
                  })
                }
              >
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>
                    {data.movedInPropertyRequestCount ?? 0}
                  </span>
                  <h6>Moved-In</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div>
          <div className="section">
            Payments
            <hr />
            <div className="row mt-2">
              <div
                className="col-6"
                onClick={() => navigate(sc.findpropertyroute)}
              >
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>
                    {data.activeLoanCount ?? 0}
                  </span>
                  <h6>Active</h6>
                </div>
              </div>
              <div
                className="col-6"
                onClick={() =>
                  navigate(sc.loansroute, {
                    state: { isLender: false, isActive: true },
                  })
                }
              >
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>
                    {data.equityPaidPropertyRequestCount ?? 0}
                  </span>
                  <h6>Equity Paid</h6>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div
                className="col-6"
                onClick={() =>
                  navigate(sc.loansroute, {
                    state: { isLender: false, isPending: true },
                  })
                }
              >
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>
                    {data.paidAmount ?? 0}
                  </span>
                  <h6>Amount Paid</h6>
                </div>
              </div>
              <div
                className="col-6"
                onClick={() =>
                  navigate(sc.loansroute, {
                    state: { isLender: false, isRejected: true },
                  })
                }
              >
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>
                    {data.overduePaymentCount ?? 0}
                  </span>
                  <h6>Overdue Payment</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="section">
            <div className="row mt-2">
              <div
                className="col-6"
                onClick={() => navigate(sc.findpropertyroute)}
              >
                <div className="bill-box">
                  <span style={{ fontSize: "2em" }}>
                    {data.overduePaymentAmount ?? 0}
                  </span>
                  <h6>Overdue Amount</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default AdminDashboard;
