import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import auth from "../../../services/authservice";
import CustomButton from "../../childcomponents/custombutton";
import { IoChevronBackOutline } from "react-icons/io5";
import propertyClient from "../../../httpClients/clients/propertyClient";
import PropertyTypeDto from "../../../models/Property/PropertyTypeDto";
import ErrorDto from "../../../models/AppFeature/ErrorDto";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const AddPropertyType = ({ ShowAlert }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [httpProcessing, setHttpProcessing] = useState(false);

  const back = () => {
    navigate(sc.propertytypesroute);
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setPropertyType((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  let propertyTypeTemp: PropertyTypeDto = {};
  const [propertyType, setPropertyType] = useState(propertyTypeTemp);

  const addPropertyType = async () => {
    try {
      if (!propertyType.title) {
        ShowAlert(sc.titlerequired, sc.warning);
        return;
      }

      setHttpProcessing(true);

      propertyClient.CreatePropertyType(propertyType).then(
        () => {
          setHttpProcessing(false);
        },
        (error: ErrorDto) => {
          setHttpProcessing(false);
          ShowAlert(error.message, sc.warning);
        }
      );

      setHttpProcessing(false);

      navigate(sc.propertytypesroute);
    } catch (e: any) {
      setHttpProcessing(false);
      ShowAlert(e.message, sc.warning);
    }
  };

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <IoChevronBackOutline size={sc.IconSize15} /> Back
          </a>
        </div>
        <div className="text-muted-medium">Property Type</div>
      </div>

      <div
        id="appCapsule"
        className="extra-header-active full-height"
        style={{ background: "white" }}
      >
        <div>
          <div className="card-body pb-1">
            <div className="form-group basic">
              <label className="label" htmlFor="title">
                Property Type
              </label>
              <input
                required
                value={propertyType.title}
                onChange={handleChange}
                id="title"
                style={{ width: "100%" }}
                className="form-control"
              />
            </div>

            <br />

            <div>
              <CustomButton
                httpProcessing={httpProcessing}
                text="Add"
                onClick={addPropertyType}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddPropertyType;
