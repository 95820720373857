import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import sc from "../../../services/stringconst";
import auth from "../../../services/authservice";
import {
  IoArrowForwardOutline,
  IoChevronBackOutline,
  IoCloseOutline,
  IoOpenOutline,
  IoPauseOutline,
} from "react-icons/io5";
import FileManager from "../../../services/FileManager";
import ErrorDto from "../../../models/AppFeature/ErrorDto";
import UserDetailDto from "../../../models/User/UserDetailDto";
import ValueLabel from "../../childcomponents/valuelabel";
import UserClient from "../../../httpClients/clients/userClient";
import LoadingTextPlaceHolder from "../../shared/Preload/loadingtextplaceholder";
import ValueLabelArray from "../../childcomponents/valuelabelarray";

type Props = {
  ShowAlert(message: string, title: string): any;
};
const CustomerDetails = ({ ShowAlert }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  if (!location.state) {
    navigate(sc.customersroute);
  }
  let customerId = location.state.customerId;
  let realtorId = location.state.realtorId;
  let propertyRequestId = location.state.propertyRequestId;
  let route = location.state.route;

  let userTemp: UserDetailDto = {};
  const [user, setUser] = useState(userTemp);
  const [httpProcessing, setHttpProcessing] = useState(false);

  const back = () => {
    navigate(route, {
      state: {
        customerId: customerId,
        realtorId: realtorId,
        propertyRequestId,
      },
    });
  };

  const [url, setUrl] = useState(auth.getUserProfileImageUrl());

  useEffect(() => {
    GetCustomer();
  }, []);

  const [dataLoaded, setDataLoaded] = useState(false);

  const GetCustomer = () => {
    UserClient.getCustomerDetails(customerId).then(
      (result: UserDetailDto) => {
        setDataLoaded(true);
        setUser(result);
      },
      (error: ErrorDto) => {
        setDataLoaded(true);
        ShowAlert(error.message, sc.warning);
      }
    );
  };

  const OpenPropertyRequest = (propertyRequestId: string) => {};

  return (
    <>
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main  bg-primary text-light"
      >
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <IoChevronBackOutline size={sc.IconSize15} />
          </a>
        </div>
        <div className="text-muted-medium">
          Customer
          <span className="text-muted">
            {user.user?.status == sc.Disabled && (
              <span>
                <IoCloseOutline size={sc.IconSize20} />
              </span>
            )}
            {user.user?.status == sc.Pending && (
              <span>
                <IoPauseOutline size={sc.IconSize20} />
              </span>
            )}
          </span>
        </div>
      </div>

      <div
        id="appCapsule"
        className="extra-header-active full-height"
        style={{ background: "white" }}
      >
        <div>
          <div className="section mt-3 text-center">
            <div className="avatar-section">
              <a>
                <img
                  src={url}
                  alt="avatar"
                  className="imaged rounded"
                  style={{ width: "8em", height: "8em" }}
                  data-bs-toggle="modal"
                  data-bs-target="#DialogImage"
                />
              </a>
            </div>
          </div>
          <div>
            <div>
              <div>
                <div className="carpet-color-bg">
                  <div style={{ margin: "1em", padding: "0.5px 0" }}>
                    <ValueLabel
                      label="Firstname"
                      value={user.user?.firstname}
                    />

                    <ValueLabel label="Lastname" value={user.user?.lastname} />
                  </div>
                </div>
                <div style={{ margin: "1em" }}>
                  <ValueLabel
                    label="Mobile Number"
                    value={user.user?.mobileNumber}
                  />

                  <ValueLabel label="Email" value={user.user?.email} />
                </div>

                <div className="carpet-color-bg">
                  <div style={{ margin: "1em", padding: "0.5px 0" }}>
                    <ValueLabelArray
                      label="Address"
                      values={[
                        user.user?.address ?? "",
                        `${user.user?.city} ${user.user?.state}`,
                      ]}
                    />
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <ValueLabel
                            label="Gender"
                            value={user.user?.gender == "F" ? "Female" : "Male"}
                          />
                        </td>
                        <td>
                          <ValueLabel
                            label="Rating"
                            value={
                              user.user?.ratingAverage?.toString() ??
                              "No Rating"
                            }
                          />
                        </td>
                        <td>
                          <span style={{ cursor: "pointer", float: "right" }}>
                            <IoOpenOutline size={sc.IconSize20} />
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>{" "}
                </div>
                <br />
                <div style={{ margin: "1em" }}>
                  <div style={Object.assign({})}>
                    <ul className="listview image-listview">
                      {user.propertyRequests?.map((item) => (
                        <li key={item._id}>
                          <div className="item">
                            <img
                              src={FileManager.getPropertyMediaPath(
                                item.propertyId
                              )} // Media Id
                              alt="image"
                              className="image"
                            />
                            <div className="in">
                              <div className="text-muted-dark">
                                {item.title}
                                <br />
                                <span className="text-muted">
                                  {item.location.city} {item.location.state}
                                </span>
                              </div>
                            </div>
                          </div>
                          <span
                            className="text-muted"
                            style={{ cursor: "pointer" }}
                            onClick={() => OpenPropertyRequest(item._id)}
                          >
                            <IoArrowForwardOutline size={sc.IconSize15} />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />

      {!dataLoaded && (
        <>
          <br />
          <LoadingTextPlaceHolder />
        </>
      )}
    </>
  );
};
export default CustomerDetails;
