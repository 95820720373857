import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import sc from "../services/stringconst";
import auth from "../services/authservice";
import ImageKit from "imagekit-javascript";
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from "@capacitor/camera";
import AppVer from "../services/apiclient";
import { Client } from "../services/apiclient";
import CustomButton from "./childcomponents/custombutton";

const PhotoVerification = (props) => {
  const navigate = useNavigate();
  let client = new Client();

  const [userPhotoOne, setUserPhotoOne] = useState();

  const [activeView, setActiveView] = useState(1);

  const imagekit = new ImageKit({
    urlEndpoint: sc.imagekitUrlEndPoint,
    publicKey: sc.imagekitPublicKey,
    authenticationEndpoint:
      sc.appbaseroute +
      sc.cloudImageRoute +
      auth.getSessionToken() +
      "/" +
      AppVer.getAppVer(),
  });

  const [httpProcessing, setHttpProcessing] = useState(false);

  const takePhoto = async (step) => {
    // if(!auth.getPlatformIsNative()){
    //     props.ShowAlert('Only applicable on mobile app', sc.warning);
    //     return;
    // }

    if (auth.appWidth() !== "75%" && auth.appWidth() !== "100%") {
      props.ShowAlert("Restricted to only mobile devices", sc.warning);
      return;
    }
    const photo = await Camera.getPhoto({
      source: CameraSource.Camera,
      resultType: CameraResultType.Base64,
      quality: 70,
    });

    if (step === 1) {
      setUserPhotoOne(photo.base64String);
      setActiveView(2);
      setHttpProcessing(false);
      props.ShowAlert("Photo captured", sc.success);
    }
    if (step === 2) {
      uploadPhotos(userPhotoOne, photo.base64String);
    }
  };

  const uploadPhotos = async (photoOne, photoTwo) => {
    setHttpProcessing(true);

    let userPhotoOneUrl = "";
    let userPhotoOneId = "";

    imagekit.upload(
      {
        file: photoOne,
        fileName: sc.getUUId() + "1",
        tags: ["profilePhoto1"],
        extensions: [
          {
            name: "aws-auto-tagging",
            minConfidence: 80,
            maxTags: 10,
          },
        ],
      },
      function (err, result) {
        if (err) {
          setHttpProcessing(false);
        }
        if (result) {
          console.log("Photo one success");
          userPhotoOneUrl = result.url;
          userPhotoOneId = result.fileId;
          imagekit.upload(
            {
              file: photoTwo,
              fileName: sc.getUUId() + "2",
              tags: ["profilePhoto2"],
              extensions: [
                {
                  name: "aws-auto-tagging",
                  minConfidence: 80,
                  maxTags: 10,
                },
              ],
            },
            function (err, result) {
              if (err) {
                setHttpProcessing(false);
              }
              if (result) {
                console.log("Photo two success");

                let data = {
                  imageOneTypeUrl: avatarImageUrls[0],
                  imageOneUrl: userPhotoOneUrl,
                  imageOneId: userPhotoOneId,
                  imageTwoTypeUrl: avatarImageUrls[1],
                  imageTwoUrl: result.url,
                  imageTwoId: result.fileId,
                };
                client.photoverification(data).then(
                  (result) => {
                    auth.updatePhotoVerifyStatus();
                    props.ShowAlert(
                      "Verification submmited for approval",
                      sc.success
                    );

                    setHttpProcessing(false);

                    navigate(sc.profileroute);
                  },
                  (error) => {
                    if (err) {
                      setHttpProcessing(false);
                    }
                    props.ShowAlert(error.response, sc.danger);
                  }
                );
              }
            }
          );
        }
      }
    );
  };

  const [avatarImageUrls, setAvatarImageUrls] = useState([]);

  const generateAvatarImageUrl = () => {
    let urls = [];
    let gender = auth.getUserProfileGender();

    let n = 0;

    while (n < 1 || n > 13) {
      n = Math.random();
      n = Math.ceil(n * 13);
    }

    let m = 0;

    while (m < 1 || m > 13 || m === n) {
      m = Math.random();
      m = Math.ceil(m * 13);
    }

    urls.push(
      `https://loanhub.ng/appimages/verificationavatars/${gender.toLowerCase()}${m}.png`
    );
    urls.push(
      `https://loanhub.ng/appimages/verificationavatars/${gender.toLowerCase()}${n}.png`
    );
    setAvatarImageUrls(urls);
  };

  useEffect(() => {
    if (auth.getPhotoVerified()) {
      navigate(sc.profileroute);
    }
    if (auth.getUserProfileImageUrl() === auth.getDefaultImageUrl()) {
      props.ShowAlert("Upload profile photo before verification", sc.warning);
      navigate(sc.profileroute);
    }

    generateAvatarImageUrl();
  }, []);

  return (
    <>
      {/* <!-- App Header --> */}
      <div
        style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}
        className="appHeader bg-primary-main   bg-primary text-light"
      >
        <div className="left">
          <a
            onClick={() => navigate(sc.profileroute)}
            className="headerButton goBack"
          >
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div>Photo Verification</div>
      </div>

      {/* <!-- * App Header -->

    <!-- App Capsule --> */}
      <div id="appCapsule" className="extra-header-active full-height">
        {activeView === 1 && (
          <>
            <div className="section mt-3 mb-3">
              <div className="card">
                <div className="card-body">
                  <h2 className="card-title">Step 1</h2>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td style={{ width: "100%" }}>
                        <hr style={{ border: "#ccc 2px solid" }} />
                      </td>
                    </tr>
                  </table>
                  Take a selfie of you with your hand positioned as seen in
                  below avatar
                </div>
              </div>
            </div>
            <div className="section text-center">
              <div className="card">
                <div className="card-body pt-3 pb-3">
                  <img
                    src={avatarImageUrls[0]}
                    alt="image"
                    className="imaged w-50 "
                  />
                </div>
              </div>
            </div>

            <div
              className="section mt-3 mb-3"
              style={{ width: "100%", textAlign: "center" }}
            >
              <CustomButton
                httpProcessing={httpProcessing}
                text="Take first selfie"
                onClick={() => takePhoto(1)}
              />
            </div>
          </>
        )}
        {activeView === 2 && (
          <>
            <div className="section mt-3 mb-3">
              <div className="card">
                <div className="card-body">
                  <h2 className="card-title">Step 2</h2>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td style={{ width: "50%" }}>
                        <hr
                          className="text-warning"
                          style={{ border: "2px solid" }}
                        />
                      </td>
                      <td style={{ width: "50%" }}>
                        <hr style={{ border: "#ccc 2px solid" }} />
                      </td>
                    </tr>
                  </table>
                  Take a selfie of you with your hand positioned as seen in
                  below avatar
                </div>
              </div>
            </div>
            <div className="section text-center">
              <div className="card">
                <div className="card-body pt-3 pb-3">
                  <img
                    src={avatarImageUrls[1]}
                    alt="image"
                    className="imaged w-50 "
                  />
                </div>
              </div>
            </div>

            <div
              className="section mt-3 mb-3"
              style={{ width: "100%", textAlign: "center" }}
            >
              <CustomButton
                httpProcessing={httpProcessing}
                text="Take second selfie"
                onClick={() => takePhoto(2)}
              />
            </div>
          </>
        )}
      </div>
      {/* <!-- * App Capsule --> */}
    </>
  );
};
export default PhotoVerification;
