import RealtorDetailDto from "../../models/Realtor/RealtorDetailDto";
import RealtorDto from "../../models/Realtor/RealtorDto";
import BankAccountDto from "../../models/Shared/BankAccountDto";
import BaseResponseDto from "../../models/Shared/baseResponseDto";
import RepresentativeDetailDto from "../../models/User/RepresentativeDetailDto";
import UserViewDto from "../../models/User/UserViewDto";
import HttpClient from "../httpClient";
import v from "../variables";

let baseRoute = "realtor/";

const GetRealtors = async (): Promise<RealtorDto[]> => {
  let route = v.baseUrl + baseRoute + "getrealtors";

  return <RealtorDto[]>await HttpClient.getRequest(route);
};
const GetRealtorDetail = async (
  realtorId: string
): Promise<RealtorDetailDto> => {
  let route = v.baseUrl + baseRoute + "getrealtordetail/" + realtorId;

  return <RealtorDetailDto>await HttpClient.getRequest(route);
};
const GetRealtorContactPersons = async (
  realtorId: string
): Promise<UserViewDto[]> => {
  let route = v.baseUrl + baseRoute + "getrealtorcontactpersons/" + realtorId;

  return <UserViewDto[]>await HttpClient.getRequest(route);
};

const CreateRealtor = async (request: RealtorDto): Promise<RealtorDto> => {
  let route = v.baseUrl + baseRoute + "createrealtor";

  return <RealtorDto>await HttpClient.postRequest(route, request);
};
const AddRealtorBankAccount = async (request: {
  realtorId: string;
  bankAccount: BankAccountDto;
}): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "addrealtorbankaccount";

  return <BaseResponseDto>await HttpClient.postRequest(route, request);
};
const RemoveRealtorBankAccount = async (
  realtorId: string,
  accountNumber: string
): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "removerealtorbankaccount";

  return <BaseResponseDto>await HttpClient.postRequest(route, {
    realtorId: realtorId,
    accountNumber: accountNumber,
  });
};
const AddRealtorContactPerson = async (
  realtorId: string,
  contactPerson: UserViewDto,
  isDefault: boolean,
  isAdmin: boolean
): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "addrealtorcontactperson";

  return <BaseResponseDto>await HttpClient.postRequest(route, {
    realtorId: realtorId,
    contactPerson: contactPerson,
    isDefault: isDefault,
    isAdmin: isAdmin,
  });
};
const RemoveRealtorContactPerson = async (
  realtorId: string,
  accountNumber: string
): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "removerealtorcontactperson";

  return <BaseResponseDto>await HttpClient.postRequest(route, {
    realtorId: realtorId,
    accountNumber: accountNumber,
  });
};

const SetDefaultRealtorContactPerson = async (
  realtorId: string,
  contactPersonId: string,
  isDefault: boolean
): Promise<BaseResponseDto> => {
  let route = v.baseUrl + baseRoute + "setdefaultrealtorcontactperson";

  return <BaseResponseDto>await HttpClient.postRequest(route, {
    realtorId: realtorId,
    contactPersonId: contactPersonId,
    isDefault: isDefault,
  });
};
const getRepresentativeDetails = async (
  representativeId: string
): Promise<RepresentativeDetailDto> => {
  let route =
    v.baseUrl + baseRoute + `getrepresentativedetails/${representativeId}`;

  return <RepresentativeDetailDto>await HttpClient.getRequest(route);
};
const getRepresentative = async (
  representativeId: string
): Promise<RepresentativeDetailDto> => {
  let route = v.baseUrl + baseRoute + `getrepresentative/${representativeId}`;

  return <RepresentativeDetailDto>await HttpClient.getRequest(route);
};

const realtorClient = {
  GetRealtors,
  GetRealtorDetail,
  GetRealtorContactPersons,
  CreateRealtor,
  AddRealtorBankAccount,
  RemoveRealtorBankAccount,
  AddRealtorContactPerson,
  RemoveRealtorContactPerson,
  SetDefaultRealtorContactPerson,
  getRepresentativeDetails,
  getRepresentative,
};

export default realtorClient;
