import RepaymentScheduleDto from "../../models/Loan/RepaymentScheduleDto";
import PropertyCostingDto from "../../models/Property/PropertyCostingDto";
import BankDto from "../../models/Transaction/BankDto";
import PayEquityDto from "../../models/Transaction/PayEquityDto";
import PropertyCostingRequestDto from "../../models/Transaction/PropertyCostingRequestDto";
import PropertyRequestDetailDto from "../../models/Transaction/PropertyRequestDetailDto";
import PropertyRequestDto from "../../models/Transaction/PropertyRequestDto";
import UpdatePropertyRequestDto from "../../models/Transaction/UpdatePropertyRequestDto";
import HttpClient from "../httpClient";
import v from "../variables";

let baseRoute = "transaction/";

const GetPropertyCosting = async (
  request: PropertyCostingRequestDto
): Promise<PropertyCostingDto> => {
  let route = v.baseUrl + baseRoute + "getpropertycosting";

  return <PropertyCostingDto>await HttpClient.postRequest(route, request);
};
const CreatePropertyRequest = async (
  request: PropertyCostingRequestDto
): Promise<PropertyCostingDto> => {
  let route = v.baseUrl + baseRoute + "createpropertyrequest";

  return <PropertyCostingDto>await HttpClient.postRequest(route, request);
};
const UpdatePropertyRequest = async (
  request: UpdatePropertyRequestDto
): Promise<PropertyRequestDto> => {
  let route = v.baseUrl + baseRoute + "updatepropertyrequest";

  return <PropertyRequestDto>await HttpClient.postRequest(route, request);
};
const PayEquity = async (
  request: PayEquityDto
): Promise<PropertyRequestDto> => {
  let route = v.baseUrl + baseRoute + "payequity";

  return <PropertyRequestDto>await HttpClient.postRequest(route, request);
};
const GetBanks = async (): Promise<BankDto[]> => {
  let route = v.baseUrl + baseRoute + "getbanks";

  return <BankDto[]>await HttpClient.getRequest(route);
};
const GetPropertyRequests = async (
  realtorId: string
): Promise<PropertyRequestDto[]> => {
  let route = v.baseUrl + baseRoute + "getpropertyrequests" + realtorId;

  return <PropertyRequestDto[]>await HttpClient.getRequest(route);
};
const GetPropertyRequestDetail = async (
  propertyRequestId: string
): Promise<PropertyRequestDetailDto> => {
  let route =
    v.baseUrl + baseRoute + "GetPropertyRequestDetail" + propertyRequestId;

  return <PropertyRequestDetailDto>await HttpClient.getRequest(route);
};
const GetPayments = async (
  propertyRequestId: string
): Promise<PropertyCostingDto> => {
  let route = v.baseUrl + baseRoute + "getpayments" + propertyRequestId;

  return <PropertyCostingDto>await HttpClient.getRequest(route);
};
const GetPaymentSchedule = async (
  propertyRequestId: string
): Promise<RepaymentScheduleDto[]> => {
  let route = v.baseUrl + baseRoute + "GetPaymentSchedule" + propertyRequestId;

  return <RepaymentScheduleDto[]>await HttpClient.getRequest(route);
};

const transactionClient = {
  GetPropertyCosting,
  CreatePropertyRequest,
  UpdatePropertyRequest,
  PayEquity,
  GetBanks,
  GetPropertyRequests,
  GetPropertyRequestDetail,
  GetPayments,
  GetPaymentSchedule,
};

export default transactionClient;
