import sc from "../services/stringconst";
class util {
  UniqueIdGenerator() {
    let dt = new Date();
    let timeStampIdGenerator = `${dt
      .getUTCFullYear()
      .toString()
      .substring(2, 4)}${(dt.getMonth() + 1).toString().padStart(2, "0")}${dt
      .getDate()
      .toString()
      .padStart(2, "0")}${dt.getHours().toString().padStart(2, "0")}${dt
      .getMinutes()
      .toString()
      .padStart(2, "0")}${dt.getSeconds().toString().padStart(2, "0")}${dt
      .getMilliseconds()
      .toString()
      .padStart(3, "0")}-${this.IdGenerator(4)}`;
    return timeStampIdGenerator;
  }

  IdGenerator(count: number) {
    count += 3;
    let variable = 0x10000;
    if (count > 0) {
      variable = parseInt("0x1".padEnd(count, "0"));
    }
    let uniqueNumber = Math.floor((1 + Math.random()) * variable)
      .toString(16)
      .substring(1);
    return uniqueNumber;
  }

  currencyFormatter(num: number, digits: number) {
    var si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (
      this.commaCurrencyFormatter(
        (num / si[i].value).toFixed(digits).replace(rx, "$1")
      ) + si[i].symbol
    );
  }

  commaCurrencyFormatter(value?: string) {
    if (!value) value = "0";
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(parseInt(value));
  }

  numberRange(start: number, end: number) {
    let data = <number[]>[];
    for (let n = start; n <= end; n++) {
      data.push(n);
    }

    return data;
  }

  toBase64 = (file: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  getcssClassByStatus(status: string, includeOutline: boolean) {
    let base = "badge badge-";

    if (includeOutline) {
      base = "badge badge-outline-";
    }
    if (status === sc.Pending) {
      return base + "warning";
    }
    if (status === sc.Open) {
      return base + "primary";
    }
    if (status === sc.Approved) {
      return base + "info";
    }
    if (status === sc.Disapproved) {
      return base + "danger";
    }
    if (status === sc.Closed) {
      return base + "success";
    }
    if (status === sc.Abandoned) {
      return base + "secondary";
    }
    if (status === sc.Overdue) {
      return base + "danger";
    }

    // view status
    if (status === sc.PaymentSent || status === sc.PartPaymentRaised) {
      return base + "warning";
    }

    if (status === sc.PaymentConfirmed) {
      return base + "success";
    }
    if (status === sc.PaymentRejected) {
      return base + "danger";
    }

    return base;
  }

  normalizeAverageRating(rating: number) {
    let newRating = rating;
    if (rating.toString().indexOf(".") > 0) {
      let ratingString = `${Math.floor(rating).toString()}.5`;
      newRating = parseInt(ratingString);
    }
    return newRating;
  }

  getAge(date: number) {
    let currentDate = new Date(date);
    return Math.floor(
      (currentDate.getTime() - new Date(date).getTime()) / 3.15576e10
    );
  }

  yearDiff(date: any) {
    let dateNow = new Date();
    let dt1 = new Date(date);
    var diff = (dateNow.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    return Math.abs(Math.round(diff / 365.25));
  }
  dateToUnixTimeStamp = (date?: Date) => {
    if (!date) return undefined;
    return Math.floor(date.getTime() / 1000);
  };
  unixTimeStampToDate = (value: number) => {
    let date = new Date(value * 1000);
    return date;
  };

  getDaysOfPeriod(periodType: string, periodValue: number) {
    let days = 1;
    if (periodType === sc.Months) {
      days = 30;
    }
    if (periodType === sc.Weeks) {
      days = 7;
    }
    return days * periodValue;
  }

  formatToYearMonth(months?: number) {
    if (!months) return months;
    if (months < 12) {
      return months + "Month(s)";
    }
    let yearsCount = Math.floor(months / 12);
    let monthsCount = months - yearsCount * 12;

    let response = yearsCount + " year" + (yearsCount > 1 ? "s" : "");
    response +=
      monthsCount > 0
        ? " " + monthsCount + " Month" + (monthsCount > 1 ? "s" : "")
        : "";

    return response;
  }

  validateUsername(value: string) {
    const regex = /^[A-Za-z0-9_.]+$/;
    return regex.test(value);
  }
  validateString(value: string) {
    const regex = /^[A-Za-z]+$/;
    return regex.test(value);
  }
  validateEmail(value: string) {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(value);
  }
  validateNumber(value: string) {
    const regex = /^[0-9]+$/;
    return regex.test(value);
  }

  getHomeRoute(userRole: string | undefined) {
    if (userRole == sc.Admin) return sc.adminhomeroute;
    if (userRole == sc.Realtor) return sc.realtorhomeroute;
    if (userRole == sc.Customer) return sc.homeroute;

    return sc.homeroute;
  }
}

export default new util();
